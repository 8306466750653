import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCanvaById, handleAddCanva } from "../../../Redux/AppsSlice";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import { MdSave } from "react-icons/md";
import { GoPencil } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import toast from "react-hot-toast";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Thumbs,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

export const CanvaDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { user, userDetails, token } = useSelector((state) => state.root.auth);
  const [edited, setEdited] = useState(false);
  const authToken = `Bearer ${token}`;
  const [showPreview, setShowPreview] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [instanceName, setInstanceName] = useState(
    moment().format("YYYY-MM-DD hh:mm A")
  );
  const [loading, setLoading] = useState(false);
  const [canva, setCanva] = useState({
    Url: "",
    Count: 5,
    TimeItemDuration: 10,
  });
  const regex = /^https:\/\/www\.canva\.com\/design/;
  const embedUrl = () => {
    return canva?.Url?.includes("embed") ? canva?.Url : `${canva?.Url}?embed`;
  };

  const finalUrl = embedUrl();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setCanva((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOnSaveInstanceName = () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    setEdited(false);
  };
  const onSubmit = async () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    if (!regex.test(finalUrl)) {
      return toast.error(
        "Invalid URL. Please enter a valid www.canva.com embed URL."
      );
    }
    let Payload = {
      canvaAppId: id ? id : 0,
      instanceName: instanceName,
      itemDuration: canva?.TimeItemDuration,
      count: canva?.Count,
      url: canva?.Url,
    };
    toast.loading("Saving ...");
    setSaveLoading(true);
    try {
      await dispatch(handleAddCanva(Payload)).then((res) => {
        toast.remove();
        setSaveLoading(false);
        navigate("/Canva");
      });
    } catch (error) {
      console.log("error :>> ", error);
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getCanvaById(id))
      .then((response) => {
        if (response?.payload?.status === 200) {
          const data = response?.payload?.data?.list;
          setCanva((pre) => ({
            Count: data?.count,
            Url: data?.url,
            TimeItemDuration: data?.itemDuration,
          }));
          setInstanceName(data?.instanceName);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id, dispatch]);

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
          user?.userDetails?.isRetailer === false &&
          !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div className="lg:flex lg:justify-between sm:block my-4 items-center">
              <div className="flex items-center">
                {edited ? (
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      className="w-full border border-primary rounded-md px-2 py-1"
                      placeholder="Enter Instance name"
                      value={instanceName}
                      onChange={(e) => {
                        setInstanceName(e.target.value);
                      }}
                    />
                    <MdSave
                      onClick={() => handleOnSaveInstanceName()}
                      className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                      {instanceName}
                    </h1>
                    <button onClick={() => setEdited(true)}>
                      <GoPencil className="ml-4 text-lg" />
                    </button>
                  </div>
                )}
              </div>
              <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                <button
                  className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                  onClick={() => {
                    if (canva?.Count && canva?.Url && canva?.TimeItemDuration)
                      return setShowPreview(!showPreview);
                    toast.error("Please Enter All Value");
                  }}
                >
                  {showPreview ? "Edit" : "Preview"}
                </button>
                <button
                  className="flex  align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                  onClick={() => {
                    if (canva?.Count && canva?.Url && canva?.TimeItemDuration)
                      return onSubmit();
                    toast.error("Please Enter All Value");
                  }}
                  disabled={saveLoading}
                >
                  {saveLoading ? "Saving..." : "Save"}
                </button>
                <Link to="/Canva">
                  <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                    <AiOutlineClose />
                  </button>
                </Link>
              </div>
            </div>

            {showPreview ? (
              regex.test(finalUrl) ? (
                <div className="w-full h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center mt-5">
                  <Swiper
                    centeredSlides={true}
                    autoplay={{
                      delay: canva?.TimeItemDuration * 1000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="mySwiper h-full w-full"
                  >
                    {[...Array(parseInt(canva?.Count || 1))].map((_, i) => (
                      <SwiperSlide key={i}>
                        <div className="relative w-[100%] h-[34rem] pb-0 shadow-md overflow-hidden">
                          <iframe
                            loading="lazy"
                            className="absolute p-1 w-full h-full"
                            src={`${finalUrl}#${i + 1}`}
                            allowFullScreen
                            allow="fullscreen"
                          ></iframe>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              ) : (
                <div className="w-full h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center mt-5">
                  <p className="text-gray-500 text-2xl font-semibold">
                    No data. Please check the URL.
                  </p>
                </div>
              )
            ) : (
              <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="message"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Canva smart embed URL*
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      name="Url"
                      placeholder="https://www.canva.com/design/DAGPsGaBqeM/view"
                      value={canva?.Url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Slide duration (seconds)
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="number"
                      name="TimeItemDuration"
                      placeholder="Item duration (in seconds)"
                      value={canva?.TimeItemDuration}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="message"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Slide count
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="number"
                      name="Count"
                      placeholder="Count"
                      value={canva?.Count}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                  <div className="w-full h-[26rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none">
                    {canva?.Url ? (
                      regex.test(finalUrl) ? (
                        loading ? (
                          ""
                        ) : (
                          <div className="md:w-[576px] md:h-[324px] sm:w-[384px] sm:h-[216px] lg:w-[860px] lg:h-[404px] flex items-center justify-center overflow-hidden scrollbar-hide ">
                            <Swiper
                              centeredSlides={true}
                              autoplay={{
                                delay: canva?.TimeItemDuration * 1000,
                                disableOnInteraction: false,
                              }}
                              modules={[Autoplay]}
                              className="mySwiper h-full w-full"
                            >
                              {[...Array(parseInt(canva?.Count || 1))].map(
                                (_, i) => {
                                  return (
                                    <SwiperSlide key={i}>
                                      <div className="text-center w-full h-full">
                                        <div className="relative w-[100%] h-[25rem] pb-0 shadow-md overflow-hidden ">
                                          <iframe
                                            loading="lazy"
                                            className="absolute p-1 w-full h-full"
                                            src={`${finalUrl}#${i + 1}`}
                                            allowFullScreen
                                            allow="fullscreen"
                                          ></iframe>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                }
                              )}
                            </Swiper>
                          </div>
                        )
                      ) : (
                        <div className="w-full h-full flex items-center justify-center text-center">
                          <p className="text-gray-500 text-xl font-semibold">
                            No data. Please check the URL.
                          </p>
                        </div>
                      )
                    ) : (
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-16 w-16 mx-auto text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M3 7V3h18v4M4 10h16v4H4v-4z"
                          />
                        </svg>
                        <p className="text-gray-500">App instance preview</p>
                        <p className="text-sm text-gray-400">
                          Please edit the app settings to get started
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
