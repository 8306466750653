import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';

const AddEditManageUserType = ({ toggleModal, heading, isActive, setIsActive, HandleSubmit, userType, setUserType, error, setError, disable }) => {

    return (
        <>
            <div
                id="default-modal"
                tabIndex="-1"
                aria-hidden="true"
                className="fixed h-full top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 max-h-full bg-black bg-opacity-50"
            >
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="relative p-4 lg:w-[500px] md:w-[500px] sm:w-full max-h-full">
                            {/* Modal content */}
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                {/* Modal header */}
                                <div className="flex items-center justify-between p-3 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        {heading} User Type
                                    </h3>
                                    <AiOutlineCloseCircle
                                        className="text-4xl text-primary cursor-pointer"
                                        onClick={() => {
                                            toggleModal();
                                        }}
                                    />
                                </div>
                                {/* Modal body */}
                                <div className="p-6 max-h-96 vertical-scroll-inner">
                                    <div className="space-y-3 md:space-y-5">
                                        <div className="flex flex-col">
                                            <div className="relative w-full">
                                                <input
                                                    type="text"
                                                    name="Name"
                                                    id="Name"
                                                    placeholder="Enter User Type"
                                                    className="formInput mb-2"
                                                    value={userType}
                                                    onChange={(e) => {
                                                        if (e.target.value === "") {
                                                            setError(true)
                                                        } else {
                                                            setError(false)
                                                        }
                                                        setUserType(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            {error && (
                                                <span className='error mb-1'>This field is required.</span>
                                            )}
                                            <div className='flex items-center py-2'>
                                                <label>Is Active</label>
                                                <input
                                                    className="border border-primary ml-8 rounded h-6 w-6 cursor-pointer"
                                                    type="checkbox"
                                                    checked={isActive}
                                                    onChange={() => setIsActive(!isActive)}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center px-4 pt-4 border-t border-gray-200 rounded-b dark:border-gray-600 gap-2">
                                            <button
                                                type="button"
                                                className="bg-white text-primary text-base px-6 py-3 border border-primary  shadow-md rounded-full hover:bg-primary hover:text-white mr-2"
                                                onClick={() => toggleModal()}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="bg-primary text-white text-base px-8 py-3 border border-primary shadow-md rounded-full "
                                                onClick={() => HandleSubmit()}
                                                disabled={disable}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEditManageUserType
