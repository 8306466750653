import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getLiveNewsById, handleAddLiveNews } from "../../../Redux/AppsSlice";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import { MdSave } from "react-icons/md";
import { GoPencil } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import toast from "react-hot-toast";
import ReactPlayer from "react-player";

export const LiveNewsDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user, userDetails, token } = useSelector((state) => state.root.auth);

  const [edited, setEdited] = useState(false);
  const authToken = `Bearer ${token}`;
  const [showPreview, setShowPreview] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [instanceName, setInstanceName] = useState(
    moment().format("YYYY-MM-DD hh:mm A")
  );
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(false);
  const [liveNews, setLiveNews] = useState({
    NewsSource: "1",
    Mute: false,
    Subtitle: false,
  });

  const SelectNews = useMemo(() => {
    switch (liveNews.NewsSource) {
      case "1":
        return "https://www.youtube.com/live/pykpO5kQJ98?si=8L28TmGGXKT2JSns";

      case "2":
        return "https://www.youtube.com/live/oJUvTVdTMyY?si=adbYWATnnIOoP6EV";

      case "3":
        return "https://www.youtube.com/live/gCNeDWCI0vo?si=M79oxtQJPVVykzbv";

      case "4":
        return "https://www.youtube.com/live/Ap-UM1O9RBU?si=OY3zYQIeNaHEEg-q";

      case "5":
        return "https://www.youtube.com/live/OxQQsIvJTTU?si=XCRJxqPo7br3u6hj";
      case "6":
        return "https://www.youtube.com/live/vOTiJkg1voo?si=3FJkPBjU703R9CLq";
    }
  }, [liveNews]);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setLiveNews((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleMuteChange = (e) => {
    const { name, checked } = e.target;
    setLiveNews((pre) => ({
      ...pre,
      [name]: checked,
    }));
  };
  const handleSubtitleChange = (e) => {
    const { name, checked } = e.target;
    setLiveNews((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };
  const handleOnSaveInstanceName = () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    setEdited(false);
  };

  const onSubmit = async () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    let Payload = {
      liveNewsAppID: id ? id : 0,
      instanceName: instanceName,
      isToggleSubtitles: liveNews?.Subtitle,
      isMute: liveNews?.Mute,
      newsSource: liveNews?.NewsSource,
    };
    toast.loading("Saving ...");
    setSaveLoading(true);
    try {
      await dispatch(handleAddLiveNews(Payload)).then((res) => {
        toast.remove();
        setSaveLoading(false);
        navigate("/Live-News");
      });
    } catch (error) {
      console.log("error :>> ", error);
      setSaveLoading(false);
    }
  };
  useEffect(() => {
    if(id){
      dispatch(getLiveNewsById(id))
        .then((response) => {
          if (response?.payload?.status === 200) {
            const data = response?.payload?.data?.list;
            setLiveNews((pre) => ({
              NewsSource: data?.newsSource,
              Mute: data?.isMute,
              Subtitle: data?.isToggleSubtitles,
            }));
            setInstanceName(data?.instanceName);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [id, dispatch]);
  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
          user?.userDetails?.isRetailer === false &&
          !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div className="lg:flex lg:justify-between sm:block my-4 items-center">
              <div className="flex items-center">
                {edited ? (
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      className="w-full border border-primary rounded-md px-2 py-1"
                      placeholder="Enter Instance name"
                      value={instanceName}
                      onChange={(e) => {
                        setInstanceName(e.target.value);
                      }}
                    />
                    <MdSave
                      onClick={() => handleOnSaveInstanceName()}
                      className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                      {instanceName}
                    </h1>
                    <button onClick={() => setEdited(true)}>
                      <GoPencil className="ml-4 text-lg" />
                    </button>
                  </div>
                )}
              </div>
              <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                <button
                  className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                  onClick={() => {
                    if (liveNews.NewsSource)
                      return setShowPreview(!showPreview);
                    toast.error("Please Enter All Value");
                  }}
                >
                  {showPreview ? "Edit" : "Preview"}
                </button>
                <button
                  className="flex  align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                  onClick={() => {
                    if (liveNews.NewsSource) return onSubmit();
                    toast.error("Please Enter All Value");
                  }}
                  disabled={saveLoading}
                >
                  {saveLoading ? "Saving..." : "Save"}
                </button>
                <Link to="/Live-News">
                  <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                    <AiOutlineClose />
                  </button>
                </Link>
              </div>
            </div>

            {showPreview ? (
              <div className="w-full h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center mt-5">
                <ReactPlayer
                  url={SelectNews}
                  className="w-full relative z-20 videoinner"
                  width={"100%"}
                  height={"100%"}
                  muted={liveNews.Mute}
                  controls={true}
                  config={{
                    youtube: {
                      playerVars: {
                        cc_load_policy: liveNews?.Subtitle ? 1 : 0,
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="message"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      News source*
                    </label>
                    <select
                      id="countries"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      defaultValue={""}
                      name="NewsSource"
                      value={liveNews.NewsSource}
                      onChange={handleChange}
                    >
                      <option value="1">Euronews (English)</option>
                      <option value="2">Sky News (UK / International)</option>
                      <option value="3">Al Jazeera (Middle East - English)</option>
                      <option value="4">FRANCE 24 (French)</option>
                      <option value="5">KBS World (Korean)</option>
                      <option value="6">ABC News (Australia)</option>
                    </select>
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Mute videos
                    </label>
                    <div className="text-right">
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          readOnly
                          name="Mute"
                          checked={liveNews?.Mute}
                          onChange={handleMuteChange}
                        />
                        <div
                          className={`w-11 h-6 ${
                            liveNews?.Mute ? "bg-SlateBlue" : "bg-gray-300"
                          } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                        ></div>
                      </label>
                    </div>
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Toggle subtitles
                    </label>
                    <div className="text-right">
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          name="Subtitle"
                          checked={liveNews?.Subtitle}
                          onChange={handleSubtitleChange}
                        />
                        <div
                          className={`w-11 h-6 ${
                            liveNews?.Subtitle ? "bg-SlateBlue" : "bg-gray-300"
                          } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                        ></div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                  <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none">
                    {liveNews.NewsSource ? (
                      loading ? (
                        ""
                      ) : (
                        <div className="md:w-[576px] md:h-[324px] sm:w-[384px] sm:h-[216px] lg:w-[860px] lg:h-[440px] w-72 h-72 flex items-center justify-center overflow-hidden scrollbar-hide ">
                          <ReactPlayer
                            url={SelectNews}
                            className="w-full relative z-20 videoinner"
                            width={"100%"}
                            height={"100%"}
                            muted={liveNews.Mute}
                            controls={true}
                            config={{
                              youtube: {
                                playerVars: {
                                  cc_load_policy: liveNews?.Subtitle ? 1 : 0,
                                },
                              },
                            }}
                          />
                        </div>
                      )
                    ) : (
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-16 w-16 mx-auto text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M3 7V3h18v4M4 10h16v4H4v-4z"
                          />
                        </svg>
                        <p className="text-gray-500">App instance preview</p>
                        <p className="text-sm text-gray-400">
                          Please edit the app settings to get started
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
