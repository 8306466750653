import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

export const WallsIoPreview = ({
  setInstanceView,
  instanceName,
  showTags,
  screenAssignName,
  wallsIoDataById,
}) => {
  return (
    <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
      <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-[1000px] h-[600px]">
        <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
          <div className="flex items-center">
            <div>
              <img
                src="https://back.disploy.com/app/walls-io-icon.svg"
                alt="Logo"
                className="cursor-pointer mx-auto h-10 w-10"
              />
            </div>
            <div className="ml-3">
              <h4 className="text-lg font-medium whitespace-wrap ">
                {instanceName}
              </h4>
            </div>
          </div>
          <button
            className="p-1 text-3xl"
            onClick={() => setInstanceView(false)}
          >
            <AiOutlineCloseCircle />
          </button>
        </div>
        <div className="w-full h-full bg-black">
        <iframe
          id="wallsio-iframe"
          src={`${wallsIoDataById?.url}colorscheme=dark&layout=${
            wallsIoDataById?.theme
          }&show_header=${
            wallsIoDataById?.showHeader ? 1 : 0
          }&show_post_info=1&accessibility=0&autoscroll=${
            wallsIoDataById?.autoScroll ? 1 : 0
          }`}
          style={{ height: "100%", width: "100%" }}
          loading="lazy"
          title="Walls.io Feed"
        ></iframe>
        </div>
        <div className="py-2 px-6">
          <div className="flex items-center gap-2 w-full">
            <div className="font-semibold w-fit">Tags:-</div>
            <div className=" w-full">{showTags}</div>
          </div>
          <div>
            <label className="font-semibold">Screen Assign :</label>
            {screenAssignName ? screenAssignName : " No Screen"}
          </div>
        </div>
      </div>
    </div>
  );
};
