import React, { useState } from "react";
import ThemePreview from "../ThemePreview";
import { AiOutlineCloseCircle } from "react-icons/ai";


export default function NoticeBoard_Theme({ handletoggle, PosTheme, setSelectedTheme, selectedtheme, onSubmit }) {

    const [viewerSrc, setViewerSrc] = useState("");
    const [openmodel, setOpenModel] = useState(false);

    const handleChange = (item) => {
        if (item !== 0) {
            setViewerSrc(item?.posThemePath);
            setSelectedTheme(item?.posThemeID);
            setOpenModel(true);
        } else {
            setSelectedTheme(0);
            setOpenModel(true);
        }

    };

    const HandleClose = () => {
        setOpenModel(!openmodel);
    };

    return (
        <>
            <div
                id="default-modal"
                tabIndex="-1"
                aria-hidden="true"
                className="fixed top-0 right-0 left-0 z-9990 flex justify-center items-center w-full h-full m-0 md:inset-0 max-h-full bg-black bg-opacity-50"
            >
                <div className="relative p-4 w-full max-w-4xl max-h-full">
                    {/* Modal content */}
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* Modal header */}
                        <div className="flex items-center justify-between p-3 md:p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Select Theme
                            </h3>
                            <AiOutlineCloseCircle
                                className="text-4xl text-primary cursor-pointer"
                                onClick={() => {
                                    handletoggle()
                                }}
                            />
                        </div>
                        <div className="p-6">
                            <div className="flex justify-between m-3 items-center">
                                <span className="w-2/5">Signage theme</span>
                                <div className="p-3 border rounded-lg border-gray w-3/5">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">

                                        <div
                                            key={""}
                                            className={`p-2 flex flex-col gap-2 cursor-pointer ${selectedtheme === 0
                                                ? "border-2 rounded-lg p-2 border-blue-500"
                                                : ""
                                                }`}
                                            onClick={() => handleChange(0)}
                                        >
                                            <div className="flex justify-center w-12 h-12 rounded-md">
                                                {/* <img
                            src={item?.posThemePath}
                            className="w-12 h-12 rounded-md"
                            alt="Dignage"
                          /> */}
                                            </div>
                                            <div className="color-name text-sm text-center">
                                                Signage theme
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between m-3 items-center">
                                <span className="w-2/5">Special Themes</span>
                                <div className="p-3 border rounded-lg border-gray w-3/5">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                                        {PosTheme?.map((item) => (
                                            <div
                                                key={item?.posThemeID}
                                                className={`p-2 flex flex-col gap-2 cursor-pointer ${selectedtheme === item?.posThemeID
                                                    ? "border-2 rounded-lg p-2 border-blue-500"
                                                    : ""
                                                    }`}
                                                onClick={() => handleChange(item)}
                                            >
                                                <div className="flex justify-center">
                                                    <img
                                                        src={item?.posThemePath}
                                                        className="w-12 h-12 rounded-md"
                                                        alt={item?.posThemeName}
                                                    />
                                                </div>
                                                <div className="color-name text-sm text-center">
                                                    {item?.posThemeName}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center justify-center gap-2 border-t border-black">
                                {/* <button
                  type="button"
                  className="w-40 text-[#FFFFFF] bg-SlateBlue not-italic font-medium rounded-full py-3.5 text-center text-base mt-4 hover:bg-primary border border-SlateBlue hover:border-white"
                  onClick={() => toggleModal()}
                >
                  Cancel
                </button> */}
                                <button
                                    className="w-40 text-[#FFFFFF] bg-SlateBlue not-italic font-medium rounded-full py-3.5 text-center text-base mt-4 hover:bg-primary border border-SlateBlue hover:border-white"
                                    onClick={() => onSubmit()}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openmodel && (
                <ThemePreview viewerSrc={viewerSrc} HandleClose={HandleClose} />
            )}
        </>
    );
};

