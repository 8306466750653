/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { MdSave } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { getNoticeBoardById, handleAddNoticeBoardApps } from "../../../Redux/NoticeBoardSlice";
import { GET_ALL_FILES, POS_THEME } from "../../../Pages/Api";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import DigitalMenuAssets from "../DigitalMenuAssets";
import NoticeBoard_Icons from "./NoticeBoard_Icons";
import NoticeBoard_Background from "./NoticeBoard_Background";
import NoticeBoard_Theme from "./NoticeBoard_Theme";
import { handleAllPosTheme } from "../../../Redux/CommonSlice";


export default function NoticeBoardDetails({ sidebarOpen, setSidebarOpen }) {
    const currentDate = new Date();
    const { id } = useParams();
    const history = useNavigate();
    const { user, token, userDetails } = useSelector((state) => state.root.auth);
    const authToken = `Bearer ${token}`;
    const dispatch = useDispatch();
    const [instanceName, setInstanceName] = useState(moment(currentDate).format("YYYY-MM-DD hh:mm"));
    const [edited, setEdited] = useState(false);
    const [NoticeBoard, setNoticeBoard] = useState({
        align: "left",
        content: "",
    });
    const [showTheme, setshowTheme] = useState(false);
    const [posTheme, setPosTheme] = useState([]);
    const [selectedtheme, setSelectedTheme] = useState(0);
    const [theme, setTheme] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [assetPreviewPopup, setAssetPreviewPopup] = useState(null);
    const [assetPreview, setAssetPreview] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(0);
    const [background, setbackground] = useState(0);
    const [showPreview, setShowPreview] = useState(false);
    const [openbackgroudModel, setOpenbackgroudModel] = useState(false);
    const [whitetext, setWhitetext] = useState(false);
    const [finalwhitetext, setFinalwhitetext] = useState(false);
    const [darkOverlay, setDarkOverlay] = useState(false);
    const [finaldarkOverlay, setFinaldarkOverlay] = useState(false);
    const [iconsModel, setIconsModel] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);


    useEffect(() => {
        if (id) {
            dispatch(getNoticeBoardById(id)).then((res) => {
                if (res?.payload?.status === 200) {
                    const noticeData = res?.payload?.data;
                    setNoticeBoard((prev) => ({
                        ...noticeData,
                        content: noticeData?.content,
                        align: noticeData?.alignment,
                    }));

                    if (noticeData?.backGroundImage_assetID !== 0) {
                        axios
                            .get(GET_ALL_FILES, { headers: { Authorization: authToken } })
                            .then((res) => {
                                const fileData = res?.data;
                                let alldata = [...fileData?.image, ...fileData?.onlineimages];

                                const filterdata = alldata?.find(
                                    (item) =>
                                        item.assetID === noticeData?.backGroundImage_assetID // compare with `noticeData`
                                );

                                setbackground(filterdata?.assetFolderPath);
                                setSelectedAsset(filterdata);
                            });
                    }
                    setDarkOverlay(noticeData?.isDark);
                    setFinaldarkOverlay(noticeData?.isDark);
                    setWhitetext(noticeData?.isWhiteText);
                    setFinalwhitetext(noticeData?.isWhiteText);
                    setTheme(noticeData?.theme);
                    setInstanceName(noticeData?.instanceName);
                }
            })
        }
    }, []);

    useEffect(() => {
        const config = {
            method: "get",
            maxBodyLength: Infinity,
            url: POS_THEME,
            headers: {
                Authorization: authToken,
            },
        };

        dispatch(handleAllPosTheme({ config }))
            .then((res) => {
                setPosTheme(res?.payload?.data);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, []);

    const handleOnSaveInstanceName = (e) => {
        if (!instanceName.replace(/\s/g, "").length) {
            toast.remove();
            return toast.error("Please enter at least minimum 1 character.");
        }
        setEdited(false);
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNoticeBoard((Pre) => ({
            ...Pre,
            [name]: value,
        }));
    };

    const handletoggle = () => {
        setshowTheme(!showTheme);
    };

    const onSubmit = () => {
        setshowTheme(false);
        if (selectedtheme !== 0) {
            const matchedTheme = posTheme?.find(
                (item) => Number(item?.posThemeID) === Number(selectedtheme)
            );
            setTheme(matchedTheme?.posThemePath);
            setbackground(0);
            setSelectedAsset(0);
        } else {
            setTheme(0);
        }
    };

    const handleAssetAdd = (asset) => {
        setSelectedAsset(asset);
        setAssetPreview(asset);
    };
    const HandleSubmitAsset = () => {
        setOpenModal(false);
        setTheme({});
    };

    const Uploadsetbackgroundimg = () => {
        if (selectedAsset?.assetFolderPath) {
            setbackground(selectedAsset.assetFolderPath);
            setTheme(0);
            setSelectedTheme(0);
        } else {
            console.warn("No asset path selected!");
        }
        setOpenbackgroudModel(false);
        setFinalwhitetext(whitetext);
        setFinaldarkOverlay(darkOverlay);
    };

    const BackGroundModelClose = () => {
        setOpenbackgroudModel(false);
        setDarkOverlay(finaldarkOverlay);
        setWhitetext(finalwhitetext);
        setSelectedAsset(background);
    };

    const handelIconsSave = () => {
        setIconsModel(false);
    };
    const RemoveIconsModel = () => {
        setIconsModel(false);
    };

    const handleInsertNoticeBoard = async () => {
        try {
            setSaveLoading(true);
            const data = {
                noticeBoardAppId: id || 0,
                instanceName: instanceName,
                content: NoticeBoard?.content,
                alignment: NoticeBoard?.align,
                icon: NoticeBoard?.icon,
                backGroundImage_assetID: selectedAsset?.assetID,
                isDark: finaldarkOverlay,
                isWhiteText: finalwhitetext,
                theme: theme,
                tags: NoticeBoard?.tags
            }

            const response = await dispatch(handleAddNoticeBoardApps(data))
            if (response?.payload?.status === 200) {
                toast.success(
                    id
                        ? "NoticeBoard updated successfully!"
                        : "NoticeBoard added successfully!"
                );
                if (window.history.length === 1) {
                    localStorage.setItem("isWindowClosed", "true");
                    window.close();
                } else {
                    history("/NoticeBoard");
                }
            } else {
                console.error("Unexpected response:", response);
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setSaveLoading(false);
        }
    };


    return (
        <>
            <div className="flex border-b border-gray">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <Navbar />
            </div>
            <div
                className={
                    userDetails?.isTrial &&
                        user?.userDetails?.isRetailer === false &&
                        !userDetails?.isActivePlan
                        ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
                        : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
                }
            >
                <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
                    <div className="px-6 page-contain">
                        <div>
                            <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                                <div className="flex items-center">
                                    {edited ? (
                                        <div className="flex items-center gap-2">
                                            <input
                                                type="text"
                                                className="w-full border border-primary rounded-md px-2 py-1"
                                                placeholder="Enter schedule name"
                                                value={instanceName}
                                                onChange={(e) => {
                                                    setInstanceName(e.target.value);
                                                }}
                                            />
                                            <MdSave
                                                onClick={() => handleOnSaveInstanceName()}
                                                className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                                                {instanceName}
                                            </h1>
                                            <button onClick={() => setEdited(true)}>
                                                <GoPencil className="ml-4 text-lg" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                                    <button
                                        className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                        onClick={() => {
                                            setShowPreview(!showPreview);
                                        }}
                                    >
                                        {showPreview ? "Edit" : "Preview"}
                                    </button>
                                    <button
                                        className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                        onClick={() => {
                                            if (NoticeBoard?.content !== "")
                                                return handleInsertNoticeBoard();
                                            toast.error("Please Feel Details");
                                        }}
                                        disabled={saveLoading}
                                    >
                                        {saveLoading ? "Saving..." : "Save"}
                                    </button>

                                    <Link to="/NoticeBoard">
                                        <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                                            <AiOutlineClose />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            {showPreview && (
                                <div className=" w-[85%] h-[35rem]  flex flex-col lg:flex-row bg-white shadow-lg rounded-lg h-full mx-auto mt-5">
                                    <div className="w-[100%] h-[35rem] bg-gray-50 rounded-sm shadow-md border-4  border-black flex items-center justify-center">
                                        <div
                                            className="w-full h-full flex items-center justify-center NoticeBoard relative"
                                            style={{
                                                backgroundImage: `url(${background === 0 ? theme : background})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                            }}
                                        >
                                            {finaldarkOverlay && (
                                                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                                            )}
                                            <textarea
                                                className={`w-[80%] h-[80%] relative test ${finalwhitetext ? "text-white" : "text-black"
                                                    } text-6xl px-4 py-2 font-medium resize-none outline-none text-${NoticeBoard?.align
                                                    } transition duration-300 bg-transparent bg-opacity-0 focus:bg-opacity-0 scrollbar-hide`}
                                                value={NoticeBoard.content}
                                                onChange={handleInputChange}
                                                name="content"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!showPreview && (
                                <div
                                    className="flex flex-col lg:flex-row shadow-lg rounded-lg h-full "
                                    style={{ backgroundColor: "#e8eff2" }}
                                >
                                    <div className="w-full lg:w-2/6 pr-0 lg:pr-4 p-5  ">
                                        <div className="mb-4 flex items-center justify-center">
                                            <div className="w-[18rem]">
                                                <h1 className="text-4xl mb-4 text-[#234c64] font-medium ">
                                                    Noticeboard 2.0
                                                </h1>
                                                <p className="text-[#234c64] font-medium">
                                                    Type in as much text as you need in the box to the
                                                    left - the app will dynamically scale it to fit.
                                                    Format your text by selecting it.
                                                </p>
                                            </div>
                                        </div>
                                        {(theme === 0 || theme === '0') ? (
                                            <>
                                                <div className="mb-4 flex items-center justify-center">
                                                    <div className="w-full flex justify-center items-center ">
                                                        <label className="text-[#234c64] font-medium">Text Align :</label>
                                                        <select
                                                            className="p-1 border  max-w-xs rounded ml-3 text-[#234c64] font-medium"
                                                            style={{ backgroundColor: "#e8eff2" }}
                                                            value={NoticeBoard?.align}
                                                            name="align"
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="left">Left</option>
                                                            <option value="center">Center</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className=" flex items-center justify-center mb-11">
                                                    <div className="">
                                                        <button
                                                            // onClick={() => setIconsModel(true)}
                                                            className="px-4 py-2 mr-4 text-gray-700 border rounded text-[#234c64] font-semibold hover:bg-bg-slate"
                                                        >
                                                            Icons
                                                        </button>
                                                    </div>
                                                    <div className="">
                                                        <button
                                                            onClick={() => setOpenbackgroudModel(true)}
                                                            className="px-4 py-2 text-gray-700 border rounded text-[#234c64] font-semibold"
                                                        >
                                                            Background
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="mb-4 flex items-center justify-center">
                                                <p className="text-[#a9b5be] font-semibold font-[2rem]" >This theme has no further options.</p>
                                            </div>
                                        )}

                                        <div className="mt-auto w-full flex justify-center">
                                            <button
                                                className="p-2 border w-[18rem] max-w-xs rounded text-[#234c64] font-semibold"
                                                onClick={() => handletoggle()}
                                            >
                                                Change Theme
                                            </button>
                                        </div>
                                    </div>

                                    <div className="w-full lg:w-3/4 h-[35rem] flex items-center justify-center bg-gray-100 mt-4 lg:mt-0  p-10">
                                        <div className="h-full w-full p-10 bg-gray-50 rounded-sm shadow-lg  flex items-center justify-center "
                                            style={{
                                                backgroundImage: `url(${background === 0 ? theme : background})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                            }}
                                        >
                                            {/* w-[85%] h-[25rem] */}
                                            <div
                                                className="w-full h-full flex items-center justify-center NoticeBoard relative  "
                                            // style={{
                                            //     backgroundImage: `url(${background === 0 ? theme : background})`,
                                            //     backgroundSize: "cover",
                                            //     backgroundPosition: "center",
                                            // }}
                                            >
                                                {finaldarkOverlay && (
                                                    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                                                )}
                                                <textarea
                                                    className={`w-full h-full relative test ${finalwhitetext ? "text-white" : "text-black"} text-6xl  font-medium resize-none outline-none text-${NoticeBoard?.align} transition duration-300 bg-transparent bg-opacity-0 focus:bg-opacity-0 scrollbar-hide focus:border-dashed focus:border-2  focus:border-white p-0`}
                                                    // px-4 py-2
                                                    value={NoticeBoard.content}
                                                    onChange={handleInputChange}
                                                    name="content"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showTheme && (
                <NoticeBoard_Theme
                    handletoggle={handletoggle}
                    PosTheme={posTheme}
                    setSelectedTheme={setSelectedTheme}
                    selectedtheme={selectedtheme}
                    onSubmit={onSubmit}
                />
            )}
            {openModal && (
                <DigitalMenuAssets
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    setAssetPreviewPopup={setAssetPreviewPopup}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    handleAssetAdd={handleAssetAdd}
                    assetPreviewPopup={assetPreviewPopup}
                    assetPreview={assetPreview}
                    HandleSubmitAsset={HandleSubmitAsset}
                />
            )}
            {openbackgroudModel && (
                <NoticeBoard_Background
                    setOpenModal={setOpenModal}
                    openModal={openModal}
                    Uploadsetbackgroundimg={Uploadsetbackgroundimg}
                    setWhitetext={setWhitetext}
                    whitetext={whitetext}
                    setDarkOverlay={setDarkOverlay}
                    darkOverlay={darkOverlay}
                    BackGroundModelClose={BackGroundModelClose}
                    setbackground={setbackground}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                />
            )}
            {iconsModel && (
                <NoticeBoard_Icons
                    handelIconsSave={handelIconsSave}
                    RemoveIconsModel={RemoveIconsModel}
                />
            )}
        </>
    );
};



