import {
  Routes,
  Route,
  Navigate,
  BrowserRouter,
  useParams,
} from "react-router-dom";
import Screens from "../Components/Screen/Screens";
import NewScreenGroup from "../Components/Screen/SubScreens/NewScreenGroup";
import Screensplayer from "../Components/Screen/SubScreens/Screensplayer";
import { useState, useEffect, useCallback } from "react";
import MergeScreen from "../Components/Screen/SubScreens/MergeScreen";
import AddMergeScreen from "../Components/Screen/SubScreens/AddMergeScreen";
import NewScreenDetail from "../Components/Screen/SubScreens/NewScreenDetail";
import Assets from "../Components/Assests/Assets";
import Apps from "../Components/Apps/Apps";
import DisployStudio from "../Components/DisployStudio/DisployStudio";
import Report from "../Components/Reports/Report";
import EditUser from "../Pages/EditUser";
import Mediareport from "../Components/Reports/Mediareport";
import Uptimereport from "../Components/Reports/Uptimereport";
import Settings from "../Components/Settings/Settings";
import MySchedule from "../Components/Schedule/MySchedule";
import AddSchedule from "../Components/Schedule/AddSchedule";
import WeatherSchedule from "../Components/Schedule/WeatherSchedule";
import SaveAssignScreenModal from "../Components/Schedule/SaveAssignScreenModal";
import Approval from "../Components/Approval/Approval";
import FileUpload from "../Components/Assests/FileUpload";
import Auditlogreport from "../Components/Reports/Auditlogreport";
import SalesReport from "../Components/Reports/SalesReport";
import CancelReport from "../Components/Reports/CancelReport";
import EventEditor from "../Components/Schedule/EventEditor";
import Userrole from "../Components/Settings/Userrole";
import Trash from "../Components/Trash";
import NewFolderDialog from "../Components/Assests/NewFolderDialog ";
import LoginContainer from "./AuthRoutes";
import UserProfile from "../Pages/Profile/UserProfile";
import AdminContainer from "./AdminRoutes";
import { useSelector } from "react-redux";
import Youtube from "../Components/Apps/Youtube";
import YoutubeDetail from "../Components/Apps/YoutubeDetail";
import Weather from "../Components/Apps/Weather";
import TextScroll from "../Components/Apps/TextScroll";
import TextScrollDetail from "../Components/Apps/TextScrollDetail";
import WeatherDetail from "../Components/Apps/WeatherDetail";
import Loading from "../Components/Loading";
import AddComposition from "../Components/Composition/AddComposition";
import Composition from "../Components/Composition/Composition";
import SelectedLayout from "../Components/Composition/SelectedLayout";
import EditSelectedLayout from "../Components/Composition/EditSelectedLayout";
import YoutubeDetailByID from "../Components/Apps/YoutubeDetailByID";
import TextScrollDetailById from "../Components/Apps/TextScrollDetailById";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../Components/ErrorFallback";
import GridAssets from "../Components/Assests/GridAssets";
import FinalReport from "../Components/Reports/FinalReport";
import UserDashboard from "../Components/Dashboard/UserDashboard";
import AddWeatherSchedule from "../Components/Schedule/AddWeatherSchedule";
import BookSlot from "../Components/Screen/SubScreens/BookSlot/BookSlot";
import BookingSlot from "../Components/Screen/SubScreens/BookSlot/BookingSlot";
import AddSlot from "../Components/Screen/SubScreens/BookSlot/AddSlot";
import DigitalMenuBoard from "../Components/Apps/DigitalMenuBoard";
import DigitalMenuBoardDetail from "../Components/Apps/DigitalMenuBoardDetail";
import DummyDashboard from "../Components/Common/DummyDashboard";
import RetailerRoutes from "./RetailerRoutes";
import SalesManRoutes from "./SalesManRoutes";
import CustomComposition from "../Components/Composition/CustomComposition";
import PlanIntegration from "../Components/PlanIntegration";
import AdvertisementScreens from "../Components/Advertisement/AdvertisementScreens";
import BookYourSlot from "../Components/BookYourSlot/BookYourSlot";
import AddBookYourSlot from "../Components/BookYourSlot/AddBookYourSlot";
import AdvertismentRoute from "./AdvertismentRoute";
import Clock from "../Components/Apps/Clock/Clock";
import ClockDetail from "../Components/Apps/Clock/ClockDetail";
import Date from "../Components/Apps/DateApps/Date";
import AddEditDate from "../Components/Apps/DateApps/AddEditDate";
import DateByID from "../Components/Apps/DateApps/DateByID";
import CurrencyApp from "../Components/Apps/CurrenciesApp/CurrencyApp";
import AddCurrencies from "../Components/Apps/CurrenciesApp/AddCurrencies";
import EditCurrencies from "../Components/Apps/CurrenciesApp/EditCurrencies";
import Sample from "../Sample/Sample";
import CountUpTimer from "../Components/Apps/CountUpTimer";
import CountUpTimerDetails from "../Components/Apps/CountUpTimerDetails";
import { Questions } from "../Components/Apps/Questions";
import { QuestionsDetails } from "../Components/Apps/QuestionsDetails";
import { CountDownTimer } from "../Components/Apps/CountDownTimer";
import MicrosoftExcel from "../Components/Apps/MicrosoftExcel";
import MicrosoftExcelDetail from "../Components/Apps/MicrosoftExcelDetail";
import Embed from "../Components/Apps/Embed";
import Embeddetail from "../Components/Apps/Embeddetail";
import { GoogleTraffic } from "../Components/Apps/GoogleTraffic/GoogleTraffic";
import { GoogleTrafficDetails } from "../Components/Apps/GoogleTraffic/GoogleTrafficDetails";
import { FollowUsOnFacebook } from "../Components/Apps/FollowUsOnFacebook/FollowUsOnFacebook";
import { FollowUsOnFacebookDetails } from "../Components/Apps/FollowUsOnFacebook/FollowUsOnFacebookDetails";
import { DigitalSigns } from "../Components/Apps/DigitalSigns/DigitalSigns";
import { DigitalSignsDetails } from "../Components/Apps/DigitalSigns/DigitalSignsDetails";
import { PageScroll } from "../Components/Apps/PageScroll/PageScroll";
import { PageScrollDetails } from "../Components/Apps/PageScroll/PageScrollDetails";
import SocialMediaAccoutList from "../Components/SocialMedia/SocialMediaAccoutList";
import { LiveNews } from "../Components/Apps/LiveNews/LiveNews";
import { LiveNewsDetails } from "../Components/Apps/LiveNews/LiveNewsDetails";
import { SportsLiveScores } from "../Components/Apps/SportsLiveScore/SportsLiveScores";
import { SportsLiveScoresDetails } from "../Components/Apps/SportsLiveScore/SportsLiveScoresDetails";
import { FollowUs } from "../Components/Apps/FollowUs/FollowUs";
import { FollowUsDetails } from "../Components/Apps/FollowUs/FollowUsDetails";
import NoticeBoard from "../Components/Apps/NoticeBoard/NoticeBoard";
import NoticeBoardDetails from "../Components/Apps/NoticeBoard/NoticeBoardDetails";
import { SoccerScores } from "../Components/Apps/SoccerScores/SoccerScores";
import { SoccerScoresDetails } from "../Components/Apps/SoccerScores/SoccerScoresDetails";
import { WallsIo } from "../Components/Apps/WallsIo/WallsIo";
import { WallsIoDetails } from "../Components/Apps/WallsIo/WallsIoDetails";
import { StreaMa } from "../Components/Apps/StreaMa/StreaMa";
import { StreaMaDetails } from "../Components/Apps/StreaMa/StreaMaDetails";
import { PosterMyWall } from "../Components/Apps/PosterMyWall/PosterMyWall";
import { PosterMyWallDetails } from "../Components/Apps/PosterMyWall/PosterMyWallDetails";
import { Bonusly } from "../Components/Apps/Bonusly/Bonusly";
import { BonuslyDetails } from "../Components/Apps/Bonusly/BonuslyDetails";
import { Canva } from "../Components/Apps/Canva/Canva";
import { CanvaDetails } from "../Components/Apps/Canva/CanvaDetails";
import { GeckoBoard } from "../Components/Apps/GeckoBoard/GeckoBoard";
import { GeckoBoardDetails } from "../Components/Apps/GeckoBoard/GeckoBoardDetails";
import { Yelp } from "../Components/Apps/Yelp/Yelp";
import { YelpDetails } from "../Components/Apps/Yelp/YelpDetails";
import WorldClock from "../Components/Apps/WorldClock/WorldClock";
import WorldClockDetails from "../Components/Apps/WorldClock/WorldClockDetails";
import CountDownTimerDetails from "../Components/Apps/CountDownTimerDetails";
import { NDTVNews } from "../Components/Apps/NDTVNews/NDTVNews";
import { NDTVNewsDetails } from "../Components/Apps/NDTVNews/NDTVNewsDetails";
import { HuffPost } from "../Components/Apps/HuffPost/HuffPost";
import { HuffPostDetails } from "../Components/Apps/HuffPost/HuffPostDetails";
import { FoxNews } from "../Components/Apps/FoxNews/FoxNews";
import { FoxNewsDetails } from "../Components/Apps/FoxNews/FoxNewsDetails";
import { NYTNews } from "../Components/Apps/NYTNews/NYTNews";
import { NYTNewsDetails } from "../Components/Apps/NYTNews/NYTNewsDetails";
import { TOINews } from "../Components/Apps/TOINews/TOINews";
import { TOINewsDetail } from "../Components/Apps/TOINews/TOINewsDetail";
import { ESPNNews } from "../Components/Apps/ESPNNews/ESPNNews";
import { ESPNNewsDetails } from "../Components/Apps/ESPNNews/ESPNNewsDetails";
import { BBCNews } from "../Components/Apps/BBCNews/BBCNews";
import { BBCNewsDetails } from "../Components/Apps/BBCNews/BBCNewsDetails";
import { RegionalNews } from "../Components/Apps/RegionalNews/RegionalNews";
import { RegionalNewsDetails } from "../Components/Apps/RegionalNews/RegionalNewsDetails";
import { WallStreetNews } from "../Components/Apps/WallStreetNews/WallStreetNews";
import { WallStreetNewsDetails } from "../Components/Apps/WallStreetNews/WallStreetNewsDetails";
import { TaggBoxDisplay } from "../Components/Apps/TaggBoxDisplay/TaggBoxDisplay";
import { TaggBoxDisplayDetails } from "../Components/Apps/TaggBoxDisplay/TaggBoxDisplayDetails";
import { GoogleAlerts } from "../Components/Apps/GoogleAlerts/GoogleAlerts";
import { GoogleAlertsDetails } from "../Components/Apps/GoogleAlerts/GoogleAlertsDetails";
import { GoogleSheets } from "../Components/Apps/GoogleSheets/GoogleSheets";
import { GoogleSheetsDetails } from "../Components/Apps/GoogleSheets/GoogleSheetsDetails";
import { OnThisDay } from "../Components/Apps/OnThisDay/OnThisDay";
import { OnThisDayDetails } from "../Components/Apps/OnThisDay/OnThisDayDetails";
import GoogleCalender from "../Components/Apps/GoogleCalender/GoogleCalender";
import { GoogleCalenderDetails } from "../Components/Apps/GoogleCalender/GoogleCalenderDetails";
import BuildingDirectory from "../Components/Apps/BuildingDirectory/BuildingDirectory";
import BuildingDirectoryDetails from "../Components/Apps/BuildingDirectory/BuildingDirectoryDetails";
import AirQuality from "../Components/Apps/AirQuality/AirQuality";
import AirQualityDetails from "../Components/Apps/AirQuality/AirQualityDetails";
import GoogleReviews from "../Components/Apps/GoogleReviews/GoogleReviews";
import GoogleReviewsDetails from "../Components/Apps/GoogleReviews/GoogleReviewsDetails";
import StockExchange from "../Components/Apps/StockExchange/StockExchange";
import StockExchangeDetails from "../Components/Apps/StockExchange/StockExchangeDetails";
import GoogleTrends from "../Components/Apps/GoogleTrends/GoogleTrends";
import GoogleTrendsDetails from "../Components/Apps/GoogleTrends/GoogleTrendsDetails";
import { SportsNews } from "../Components/Apps/SportsNews/SportsNews";
import { SportsNewsDetails } from "../Components/Apps/SportsNews/SportsNewsDetails";
import { BusinessAndFinance } from "../Components/Apps/BusinessAndFinance/BusinessAndFinance";
import { BusinessAndFinanceDetails } from "../Components/Apps/BusinessAndFinance/BusinessAndFinanceDetails";
import { EntertainmentNews } from "../Components/Apps/EntertainmentNews/EntertainmentNews";
import { EntertainmentNewsDetails } from "../Components/Apps/EntertainmentNews/EntertainmentNewsDetails";
import { HeadlineNews } from "../Components/Apps/HeadlineNews/HeadlineNews";
import { HeadlineNewsDetails } from "../Components/Apps/HeadlineNews/HeadlineNewsDetails";
import FlightSchedules from "../Components/Apps/FlightSchedules/FlightSchedules.jsx";
import FlightSchedulesDetails from "../Components/Apps/FlightSchedules/FlightSchedulesDetails";
import MeetTheTeam from "../Components/Apps/MeetTheTeam/MeetTheTeam.jsx";
import MeetTheTeamDetails from "../Components/Apps/MeetTheTeam/MeetTheTeamDetails.jsx";
import { InspirationalQuotes } from "../Components/Apps/InspirationalQuotes/InspirationalQuotes";
import { InspirationalQuotesDetails } from "../Components/Apps/InspirationalQuotes/InspirationalQuotesDetails";

const Routing = () => {
  const { user } = useSelector((state) => state.root.auth);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const currentUrl = window.location.href;
  const accessDetails = localStorage.getItem("role_access");
  const handleResize = useCallback(() => {
    if (window.innerWidth < 780) {
      setSidebarOpen(false);
    } else if (!sidebarOpen) {
      setSidebarOpen(true);
    }
  }, [sidebarOpen]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, sidebarOpen]);

  useEffect(() => {
    handleResize();
    window.addEventListener("load", handleResize);

    return () => {
      window.removeEventListener("load", handleResize);
    };
  }, [handleResize]);

  if (currentUrl.includes("PaymentIntegration")) {
    return (
      <BrowserRouter>
        <Routes>
          <Route>
            <Route
              path="/PaymentIntegration/:planId"
              element={
                <PlanIntegration
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else if (currentUrl.includes("bookslot")) {
    return (
      <BrowserRouter>
        <Routes>
          <Route>
            <Route
              path="/bookslot"
              element={
                <AddSlot
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else if (currentUrl.includes("disployUser")) {
    return (
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/disployUser/:id" element={<Sample />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else {
    if (!accessDetails)
      return (
        <LoginContainer
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      );
    if (accessDetails === "ADMIN")
      return (
        <AdminContainer
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      );
    if (accessDetails === "RETAILER")
      return (
        <RetailerRoutes
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      );

    if (accessDetails === "SALESMAN")
      return (
        <SalesManRoutes
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      );

    if (accessDetails === "ADVERTISMENT")
      return (
        <AdvertismentRoute
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      );

    if (
      accessDetails === "USER" &&
      ((user?.isTrial && user?.trialDays > 0) ||
        user?.isActivePlan ||
        user?.userDetails?.isRetailer === true)
    ) {
      return (
        <BrowserRouter>
          <ErrorBoundary
            fallback={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
              <Route path="/register" element={<Navigate to="/dashboard" />} />
              <Route
                path="/dashboard"
                element={
                  <UserDashboard
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              <Route
                path="/screens"
                element={
                  <Screens
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/edituser"
                element={
                  <EditUser
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/userprofile"
                element={
                  <UserProfile
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* screen component route */}
              <Route
                path="/mergescreen"
                element={
                  <MergeScreen
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/add-mergescreen"
                element={
                  <AddMergeScreen
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/newscreengroup"
                element={
                  <NewScreenGroup
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/advertisement-screens"
                element={
                  <AdvertisementScreens
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/book-your-slot"
                element={
                  <BookYourSlot
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/addbookyourslot"
                element={
                  <AddBookYourSlot
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* <Route
                path="/bookslot"
                element={
                  <AddSlot />
                }
              />
              <Route
                path="/bookingslot"
                element={
                  <BookingSlot
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />*/}
              <Route
                path="/screensplayer"
                element={
                  <Screensplayer
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/newscreendetail"
                element={
                  <NewScreenDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Assests component route */}
              <Route
                path="/fileupload"
                element={
                  <FileUpload
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/assets"
                element={
                  <Assets
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              <Route
                path="/assets-grid"
                element={
                  <GridAssets
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* Apps component route */}
              <Route
                path="/apps"
                element={
                  <Apps
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/youtube"
                element={
                  <Youtube
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/youtubedetail/:id"
                element={
                  <YoutubeDetailByID
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/youtubedetail"
                element={
                  <YoutubeDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/textscrolldetail/:id"
                element={
                  <TextScrollDetailById
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/textscrolldetail"
                element={
                  <TextScrollDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              <Route
                path="/Weather"
                element={
                  <Weather
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/weatherdetail"
                element={
                  <WeatherDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/weatherdetail/:id"
                element={
                  <WeatherDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/text-scroll"
                element={
                  <TextScroll
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Date"
                element={
                  <Date
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Datedetail"
                element={
                  <AddEditDate
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Datedetail/:id"
                element={
                  <DateByID
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Noticeboard App */}
              <Route
                path="/Noticeboard"
                element={
                  <NoticeBoard
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/NoticeBoardDetails"
                element={
                  <NoticeBoardDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/NoticeBoardDetails/:id"
                element={
                  <NoticeBoardDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* WorldClock App */}
              <Route
                path="/World-Clock"
                element={
                  <WorldClock
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/WorldClockDetails"
                element={
                  <WorldClockDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/WorldClockDetails/:id"
                element={
                  <WorldClockDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* BuildingDirectory App */}
              <Route
                path="/Building-Directory"
                element={
                  <BuildingDirectory
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Building-DirectoryDetails"
                element={
                  <BuildingDirectoryDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Building-DirectoryDetails/:id"
                element={
                  <BuildingDirectoryDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* Live News Routes */}
              <Route
                path="/Live-News"
                element={
                  <LiveNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Live-News-Detail"
                element={
                  <LiveNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Live-News-Detail/:id"
                element={
                  <LiveNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Sports Live Scores Routes */}
              <Route
                path="/Sports-Live-Scores"
                element={
                  <SportsLiveScores
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Sports-Live-Scores-Detail"
                element={
                  <SportsLiveScoresDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Sports-Live-Scores-Detail/:id"
                element={
                  <SportsLiveScoresDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Follow Us Routes */}
              <Route
                path="/Follow-Us"
                element={
                  <FollowUs
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Follow-Us-Detail"
                element={
                  <FollowUsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Follow-Us-Detail/:id"
                element={
                  <FollowUsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Google Traffic Routes */}
              <Route
                path="/Google-Traffic"
                element={
                  <GoogleTraffic
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Google-Traffic-Detail"
                element={
                  <GoogleTrafficDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Google-Traffic-Detail/:id"
                element={
                  <GoogleTrafficDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Gecko Board Routes */}
              <Route
                path="/Gecko-Board"
                element={
                  <GeckoBoard
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Gecko-Board-Detail"
                element={
                  <GeckoBoardDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Gecko-Board-Detail/:id"
                element={
                  <GeckoBoardDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Canva Board Routes */}
              <Route
                path="/Canva"
                element={
                  <Canva
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Canva-Detail"
                element={
                  <CanvaDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Canva-Detail/:id"
                element={
                  <GeckoBoardDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* InspInspirational Quotes Routes */}
              <Route
                path="/Inspirational-Quotes"
                element={
                  <InspirationalQuotes
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Inspirational-Quotes-Detail"
                element={
                  <InspirationalQuotesDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Inspirational-Quotes-Detail/:id"
                element={
                  <InspirationalQuotesDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Google Sheets Routes */}
              <Route
                path="/Google-Sheets"
                element={
                  <GoogleSheets
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Google-Sheets-Detail"
                element={
                  <GoogleSheetsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Google-Sheets-Detail/:id"
                element={
                  <GoogleSheetsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* OnThisDay Routes */}
              <Route
                path="/On-This-Day"
                element={
                  <OnThisDay
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/On-This-Day-Detail"
                element={
                  <OnThisDayDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/On-This-Day-Detail/:id"
                element={
                  <OnThisDayDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* NDTV News Routes */}
              <Route
                path="/NDTV-News"
                element={
                  <NDTVNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/NDTV-News-Detail"
                element={
                  <NDTVNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/NDTV-News-Detail/:id"
                element={
                  <NDTVNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Sports News Routes */}
              <Route
                path="/Sports-News"
                element={
                  <SportsNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Sports-News-Detail"
                element={
                  <SportsNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Sports-News-Detail/:id"
                element={
                  <SportsNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Headline News Routes */}
              <Route
                path="/Headline-News"
                element={
                  <HeadlineNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Headline-News-Detail"
                element={
                  <HeadlineNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Headline-News-Detail/:id"
                element={
                  <HeadlineNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Entertainment News Routes */}
              <Route
                path="/Entertainment-News"
                element={
                  <EntertainmentNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Entertainment-News-Detail"
                element={
                  <EntertainmentNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Entertainment-News-Detail/:id"
                element={
                  <EntertainmentNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Business And Finance News Routes */}
              <Route
                path="/Business-Finance-News"
                element={
                  <BusinessAndFinance
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Business-Finance-News-Detail"
                element={
                  <BusinessAndFinanceDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Business-Finance-News-Detail/:id"
                element={
                  <BusinessAndFinanceDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* NYT News Routes */}
              <Route
                path="/NYT-News"
                element={
                  <NYTNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/NYT-News-Detail"
                element={
                  <NYTNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/NYT-News-Detail/:id"
                element={
                  <NYTNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* TOI News Routes */}
              <Route
                path="/TOI-News"
                element={
                  <TOINews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/TOI-News-Detail"
                element={
                  <TOINewsDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/TOI-News-Detail/:id"
                element={
                  <TOINewsDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Fox News Routes */}
              <Route
                path="/Fox-News"
                element={
                  <FoxNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Fox-News-Detail"
                element={
                  <FoxNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Fox-News-Detail/:id"
                element={
                  <FoxNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* ESPN News Routes */}
              <Route
                path="/ESPN-News"
                element={
                  <ESPNNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/ESPN-News-Detail"
                element={
                  <ESPNNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/ESPN-News-Detail/:id"
                element={
                  <ESPNNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* BBC News Routes */}
              <Route
                path="/BBC-News"
                element={
                  <BBCNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/BBC-News-Detail"
                element={
                  <BBCNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/BBC-News-Detail/:id"
                element={
                  <BBCNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Regional News Routes */}
              <Route
                path="/Regional-News"
                element={
                  <RegionalNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Regional-News-Detail"
                element={
                  <RegionalNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Regional-News-Detail/:id"
                element={
                  <RegionalNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Regional News Routes */}
              <Route
                path="/Wall-Street-Journal"
                element={
                  <WallStreetNews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Wall-Street-Journal-Detail"
                element={
                  <WallStreetNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Wall-Street-Journal-Detail/:id"
                element={
                  <WallStreetNewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* HuffPost Routes */}
              <Route
                path="/HuffPost"
                element={
                  <HuffPost
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/HuffPost-Detail"
                element={
                  <HuffPostDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/HuffPost-Detail/:id"
                element={
                  <HuffPostDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Yelp Routes */}
              <Route
                path="/Yelp"
                element={
                  <Yelp
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Yelp-Detail"
                element={
                  <YelpDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Yelp-Detail/:id"
                element={
                  <YelpDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Google Alerts Routes */}
              <Route
                path="/Google-Alerts"
                element={
                  <GoogleAlerts
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Google-Alerts-Detail"
                element={
                  <GoogleAlertsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Google-Alerts-Detail/:id"
                element={
                  <GoogleAlertsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* TaggboxDisplay Routes */}
              <Route
                path="/Taggbox-Display"
                element={
                  <TaggBoxDisplay
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Taggbox-Display-Detail"
                element={
                  <TaggBoxDisplayDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Taggbox-Display-Detail/:id"
                element={
                  <TaggBoxDisplayDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* CalenderForGoogle Routes */}
              <Route
                path="/Calender-For-Google"
                element={
                  <GoogleCalender
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Calender-Google-Detail"
                element={
                  <GoogleCalenderDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Calender-Google-Detail/:id"
                element={
                  <GoogleCalenderDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Air-Quality Routes */}
              <Route
                path="/Air-Quality"
                element={
                  <AirQuality
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Air-Quality-Detail"
                element={
                  <AirQualityDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Air-Quality-Detail/:id"
                element={
                  <AirQualityDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* StockExchange Routes */}
              <Route
                path="/Stocks-Exchanges"
                element={
                  <StockExchange
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Stocks-Exchanges-Detail"
                element={
                  <StockExchangeDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Stocks-Exchanges-Detail/:id"
                element={
                  <StockExchangeDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Flight Schedules Routes */}
              <Route
                path="/Flight-Schedules"
                element={
                  <FlightSchedules
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Flight-Schedules-Detail"
                element={
                  <FlightSchedulesDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Flight-Schedules-Detail/:id"
                element={
                  <FlightSchedulesDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* MeetTheTeam Routes */}
              <Route
                path="/Meet-The-Team"
                element={
                  <MeetTheTeam
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Meet-The-Team-Detail"
                element={
                  <MeetTheTeamDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Meet-The-Team-Detail/:id"
                element={
                  <MeetTheTeamDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* GoogleTrends Routes */}
              <Route
                path="/Google-Trends"
                element={
                  <GoogleTrends
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Google-Trends-Detail"
                element={
                  <GoogleTrendsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Google-Trends-Detail/:id"
                element={
                  <GoogleTrendsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Google-Reviews Routes */}
              <Route
                path="/Google-Reviews"
                element={
                  <GoogleReviews
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Google-Reviews-Detail"
                element={
                  <GoogleReviewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Google-Reviews-Detail/:id"
                element={
                  <GoogleReviewsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Soccer Scores Routes */}
              <Route
                path="/Soccer-Scores"
                element={
                  <SoccerScores
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Soccer-Scores-Detail"
                element={
                  <SoccerScoresDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Soccer-Scores-Detail/:id"
                element={
                  <SoccerScoresDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* WallsIo  Routes */}
              <Route
                path="/Wallsio"
                element={
                  <WallsIo
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Wallsio-Detail"
                element={
                  <WallsIoDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Wallsio-Detail/:id"
                element={
                  <WallsIoDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* StreaMa  Routes */}
              <Route
                path="/Streama"
                element={
                  <StreaMa
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Streama-Detail"
                element={
                  <StreaMaDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Streama-Detail/:id"
                element={
                  <StreaMaDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Poster My Wall  Routes */}
              <Route
                path="/Poster-My-Wall"
                element={
                  <PosterMyWall
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Poster-My-Wall-Detail"
                element={
                  <PosterMyWallDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Poster-My-Wall-Detail/:id"
                element={
                  <PosterMyWallDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Bonusly Routes */}
              <Route
                path="/Bonusly"
                element={
                  <Bonusly
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Bonusly-Detail"
                element={
                  <BonuslyDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Bonusly-Detail/:id"
                element={
                  <BonuslyDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* Follow Us ON FacebookRoutes */}
              <Route
                path="/FollowUs-On-Facebook"
                element={
                  <FollowUsOnFacebook
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/FollowUs-On-Facebook-Detail"
                element={
                  <FollowUsOnFacebookDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/FollowUs-On-Facebook-Detail/:id"
                element={
                  <FollowUsOnFacebookDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              <Route
                path="/Currency"
                element={
                  <CurrencyApp
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/CurrenciesDetails"
                element={
                  <AddCurrencies
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/CurrenciesDetails/:id"
                element={
                  <EditCurrencies
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* Digital Signs Route */}
              <Route
                path="/Digital-Signs"
                element={
                  <DigitalSigns
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Digital-Signs-Detail"
                element={
                  <DigitalSignsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Digital-Signs-Detail/:id"
                element={
                  <DigitalSignsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* Page Scroll Route */}
              <Route
                path="/Page-Scroll"
                element={
                  <PageScroll
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Page-Scroll-Detail"
                element={
                  <PageScrollDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Page-Scroll-Detail/:id"
                element={
                  <PageScrollDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* Digital Menu Board route */}
              <Route
                path="/Digital-Menu-Board"
                element={
                  <DigitalMenuBoard
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              <Route
                path="/digital-menu-detail"
                element={
                  <DigitalMenuBoardDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/digital-menu-detail/:id"
                element={
                  <DigitalMenuBoardDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Count-Up-Timer component route */}
              <Route
                path="/Count-Up-Timer"
                element={
                  <CountUpTimer
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/CountUpTimererdetail"
                element={
                  <CountUpTimerDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/CountUpTimererdetail/:id"
                element={
                  <CountUpTimerDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Count-Down-Timer component route */}
              <Route
                path="/Count-Down-Timer"
                element={
                  <CountDownTimer
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/CountdownTimerdetail"
                element={
                  <CountDownTimerDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/CountdownTimerdetail/:id"
                element={
                  <CountDownTimerDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/*  Ask a Qestions component route */}
              <Route
                path="/Ask-A-Question"
                element={
                  <Questions
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Questiondetail"
                element={
                  <QuestionsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              <Route
                path="/Questiondetail/:id"
                element={
                  <QuestionsDetails
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* Microsoft-Excel component route */}
              <Route
                path="/Microsoft-Excel"
                element={
                  <MicrosoftExcel
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/MicrosoftExceldetail"
                element={
                  <MicrosoftExcelDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              <Route
                path="/MicrosoftExceldetail/:id"
                element={
                  <MicrosoftExcelDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/*  Embed component route */}
              <Route
                path="/Embed"
                element={
                  <Embed
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Embeddetail"
                element={
                  <Embeddetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              <Route
                path="/Embeddetail/:id"
                element={
                  <Embeddetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Playlist component route */}
              <Route
                path="/composition"
                element={
                  <Composition
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/addcomposition"
                element={
                  <AddComposition
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/customcomposition"
                element={
                  <CustomComposition
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/addcomposition/:id"
                element={
                  <SelectedLayout
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/editcomposition/:id/:layoutId"
                element={
                  <EditSelectedLayout
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              {/* <Route
                path="/selectedlayout"
                element={
                  <SelectLayout
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              /> */}
              {/* DisployStudio component route */}
              <Route
                path="/disploystudio"
                element={
                  <DisployStudio
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Reports component route */}
              <Route
                path="/reports"
                element={
                  <Report
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/reports/:report/:daily/:date"
                element={
                  <FinalReport
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/uptimereport"
                element={
                  <Uptimereport
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/auditlogreport"
                element={
                  <Auditlogreport
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/salesreport"
                element={
                  <SalesReport
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/cancelreport"
                element={
                  <CancelReport
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/mediareport"
                element={
                  <Mediareport
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Schedule component route */}
              <Route
                path="/myschedule"
                element={
                  <MySchedule
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/weatherschedule"
                element={
                  <WeatherSchedule
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/addschedule"
                element={
                  <AddSchedule
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/addweatherschedule"
                element={
                  <AddWeatherSchedule
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/saveassignscreenmodal"
                element={<SaveAssignScreenModal />}
              />
              {/* Approval component route */}
              <Route
                path="/approval"
                element={
                  <Approval
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/* Settings component route */}
              <Route
                path="/settings"
                element={
                  <Settings
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/userrole"
                element={
                  <Userrole
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              {/*Event Editors */}
              <Route
                path="/eventedit"
                element={
                  <EventEditor
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/NewFolderDialog/:folderId"
                component={NewFolderDialog}
                element={
                  <NewFolderDialog
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/trash"
                element={
                  <Trash
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              <Route
                path="/Clock"
                element={
                  <Clock
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Clockdetail"
                element={
                  <ClockDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
              <Route
                path="/Clockdetail/:id"
                element={
                  <ClockDetail
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />

              <Route
                path="/Social_Media_Tools"
                element={
                  <SocialMediaAccoutList
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
      );
    }

    if (
      (accessDetails === "USER" &&
        user?.isTrial === false &&
        user?.isActivePlan === false &&
        user?.userDetails?.isRetailer === false) ||
      user?.trialDays <= 0
    ) {
      return (
        <BrowserRouter>
          <ErrorBoundary
            fallback={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
              <Route path="/register" element={<Navigate to="/dashboard" />} />
              <Route
                path="/dashboard"
                element={
                  <DummyDashboard
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
              />
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
      );
    }
  }

  return (
    <>
      <Loading />
    </>
    // <div className="flex justify-center items-center h-screen">
    //   <TailSpin color="red" radius={"8px"} />
    // </div>
  );
};

export default Routing;
