import React from 'react'
import NoticeboardIMG from '../../../images/AppsImg/noticeboard.svg'
import { AiOutlineCloseCircle } from 'react-icons/ai';

export default function NoticeBoardPreview({ noticeboarddata, setInstanceView, background }) {
    return (
        <>
            <div className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-9990 outline-none focus:outline-none">
                <div >
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
                            <div className="flex items-center">
                                <div>
                                    <img src={NoticeboardIMG} className="w-10" alt='NoticeBoard' />
                                </div>
                                <div className="ml-3">
                                    <h4 className="text-lg font-medium">
                                        {noticeboarddata?.instanceName}
                                    </h4>
                                </div>
                            </div>
                            <button
                                className="p-1 text-3xl"
                                onClick={() => {
                                    setInstanceView(false);
                                    // setBackground("");
                                }}
                            >
                                <AiOutlineCloseCircle />
                            </button>
                        </div>

                        <div className="md:w-[576px] md:h-[324px] sm:w-[384px] sm:h-[216px] lg:w-[960px] lg:h-[540px] w-72 h-72 flex items-center">
                            <div
                                className="w-full h-full flex items-center justify-center NoticeBoard relative"
                                style={{
                                    backgroundImage: `url(${noticeboarddata?.backGroundImage_assetID ===
                                        0
                                        ? noticeboarddata?.theme
                                        : background
                                        })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            >
                                {noticeboarddata?.isDark && (
                                    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                                )}
                                <textarea
                                    className={`w-[80%] h-[80%] relative test ${noticeboarddata?.isWhiteText
                                        ? "text-white"
                                        : "text-black"
                                        } text-6xl px-4 py-2 font-medium resize-none outline-none text-${noticeboarddata?.alignment
                                        } transition duration-300 bg-transparent bg-opacity-0 focus:bg-opacity-0 scrollbar-hide`}
                                    value={noticeboarddata.content}
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="py-2 px-6">
                            <div className="flex items-center gap-2 w-full">
                                <div className="font-semibold w-fit">Tags:-</div>
                                <div className=" w-full">
                                    {noticeboarddata?.tags}
                                </div>
                            </div>
                            <div>
                                <label className="font-semibold">
                                    Screen Assign :
                                </label>
                                {noticeboarddata?.ScreenAssignName == ""
                                    ? " No Screen"
                                    : noticeboarddata?.ScreenAssignName}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
