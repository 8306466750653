import React, { useEffect, useRef, useState } from 'react'
import Select from "react-select";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { IoIosArrowDown } from 'react-icons/io';
import logo from "../../../../images/DisployImg/Black-Logo2.png";
import { getPurposeScreens, getVaildReferralcode } from '../../../../Redux/admin/AdvertisementSlice';
import toast from 'react-hot-toast';
import { getIndustry } from '../../../../Redux/CommonSlice';
import BookTermsCondition from './BookTermsCondition';

export default function AddSoltPage_2({ setPage, countries, page, setallSlateDetails, allSlateDetails, UserName }) {
    const SelectDropdownRef = useRef(null);
    const dispatch = useDispatch()
    const modalRef = useRef(null);
    const store = useSelector((state) => state.root.common);
    const Purpose = useSelector((state) => state.root.advertisementData);
    const [Error, setError] = useState(false);
    const [includeoption, setincludeoption] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);


    useEffect(() => {
        dispatch(getIndustry({}))
        dispatch(getPurposeScreens({}))
        setSelectedOption(allSlateDetails?.Industry)
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (SelectDropdownRef.current && !SelectDropdownRef.current.contains(event?.target)) {
                setMenuIsOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const handleClick = (label) => {
        setallSlateDetails((prev) => {
            const isSelected = prev.selecteScreens.includes(label);
            return {
                ...prev,
                selecteScreens: isSelected
                    ? prev.selecteScreens.filter((item) => item !== label)
                    : [...prev.selecteScreens, label]
            };
        });
    };

    const onsumbitPage2 = () => {
        if (allSlateDetails?.Industry === null || (allSlateDetails?.refCode === 'Yes' && !allSlateDetails?.refVale)) {
            return setError(true)
        }
        if (allSlateDetails?.refVale && allSlateDetails?.refCode === 'Yes') {
            dispatch(getVaildReferralcode(allSlateDetails?.refVale)).then((res) => {
                if (res?.payload?.data === false) {
                    return toast.error("Enter Valid Referral Code.")
                } else {
                    setPage(page + 1)
                }
            })
        } else { setPage(page + 1) }
    }


    // Prepare combined options for Select
    const options1 = store?.Industry?.flatMap(item => [
        {
            value: item.industryID,
            label: (
                <div className='flex items-center justify-between' style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginLeft: '8px' }}>{item.industryName}</span>
                    <IoIosArrowDown onClicsetMenuIsOpenk={() => setincludeoption(true)} />
                </div>
            ),
            isIndustry: true // Indicate this is an industry option
        },
        ...item.industryInclude.map(include => ({
            value: `${item.industryID},${include.industryIncludeID}`,
            label: `${item.industryName} - ${include.category}`, // Nested format
            isIndustry: false // Indicate this is a category option
        }))
    ]) || [];

    const options = store?.Industry?.flatMap((item, index) => {

        const industryOption = {
            value: item?.industryID,
            label: (
                <div className='flex items-center justify-between' onClick={() => { setincludeoption(includeoption === index ? null : index); setMenuIsOpen(true) }}>
                    <span className=' w-full font-semibold' >{item?.industryName}</span>
                    <IoIosArrowDown className='h-5 w-5 ' onClick={() => { setincludeoption(includeoption === index ? null : index); setMenuIsOpen(true) }} />
                </div>
            ),
            isIndustry: true
        }


        const result = [industryOption];


        if (includeoption === index) {
            const includeOptions = item.industryInclude.map(include => ({
                value: include.industryIncludeID,
                label: (<div className='h-full' onClick={() => { setMenuIsOpen(false) }}>{`${item.industryName} - ${include.category}`}</div>),
                isIndustry: false
            }));
            result.push(...includeOptions);
        }

        return result;
    }) || [];


    const handleChange = (option) => {
        setSelectedOption(option);
        setallSlateDetails({ ...allSlateDetails, Industry: option })
    };

    const handleAcceptTerms = () => {
        setallSlateDetails({ ...allSlateDetails, terms: true });
        setShowModal(false);
    };

    return (
        <>
            <div className="w-full h-full p-5 flex items-center justify-center">
                <div className="lg:w-[700px] md:w-[600px] w-full bg-white lg:p-6 p-3 rounded-xl shadow-lg overflow-auto">
                    <div className="flex items-center justify-center mb-4">
                        <img
                            alt="Logo"
                            src={logo}
                            className="cursor-pointer duration-500 w-52"
                        />
                    </div>
                    <div className="grid grid-cols-4 gap-4 w-full ">
                        <div className="col-span-4">
                            <div className="p-5 flex flex-col gap-4 h-full">
                                <div className="flex flex-col justify-center m-auto">
                                    <h3 className="text-center font-bold mb-2">Hi {UserName},</h3>
                                    <p className="text-sm text-center w-[500px]"> Before we begin, please take a moment to share some details about your organization. This will help us tailor the screen experience to perfectly suit your needs.</p>
                                </div>
                                <div className="relative w-full col-span-2" ref={SelectDropdownRef}>
                                    <Select
                                        placeholder='Select Industry'
                                        value={selectedOption}
                                        onChange={handleChange}
                                        options={[...options]}
                                        isClearable={true}
                                        menuIsOpen={menuIsOpen}
                                        // onFocus={handleMenuOpen} 
                                        // onBlur={handleMenuClose} 
                                        onMenuOpen={() => setMenuIsOpen(true)}
                                    // onMenuClose={() => setMenuIsOpen(false)}
                                    />
                                    {Error && !allSlateDetails?.Industry && (
                                        <p className="text-red-600 text-sm font-semibold">This field is Required.</p>
                                    )}
                                </div>
                                {allSlateDetails?.Industry?.label === 'Others' && (
                                    <div className='p-0 m-auto my-2'>
                                        <input
                                            type="text"
                                            placeholder='Enter Text'
                                            className={`bg-transparent placeholder-slate-400 focus:text-black border-black  focus:border-0 focus:bg-black  focus:ring-0  focus:outline-none  border-b-2 border-current w-72 p-2`}
                                            onChange={(e) => {
                                                setallSlateDetails({ ...allSlateDetails, otherIndustry: e.target.value })
                                            }}
                                            value={allSlateDetails?.otherIndustry}
                                        />
                                    </div>
                                )}

                                <div className="flex flex-col justify-center">
                                    <p className="text-center mb-2 font-semibold"> Purpose of using Disploy screens
                                    </p>
                                    <div className="m-auto flex justify-center flex-wrap my-3">
                                        {Purpose?.data?.length > 0 && Purpose?.data?.map((label, index) => (
                                            <button
                                                className={`border m-0 border-primary px-3 py-1 mr-2 mb-2 rounded-full ${allSlateDetails?.selecteScreens?.includes(label?.purposeID) && "bg-SlateBlue border-white"} `}
                                                key={index}
                                                onClick={() => handleClick(label?.purposeID)}
                                            >
                                                {label?.purposeName}
                                            </button>
                                        ))}
                                        <button
                                            className={`border m-0 border-primary px-3 py-1 mr-2 mb-2 rounded-full ${allSlateDetails?.selecteScreens?.includes('others') && "bg-SlateBlue border-white"} `}

                                            onClick={() => handleClick('others')}
                                        >
                                            Others
                                        </button>
                                    </div>
                                    {allSlateDetails?.selecteScreens?.some((x) => x === "others") && (
                                        <div className='p-0 m-auto mb-3 bookslot-ref w-52'>
                                            <input
                                                type="text"
                                                placeholder=' Enter your purpose'
                                                className={`bg-transparent placeholder-slate-400 border-current w-full p-2`}
                                                onChange={(e) => {
                                                    if (e.target.value?.length > 50) {
                                                        toast.remove();
                                                        return toast.error("Purpose has to be maximum 50 characters.");
                                                    } else {
                                                        setallSlateDetails({ ...allSlateDetails, purposeText: e.target.value })
                                                    }
                                                }}
                                                value={allSlateDetails?.purposeText}
                                            />
                                        </div>
                                    )}
                                    {/* {allSlateDetails?.selecteScreens?.some((x) => x === "others") && (
                                    <div className='p-0 m-auto my-2'>
                                        <input
                                            type="text"
                                            placeholder='Enter Text'
                                            className={`bg-transparent placeholder-slate-400 focus:text-black border-black  focus:border-0 focus:bg-black  focus:ring-0  focus:outline-none  border-b-2 border-current w-72 p-2`}
                                            // style={{ outline: 'none', }}
                                            onChange={(e) => {
                                                setallSlateDetails({ ...allSlateDetails, purposeText: e.target.value })
                                            }}
                                            value={allSlateDetails?.purposeText}
                                        />
                                    </div>
                                )} */}
                                    <hr className='mb-2' />
                                    <div className="my-4 flex items-center gap-3 m-auto">
                                        <div className="flex justify-center items-center gap-4 m-auto">
                                            <div className=''>
                                                <div className="flex">
                                                    <div className="ml-2 flex items-center">
                                                        <input
                                                            type="radio"
                                                            value={allSlateDetails?.refCode}
                                                            checked={allSlateDetails?.refCode === "Yes"}
                                                            name="Cel"
                                                            id='Yes'
                                                            className='cursor-pointer'
                                                            onChange={() => {
                                                                setallSlateDetails({ ...allSlateDetails, refCode: "Yes" });
                                                            }}
                                                        />
                                                        <label for='Yes' className="ml-1 lg:text-base md:text-base sm:text-xs xs:text-xs">
                                                            Referral code
                                                        </label>
                                                    </div>

                                                    <div className="ml-3 flex items-center">
                                                        <input
                                                            id='NO'
                                                            type="radio"
                                                            value={allSlateDetails?.refCode}
                                                            checked={allSlateDetails?.refCode === "NO"}
                                                            name="Cel"
                                                            className='cursor-pointer'
                                                            onChange={() => {
                                                                setallSlateDetails({ ...allSlateDetails, refCode: "NO", refVale: "" });

                                                            }}
                                                        />
                                                        <label for='NO' className="ml-1 lg:text-base md:text-base sm:text-xs xs:text-xs">
                                                            I don't have
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {allSlateDetails?.refCode === "Yes" && (
                                        <div className='flex justify-center items-center mb-4'>
                                            <div className={`${(Error && !allSlateDetails?.refVale) ? "bookslot-ref-error" : "bookslot-ref w-52"}`}>
                                                <input
                                                    type="text"
                                                    className={`bg-transparent placeholder-slate-400 border-current w-52 p-2`}
                                                    value={allSlateDetails?.refVale}
                                                    placeholder='Referral code'
                                                    onChange={(e) => {
                                                        const value = e.target.value.toUpperCase();
                                                        if (value.length <= 6) {
                                                            setallSlateDetails({ ...allSlateDetails, refVale: value });
                                                        }
                                                    }}
                                                />
                                                {/*{Error && !allSlateDetails?.refVale && (
                                                        <p className="text-red-600 text-sm font-semibold text-center ">This field is Required.</p>
                                                    )}*/}
                                            </div>
                                        </div>
                                    )}
                                    {/*<div className='m-auto'>
                                        <label className="custom-label flex items-center justify-center mb-2">
                                            <div className="bg-white cursor-pointer dark:bg-slate-700 border border-slate-200 dark:border-slate-600 rounded w-4 h-4 flex items-center justify-center mr-2">
                                                <input
                                                    type="checkbox"
                                                    name="terms"
                                                    className='cursor-pointer'
                                                    onChange={(e) => {
                                                        setallSlateDetails({ ...allSlateDetails, terms: e.target.checked });
                                                    }}
                                                    checked={allSlateDetails?.terms}
                                                />
                                            </div>
                                            <p>I have read the terms and agreement of Disploy</p>
                                        </label>
                                    </div>*/}
                                    <div className="flex items-center justify-center">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="terms"
                                                name="terms"
                                                type="checkbox"
                                                checked={allSlateDetails?.terms}
                                                onChange={(e) => {
                                                    setallSlateDetails({ ...allSlateDetails, terms: e.target.checked });
                                                }}
                                                className="w-4 h-4 cursor-pointer border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                            />
                                        </div>
                                        <div className="flex ml-3 lg:text-sm md:text-sm sm:text-sm xs:text-[14px] flex-wrap">
                                            <p className="not-italic text-black font-medium">
                                                I have read the
                                            </p>
                                            <p
                                                className="ml-1 not-italic text-black font-medium decoration-black border-b cursor-pointer"
                                                onClick={() => setShowModal(true)}
                                            >
                                                terms and agreement of Disploy.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full h-full">
                                    <div className="flex justify-center h-full items-end gap-4">
                                        <button
                                            className="flex align-middle bg-SlateBlue text-white items-center  rounded-full xs:px-3 xs:py-1 sm:px-4 md:px-8 sm:py-2 text-base  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                            onClick={() => setPage(page - 1)}
                                        >
                                            Back
                                        </button>
                                        <button
                                            disabled={allSlateDetails?.terms === false}
                                            className={`flex align-middle bg-SlateBlue text-white items-center  rounded-full xs:px-3 xs:py-1 sm:px-4 md:px-8 sm:py-2 text-base  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50 ${!allSlateDetails?.terms ? 'cursor-not-allowed  opacity-50' : ""}`}
                                            onClick={onsumbitPage2}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <BookTermsCondition
                    handleAcceptTerms={handleAcceptTerms}
                    modalRef={modalRef}
                    setShowModal={setShowModal}
                    setallSlateDetails={setallSlateDetails}
                    allSlateDetails={allSlateDetails}
                />
            )}
        </>
    )
}
