import { GoogleMap, LoadScript, TrafficLayer } from "@react-google-maps/api";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { GoPencil } from "react-icons/go";
import { MdSave } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getGoogleTrafficById,
  handleAddGoogleTraffic,
} from "../../../Redux/AppsSlice";
import { darkTheme, lightTheme } from "../../Common/Common";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";

export const GoogleTrafficDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, userDetails, token } = useSelector((state) => state.root.auth);
  const [edited, setEdited] = useState(false);
  const authToken = `Bearer ${token}`;
  const [showPreview, setShowPreview] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [instanceName, setInstanceName] = useState(
    moment().format("YYYY-MM-DD hh:mm A")
  );
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [Loading, setLoading] = useState(false);
  const [Google_Traffic, setGoogle_Traffic] = useState({
    Location: "",
    ZoomLevel: "15",
    Theme: "true",
    GoogleAPIkey: "",
  });
  const { id } = useParams();
  const handleChenge = (e) => {
    const { value, name } = e.target;
    setGoogle_Traffic((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setGoogle_Traffic((prev) => ({
      ...prev,
      Location: value,
    }));
  };
  const fetchLocation = async () => {
    if (Google_Traffic.Location) {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            Google_Traffic.Location
          )}&key=${Google_Traffic?.GoogleAPIkey}`
        );

        if (response.data.results.length > 0) {
          const { lat, lng } = response.data.results[0].geometry.location;
          setLocation({ lat, lng });
        } else {
          setLocation({ lat: 0, lng: 0 });
        }
      } catch (error) {
        console.error("Error fetching the location:", error);
        setLocation({ lat: 0, lng: 0 });
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchLocation();
  }, [Google_Traffic.Location, Google_Traffic.GoogleAPIkey, Google_Traffic]);
  const autocompleteRef = useRef(null);

  const handleOnSaveInstanceName = () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    setEdited(false);
  };
  const onSubmit = async () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    // if (!location || !zoomLevel || !theme || !googleApiKey) {
    //   return toast.error("All fields are required.");
    // }
    let Payload = {
      googleTrafficAppId: id ? id : 0,
      instanceName: instanceName,
      theme: Google_Traffic?.Theme,
      location: Google_Traffic?.Location,
      zoomLevel: Google_Traffic?.ZoomLevel,
      googleApiKey: Google_Traffic?.GoogleAPIkey,
    };
    toast.loading("Saving ...");
    setSaveLoading(true);
    try {
      await dispatch(handleAddGoogleTraffic(Payload)).then((res) => {
        toast.remove();
        setSaveLoading(false);
        navigate("/Google-Traffic");
      });
    } catch (error) {
      console.log("error :>> ", error);
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    if(id){
      dispatch(getGoogleTrafficById(id))
        .then((response) => {
          if (response?.payload?.status === 200) {
            const data = response?.payload?.data?.list;
            setGoogle_Traffic((pre) => ({
              Location: data?.location,
              GoogleAPIkey: data?.googleApikey,
              Theme: data?.theme,
              ZoomLevel: data?.zoomLevel,
            }));
            setInstanceName(data?.instanceName);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [id, dispatch]);

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
          user?.userDetails?.isRetailer === false &&
          !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div>
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter Instance name"
                        value={instanceName}
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      if (
                        Google_Traffic.GoogleAPIkey &&
                        Google_Traffic.Location
                      )
                        return setShowPreview(!showPreview);
                      toast.error("Please Enter All Value");
                    }}
                  >
                    {showPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    className="flex  align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      if (
                        Google_Traffic.GoogleAPIkey &&
                        Google_Traffic.Location
                      )
                        return onSubmit();
                      toast.error("Please Enter All Value");
                    }}
                    disabled={saveLoading}
                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>
                  <Link to="/Google-Traffic">
                    <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>
              {showPreview ? (
                <div className="w-full h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center mt-5">
                  <LoadScript googleMapsApiKey={Google_Traffic.GoogleAPIkey}>
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      {location.lat !== 0 && location.lng !== 0 ? (
                        <GoogleMap
                          center={location}
                          zoom={parseInt(Google_Traffic.ZoomLevel)}
                          mapContainerStyle={{
                            height: "100%",
                            width: "100%",
                          }}
                          options={{
                            styles:
                              Google_Traffic.Theme === "true"
                                ? darkTheme
                                : lightTheme,
                            disableDefaultUI: false,
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            trafficLayer: true,
                          }}
                        >
                          <TrafficLayer />
                        </GoogleMap>
                      ) : (
                        <div className="flex items-center justify-center h-full w-full">
                          <p>Enter Valid APi Key</p>
                        </div>
                      )}
                      <h1
                        className=""
                        style={{
                          position: "absolute",
                          top: "85%",
                          left: "10px",
                          color: "#fff",
                          zIndex: 1000,
                        }}
                      >
                        Traffic: {Google_Traffic.Location}
                      </h1>
                    </div>
                  </LoadScript>
                </div>
              ) : (
                <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                  <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                    <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Location *
                      </label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="text"
                        id="first_name"
                        placeholder="Select Location"
                        value={Google_Traffic.Location}
                        onChange={handleLocationChange}
                      />
                    </div>
                    <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                      <label
                        htmlFor="message"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Zoom Level*
                      </label>
                      <select
                        id="countries"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        defaultValue={""}
                        name="ZoomLevel"
                        value={Google_Traffic.ZoomLevel}
                        onChange={handleChenge}
                      >
                        <option value="" disabled>
                          Select Zoom
                        </option>
                        {Array.from({ length: 16 }, (_, i) => (
                          <option key={i} value={`${20 - i}`}>
                            Level {20 - i}
                          </option>
                        ))}
                        {/* {GoogleTrafficZoomLevel?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))} */}
                      </select>
                    </div>
                    <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                      <label
                        htmlFor="message"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Theme*
                      </label>
                      <select
                        id="countries"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        defaultValue={""}
                        name="Theme"
                        value={Google_Traffic?.Theme}
                        onChange={handleChenge}
                      >
                        <option value="" disabled>
                          Select Theme{" "}
                        </option>
                        <option value="true">Dark</option>
                        <option value="false">Light</option>
                        {/* {GoogleTrafficTheme?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))} */}
                      </select>
                    </div>
                    <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                      <label
                        htmlFor="message"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Google Api Key *
                      </label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="text"
                        placeholder="Key"
                        name="GoogleAPIkey"
                        value={Google_Traffic.GoogleAPIkey}
                        onChange={handleChenge}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                    <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none">
                      {Google_Traffic.Location &&
                      Google_Traffic.ZoomLevel &&
                      Google_Traffic.Theme &&
                      Google_Traffic.GoogleAPIkey ? (
                        Loading ? (
                          ""
                        ) : (
                          <LoadScript
                            googleMapsApiKey={Google_Traffic.GoogleAPIkey}
                          >
                            <div
                              style={{
                                position: "relative",
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <GoogleMap
                                center={location}
                                zoom={parseInt(Google_Traffic.ZoomLevel)}
                                mapContainerStyle={{
                                  height: "100%",
                                  width: "100%",
                                }}
                                options={{
                                  styles:
                                    Google_Traffic.Theme === "true"
                                      ? darkTheme
                                      : lightTheme,
                                  disableDefaultUI: false,
                                  zoomControl: false,
                                  streetViewControl: false,
                                  mapTypeControl: false,
                                  fullscreenControl: false,
                                  trafficLayer: true,
                                }}
                              >
                                <TrafficLayer />
                              </GoogleMap>

                              <h1
                                className=""
                                style={{
                                  position: "absolute",
                                  top: "85%",
                                  left: "10px",
                                  color: "#fff",
                                  zIndex: 1000,
                                }}
                              >
                                Traffic: {Google_Traffic.Location}
                              </h1>
                            </div>
                          </LoadScript>
                        )
                      ) : (
                        <div className="text-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-16 w-16 mx-auto text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 7V3h18v4M4 10h16v4H4v-4z"
                            />
                          </svg>
                          <p className="text-gray-500">App instance preview</p>
                          <p className="text-sm text-gray-400">
                            Please edit the app settings to get started
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
