import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { GoPencil } from "react-icons/go";
import { MdSave } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getWallIoById, handleAddWallIo } from "../../../Redux/AppsSlice";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";

export const WallsIoDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const { user, userDetails, token } = useSelector((state) => state.root.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [edited, setEdited] = useState(false);
  const [instanceName, setInstanceName] = useState(
    moment().format("YYYY-MM-DD hh:mm A")
  );
  const [wallsIo, setWallsIo] = useState({
    Url: "",
    Theme: "fluid",
    ShowHeader: false,
    AutoScroll: false,
  });
  const [showPreview, setShowPreview] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setWallsIo((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const regex = /^https:\/\/my\.walls\.io/;
  const handleCheckBoxChange = (e) => {
    const { name, checked } = e.target;
    setWallsIo((pre) => ({
      ...pre,
      [name]: checked,
    }));
  };
  const handleOnSaveInstanceName = () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    setEdited(false);
  };

  const onSubmit = async () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    if (!regex.test(wallsIo?.Url)) {
      return toast.error(
        "Invalid URL. Please enter a valid my.walls.io Public URL."
      );
    }

    let Payload = {
      wallsioAppId: id ? id : 0,
      instanceName: instanceName,
      theme: wallsIo?.Theme,
      url: wallsIo?.Url,
      showHeader: wallsIo?.ShowHeader,
      autoScroll: wallsIo?.AutoScroll,
    };
    toast.loading("Saving ...");
    setSaveLoading(true);
    try {
      await dispatch(handleAddWallIo(Payload)).then((res) => {
        toast.remove();
        setSaveLoading(false);
        navigate("/Wallsio");
      });
    } catch (error) {
      console.log("error :>> ", error);
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    if(id){
      dispatch(getWallIoById(id))
        .then((response) => {
          if (response?.payload?.status === 200) {
            const data = response?.payload?.data?.list;
            setWallsIo((pre) => ({
              Url: data?.url,
              Theme: data?.theme,
              ShowHeader: data?.showHeader,
              AutoScroll: data?.AutoScroll,
            }));
            setInstanceName(data?.instanceName);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [id, dispatch]);
  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
          user?.userDetails?.isRetailer === false &&
          !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div className="lg:flex lg:justify-between sm:block my-4 items-center">
              <div className="flex items-center">
                {edited ? (
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      className="w-full border border-primary rounded-md px-2 py-1"
                      placeholder="Enter Instance name"
                      value={instanceName}
                      onChange={(e) => {
                        setInstanceName(e.target.value);
                      }}
                    />
                    <MdSave
                      onClick={() => handleOnSaveInstanceName()}
                      className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                      {instanceName}
                    </h1>
                    <button onClick={() => setEdited(true)}>
                      <GoPencil className="ml-4 text-lg" />
                    </button>
                  </div>
                )}
              </div>
              <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                <button
                  className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                  onClick={() => {
                    if (wallsIo?.Url && wallsIo?.Theme)
                      return setShowPreview(!showPreview);
                    toast.error("Please Enter All Value");
                  }}
                >
                  {showPreview ? "Edit" : "Preview"}
                </button>
                <button
                  className="flex  align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                  onClick={() => {
                    if (wallsIo?.Url && wallsIo?.Theme) return onSubmit();
                    toast.error("Please Enter All Value");
                  }}
                  disabled={saveLoading}
                >
                  {saveLoading ? "Saving..." : "Save"}
                </button>
                <Link to="/Wallsio">
                  <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                    <AiOutlineClose />
                  </button>
                </Link>
              </div>
            </div>

            {showPreview ? (
              regex.test(wallsIo?.Url) ? (
                <div className="w-full h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-black flex items-center justify-center mt-5">
                  <iframe
                    id="wallsio-iframe"
                    src={`${wallsIo?.Url}colorscheme=dark&layout=${
                      wallsIo?.Theme
                    }&show_header=${
                      wallsIo?.ShowHeader ? 1 : 0
                    }&show_post_info=1&accessibility=0&autoscroll=${
                      wallsIo?.AutoScroll ? 1 : 0
                    }`}
                    style={{ height: "100%", width: "100%" }}
                    loading="lazy"
                    title="Walls.io Feed"
                  ></iframe>
                </div>
              ) : (
                <div className="w-full h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-black flex items-center justify-center mt-5">
                  <p className="text-gray-500 text-2xl font-semibold">
                    Invalid URL. Please enter a my.walls.io URL.
                  </p>
                </div>
              )
            ) : (
              <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full border-[#E4AA07] border-2 p-3 rounded-lg ">
                    <label
                      htmlFor="countries"
                      className="text-sm font-medium text-gray-900 dark:text-white flex flex-row justify-between "
                    >
                      <div className="text-xl text-[#927400] font-bold ">ⓘ</div>
                      <div className="ml-4 text-md">
                        Add a social wall to your screen in 5 minutes. Sign up
                        for free at http://walls.io/sign-up. Create a social
                        wall and add your content. Copy your wall URL and paste
                        it below.
                      </div>
                    </label>
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      URL*
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      name="Url"
                      placeholder="My Walls.io public URL"
                      value={wallsIo?.Url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full ">
                    <label
                      htmlFor="message"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Theme*
                    </label>
                    <select
                      id="countries"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      defaultValue={"5"}
                      name="Theme"
                      value={wallsIo?.Theme}
                      onChange={handleChange}
                    >
                      <option value="fluid">Fluid</option>
                      <option value="bricks">Bricks</option>
                      <option value="kiosk">Kiosk</option>
                      <option value="billboard">Billboard</option>
                      <option value="grid">Grid</option>
                      <option value="map">Map</option>
                    </select>
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Auto Scroll
                    </label>
                    <div className="text-right">
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          readOnly
                          name="AutoScroll"
                          checked={wallsIo?.AutoScroll}
                          onChange={handleCheckBoxChange}
                        />
                        <div
                          className={`w-11 h-6 ${
                            wallsIo?.AutoScroll ? "bg-SlateBlue" : "bg-gray-300"
                          } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                        ></div>
                        <div className="relative inline-block"></div>
                      </label>
                    </div>
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Show Header
                    </label>
                    <div className="text-right">
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          readOnly
                          name="ShowHeader"
                          checked={wallsIo?.ShowHeader}
                          onChange={handleCheckBoxChange}
                        />
                        <div
                          className={`w-11 h-6 ${
                            wallsIo?.ShowHeader ? "bg-SlateBlue" : "bg-gray-300"
                          } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                        ></div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                  <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-black flex items-center justify-center overscroll-none">
                    {wallsIo?.Url && wallsIo?.Theme ? (
                      regex.test(wallsIo?.Url) ? (
                        loading ? (
                          ""
                        ) : (
                          <div className="md:w-[576px] md:h-[324px] sm:w-[384px] sm:h-[216px] lg:w-[860px] lg:h-[440px] w-72 h-72 flex items-center justify-center overflow-hidden scrollbar-hide ">
                            <iframe
                              id="wallsio-iframe"
                              src={`${wallsIo?.Url}colorscheme=dark&layout=${
                                wallsIo?.Theme
                              }&show_header=${
                                wallsIo?.ShowHeader ? 1 : 0
                              }&show_post_info=1&accessibility=0&autoscroll=${
                                wallsIo?.AutoScroll ? 1 : 0
                              }`}
                              style={{ height: "100%", width: "100%" }}
                              loading="lazy"
                              title="Walls.io Feed"
                            ></iframe>
                          </div>
                        )
                      ) : (
                        <div className="w-full h-full flex items-center justify-center text-center">
                          <p className="text-gray-500 text-xl font-semibold">
                            Invalid URL. Please enter a my.walls.io URL.
                          </p>
                        </div>
                      )
                    ) : (
                      <div className=" bg-black w-[100%] h-[100%] flex items-center justify-center">
                        <h5 className=" text-white">
                          Invalid URL. Please enter a my.walls.io URL.
                        </h5>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
