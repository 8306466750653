import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { AddBankDetails, getBankDetailsbyUser } from '../../Redux/BankSlice';
import toast from 'react-hot-toast';

export default function BankDetails() {
    const { register, handleSubmit, setValue, formState: { errors }, } = useForm();
    const dispatch = useDispatch();
    const { bankDetails, loading } = useSelector((state) => state.root.Bank);

    const onSubmit = (params) => {

        const Payload = {
            bankDetailsID: bankDetails?.bankDetailsID,
            bankName: params?.bankName,
            accountName: params?.accountName,
            ifscCode: params?.ifscCode,
            accountNo: params?.accountNo,
            accountHolderName: params?.holderName,
            branchName: params?.branchName,
        }
        dispatch(AddBankDetails(Payload)).then((res) => {
            if (res?.payload?.status === 200) {
                toast.success('BankDetails Edited Successfully.')
            }
        })
    }

    useEffect(() => {
        dispatch(getBankDetailsbyUser({})).then((res) => {
            const bankdata = res?.payload?.data
            setValue('bankName', bankdata?.bankName);
            setValue('accountName', bankdata?.accountName);
            setValue('ifscCode', bankdata?.ifscCode);
            setValue('accountNo', bankdata?.accountNo);
            setValue('holderName', bankdata?.accountHolderName);
            setValue('branchName', bankdata?.branchName);
        })

    }, [dispatch]);

    return (
        <>
            <div className="rounded-xl mt-5 shadow-lg bg-white">
                <h4 className="text-xl font-semibold p-5 ">Bank Details</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="p-5 pt-0 mb-5 flex flex-col">
                        <div className="-mx-3 md:flex ">
                            <div className="md:w-1/2 px-3">
                                <label className="label_top text-xs">Bank Name </label>
                                <input
                                    className="w-full text-black border rounded-lg py-3 px-4"
                                    type="text"
                                    name='bankName'
                                    {...register("bankName", {
                                        required: "Bank Name is required",
                                    })}
                                />
                                <span className="error">{errors?.bankName?.message}</span>
                            </div>
                            <div className="md:w-1/2 px-3">
                                <label className="label_top text-xs">Branch Name</label>
                                <input
                                    className="w-full  text-black border  rounded-lg py-3 px-4"
                                    type="text"
                                    name="branchName"
                                    {...register("branchName", {
                                        required: "Branch Name is required",

                                    })}
                                />
                                <span className="error">{errors?.branchName?.message}</span>
                            </div>
                        </div>
                        <div className="-mx-3 md:flex">

                            <div className="md:w-1/2 px-3">
                                <label className="label_top text-xs">Account Name </label>
                                <input
                                    className="w-full  text-black border  rounded-lg py-3 px-4"
                                    type="text"
                                    name='accountName'
                                    {...register("accountName", {
                                        required: "Account Name is required",
                                    })}
                                />
                                {errors.accountName && (
                                    <span className="error">{errors?.accountName?.message}</span>
                                )}
                            </div>
                            <div className="md:w-1/2 px-3">
                                <label className="label_top text-xs">Account No</label>
                                <input
                                    className="w-full  text-black border  rounded-lg py-3 px-4"
                                    type="number"
                                    {...register("accountNo", {
                                        required: "Account Number is required",
                                        // minLength: {
                                        //     value: 8,
                                        //     message: "Account Number must be at least 8 digits",
                                        // },
                                        // maxLength: {
                                        //     value: 16,
                                        //     message: "Account Number cannot exceed 16 digits",
                                        // },
                                        pattern: {
                                            value: /^[0-9]{9,18}$/,
                                            message: "Invaild Account Number.",
                                        },
                                    })}
                                    onInput={(e) => {
                                        let inputValue = e.target.value.replace(/\D/g, '');
                                        if (inputValue.length > 16) {
                                            inputValue = inputValue.slice(0, 16);
                                            setValue("accountNo", inputValue)
                                        }
                                    }}
                                    name='accountNo'
                                />
                                <span className="error">{errors?.accountNo?.message}</span>
                            </div>
                        </div>
                        <div className="-mx-3 md:flex">

                            <div className="md:w-1/2 px-3">
                                <label className="label_top text-xs">Holder Name </label>
                                <input
                                    name='holderName'
                                    className="w-full  text-black border  rounded-lg py-3 px-4"
                                    type="text"
                                    {...register("holderName", {
                                        required: "Holder Name is required",
                                    })}
                                />
                                <span className="error">{errors?.holderName?.message}</span>
                            </div>
                            <div className="md:w-1/2 px-3">
                                <label className="label_top text-xs">IFSC Code </label>
                                <input

                                    className="w-full  text-black border  rounded-lg py-3 px-4"
                                    name="ifscCode"
                                    type="text"
                                    {...register("ifscCode", {
                                        required: "IFSC Code is required",
                                        pattern: {
                                            value: /^[A-Z]{4}0[A-Z0-9]{6}$/, // Regex for IFSC code format
                                            message: "Invalid IFSC Code format",
                                        },
                                    })}
                                />
                                <span className="error">{errors?.ifscCode?.message}</span>
                            </div>
                        </div>


                        <div className="-mx-3 md:flex mt-5">
                            <div className="md:w-full px-3 flex">
                                <button
                                    type="submit"
                                    className="px-5 bg-primary text-white rounded-full py-2 border border-primary me-3"
                                    disabled={loading}
                                >
                                    {/* Save */}
                                    {loading ? "Saving..." : "Save Changes"}
                                </button>
                                <button
                                    className=" px-5 py-2 border border-primary rounded-full text-primary"
                                    type="reset"
                                    disabled={loading}
                                >
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
