import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { onThisDayStaticData } from "../../Common/Common";

export const EntertainmentNewsPreview = ({
  instanceName,
  setInstanceView,
  showTags,
  screenAssignName,
  entertainmentNewsDataById,
}) => {
  return (
    <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
      <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-[1000px] h-[700px]">
        <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
          <div className="flex items-center">
            <div>
              <img
                src="https://back.disploy.com/app/entertainment-news.svg"
                alt="Logo"
                className="cursor-pointer mx-auto h-10 w-10"
              />
            </div>
            <div className="ml-3">
              <h4 className="text-lg font-medium whitespace-wrap ">
                {instanceName}
              </h4>
            </div>
          </div>
          <button
            className="p-1 text-3xl"
            onClick={() => setInstanceView(false)}
          >
            <AiOutlineCloseCircle />
          </button>
        </div>
        <Swiper
          modules={[Autoplay]}
          centeredSlides={true}
          slidesPerView={1}
          allowTouchMove={false}
          loop={false}
          autoplay={{
            delay: entertainmentNewsDataById?.postDurationInSec * 1000,
            disableOnInteraction: false,
          }}
          onSlideChange={(swiper) => {
            if (swiper.isEnd) {
              if (entertainmentNewsDataById?.isResumePlayback === true) {
                swiper.autoplay.stop();
              } else {
                swiper.autoplay.start();
              }
            }
          }}
        >
          {onThisDayStaticData?.[
            entertainmentNewsDataById?.entertainmentType
          ]?.map((items, index) => (
            <SwiperSlide key={index}>
              <div className="absolute inset-0 bg-sky-100 flex justify-start text-left items-center">
                {items?.image ? (
                  <>
                    <div
                      className="flex items-center justify-center"
                      style={{ width: "50%" }}
                    >
                      <img
                        src="https://back.disploy.com/app/entertainment-news.svg"
                        alt="Google Alerts Icon"
                        className="w-16 h-16"
                      />
                    </div>
                    <div style={{ width: "50%" }} className="ml-10 mr-10">
                      <h1 className="text-2xl font-bold mb-5">{items.title}</h1>
                      <p className="text-sm text-gray-600 mb-5">
                        {items?.updatedTime}
                      </p>
                      <p className="text-xl">{items.content}</p>
                      <div className="flex align-center justify-between mt-5 ml-10 mr-10">
                        <div className="justify-start flex">
                          <div
                            className="flex items-center justify-center"
                            style={{ width: "50%" }}
                          >
                            <img
                              src="https://back.disploy.com/app/entertainment-news.svg"
                              alt="Google Alerts Icon"
                              className="w-16 h-16"
                            />
                            <div className="w-full h-10 rounded-full bg-[#EA7C73] flex justify-center items-center p-6 m-2.5 text-lg text-white font-semibold ml-10">
                              {entertainmentNewsDataById?.entertainmentType}
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end">
                          {entertainmentNewsDataById?.isShowQRCode && (
                            <QRCodeSVG
                              value={items?.qrCode}
                              size={100}
                              fgColor="#000000"
                              bgColor="#ffffff"
                              className="p-4"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="flex items-center justify-center"
                      style={{ width: "30%" }}
                    >
                      <img
                        src="https://back.disploy.com/app/entertainment-news.svg"
                        alt="Google Alerts Icon"
                        className="w-16 h-16"
                      />
                    </div>
                    <div className="ml-10 mr-10">
                      <h1 className="text-2xl font-bold mb-5">{items.title}</h1>
                      <p className="text-sm text-gray-600 mb-5">
                        {items?.updatedTime}
                      </p>
                      <p className="text-xl">{items.content}</p>
                      <div className="flex justify-end">
                        {entertainmentNewsDataById?.isShowQRCode && (
                          <QRCodeSVG
                            value={items?.qrCode}
                            size={100}
                            fgColor="#000000"
                            bgColor="#ffffff"
                            className="p-4"
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="py-2 px-6">
          <div className="flex items-center gap-2 w-full">
            <div className="font-semibold w-fit">Tags:-</div>
            <div className=" w-full">{showTags}</div>
          </div>
          <div>
            <label className="font-semibold">Screen Assign :</label>
            {screenAssignName ? screenAssignName : " No Screen"}
          </div>
        </div>
      </div>
    </div>
  );
};
