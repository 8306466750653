import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CountDown_Img from "../../images/AppsImg/count-down-timer.svg";


export const CountDownPreview = ({ setInstanceView, countDownDataById }) => {
  return (
    <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-[1000px] h-[600px]">
        <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
            <div className="flex items-center">
              <div>
                <img
                src={CountDown_Img}
                  alt="Logo"
                  className="cursor-pointer mx-auto h-10 w-10"
                />
              </div>
              <div className="ml-3">
                <h4 className="text-lg font-medium whitespace-wrap ">
                  {countDownDataById?.instanceName}
                </h4>
              </div>
            </div>
            <button
              className="p-1 text-3xl"
              onClick={() => setInstanceView(false)}
            >
              <AiOutlineCloseCircle />
            </button>
          </div>
    

          <div className="py-2 px-6">
            <div className="flex items-center gap-2 w-full">
              <div className="font-semibold w-fit">Tags:-</div>
              <div className=" w-full">{countDownDataById?.tags}</div>
            </div>
            <div>
              <label className="font-semibold">Screen Assign :</label>
              {countDownDataById?.screens ? countDownDataById?.screens : " No Screen"}
            </div>
          </div>
    </div>
    </div>
  );
};
