import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineClose, } from "react-icons/ai";
import { MdSave } from "react-icons/md";
import { useSelector } from "react-redux";
import { Embed_INSTANCE_ADD_URL, GET_Embed_BY_ID } from "../../Pages/Api";
import axios from "axios";

const Embeddetail = ({ sidebarOpen, setSidebarOpen }) => {
  const currentDate = new Date();
  const { id } = useParams();
  const navigate = useNavigate();


  const { user, token, userDetails } = useSelector((state) => state.root.auth);
  const authToken = `Bearer ${token}`;
  const [instanceName, setInstanceName] = useState(
    moment(currentDate).format("YYYY-MM-DD hh:mm")
  );

  const [saveLoading, setSaveLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [url, setUrl] = useState(""); // State to hold the iframe string entered by the user
  const [embedUrl, setEmbedUrl] = useState("");
  const [Zoom, setZoom] = useState(1);
  const [ShowPreview, setShowPreview] = useState(false);
  const googleMapsApiKey = 'AIzaSyDL9J82iDhcUWdQiuIvBYa0t5asrtz3Swk'
  const mapUrl = `${embedUrl}?zoom=${Zoom}&center=23.032608879079945,72.6366841120034&key=${googleMapsApiKey}`;
  const handleOnSaveInstanceName = (e) => {
    if (!instanceName.replace(/\s/g, "").length) {
      toast.remove();
      return toast.error("Please enter at least minimum 1 character.");
    }
    setEdited(false);
  };

  const handelembedchange = (e) => {
    const { value } = e.target;
    setUrl(value);

  };


  useEffect(() => {
    const regex = /<iframe.*?src=["'](.*?)["'].*?>/;
    const match = url ? url.match(regex) : '';
    if (match && match[1]) {
      setEmbedUrl(match[1]);
    }
  }, [url, embedUrl]);

  // const handleZoomChange = (e) => {
  //   const { value } = e.target;
  //   setZoom(value)
  // };

  const handleZoomChange = (e) => {
    const value = parseInt(e.target.value);

    if (value >= 1 && value <= 10) {
      setZoom(value);
    }
  };

  const addEmbedApp = () => {
    if (instanceName === "" || Zoom === "" || url === "") {
      toast.remove();
      return toast.error("Please fill all the details");
    }

    const data = {
      embedAppId: id ? id : 0,
      instanceName: instanceName,
      embedCode: url,
      zoomFactor: Zoom,
    };


    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: Embed_INSTANCE_ADD_URL,
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setSaveLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (window.history.length === 1) {
          localStorage.setItem("isWindowClosed", "true");
          window.close();
        } else {
          navigate("/Embed");
        }
        setSaveLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSaveLoading(false);
      });
  };


  useEffect(() => {
    if (id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${GET_Embed_BY_ID}?embedAppId=${id}`,
        headers: {
          Authorization: authToken,
        },
      };
      axios.request(config).then((response) => {
        const data = response?.data?.data;
        setZoom(data?.zoomFactor);
        setUrl(data?.embedCode);
      });
    }
  }, [id]);

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
            user?.userDetails?.isRetailer === false &&
            !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div>
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter schedule name"
                        value={instanceName}
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className={` flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50`}
                    onClick={() => {
                      if (url === "")
                        return toast.error("Please enter Embed URL");
                      setShowPreview(!ShowPreview);
                    }}
                  >
                    {ShowPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => addEmbedApp()}
                    disabled={""}
                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>

                  <Link to="/Embed">
                    <button
                      className={`  sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 `}
                    >
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>
              {ShowPreview && (
                <div className="w-[95%] xl:h-[700px] lg:h-[500px] mx-auto bg-gray-50 rounded-sm shadow-md border-4 border-black flex items-center justify-center min-h-[100px] overflow-hidden">
                  <iframe
                    src={embedUrl}
                    height="100%"
                    width="100%"
                    title="Embedded Page"
                    className="rounded-sm border-2 border-gray-300"
                    style={{ transform: `scale(${embedUrl.includes('https://www.youtube.com') ? 1 : Zoom ? Zoom : 1})` }}
                  ></iframe>
                </div>
              )}
              {!ShowPreview && (
                <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                  <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Embed code*
                      </label>

                      <input
                        type="Text"
                        name="url"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="e.g. <iframe src='https://embed-app.screen.cloud/'></iframe>"
                        value={url}
                        onChange={handelembedchange}
                      />
                    </div>
                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Zoom factor
                      </label>

                      <input
                        type="number"
                        name="Zoom"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Value from 1 to 10"
                        value={Zoom}
                        onChange={handleZoomChange}
                      />
                    </div>
                  </div>

                  {/* <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                    <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none">
                      <iframe
                        src={`${embedUrl}&zoom=${Zoom || 1}`}
                        height="100%"
                        width="100%"
                        title="Embedded Page"
                        className="rounded-sm border-2 border-gray-300"
                        allowfullscreen={false}
                        loading="lazy"
                        style={{ transform: `scale(${embedUrl.includes('https://www.youtube.com') ? 1 : Zoom ? Zoom : 1})` }}
                      ></iframe>
                    </div>
                  </div> */}
                  <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                    <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overflow-hidden">
                      <iframe
                        src={`${embedUrl}&zoom=${Zoom || 1}`}
                        height="100%"
                        width="100%"
                        title="Embedded Map"
                        className="rounded-sm border-2 border-gray-300"
                        allowfullscreen={false}
                        loading="lazy"
                        style={{
                          transform: `scale(${Zoom ? (Zoom <= 3 ? 1.2 : Zoom <= 8 ? 1.5 : Zoom <= 10 ? 2 : 3) : 1})`,
                          transformOrigin: 'center',
                          transition: 'transform 0.3s ease',

                        }}
                      ></iframe>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Embeddetail;
