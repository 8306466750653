import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TbAppsFilled } from "react-icons/tb";
import { MdArrowBackIosNew, MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin5Line, RiDeleteBinLine } from "react-icons/ri";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
import toast from "react-hot-toast";
import axios from "axios";
import moment from "moment";
import { useJsApiLoader } from "@react-google-maps/api";
import { AiOutlineCloseCircle } from "react-icons/ai";

import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";
import { AddTagsWordClockData, DeleteWordClockData, GetByIdWordClockData, GetWordClockData } from "../../../Redux/WorldClockSlice";
import AddOrEditTagPopup from "../../AddOrEditTagPopup";
import { socket } from "../../../App";
import ScreenAssignModal from "../../ScreenAssignModal";
import WorldClockPreview from "./WorldClockPreview";
import Loading from "../../Loading";
import SweetAlert from "../../BookYourSlot/SweetAlert";
import { WORLD_CLOCK_ALLDELETE, WORLD_CLOCK_SETSCREEN } from "../../../Pages/Api";
import worldClock from "../../../images/AppsImg/world-clock.svg";

const ct = require("countries-and-timezones");
const libraries = ["places"];

export default function WorldClock({ sidebarOpen, setSidebarOpen }) {
  const { userDetails, user, token } = useSelector((state) => state.root.auth);
  const GoogleAPIkey = "AIzaSyDL9J82iDhcUWdQiuIvBYa0t5asrtz3Swk";
  const authToken = `Bearer ${token}`;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addScreenRef = useRef();
  const appDropdownRef = useRef(null);
  const [selectAll, setselectAll] = useState(false);
  const [instanceID, setInstanceID] = useState();
  const [appDropDown, setAppDropDown] = useState(null);
  const [word_clock, setWord_Clock] = useState([]);
  const [word_clockbyid, setWorld_ClockById] = useState({
    InstanceName: "",
    Tags: "",
    ScreenAssignName: "",
    ClockType: "",
    locations: [
      {
        location: "",
        plaseholder: "New York,us",
        formatted_address: "",
      },
      {
        location: "",
        plaseholder: "Bankok,Thailend",
        formatted_address: "",
      },
      {
        location: "",
        plaseholder: "",
        formatted_address: "",
      },
      {
        location: "",
        plaseholder: "",
        formatted_address: "",
      },
      {
        location: "",
        plaseholder: "",
        formatted_address: "",
      },
      {
        location: "",
        plaseholder: "",
        formatted_address: "",
      },
    ],
  });
  const [filterclock, setFilterClock] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [updateworld_clockTag, setUpdateWorld_ClockTag] = useState(null);
  const [tags, setTags] = useState(null);
  const [instanceView, setInstanceView] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [selectdata, setSelectData] = useState({});
  const [addScreenModal, setAddScreenModal] = useState(false);
  const [selectScreenModal, setSelectScreenModal] = useState(false);
  const [selectedScreens, setSelectedScreens] = useState([]);
  const [screenSelected, setScreenSelected] = useState([]);
  const [loadingModel, setLoadingModel] = useState(false);
  const [loadFirst, setloadFirst] = useState(true);

  const handleSelectAll = () => {
    setselectAll((prev) => !prev);
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDL9J82iDhcUWdQiuIvBYa0t5asrtz3Swk",
    libraries,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        appDropdownRef.current &&
        !appDropdownRef.current.contains(event?.target)
      ) {
        setAppDropDown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (loadFirst) {
      setLoading(true);
      dispatch(GetWordClockData({ authToken })).then((res) => {
        const data = res?.payload?.data?.list;
        setWord_Clock(data);
        setLoading(false)
      })
      setloadFirst(false)
    }
  }, [dispatch, loadFirst]);

  const handleCheckboxChange = (instanceId) => {
    const updatedInstance = word_clock?.map((date) =>
      date.worldClockAppId === instanceId
        ? {
          ...date,
          isChecked: !date.isChecked,
        }
        : date
    );
    setWord_Clock(updatedInstance);
    const allChecked = updatedInstance.every((date) => date.isChecked);
    setselectAll(allChecked);
  };

  const handleAppDropDownClick = (id) => {
    setInstanceID(id);
    if (appDropDown === id) {
      setAppDropDown(null);
    } else {
      setAppDropDown(id);
    }
  };

  const handelDeleteInstance = async (id) => {
    try {
      const result = await SweetAlert.confirm("Are you sure?", "Are you sure you want to delete this!");
      if (result?.isConfirmed) {
        dispatch(DeleteWordClockData({ authToken, id })).then((res) => {
          if (res?.payload?.status === 200) {
            setloadFirst(true)
            SweetAlert.success("Deleted successfully");
          }
        });
      }
    } catch (error) {
      console.error("Error:", error);
      SweetAlert.error("An error occurred");
    }
  };

  const DeleteAllWorldClock = () => {
    if (!window.confirm("Are you sure?")) return;

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: WORLD_CLOCK_ALLDELETE,
      headers: { Authorization: authToken },
    };

    toast.loading("Deleting...");
    axios
      .request(config)
      .then(() => {
        toast.remove();
        setloadFirst(true)
        setselectAll(false);
      })
      .catch((error) => {
        toast.error("Failed to delete instances.");
        console.log(error);
      });
  };

  const handleUpdateTagsWorld_Clock = async (tags) => {
    try {
      const id = updateworld_clockTag?.worldClockAppId;
      const response = await dispatch(AddTagsWordClockData({ authToken, id, tags })).unwrap();
      if (response?.status === 200) {
        const UpdateData = word_clock?.map((items) => {
          if (items?.worldClockAppId === id) {
            return { ...items, tags: tags };
          } else {
            return items;
          }
        });
        setWord_Clock(UpdateData);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFetcWorld_ClockById = async (id) => {
    try {
      setLoadingModel(true);
      setInstanceView(true);
      const response = await dispatch(
        GetByIdWordClockData({ authToken, id })
      ).unwrap();
      if (response?.status === 200) {
        const data = response?.data?.list;
        setWorld_ClockById((prev) => ({
          ...prev,
          Tags: data?.tags,
          InstanceName: data?.instanceName,
          ScreenAssignName: data?.screens,
          ClockType: data?.clockType,
          locations: prev.locations.map((item, index) => ({
            ...item,
            location: data[`location${index + 1}`] || "",
            formatted_address: data?.formatted_address?.[index] || "",
          })),
        }));
        setScreenSelected(data?.screens?.split(","));
        setTriggerFetch(true);
        setLoadingModel(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingModel(false);
    }
  };

  const fetchLocation = async () => {
    // console.log("call fetch");
    if (GoogleAPIkey) {
      try {
        word_clockbyid?.locations?.map(async (items, index) => {
          if (items?.location !== "") {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(items?.location)}&key=${GoogleAPIkey}`);
            if (response?.data?.results?.length > 0) {
              const { lat, lng } = response?.data?.results[0]?.geometry?.location;
              const geocoder = new window.google.maps.Geocoder();
              const countrycode = await new Promise((resolve, reject) => {

                geocoder.geocode(
                  { location: { lat, lng } },
                  (results, status) => {
                    if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
                      const country = results[0].address_components.find((component) => component.types.includes("country"));
                      resolve(country ? country?.short_name : null);
                    } else {
                      resolve(null);
                    }
                  }
                );
              });
              const country = ct.getCountry(countrycode);
              const timezone = country?.timezones[0];
              setWorld_ClockById((prev) => {
                const updatedLocations = [...prev.locations];
                updatedLocations[index] = {
                  ...updatedLocations[index],
                  timezone: timezone,
                  formatted_address:
                    response?.data?.results[0]?.formatted_address
                      ?.split(",")[0]
                      ?.trim(),
                };
                return {
                  ...prev,
                  locations: updatedLocations,
                };
              });
            } else {
              setWorld_ClockById((prev) => {
                const updatedLocations = [...prev.locations];
                updatedLocations[index] = {
                  ...updatedLocations[index],
                  timezone: "",
                  formatted_address: "",
                };
                return {
                  ...prev,
                  locations: updatedLocations,
                };
              });
            }
          } else {
            setWorld_ClockById((prev) => {
              const updatedLocations = [...prev.locations];
              updatedLocations[index] = {
                ...updatedLocations[index],
                formatted_address: "",
                timezone: "",
              };
              return {
                ...prev,
                locations: updatedLocations,
              };
            });
          }
        });
      } catch (error) {
        console.error("Error fetching the location:", error);
      }
    }
  };
  useEffect(() => {
    fetchLocation();
    setTriggerFetch(false);
  }, [triggerFetch]);

  useEffect(() => {
    const time = () => {
      const intervalId = setInterval(() => {
        setWorld_ClockById((prev) => ({
          ...prev, // Correctly spread the previous state
          locations: prev?.locations?.map((item) => {
            if (!item?.location) {
              return item; // Return the item unchanged if location is missing
            }

            const timezone = item.timezone || "Asia/Kolkata";
            const currentTime = moment().tz(timezone);

            return {
              ...item,
              hours:
                word_clockbyid?.ClockType === "digital24"
                  ? currentTime.format("HH")
                  : currentTime.format("hh"),
              minutes: currentTime.format("mm"),
              second: currentTime.format("ss"),
              ampm:
                word_clockbyid?.ClockType === "digital24"
                  ? ""
                  : currentTime.format("A"),
            };
          }),
        }));
      }, 1000);

      return () => clearInterval(intervalId);
    };
    time();
  }, []);

  useEffect(() => {
    const filterdata = word_clockbyid?.locations?.filter(
      (item, index) => item?.location !== ""
    );
    setFilterClock(filterdata);
  }, [word_clockbyid]);

  const handleUpdateScreenAssign = async (screenIds, macids) => {
    try {
      let idS = "";
      for (const key in screenIds) {
        if (screenIds[key] === true) {
          idS += `${key},`;
        }
      }
      const config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: `${WORLD_CLOCK_SETSCREEN}?worldClockAppId=${instanceID}&screenIds=${idS}`,
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      };
      toast.loading("Saving...");
      axios
        .request(config)
        .then((response) => {
          if (response.data.status === 200) {
            toast.remove()
            const Params = {
              id: socket.id,
              connection: socket.connected,
              macId: macids,
            };
            socket.emit("ScreenConnected", Params);
          }
          setSelectScreenModal(false);
          setAddScreenModal(false);
          setloadFirst(true)
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.remove();
      console.error(error);
    }
  };

  return (
    <>
      {loading && <Loading />}

      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial && !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 pt-10 px-5"
            : "lg:pt-24 md:pt-24 pt-10 px-5 "
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="lg:flex lg:justify-between sm:block items-center">
            <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
              Apps
            </h1>
            <div className="lg:flex">
              <Link to="/WorldClockDetails">
                <button className="flex items-center bg-SlateBlue text-white rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2 text-base sm:text-sm mr-3 hover:bg-primary">
                  <TbAppsFilled className="text-2xl mr-2" />
                  New Instance
                </button>
              </Link>
              <Link to="/apps">
                <button className="flex items-center bg-SlateBlue text-white rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2 text-base sm:text-sm mr-3 hover:bg-primary">
                  <MdArrowBackIosNew className="text-2xl mr-2" />
                  Back
                </button>
              </Link>
            </div>
          </div>
          <div className="mt-5 mb-5">
            <div className="shadow-md bg-white rounded-lg p-5">
              <div className="flex justify-between items-center">
                <h1 className="font-medium text-xl text-[#001737]">
                  World Clock
                </h1>
                <div className="flex items-center">
                  <button
                    className="w-8 h-8 ml-2 border-primary items-center border-2 rounded-full px-1 text-2xl hover:bg-SlateBlue"
                    onClick={DeleteAllWorldClock}
                    style={{ display: selectAll ? "block" : "none" }}
                  >
                    <RiDeleteBinLine className="text-xl" />
                  </button>

                  <button className="sm:ml-2 xs:ml-1 mt-1">
                    <input
                      type="checkbox"
                      className="h-7 w-7"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </button>
                </div>
              </div>

              {loading ? (
                <div className="flex text-center m-5 justify-center">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-10 h-10 me-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                </div>
              ) : word_clock?.length > 0 ? (
                <div className=" grid grid-cols-12 gap-4 mt-5">
                  {word_clock?.map((item, index) => (
                    <div
                      key={index}
                      className="xl:col-span-2 lg:col-span-3 md:col-span-4 sm:col-span-12"
                    >
                      <div className="shadow-md bg-[#EFF3FF] rounded-lg h-full">
                        <div className="relative flex justify-between">
                          <button className="float-right p-2">
                            <input
                              className="h-5 w-5"
                              type="checkbox"
                              style={{
                                display: selectAll ? "block" : "none",
                              }}
                              checked={true}
                              onChange={() =>
                                handleCheckboxChange(item?.worldClockAppId)

                              }
                            />
                          </button>
                          <div className="relative">
                            <button className="float-right">
                              <BiDotsHorizontalRounded
                                className="text-2xl"
                                onClick={() =>
                                  handleAppDropDownClick(item?.worldClockAppId)
                                }
                              />
                            </button>
                            {appDropDown === item?.worldClockAppId && (
                              <div ref={appDropdownRef} className="appdw">
                                <ul className="space-y-2">
                                  <li
                                    onClick={() => navigate(`/WorldClockDetails/${item?.worldClockAppId}`)}
                                    className="flex text-sm items-center cursor-pointer"
                                  >
                                    <MdOutlineEdit className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                    Edit
                                  </li>
                                  <li
                                    className="flex text-sm items-center cursor-pointer"
                                    onClick={() => {
                                      setAddScreenModal(true);
                                      setSelectData(item);
                                    }}
                                  >
                                    <FiUpload className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                    Set to Screen
                                  </li>
                                  <li
                                    className="flex text-sm items-center cursor-pointer"
                                    onClick={() =>
                                      handelDeleteInstance(item?.worldClockAppId)
                                    }
                                  >
                                    <RiDeleteBin5Line className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                    Delete
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-center clear-both pb-8">
                          <img
                            src={worldClock}
                            alt="Logo"
                            className="cursor-pointer mx-auto h-20 w-20"
                            onClick={() => {
                              handleFetcWorld_ClockById(item?.worldClockAppId);
                            }}
                          />
                          <h4 className="text-lg font-medium mt-3">
                            {item?.instanceName}
                          </h4>
                          <h4
                            onClick={() => {
                              item?.tags !== null &&
                                item?.tags !== undefined &&
                                item?.tags !== ""
                                ? setTags(item?.tags?.split(","))
                                : setTags([]);
                              setShowTagModal(true);
                              setUpdateWorld_ClockTag(item);
                            }}
                            className="text-sm font-normal cursor-pointer"
                          >
                            Add tags +
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No World Clock data available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {addScreenModal && (
        <div className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-9990 outline-none focus:outline-none">
          <div
            ref={addScreenRef}
            className="w-auto my-6 mx-auto lg:max-w-4xl md:max-w-xl sm:max-w-sm xs:max-w-xs"
          >
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-4 px-6 border-b border-[#A7AFB7] border-slate-200 rounded-t text-black">
                <div className="flex items-center">
                  <h3 className="lg:text-lg md:text-lg sm:text-base xs:text-sm font-medium">
                    Select the screen to set the App
                  </h3>
                </div>
                <button
                  className="p-1 text-xl ml-8"
                  onClick={() => setAddScreenModal(false)}
                >
                  <AiOutlineCloseCircle className="text-2xl" />
                </button>
              </div>
              <div className="flex justify-center p-9 ">
                <p className="break-words w-[280px] text-base text-black text-center">
                  New Text-Scroll App Instance would be applied. Do you want to
                  proceed?
                </p>
              </div>
              <div className="pb-6 flex justify-center">
                <button
                  className="bg-primary text-white px-8 py-2 rounded-full"
                  onClick={() => {
                    if (selectdata?.screenIDs) {
                      let arr = [selectdata?.screenIDs];
                      let newArr = arr[0]
                        .split(",")
                        .map((item) => parseInt(item.trim()));
                      setSelectedScreens(newArr);
                    }
                    setSelectScreenModal(true);
                    setAddScreenModal(false);
                  }}
                >
                  OK
                </button>

                <button
                  className="bg-primary text-white px-4 py-2 rounded-full ml-3"
                  onClick={() => setAddScreenModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showTagModal && (
        <AddOrEditTagPopup
          setShowTagModal={setShowTagModal}
          tags={tags}
          setTags={setTags}
          handleUpdateTagsWorld_Clock={handleUpdateTagsWorld_Clock}
          from="World_Clock"
          setUpdateWorld_ClockTag={setUpdateWorld_ClockTag}
        />
      )}
      {instanceView && (
        <WorldClockPreview
          setInstanceView={setInstanceView}
          word_clockbyid={word_clockbyid}
          filterclock={filterclock}
          loadingModel={loadingModel}
        />
      )}
      {selectScreenModal && (
        <ScreenAssignModal
          setAddScreenModal={setAddScreenModal}
          setSelectScreenModal={setSelectScreenModal}
          handleUpdateScreenAssign={handleUpdateScreenAssign}
          selectedScreens={selectedScreens}
          setSelectedScreens={setSelectedScreens}
          screenSelected={screenSelected}
          sidebarOpen={sidebarOpen}
        />
      )}
    </>
  );
};


