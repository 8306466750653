import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getYelpById, handleAddYelp } from '../../../Redux/AppsSlice';
import Sidebar from '../../Sidebar';
import Navbar from '../../Navbar';
import { MdSave } from 'react-icons/md';
import { GoPencil } from 'react-icons/go';
import { AiOutlineClose } from 'react-icons/ai';
import toast from 'react-hot-toast';

export const YelpDetails = ({ sidebarOpen, setSidebarOpen }) => {
    const { user, userDetails, token } = useSelector((state) => state.root.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [edited, setEdited] = useState(false);
    const [instanceName, setInstanceName] = useState(
      moment().format("YYYY-MM-DD hh:mm A")
    );
    const [yelp, setYelp] = useState({
        Url: "",
        YelpFilter: "All",
        ItemDuration: 5,
    });
    const [showPreview, setShowPreview] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
  
    const handleChange = (e) => {
      const { value, name } = e.target;
      setYelp((pre) => ({
        ...pre,
        [name]: value,
      }));
    };
    const regex = /^https/;
    const handleOnSaveInstanceName = () => {
      if (!instanceName.replace(/\s/g, "").length) {
        return toast.error("Instance Name is Required.");
      }
      setEdited(false);
    };
  
    const onSubmit = async () => {
      if (!instanceName.replace(/\s/g, "").length) {
        return toast.error("Instance Name is Required.");
      }
      if (!regex.test(yelp?.Url)) {
        return toast.error(
          "Invalid URL. Please enter a valid my.walls.io Public URL."
        );
      }
  
      let Payload = {
        yelpAppId: id ? id : 0,
        instanceName: instanceName,
        ratingFilters: yelp?.YelpFilter,
        url: yelp?.Url,
        itemDurationInSec: yelp?.ItemDuration,
      };
      toast.loading("Saving ...");
      setSaveLoading(true);
      try {
        await dispatch(handleAddYelp(Payload)).then((res) => {
          toast.remove();
          setSaveLoading(false);
          navigate("/Yelp");
        });
      } catch (error) {
        console.log("error :>> ", error);
        setSaveLoading(false);
      }
    };
  
    useEffect(() => {
      if(id){
        dispatch(getYelpById(id))
          .then((response) => {
            if (response?.payload?.status === 200) {
              const data = response?.payload?.data;
              setYelp((pre) => ({
                Url: data?.url,
                YelpFilter: data?.ratingFilters,
                ItemDuration: data?.itemDurationInSec,
              }));
              setInstanceName(data?.instanceName);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }, [id, dispatch]);

    return (
      <>
        <div className="flex border-b border-gray">
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <Navbar />
        </div>
        <div
          className={
            userDetails?.isTrial &&
            user?.userDetails?.isRetailer === false &&
            !userDetails?.isActivePlan
              ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
              : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
          }
        >
          <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
            <div className="px-6 page-contain">
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter Instance name"
                        value={instanceName}
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      if (yelp?.Url)
                        return setShowPreview(!showPreview);
                      toast.error("Please Enter All Value");
                    }}
                  >
                    {showPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    className="flex  align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      if (yelp?.Url ) return onSubmit();
                      toast.error("Please Enter All Value");
                    }}
                    disabled={saveLoading}
                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>
                  <Link to="/Yelp">
                    <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>
  
              {showPreview ? (
                regex.test(yelp?.Url) ? (
                  <div className="w-[85%] h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-black flex items-center justify-center mt-5">
                    <iframe
                      id="wallsio-iframe"
                      src={yelp?.Url}
                      style={{ height: "100%", width: "100%" }}
                      loading="lazy"
                      title="Walls.io Feed"
                    ></iframe>
                  </div>
                ) : (
                  <div className="w-[85%] h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-black flex items-center justify-center mt-5">
                    <p className="text-gray-500">
                      Invalid URL. Please enter a valid URL.
                    </p>
                  </div>
                )
              ) : (
                <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                  <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                    <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        URL*
                      </label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="text"
                        name="Url"
                        placeholder="Yelp URL"
                        value={yelp?.Url}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 relative flex flex-row justify-between items-center w-full ">
                      <label
                        htmlFor="message"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Theme*
                      </label>
                      <select
                        id="countries"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        defaultValue={"5"}
                        name="Theme"
                        value={yelp?.Theme}
                        onChange={handleChange}
                      >
                        <option value="all">All</option>
                        <option value="4+">4+</option>
                        <option value="3+">3+</option>
                        <option value="3orLess">3 or less</option>
                      </select>
                    </div>
                    <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        URL*
                      </label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        name="ItemDuration"
                        placeholder="Item Time duration"
                        value={yelp?.ItemDuration}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                    <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-black flex items-center justify-center overscroll-none">
                      {yelp?.Url ? (
                        regex.test(yelp?.Url) ? (
                          loading ? (
                            ""
                          ) : (
                            <div className="md:w-[576px] md:h-[324px] sm:w-[384px] sm:h-[216px] lg:w-[860px] lg:h-[440px] w-72 h-72 flex items-center justify-center overflow-hidden scrollbar-hide ">
                              <iframe
                                id="wallsio-iframe"
                                src={yelp?.Url}
                                style={{ height: "100%", width: "100%" }}
                                loading="lazy"
                                title="Walls.io Feed"
                              ></iframe>
                            </div>
                          )
                        ) : (
                          <div className="w-full h-full flex items-center justify-center text-center">
                            <p className="text-gray-500">
                              Invalid URL. Please enter a valid URL.
                            </p>
                          </div>
                        )
                      ) : (
                        <div className=" bg-black w-[100%] h-[100%] flex items-center justify-center">
                          <h5 className=" text-white">
                            Invalid URL. Please enter a valid URL.
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };