import React from "react";
import { useState, useEffect, } from "react";
import moment from "moment";
import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams, } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineClose, } from "react-icons/ai";
import { MdSave } from "react-icons/md";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Img from '../../../images/AppsImg/close.png'
import { useDispatch } from "react-redux";
import { countryList } from "../../Common/Common";
import { getCurrencyById, getStockExchangeById, handleAddCurrencyApps, handleAddStockExchange } from "../../../Redux/AppsSlice";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";

export default function StockExchangeDetails({ sidebarOpen, setSidebarOpen }) {
  const currentDate = new Date();
  const { id } = useParams()
  const { user, token, userDetails } = useSelector((state) => state.root.auth);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [instanceName, setInstanceName] = useState(moment(currentDate).format("YYYY-MM-DD hh:mm"));


  const [ShowPreview, setShowPreview] = useState(false);
  const [edited, setEdited] = useState(false);
  const [loader, setLoader] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [valid, setvalid] = useState();
  const [stockExchange, setStockExchange] = useState({
    title: "",
    stocksSymbol: "",
    displayTitle: true,

  });

  useEffect(() => {
    if (id) {
      dispatch(getStockExchangeById(id)).then((res) => {
        if (res?.payload?.status === 200) {
          const fetchData = res?.payload?.data?.data
          setStockExchange(fetchData)
          setInstanceName(fetchData?.instanceName)
        }
      })

    }
  }, [dispatch, id]);

  const handleOnSaveInstanceName = (e) => {
    if (!instanceName.replace(/\s/g, "").length) {
      toast.remove();
      return toast.error("Please enter Instance Name.");
    }
    setEdited(false);
  };


  const onSumbit = () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    const Payload = {
      ...stockExchange,
      instanceName: instanceName,
      stockExchangeAppId: id ? id : 0,
    }
    setSaveLoading(true);
    toast.loading('Saving ...')

    dispatch(handleAddStockExchange(Payload)).then((res) => {
      toast.remove()
      setSaveLoading(false);
      navigate('/Stocks-Exchanges')
    }).catch((error) => {
      console.log('error :>> ', error);
      setSaveLoading(false);
    })
  }


  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
            user?.userDetails?.isRetailer === false &&
            !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div>
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter schedule name"
                        value={instanceName}
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      setShowPreview(!ShowPreview);
                    }}
                  >
                    {ShowPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    type="button"
                    className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={onSumbit}
                    disabled={saveLoading}
                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>

                  <Link to="/Stocks-Exchanges">
                    <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>
              {/* {ShowPreview && (
                <div className="w-[85%] h-[35rem] bg-gray-50 rounded-sm flex items-center justify-center border mx-auto mt-5">
                  <div className="bg-black w-full h-full flex flex-col justify-between py-14">
                    <div className="flex justify-center">
                      <h1 className="mb-3 text-3xl font-medium text-white">

                      </h1>
                    </div>

                    <div className="w-full h-[15rem] vertical-scroll-inner bg-gray-700 flex justify-center items-center rounded-md p-7">
                      <div className="flex flex-wrap justify-center items-center gap-4">
                        {currency?.Currencies?.length > 0 ? (
                          currency.Currencies
                            .filter(
                              (item) =>
                                item?.currencyname?.toLowerCase() !==
                                currency?.Basecurrency?.toLowerCase()
                            )
                            .map((item, index) => (
                              <div
                                key={index}
                                className="w-[250px] h-[60px] bg-slate-600 rounded-lg flex items-center justify-between p-3 shadow-md"
                              >
                                <div className="flex items-center gap-3">
                                  <img
                                    src={item?.total === 0 ? Img : item?.flagUrl}
                                    alt="Flag"
                                    className="h-10 rounded-md"
                                  />
                                  <div>
                                    <p className="text-white text-lg font-semibold">
                                      {item?.currencyname?.toUpperCase()}
                                    </p>
                                    <p className="text-gray-300 text-xs font-semibold">
                                      {item?.currencyName}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p className="text-white text-lg font-semibold">
                                    {item?.total}
                                  </p>
                                </div>
                              </div>
                            ))
                        ) : (
                          <p className="text-white">No currencies available</p>
                        )}
                      </div>
                    </div>

                    <div className="flex justify-center">
                      <p className="flex items-center text-xl font-normal text-white">
                        <img
                          className="h-10 mr-2"
                          // src={`https://flagsapi.com/${countryList[formik?.values?.Basecurrency?.toUpperCase()] || ""}/flat/64.png`}
                          alt="flag"
                        />
                 {formik?.values?.Unit} {formik?.values?.Basecurrency?.toUpperCase() || "USD"} ({currency?.baseCurrencyName || "Unknown"})
                      </p>
                    </div>
                  </div>
                </div>
              )} */}

              {!ShowPreview && (
                <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg h-full pr-3">
                  <div className="w-full lg:w-2/5 pr-0 lg:pr-4 mb-4 lg:mb-0 p-5">
                    <div className="mb-4 flex items-center justify-between placeHolderColor ">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Title is optional"
                        name="title"
                        value={stockExchange?.title}
                        onChange={(e) => setStockExchange({ ...stockExchange, title: e.target.value })}
                      />
                    </div>

                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Stocks symbol*
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="e.g.AAPL,GOOGLE"
                        name="Basecurrency"
                        value={stockExchange?.stocksSymbol}
                        onChange={(e) => setStockExchange({ ...stockExchange, stocksSymbol: e.target.value })}
                      />
                    </div>

                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Display Title
                      </label>
                      <div className="text-right">
                        <label className="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer cursor-pointer"
                            readOnly
                            name="Continuous"
                            checked={stockExchange?.displayTitle}
                            onChange={(e) => setStockExchange({ ...stockExchange, displayTitle: e.target.value })}
                          />
                          <div
                            className={`w-11 h-6 ${stockExchange?.displayTitle
                              ? "bg-SlateBlue"
                              : "bg-gray-300"
                              } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                          ></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="border-r-0 lg:border-r-2 border-gray-300 h-auto mx-4 hidden lg:block"></div>

                  <div className="w-full lg:w-3/5  flex items-center justify-center m-5  ">
                    {!valid && (
                      <div className="w-[95%] h-[35rem] rounded-sm shadow-xl border-4 border-black flex items-center justify-center">
                        <div className="text-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-16 w-16 mx-auto text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 7V3h18v4M4 10h16v4H4v-4z"
                            />
                          </svg>
                          <p className="text-gray-500">App instance preview</p>
                          <p className="text-sm text-gray-400">
                            Please edit the app settings to get started
                          </p>
                        </div>
                      </div>
                    )}
                    {valid && (
                      <div className="w-[95%] h-[35rem]  bg-gray-50 rounded-sm flex items-center justify-center ">
                        <div className="bg-black w-full h-full flex flex-col justify-between py-14">
                          {/* Title Section */}
                          <div className="flex justify-center">
                            <h1 className="mb-3 text-3xl font-medium text-white">
                              {/* {formik.values?.title || "Exchange Rates"} */}
                            </h1>
                          </div>

                          {/* Currency List Section */}
                          <div className="w-full h-[15rem] vertical-scroll-inner bg-gray-700 flex justify-center items-center rounded-md p-7">
                            <div className="flex flex-wrap justify-center items-center gap-4">
                              {/* {currency?.Currencies?.length > 0 ? (
                                currency.Currencies
                                  .filter(
                                    (item) =>
                                      item?.currencyname?.toLowerCase() !==
                                      currency?.Basecurrency?.toLowerCase()
                                  )
                                  .map((item, index) => (
                                    <div
                                      key={index}
                                      className="w-[250px] h-[60px] bg-slate-600 rounded-lg flex items-center justify-between p-3 shadow-md"
                                    >
                                      <div className="flex items-center gap-3">
                                        <img
                                          src={item?.total === 0 ? Img : item?.flagUrl}
                                          alt="Flag"
                                          className="h-10 rounded-md"
                                        />
                                        <div>
                                          <p className="text-white text-lg font-semibold">
                                            {item?.currencyname?.toUpperCase()}
                                          </p>
                                          <p className="text-gray-300 text-xs font-semibold">
                                            {item?.currencyName}
                                          </p>
                                        </div>
                                      </div>
                                      <div>
                                        <p className="text-white text-lg font-semibold">
                                          {item?.total}
                                        </p>
                                      </div>
                                    </div>
                                  ))
                              ) : (
                                <p className="text-white">No currencies available</p>
                              )} */}
                            </div>
                          </div>

                          <div className="flex justify-center">
                            <p className="flex items-center text-xl font-normal text-white">
                              <img
                                className="h-10 mr-2"
                                // src={`https://flagsapi.com/${countryList[formik?.values?.Basecurrency?.toUpperCase()] || ""}/flat/64.png`}
                                alt="flag"
                              />
                              {/* {formik?.values?.Unit} {formik?.values?.Basecurrency?.toUpperCase() || "USD"} ({currency?.baseCurrencyName || "Unknown"}) */}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


