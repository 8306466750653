import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import AirQualityIMG from "../../../images/AppsImg/AQI.svg";

export default function AirQualityPreview({ EditAQIData, setInstanceView,  airquality }) {

  return (

    <div className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-9990 outline-none focus:outline-none">
      <div >
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
          <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
            <div className="flex items-center">
              <div>
                <img src={AirQualityIMG} className="w-10" alt='NoticeBoard' />
              </div>
              <div className="ml-3">
                <h4 className="text-lg font-medium">
                  {EditAQIData?.instanceName}
                </h4>
              </div>
            </div>
            <button
              className="p-1 text-3xl"
              onClick={() => {
                setInstanceView(false);
              }}
            >
              <AiOutlineCloseCircle />
            </button>
          </div>

          <div className="md:w-[576px] md:h-[320px] sm:w-[384px] sm:h-[210px] lg:w-[960px] lg:h-[530px] w-72 h-72 flex items-center">
            <div className="w-full h-full flex flex-col lg:flex-row bg-white shadow-lg rounded-lg ">
              <div className="w-full mx-auto bg-black rounded-sm shadow-md border-4 border-black  flex items-center justify-center ">
                <div className="w-full h-full flex items-center justify-center bg-cover bg-center text-white relative">
                  <div className=" w-full h-full p-5 flex flex-col items-center justify-center ">
                    <h2 className="text-lg text-left font-semibold lg:text-xl text-white uppercase mb-5 ">
                      {EditAQIData?.location}
                    </h2>
                    <div
                      className={`grid ${(EditAQIData?.indexesToDisplay === "Air Quality" &&
                        EditAQIData?.indexesToDisplay === "Heat") ||
                        EditAQIData?.indexesToDisplay === "Air Quality and Heat"
                        ? "grid-cols-2"
                        : "grid-cols-1"
                        } gap-4 md:gap-6 lg:gap-8  w-full`}
                    >
                      {(EditAQIData?.indexesToDisplay === "Air Quality" ||
                        EditAQIData?.indexesToDisplay === "Air Quality and Heat") && (
                          <div className="bg-[#ffff00]  md:p-8 lg:p-5 rounded-xl shadow-xl text-center ">
                            <h2 className="text-lg font-semibold lg:text-xl text-black uppercase">
                              Air Quality
                            </h2>
                            <div className=" bg-[#00000026] p-5 py-8 mt-2 rounded-lg flex flex-col items-center justify-center h-60">
                              <p className="text-3xl font-bold  lg:text-6xl  text-black">
                                {airquality?.AirQuality || ""}
                              </p>
                              <p className="text-lg mt-2 lg:text-xl lg:mt-4 text-black font-medium uppercase" >
                                Moderate Air Quality
                              </p>
                            </div>
                          </div>
                        )}
                      {(EditAQIData?.indexesToDisplay === "Heat" || EditAQIData?.indexesToDisplay === "Air Quality and Heat") && (
                        <div className="bg-[#ffff00]  md:p-8 lg:p-5 rounded-xl shadow-xl text-center">
                          <h2 className="text-lg font-semibold lg:text-xl text-black">
                            Heat Index
                          </h2>
                          <div className=" bg-[#00000026] p-5 py-8 mt-2 rounded-lg flex flex-col items-center justify-center h-60">
                            <p className="text-3xl font-bold  lg:text-6xl  text-black">
                              {airquality?.Weatherdata?.temp
                                ? `${airquality?.Weatherdata?.temp}°${EditAQIData?.temperatureUnit === "Fahrenheit"
                                  ? "F"
                                  : "C"
                                }`
                                : "N/A"}
                            </p>
                            <p className="text-lg mt-2 lg:text-xl lg:mt-4 text-black font-medium uppercase" >
                              {airquality?.Weatherdata?.currentWeather || "N/A"}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-6">
            <div className="flex items-center gap-2 w-full">
              <div className="font-semibold w-fit">Tags:-</div>
              <div className=" w-full">
                {EditAQIData?.tags}
              </div>
            </div>
            <div>
              <label className="font-semibold">
                Screen Assign :
              </label>
              {EditAQIData?.ScreenAssignName == ""
                ? " No Screen"
                : EditAQIData?.ScreenAssignName}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
