import React from 'react'
import PropTypes from "prop-types";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';
import { CustomLayout, generateLayoutArray } from '../Common/Common';
import { MdArrowBackIosNew, MdDeleteForever } from 'react-icons/md';
import ElementComposition from './ElementComposition';
import toast from 'react-hot-toast';
import { debounce, filter } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { CUSTOM_COMPOSITION } from '../../Pages/Api';
import axios from 'axios';
import { handleCheckComposition } from '../../Redux/CompositionSlice';

const CustomComposition = ({ sidebarOpen, setSidebarOpen }) => {
  CustomComposition.propTypes = {
    sidebarOpen: PropTypes.bool.isRequired,
    setSidebarOpen: PropTypes.func.isRequired,
  };

  const { user, token, userDetails } = useSelector((state) => state.root.auth);
  const authToken = `Bearer ${token}`;

  const navigation = useNavigate();
  const [rotate, setRotate] = useState(0);
  const [customCompositionName, setCustomCompositionName] = useState(moment(new Date()).format("YYYY-MM-DD hh:mm A"))
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectResolution, setSelectResolution] = useState("Landscape");
  const [LayoutList, setLayoutList] = useState([]);

  const updateComponentSizeAndPosition = debounce(
    (id, newWidth, newHeight, newTop, newLeft, newRight, newBottom) => {
      const finalWidth = selectResolution === "Landscape"
        ? (newWidth > 640 ? 640 : newWidth)
        : (newWidth > 360 ? 360 : newWidth);
      const finalHeight = selectResolution === "Landscape"
        ? (newHeight > 360 ? 360 : newHeight)
        : (newHeight > 640 ? 640 : newHeight);

      setLayoutList((prevComponents) =>
        prevComponents.map((comp) =>
          comp.id === id
            ? {
              ...comp,
              width: finalWidth,
              height: finalHeight,
              top: newTop > 10 ? newTop : 0,
              left: newLeft > 10 ? newLeft : 0,
              right: newRight > 10 ? newRight : 0,
              bottom: newBottom > 10 ? newBottom : 0,
            }
            : comp
        )
      );
    },
    100
  );

  const resizeElement = (id, currentInfo, handler) => {

    let isMoving = true;

    const currentDiv = document.getElementById(id);
    const mouseMove = ({ movementX, movementY }) => {
      if (isMoving) {
        handler(currentDiv, movementX, movementY);
      }
    };

    const mouseUp = () => {
      isMoving = false;
      window.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", mouseUp);
    };

    window.addEventListener("mousemove", mouseMove);
    window.addEventListener("mouseup", mouseUp);
  };

  const resizeElementbottom_right = (id, currentInfo) => {
    resizeElement(id, currentInfo, (currentDiv, movementX, movementY) => {
      const container = currentDiv.parentNode; // Get the parent container
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      // Calculate the new width and height
      const newWidth = Math.max(
        parseInt(currentDiv.style.width) + movementX,
        50 // Minimum width
      );
      const newHeight = Math.max(
        parseInt(currentDiv.style.height) + movementY,
        50 // Minimum height
      );

      // Calculate right and bottom based on the new width and height
      const left = parseInt(currentDiv.style.left) || 0;
      const top = parseInt(currentDiv.style.top) || 0;
      const newRight = containerWidth - (left + newWidth);
      const newBottom = containerHeight - (top + newHeight);

      // Update the element's style
      currentDiv.style.width = `${newWidth}px`;
      currentDiv.style.height = `${newHeight}px`;
      currentDiv.style.right = `${newRight}px`;
      currentDiv.style.bottom = `${newBottom}px`;

      // Update the component's state with the new size and position
      updateComponentSizeAndPosition(
        id,
        newWidth,
        newHeight,
        top,
        left,
        newRight,
        newBottom
      );
    });
  };


  const resizeElementtop_left = (id, currentInfo) => {
    resizeElement(id, currentInfo, (currentDiv, movementX, movementY) => {
      const container = currentDiv.parentNode; // Parent container
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      // Current dimensions and positions
      const currentWidth = parseInt(currentDiv.style.width) || 0;
      const currentHeight = parseInt(currentDiv.style.height) || 0;
      const currentTop = parseInt(currentDiv.style.top) || 0;
      const currentLeft = parseInt(currentDiv.style.left) || 0;

      // Calculate the new width and height
      const newWidth = Math.max(currentWidth - movementX, 50); // Decreasing width as resizing to the left
      const newHeight = Math.max(currentHeight - movementY, 50); // Decreasing height as resizing upward

      // Calculate new top and left positions
      const newTop = currentTop + movementY;
      const newLeft = currentLeft + movementX;

      // Calculate right and bottom
      const newRight = containerWidth - (newLeft + newWidth); // Right depends on new width and left position
      const newBottom = containerHeight - (newTop + newHeight); // Bottom depends on new top and height

      // Update styles
      currentDiv.style.width = `${newWidth}px`;
      currentDiv.style.height = `${newHeight}px`;
      currentDiv.style.top = `${newTop}px`;
      currentDiv.style.left = `${newLeft}px`;
      currentDiv.style.right = `${newRight}px`;
      currentDiv.style.bottom = `${newBottom}px`;

      // Update component's state
      updateComponentSizeAndPosition(
        id,
        newWidth,
        newHeight,
        newTop,
        newLeft,
        newRight,
        newBottom
      );
    });
  };


  const resizeElementtop_right = (id, currentInfo) => {
    resizeElement(id, currentInfo, (currentDiv, movementX, movementY) => {
      const container = currentDiv.parentNode; // Parent container
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      // Current dimensions and positions
      const currentWidth = parseInt(currentDiv.style.width) || 0;
      const currentHeight = parseInt(currentDiv.style.height) || 0;
      const currentTop = parseInt(currentDiv.style.top) || 0;
      const currentLeft = parseInt(currentDiv.style.left) || 0;

      // Calculate the new width and height
      const newWidth = Math.max(currentWidth + movementX, 50); // Minimum width
      const newHeight = Math.max(currentHeight - movementY, 50); // Resizing upwards reduces height

      // Calculate the new top position
      const newTop = currentTop + movementY;

      // Calculate right and bottom
      const newRight = containerWidth - (currentLeft + newWidth); // Right stays relative to the left + width
      const newBottom = containerHeight - (newTop + newHeight);

      // Update styles
      currentDiv.style.width = `${newWidth}px`;
      currentDiv.style.height = `${newHeight}px`;
      currentDiv.style.top = `${newTop}px`;
      currentDiv.style.right = `${newRight}px`;
      currentDiv.style.bottom = `${newBottom}px`;

      // Update component's state
      updateComponentSizeAndPosition(
        id,
        newWidth,
        newHeight,
        newTop,
        currentLeft,
        newRight,
        newBottom
      );
    });
  };



  const resizeElementbottom_left = (id, currentInfo) => {
    resizeElement(id, currentInfo, (currentDiv, movementX, movementY) => {
      const container = currentDiv.parentNode; // Parent container
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      // Current dimensions and positions
      const currentWidth = parseInt(currentDiv.style.width) || 0;
      const currentHeight = parseInt(currentDiv.style.height) || 0;
      const currentTop = parseInt(currentDiv.style.top) || 0;
      const currentLeft = parseInt(currentDiv.style.left) || 0;

      // Calculate the new width and height
      const newWidth = Math.max(currentWidth - movementX, 50); // MovementX affects width negatively (resizing left)
      const newHeight = Math.max(currentHeight + movementY, 50); // Resizing down increases height

      // Calculate new left position
      const newLeft = currentLeft + movementX;

      // Calculate right and bottom
      const newRight = containerWidth - (newLeft + newWidth); // Right depends on new width and left position
      const newBottom = containerHeight - (currentTop + newHeight); // Bottom based on top and height

      // Update styles
      currentDiv.style.width = `${newWidth}px`;
      currentDiv.style.height = `${newHeight}px`;
      currentDiv.style.left = `${newLeft}px`;
      currentDiv.style.right = `${newRight}px`;
      currentDiv.style.bottom = `${newBottom}px`;

      // Update component's state
      updateComponentSizeAndPosition(
        id,
        newWidth,
        newHeight,
        currentTop,
        newLeft,
        newRight,
        newBottom
      );
    });
  };


  const moveElement = (id, currentInfo) => {
    resizeElement(id, currentInfo, (currentDiv, movementX, movementY) => {
      const container = currentDiv.parentNode; // Get the parent container
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      // Existing position and dimensions
      const left = parseInt(currentDiv.style.left) || 0;
      const top = parseInt(currentDiv.style.top) || 0;
      const width = parseInt(currentDiv.style.width) || 0;
      const height = parseInt(currentDiv.style.height) || 0;

      // New position
      const newLeft = left + movementX;
      const newTop = top + movementY;

      // Calculate right and bottom based on container dimensions
      const newRight = containerWidth - (newLeft + width);
      const newBottom = containerHeight - (newTop + height);

      // Prevent overflow (optional)
      if (newLeft < 0 || newTop < 0 || newRight < 0 || newBottom < 0) return;

      // Update the element's style
      currentDiv.style.left = `${newLeft}px`;
      currentDiv.style.top = `${newTop}px`;
      currentDiv.style.right = `${newRight}px`;
      currentDiv.style.bottom = `${newBottom}px`;

      // Update the state with the new position
      updateComponentSizeAndPosition(
        id,
        width,
        height,
        newTop,
        newLeft,
        newRight,
        newBottom
      );
    });
  };


  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const createShape = (name, type) => {
    const newPosition = { left: 10, top: 10, bottom: 30, right: 30 };

    const style = {
      id: `component-${LayoutList.length + 1}`,
      name,
      type,
      left: newPosition.left,
      top: newPosition.top,
      bottom: newPosition.bottom,
      right: newPosition.right,
      opacity: 1,
      width: 200,
      height: 150,
      rotate,
      zIndex: 2,
      color: "#D5E3FF",
      resizeElementbottom_right,
      moveElement,
      resizeElementtop_left,
      resizeElementtop_right,
      resizeElementbottom_left
    };

    setLayoutList([...LayoutList, style]);
  };

  const handleDelete = (item, index) => {
    const filterData = LayoutList?.filter((item, i) => i !== index);
    setLayoutList(filterData)
  }

  const handleSave = () => {
    try {
      if (LayoutList?.length === 0) {
        toast.remove()
        return toast.error("Please add one Section.")
      }
      setSaveLoading(true)
      const svgWidth = selectResolution === "Landscape" ? 640 : 180;
      const svgHeight = selectResolution === "Landscape" ? 360 : 360;

      // Create SVG structure
      const svgContent = `
      <svg xmlns="http://www.w3.org/2000/svg" width="${svgWidth}" height="${svgHeight}" viewBox="0 0 ${svgWidth} ${svgHeight}">
        ${LayoutList.map((component) => {
        const ReactWidth = selectResolution === "Landscape" ? component.width : (component.width / 2);
        const ReactHeight = selectResolution === "Landscape" ? component.height : (component.height / 2);
        const ReactY = selectResolution === "Landscape" ? component.top : (component.top / 2);
        const ReactX = selectResolution === "Landscape" ? component.left : (component.left / 3);
        return `
            <rect
              x="${ReactX}"
              y="${ReactY}"
              width="${ReactWidth}"
              height="${ReactHeight}"
              fill="${component.color}"
              transform="${component.rotate ? `rotate(${component.rotate}, ${ReactX + ReactWidth}, ${component.top + ReactHeight})` : ''}"
              style="z-index:${component.zIndex || 1};"
              stroke="#8E94A9"
            />
          `;
      }).join('')}
      </svg>
    `;

      let Params = {
        "layoutDtlID": 0,
        "svg": svgContent,
        "screenHeight": selectResolution === "Landscape" ? "210" : "288",
        "screenWidth": selectResolution === "Landscape" ? "300" : "150",
        "name": customCompositionName,
        "screenType": selectResolution,
        "lstLayloutModelList": generateLayoutArray(LayoutList, selectResolution),
      }
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${CUSTOM_COMPOSITION}`,
        headers: { Authorization: authToken, "Content-Type": "application/json" },
        data: Params,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.status === 200) {
            setSaveLoading(false)
            toast.remove();
            toast.success("Custom Composition Created Successfully!")
            navigation("/addcomposition");
          }
        })
        .catch((error) => {
          console.log(error);
          setSaveLoading(false)
        });
    } catch (error) {
      console.log('error', error)
      setSaveLoading(false)
    }
  }

  const handleInputChange = (index, field, value) => {
    // if (field === "height" || field === "width") {
    //   setLayoutList((prevList) =>
    //     prevList.map((item, i) =>
    //       i === index ? { ...item, [field]: (parseInt(value) / 3) || 0 } : item
    //     )
    //   );
    // } else {
    //   setLayoutList((prevList) =>
    //     prevList.map((item, i) =>
    //       i === index ? { ...item, [field]: parseInt(value) || 0 } : item
    //     )
    //   );
    // }

    setLayoutList((prevList) =>
      prevList.map((item, i) =>
        i === index ? { ...item, [field]: (parseInt(value) / 3) || 0 } : item
      )
    );
  };

  return (
    <>
      <div className="flex bg-white border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div className={userDetails?.isTrial && user?.userDetails?.isRetailer === false && !userDetails?.isActivePlan ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain" : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"}>
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="lg:mt-5">
            <div className="flex flex-col w-full gap-2 items-start mb-3">
              <div className='flex items-center w-full justify-between flex-row'>
                <input
                  type="text"
                  className="w-72 border border-primary rounded-md px-2 py-1"
                  placeholder="Enter Custom Layout Name"
                  value={customCompositionName}
                  onChange={(e) => setCustomCompositionName(e.target.value)}
                />
                <div className='flex gap-3'>
                  <Link to="/addcomposition">
                    <button className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50">
                      <MdArrowBackIosNew className="text-2xl text-white p-1" />
                      Back
                    </button>
                  </Link>
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2 items-center border rounded-full lg:px-6 sm:px-5 py-2.5 text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    disabled={saveLoading}
                    onClick={() => handleSave()}
                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </div>
            <div className='bg-white mt-2 rounded-lg'>
              <div className='flex flex-row gap-2'>
                <div className="p-5 w-full h-full">
                  <div className='flex w-full justify-center items-center'>
                    <div className={`border ${selectResolution == "Landscape" ? "w-[640px] h-[360px]" : "w-[360px] h-[640px]"} relative overflow-hidden`}>
                      {LayoutList?.map((component) => {
                        return (
                          <div
                            key={component.id}
                            id={component.id}
                            style={{
                              position: "absolute",
                              left: component.left,
                              top: component.top,
                              bottom: component.bottom,
                              right: component.right,
                              width: component.width + "px",
                              height: component.height + "px",
                              backgroundColor: component.color,
                              zIndex: component.zIndex,
                              transform: component.rotate
                                ? `rotate(${component.rotate}deg)`
                                : "rotate(0deg)",

                            }}
                            className="absolute group hover:border-[2px] border border-black hover:border-indigo-500"
                          >
                            <ElementComposition
                              id={component.id}
                              info={component}
                              resizeElementbottom_right={resizeElementbottom_right}
                              moveElement={moveElement}
                              resizeElementtop_left={resizeElementtop_left}
                              resizeElementtop_right={resizeElementtop_right}
                              resizeElementbottom_left={resizeElementbottom_left}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="border-l-2 border-lightgray"></div>
                <div className="col-span-5 p-5 w-full">
                  <div className='flex flex-col gap-2'>
                    <label className='text-xl font-semibold'>Resolution</label>
                    <select
                      className="w-64 border rounded-full p-3 relative"
                      onChange={(e) => { setSelectResolution(e.target.value); setLayoutList([]); }}
                      value={selectResolution}
                    >
                      {CustomLayout?.map((item) => {
                        return (
                          <option value={item?.id} key={item?.id}>{item?.value}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='h-[260px]'>
                    {LayoutList?.map((item, index) => {
                      return (
                        <div className='flex flex-row items-center gap-2 mt-4' key={index}>
                          <label className='w-24'>Section:- {index + 1}</label>
                          <div className="flex items-center">
                            <div className="flex flex-row gap-1 items-center">
                              <h6>Width</h6>
                              <input
                                type="number"
                                value={item.width * 3}
                                className="border border-bg-gray-200k p-1 mx-1 rounded-lg w-[60px] text-center"
                                onChange={(e) => handleInputChange(index, 'width', e.target.value)}
                              />
                            </div>
                            <label>Pixels,</label>
                          </div>
                          {/* Height Input */}
                          <div className="flex items-center">
                            <div className="flex flex-row gap-1 items-center">
                              <h6>Height</h6>
                              <input
                                type="number"
                                value={item.height * 3}
                                className="border border-bg-gray-200k p-1 mx-1 rounded-lg w-[60px] text-center"
                                onChange={(e) => handleInputChange(index, 'height', e.target.value)}
                              />
                            </div>
                            <label>Pixels,</label>
                          </div>
                          <div className="flex items-center">
                            <div className="flex flex-row gap-1 items-center">
                              <h6>Top</h6>
                              <input
                                type="number"
                                value={item.top * 3}
                                className="border border-bg-gray-200k p-1 mx-1 rounded-lg w-[60px] text-center"
                                onChange={(e) => handleInputChange(index, 'top', e.target.value)}
                              />
                            </div>
                            <label>Pixels,</label>
                          </div>
                          {/*<div className='flex items-center'>
                            <div className="flex flex-row gap-1 items-center">
                              <h6>Top</h6>
                              <h6 className="border border-bg-gray-200k p-1 mx-1 rounded-lg w-[60px] flex items-center justify-center">
                                {item.top}
                              </h6>
                            </div>
                            <label>Pixels,</label>
                          </div>*/}
                          <div className="flex items-center">
                            <div className="flex flex-row gap-1 items-center">
                              <h6>Left</h6>
                              <input
                                type="number"
                                value={item.left * 3}
                                className="border border-bg-gray-200k p-1 mx-1 rounded-lg w-[60px] text-center"
                                onChange={(e) => handleInputChange(index, 'left', e.target.value)}
                              />
                            </div>
                            <label>Pixels,</label>
                          </div>
                          {/*<div className='flex items-center'>
                            <div className="flex flex-row gap-1 items-center">
                              <h6>Left</h6>
                              <h6 className="border border-bg-gray-200k p-1 mx-1 rounded-lg w-[60px] flex items-center justify-center">
                                {item.left}
                              </h6>
                            </div>
                            <label>Pixels</label>
                          </div>*/}
                          <div className='flex items-center'>
                            <button
                              data-tip
                              data-for="Delete"
                              type="button"
                              className="rounded-full px-2 py-2 text-white text-center bg-[#FF0000]"
                              onClick={() => handleDelete(item, index)}
                            >
                              <MdDeleteForever />
                              <ReactTooltip
                                id="Delete"
                                place="bottom"
                                type="warning"
                                effect="solid"
                              >
                                <span>Delete</span>
                              </ReactTooltip>
                            </button>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className='w-full flex justify-center items-center'>
                    <div className="my-6">
                      <h4>
                        Click
                        <span
                          className="text-blue-500 cursor-pointer underline mx-1"
                          onClick={() => {
                            if (LayoutList?.length < 5) {
                              createShape("Shape", "rect")
                            } else {
                              toast.remove();
                              toast.error("You can only Create 5 Section");
                            }
                          }}
                        >
                          (Add Section)
                        </span>
                        to add a new Section
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default CustomComposition
