import "../Styles/loginRegister.css";
import {
  BsApple,
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsFillInfoCircleFill,
  BsGoogle,
  BsMicrosoft,
} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
// import {BsMicrosoft} from "react-icons/bs";
// import {BsApple} from "react-icons/bs";
// import {BsGoogle} from "react-icons/bs";
// import {FaFacebookF} from "react-icons/fa";
import { useRef, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineClose } from "react-icons/ai";
import { ADD_REGISTER_URL } from "./Api";
import video from "../images/DisployImg/iStock-1137481126.mp4";
import {
  Googleauthprovider,
  appleProvider,
  auth,
  facebookProvider,
  microsoftProvider,
} from "../FireBase/firebase";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { handleLoginWithGoogle, handleRegisterUser } from "../Redux/Authslice";
import logo from "../images/DisployImg/White-Logo2.png";
// import logo from "../images/DisployImg/White-Logo2.png";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { FaFacebookF } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import { signInWithPopup } from "firebase/auth";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../Components/Common/authconfig";
import { MsalProvider, useMsal } from "@azure/msal-react";
import MicrosoftBtn from "./MicrosoftBtn";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import { LoginSocialFacebook } from "reactjs-social-login";
import TermsConditions from "../Components/Common/TermsConditions";

const Registration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const msalInstance = new PublicClientApplication(msalConfig);
  const { instance } = useMsal();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessge, setErrorMessge] = useState("");
  const [errorMessgeVisible, setErrorMessgeVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  //using for validation and register api calling
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    firstName: Yup.string().required("First Name is required").max(50),
    lastName: Yup.string().required("Last Name is required").max(50),
    emailID: Yup.string()
      .required("Email is required")
      .email("E-mail must be a valid e-mail!"),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .test('is-valid-phone', 'Invalid phone number', value => isValidPhoneNumber(value)),
    googleLocation: Yup.string().required("Google Location is required"),
    // terms: Yup.boolean()
    //   .oneOf([true], "You must accept the terms and conditions")
    //   .required("You must accept the terms and conditions"),
    captcha: Yup.string().required("captcha is required."),
  });

  // const formik = useFormik({
  //   initialValues: {
  //     companyName: "",
  //     password: "",
  //     firstName: "",
  //     emailID: "",
  //     googleLocation: "",
  //     phoneNumber: "",
  //     lastName: "",
  //     // terms: false,
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values) => {
  //     createUserWithEmailAndPassword(auth, values.emailID, values.password)
  //       .then((userCredential) => {
  //         const user = userCredential.user;
  //         const usertoken = user.za;
  //         // console.log({ usertoken, user });
  //         sendEmailVerification()
  //           .then(() => {
  //             // console.log("Verification email sent.");
  //             alert("Verification email sent.");
  //             const formData = new FormData();
  //             formData.append("OrganizationName", values.companyName);
  //             formData.append("Password", values.password);
  //             formData.append("FirstName", values.firstName);
  //             formData.append("LastName", values.lastName);
  //             formData.append("Email", values.emailID);
  //             formData.append("GoogleLocation", values.googleLocation);
  //             formData.append("Phone", values.phoneNumber);
  //             formData.append("UserTokan", usertoken);
  //             formData.append("Operation", "Insert");
  //             setLoading(true);

  //             let config = {
  //               method: "post",
  //               maxBodyLength: Infinity,
  //               url: ADD_REGISTER_URL,
  //               headers: {
  //                 "Content-Type": "multipart/form-data",
  //               },
  //               data: formData,
  //             };

  //             const response = dispatch(handleRegisterUser({ config }));
  //             if (response) {
  //               response
  //                 .then(() => {
  //                   window.localStorage.setItem("timer", JSON.stringify(18_00));
  //                   toast.success("Registration successfully.");
  //                   navigate("/screens");
  //                   setLoading(false);
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                   setErrorMessgeVisible(true);
  //                   setErrorMessge(error.response.data);
  //                   setLoading(false);
  //                 });
  //             }
  //           })
  //           .catch((error) => {
  //             console.error(error);
  //             setLoading(false);
  //           });
  //       })
  //       .catch((error) => {
  //         console.log("error", error.message);
  //         setErrorMessgeVisible(true);
  //         setErrorMessge(error.message);
  //         var errorMessage = JSON.parse(error.message);
  //         switch (errorMessage.error.message) {
  //           case "ERROR_INVALID_EMAIL":
  //             alert("Your email address appears to be malformed.");
  //             console.log("ERROR_INVALID_EMAI");
  //             break;
  //           case "ERROR_WRONG_PASSWORD":
  //             alert("Your password is wrong.");
  //             break;
  //           case "ERROR_USER_NOT_FOUND":
  //             alert("User with this email doesn't exist.");
  //             break;
  //           case "ERROR_USER_DISABLED":
  //             alert("User with this email has been disabled.");
  //             break;
  //           case "ERROR_TOO_MANY_REQUESTS":
  //             alert("Too many requests. Try again later.");
  //             break;
  //           case "ERROR_OPERATION_NOT_ALLOWED":
  //             alert("Signing in with Email and Password is not enabled.");
  //             break;
  //           case "INVALID_LOGIN_CREDENTIALS":
  //             alert("Invaild Email Or Password");
  //             break;
  //           default:
  //             alert("An undefined Error happened.");
  //         }
  //         setLoading(false);
  //       });
  //   },
  // });

  const formik = useFormik({
    initialValues: {
      companyName: "",
      password: "",
      firstName: "",
      emailID: "",
      googleLocation: "",
      phoneNumber: "",
      lastName: "",
      captcha: "",
      // terms: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!isCheckboxChecked) {
        toast.error("Please check & accept the terms and conditions.");
        return; // Exit the submission process if checkbox is not checked
      }
      const formData = new FormData();
      const TimeZone = new Date()
        .toLocaleDateString(undefined, {
          day: "2-digit",
          timeZoneName: "long",
        })
        .substring(4);
      formData.append("OrganizationName", values.companyName);
      formData.append("Password", values.password);
      formData.append("FirstName", values.firstName);
      formData.append("LastName", values.lastName);
      formData.append("Email", values.emailID);
      formData.append("GoogleLocation", values.googleLocation);
      formData.append("Phone", values.phoneNumber);
      formData.append("IsRetailer", false);
      formData.append("IsSalesMan", false);
      formData.append("Currency", TimeZone?.includes("India") ? "inr" : "usd");

      // formData.append("UserTokan", usertoken);   // used Firebase token
      formData.append("Operation", "Insert");
      setLoading(true);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: ADD_REGISTER_URL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = dispatch(handleRegisterUser({ config }));
      if (response) {
        response
          .then((res) => {
            if (res?.error?.messstatusage !== "Rejected") {
              if (res?.payload?.status) {
                toast.success("Registration successfully.");
                navigate("/");
              } else {
                toast.error(res?.payload?.message);
              }
              // window.localStorage.setItem("timer", JSON.stringify(18_00));
              setLoading(false);
            } else {
              toast.error(res?.payload)
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setErrorMessgeVisible(true);
            setErrorMessge(error.response.data);
            setLoading(false);
          });
      }
    },
  });

  const { setFieldValue, values, getFieldProps } = formik;

  const SignInWithGoogle = async (data) => {
    //  return console.log(data);
    const TimeZone = new Date()
      .toLocaleDateString(undefined, {
        day: "2-digit",
        timeZoneName: "long",
      })
    const formData = new FormData();

    formData.append("FirstName", data.name);
    formData.append("Email", data.email);
    formData.append("Phone", null);
    formData.append("Operation", "Insert");
    formData.append("googleID", data?.sub);
    formData.append("IsRetailer", false);
    formData.append("IsSalesMan", false);
    formData.append("Currency", TimeZone?.includes("India") ? "inr" : "usd");
    const config = {
      method: "post",
      url: ADD_REGISTER_URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    // setTimeout(async () => {
    try {
      const response = await dispatch(handleLoginWithGoogle({ config })).then((res) => {
        if (res?.payload?.status === 200) {
          window.localStorage.setItem("timer", JSON.stringify(18_00));
          toast.success("Sign up successfully.");
        } else {
          // setErrorMessgeVisible(true);
          // setErrorMessge("Registration failed.");
          toast.error("Registration failed.")
        }
      });
      if (!response) return;
      // response
      //   .then(() => {
      //     window.localStorage.setItem("timer", JSON.stringify(18_00));
      //     toast.success("Sign up successfully.");
      //     // navigate("/screens");
      //     console.log(data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     setErrorMessgeVisible(true);
      //     setErrorMessge("Registration failed.");
      //   });
    } catch (error) {
      console.log(error);
      setErrorMessgeVisible(true);
      setErrorMessge("Registration failed.");
    }
    // }, 1000);
  };

  const SignInWithGooglebtn = async () => {
    setLoading(true)
    const TimeZone = new Date()
      .toLocaleDateString(undefined, {
        day: "2-digit",
        timeZoneName: "long",
      })
    const res = await signInWithPopup(auth, Googleauthprovider)
      .then((result) => {
        // Google sign-in successful, you can access user information via result.user
        const data = result.user;
        const formData = new FormData();

        formData.append("FirstName", result?.user?.displayName);
        formData.append("Email", result?.user?.email);
        formData.append("Phone", null);
        formData.append("Operation", "Insert");
        formData.append("googleID", result?.user?.uid);
        formData.append("IsRetailer", false);
        formData.append("IsSalesMan", false);
        formData.append("Currency", TimeZone?.includes("India") ? "inr" : "usd");
        const config = {
          method: "post",
          url: ADD_REGISTER_URL,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        // setTimeout(async () => {
        try {
          const response = dispatch(handleLoginWithGoogle({ config })).then((res) => {
            if (res?.payload?.status) {
              window.localStorage.setItem("timer", JSON.stringify(18_00));
              toast.success("Sign up successfully.");
              setLoading(false)
            } else {
              // setErrorMessgeVisible(true);
              // setErrorMessge("Registration failed.");
              toast.error(res?.payload?.message)
              setLoading(false)
            }
          });
          if (!response) return;
          // response
          //   .then(() => {
          //     window.localStorage.setItem("timer", JSON.stringify(18_00));
          //     toast.success("Sign up successfully.");
          //     // navigate("/screens");
          //     console.log(data);
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //     setErrorMessgeVisible(true);
          //     setErrorMessge("Registration failed.");
          //   });
        } catch (error) {
          console.log(error);
          setErrorMessgeVisible(true);
          setLoading(false)
          setErrorMessge("Registration failed.");
        }
      })
      .catch((error) => {
        setLoading(false)
        // Handle errors here
        console.error(error);
      });
  };

  const SignInFaceBook = async (data) => {
    try {
      setLoading(true)
      const TimeZone = new Date()
        .toLocaleDateString(undefined, {
          day: "2-digit",
          timeZoneName: "long",
        })
      const res = await signInWithPopup(auth, facebookProvider);

      const formData = new FormData();
      formData.append("FirstName", res?.user?.displayName);
      formData.append("Email", res?.user?.email);
      formData.append("Phone", null);
      formData.append("Operation", "Insert");
      formData.append("googleID", res?.user?.uid);
      formData.append("IsRetailer", false);
      formData.append("IsSalesMan", false);
      formData.append("Currency", TimeZone?.includes("India") ? "inr" : "usd");
      // onclose();
      const config = {
        method: "post",
        url: ADD_REGISTER_URL,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        const response = dispatch(handleLoginWithGoogle({ config })).then((res) => {
          if (res?.payload?.status) {
            window.localStorage.setItem("timer", JSON.stringify(18_00));
            toast.success("Sign up successfully.");
            setLoading(false)
          } else {
            // setErrorMessgeVisible(true);
            // setErrorMessge("Registration failed.");
            toast.error(res?.payload?.message)
            setLoading(false)
          }
        });
        if (!response) return;

      } catch (error) {
        console.log(error);
        setErrorMessgeVisible(true);
        setErrorMessge("Registration failed.");
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  const SignInapple = async () => {
    try {
      const res = await signInWithPopup(auth, appleProvider);
      const user = res.user;
      // onclose();
    } catch (error) {
      console.log(error);
    }
  };

  const SignInMicroSoft = async () => {
    // microsoftProvider.setCustomParameters({
    //   prompt: "consent",
    //   tenant: "f8cdef31-a31e-4b4a-93e4-5f571e91255a",
    // });
    // try {
    //   const res = await signInWithPopup(auth, microsoftProvider);
    //   console.log('res', res)
    //   const user = res.user;
    //   console.log('user', user)
    //   // onclose();
    // } catch (error) {
    //   console.log(error);
    // }

    const data = instance.loginPopup(loginRequest).then((res) => {
      console.log('res', res)
    }).
      catch((e) => {
        console.log(e);
      });
  };

  const handleCheckboxChange = (e, formik) => {
    setIsCheckboxChecked(e.target.checked);
    // setShowModal(true);
  };

  const handleAcceptTerms = () => {
    setIsCheckboxChecked(true);
    setShowModal(false);
  };

  const handlePhoneChange = value => {
    formik.setFieldValue('phoneNumber', '+' + value); // Update the phoneNumber value with the correct format
  };

  return (
    <>
      {/* registration faild error msg display start*/}
      {errorMessgeVisible && (
        <div
          className="bg-[#fff2cd] px-5 py-3 border-b-2 border-SlateBlue shadow-md"
          style={{
            position: "fixed",
            top: "16px",
            right: "20px",
            zIndex: "999999",
          }}
        >
          <div className="flex text-SlateBlue  text-base font-normal items-center relative">
            <BsFillInfoCircleFill className="mr-1" />
            {errorMessge}
            <button
              className="absolute top-[-26px] right-[-26px] bg-white rounded-full p-1 "
              onClick={() => setErrorMessgeVisible(false)}
            >
              <AiOutlineClose className="text-xl  text-SlateBlue " />
            </button>
          </div>
        </div>
      )}
      {/* registration faild error msg display end*/}

      {/* registration form start*/}
      <div className="videobg login relative">
        <video src={video} autoPlay muted loop playsInline />
        <div className="bg-cover bg-no-repeat min-h-screen flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center loginbg  lg:px-6 md:px-6 sm:px-2 xs:px-2 lg:mx-auto md:mx-auto sm:mx-auto xs:mx-2  lg:py-2 md:py-3 sm:py-5 xs:py-5 z-10">
            <div className="flex items-center pb-5">
              <img className="w-52 h-14" alt="logo" src={logo} />
            </div>
            <div className="w-full border-[#ffffff6e] border rounded-lg shadow-md md:mt-0  xl:p-0 lg:min-w-[600px] md:min-w-[600px] sm:min-w-auto xs:min-w-auto">
              <div className="p-3 sm:px-8 py-1">
                <div className="my-1 font-inter not-italic font-medium text-[24px] text-white mt-4">
                  Create an account
                </div>
                <div className="mb-8 font-['Poppins'] not-italic font-normal text-[16px] text-white">
                  Fill in the fields below to sign up for an account.
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="space-y-3 md:space-y-5"
                >
                  <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 lg:gap-4 md:gap-4 sm:gap-2 xs:gap-2">
                    <div className="relative lg:w-64 md:w-64 sm:max-w-[376px]">
                      <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        placeholder="Enter Company Name"
                        className="formInput"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyName}
                      />
                      {formik.errors.companyName &&
                        formik.touched.companyName && (
                          <div className="error">
                            {formik.errors.companyName}
                          </div>
                        )}
                    </div>
                    <div className="relative lg:w-64 md:w-64 sm:max-w-[376px]">
                      <input
                        type="text"
                        name="googleLocation"
                        id="googleLocation"
                        placeholder="Enter Your Google Location"
                        className="formInput"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.googleLocation}
                      />
                      {formik.errors.googleLocation &&
                        formik.touched.googleLocation && (
                          <div className="error">
                            {formik.errors.googleLocation}
                          </div>
                        )}
                    </div>

                    <div className="relative lg:w-64 md:w-64 sm:max-w-[376px]">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="Enter Your First Name"
                        className="formInput"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                      {formik.errors.firstName && formik.touched.firstName && (
                        <div className="error">{formik.errors.firstName}</div>
                      )}
                    </div>
                    <div className="relative lg:w-64 md:w-64 sm:max-w-[376px]">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Enter Your Last Name"
                        className="formInput"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                      />
                      {formik.errors.lastName && formik.touched.lastName && (
                        <div className="error">{formik.errors.lastName}</div>
                      )}
                    </div>
                    <div className="relative register lg:w-64 md:w-64 sm:max-w-[376px]">
                      <PhoneInput
                        country={"in"}
                        onChange={handlePhoneChange}
                        value={formik.values.phoneNumber.replace('+', '')} // Remove the '+' for the PhoneInput
                        autocompleteSearch={true}
                        countryCodeEditable={false}
                        enableSearch={true}
                        inputStyle={{
                          width: "100%",
                          background: "white",
                          padding: "25px 0 25px 3rem",
                          borderRadius: "10px",
                          fontSize: "1rem",
                          border: "1px solid #000",
                        }}
                        dropdownStyle={{
                          color: "#000",
                          fontWeight: "600",
                          padding: "0px 0px 0px 10px",
                        }}
                      />
                      {formik.errors.phoneNumber &&
                        formik.touched.phoneNumber && (
                          <div className="error">
                            {formik.errors.phoneNumber}
                          </div>
                        )}
                    </div>

                    <div className="relative lg:w-64 md:w-64 sm:max-w-[376px]">
                      <input
                        type="email"
                        name="emailID"
                        id="emailID"
                        className="formInput"
                        placeholder="Enter Your Email Address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.emailID}
                      />
                      {formik.errors.emailID && formik.touched.emailID && (
                        <div className="error">{formik.errors.emailID}</div>
                      )}
                    </div>
                    <div className="relative lg:w-64 md:w-64 sm:max-w-[376px]">
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Enter Your Password"
                          className="formInput"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        <div className="register-icon">
                          {showPassword ? (
                            <BsFillEyeFill
                              onClick={() => setShowPassword(!showPassword)}
                            />
                          ) : (
                            <BsFillEyeSlashFill
                              onClick={() => setShowPassword(!showPassword)}
                            />
                          )}
                        </div>
                      </div>
                      {formik.errors.password && formik.touched.password && (
                        <div className="error">{formik.errors.password}</div>
                      )}
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="terms"
                        name="terms"
                        type="checkbox"
                        checked={isCheckboxChecked}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 border border-gray-300 cursor-pointer rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                      />
                    </div>
                    <div className="flex ml-3 lg:text-sm md:text-sm sm:text-sm xs:text-[14px] flex-wrap">
                      <p className="not-italic text-white font-medium">
                        I accept the
                      </p>
                      {/* Modal trigger */}
                      <p
                        className="ml-1 not-italic text-white font-medium decoration-white border-b cursor-pointer"
                        onClick={() => setShowModal(true)}
                      >
                        terms and conditions
                      </p>
                    </div>
                  </div>
                  <div className="relative lg:w-64 md:w-64 sm:max-w-[376px]">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA}
                      onChange={(e) => {
                        setFieldValue("captcha", e);
                      }}
                    />
                    {formik.errors.captcha && formik.touched.captcha && (
                      <div className="error">{formik.errors.captcha}</div>
                    )}
                  </div>
                  {/* <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="terms"
                        name="terms"
                        type="checkbox"
                        checked={formik.values.terms}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                      />
                    </div>
                    <div className="flex ml-3 text-sm flex-wrap">
                      <label className="not-italic text-white font-medium">
                        I accept the
                      </label>
                       <Link to="/termsconditions">
                        <p className="lg:ml-1 not-italic text-white font-medium">
                          terms and conditions
                        </p>
                      </Link> 
                    </div>
                  </div> */}
                  {/* {formik.errors.terms && formik.touched.terms && (
                    <div className="error">{formik.errors.terms}</div>
                  )} */}
                  <button
                    type="submit"
                    className="w-full text-[#FFFFFF] bg-SlateBlue not-italic font-medium rounded-lg py-3.5 text-center text-base mt-4 hover:bg-primary border border-SlateBlue hover:border-white"
                    disabled={loading}
                  >
                    {loading ? "Signing up..." : "Create Your Account"}
                  </button>
                  <div className="flex lg:ml-3 text-sm flex-wrap">
                    <label className="not-italic text-white font-medium mb-3">
                      Already have an account?
                    </label>
                    <Link to="/">
                      <p className="lg:ml-1 not-italic text-white font-medium mb-3 hover:text-SlateBlue">
                        Sign in here
                      </p>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            {/* login with google  */}
            {/*  <div className="mt-4">
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_DRIVE_CLIENTID}
              >
                <GoogleLogin
                  theme="outline"
                  type="standard"
                  onSuccess={(res) => {
                    SignInWithGoogle(jwtDecode(res.credential));
                  }}
                  onError={(error) => console.log(error)}
                ></GoogleLogin>
              </GoogleOAuthProvider>
                </div>*/}
            <div className="flex items-center justify-center mt-4">
              <button onClick={SignInWithGooglebtn}>
                <div className="socialIcon socialIcon1">
                  <BsGoogle className="text-2xl text-white bg-primary rounded-full p-1" />
                </div>
              </button>
              {/* <LoginSocialFacebook
                appId={process.env.REACT_APP_FACEBOOK_APK_SECRET_KEY}
                onResolve={(response) => {
                  console.log(response);
                  SignInFaceBook(response.data);
                }}
                onReject={(error) => {
                  console.log(error);
                }}
              >
                <button>
                  <div className="socialIcon socialIcon2">
                    <FaFacebookF className="text-2xl text-white bg-primary rounded-full p-1" />
                  </div>
                </button>
              </LoginSocialFacebook>*/}
              <button onClick={SignInFaceBook}>
                <div className="socialIcon socialIcon2">
                  <FaFacebookF className="text-2xl text-white bg-primary rounded-full p-1" />
                </div>
              </button>
              <button onClick={SignInapple}>
                <div className="socialIcon socialIcon3">
                  <BsApple className="text-2xl text-white bg-primary rounded-full p-1" />
                </div>
              </button>
              <MsalProvider instance={msalInstance}>
                <MicrosoftBtn register={true} setLoading={setLoading} />
              </MsalProvider>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <TermsConditions
          handleAcceptTerms={handleAcceptTerms}
          modalRef={modalRef}
          setShowModal={setShowModal}
          setIsCheckboxChecked={setIsCheckboxChecked}
        />
      )}
      {/* registration form end*/}
    </>
  );
};

export default Registration;
