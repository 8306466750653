import React, { useEffect, useRef } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { useState } from "react";
import moment from "moment";
import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { MdPlaylistPlay, MdSave } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  GET_MicrosoftExcel_BY_ID,
  MicrosoftExcel_INSTANCE_ADD_URL,
} from "../../Pages/Api";
import axios from "axios";
import { MsalProvider } from "@azure/msal-react";
import MicrosoftBtn from "../../Pages/MicrosoftBtn";
import { msalConfig } from "../Common/authconfig";
import { PublicClientApplication } from "@azure/msal-browser";

const MicrosoftExcelDetail = ({ sidebarOpen, setSidebarOpen }) => {
  const currentDate = new Date();
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, token, userDetails } = useSelector((state) => state.root.auth);
  const authToken = `Bearer ${token}`;
  const [instanceName, setInstanceName] = useState(
    moment(currentDate).format("YYYY-MM-DD hh:mm")
  );
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [toggle, settoggle] = useState({
    header: false,
    Pinned: false,
  });
  const [microsoftData, setMicroSoftData] = useState({
    range: "",
    title: "",
  });

  const msalInstance = new PublicClientApplication(msalConfig);

  useEffect(() => {
    if (id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${GET_MicrosoftExcel_BY_ID}?msExcelId=${id}`,
        headers: {
          Authorization: authToken,
        },
      };
      axios.request(config).then((response) => {
        const data = response?.data?.data?.list;
        setMicroSoftData({
          range: data?.cellRange,
          title: data?.title,
        });

        settoggle({
          header: data?.isToggle,
          Pinned: data?.isPinned,
        });
      });
    }
  }, [id]);

  const handleOnSaveInstanceName = (e) => {
    if (!instanceName.replace(/\s/g, "").length) {
      toast.remove();
      return toast.error("Please enter at least minimum 1 character.");
    }
    setEdited(false);
  };
  const handleMuteChange = (e) => {
    console.log(e, "e");
    const { name, checked } = e.target;
    settoggle((pre) => ({
      ...pre,
      [name]: checked,
    }));
  };

  const handleChange = (e) => {
    setMicroSoftData({ ...microsoftData, [e.target.name]: e.target.value });
  };
  const addMicrosoftApp = () => {
    if (instanceName === "" && microsoftData === "") {
      toast.remove();
      return toast.error("Please fill all the details");
    }

    const data = {
      microsoftExcelAppId: id ? id : 0,
      instanceName: instanceName,
      chooseItem: "",
      cellRange: microsoftData?.range,
      isToggle: toggle.header,
      isPinned: toggle.Pinned,
      title: microsoftData?.title,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: MicrosoftExcel_INSTANCE_ADD_URL,
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setSaveLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (window?.history?.length === 1) {
          localStorage.setItem("isWindowClosed", "true");
          window.close();
        } else {
          navigate("/Microsoft-Excel");
        }
        setSaveLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSaveLoading(false);
      });
  };

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
          user?.userDetails?.isRetailer === false &&
          !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div>
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter schedule name"
                        value={instanceName}
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      //   if (locations?.length === errorList?.length) {
                      //     toast.error("Please Enter any Location");
                      //     return;
                      //   }
                      //   setSelectedPreview(!selectedPreview);
                      //   setSelectedLayout("Landscape");
                      "";
                    }}
                  >
                    {/* // {selectedPreview ? "Edit" : "Preview"} */}
                    Preview
                  </button>
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => addMicrosoftApp()}
                    disabled={""}
                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>

                  <Link to="/Microsoft-Excel">
                    <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                  <div className="mb-4 flex items-center justify-between">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Choose Items from OneDrive *
                    </label>
                    <div className="text-right">
                      <MsalProvider instance={msalInstance}>
                        <MicrosoftBtn
                          register={false}
                          setLoading={setLoading}
                          textShow={true}
                        />
                      </MsalProvider>
                    </div>
                  </div>

                  <div className="mb-4 flex items-center justify-between">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Cell range*
                    </label>
                    <input
                      name="range"
                      type="text"
                      value={microsoftData.range}
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="A1:B2"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-4 flex items-center justify-between">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Toggle header
                    </label>
                    <div className="text-right">
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          readOnly
                          name="header"
                          checked={toggle.header}
                          onChange={(e) => handleMuteChange(e)}
                        />
                        <div
                          className={`w-11 h-6 ${
                            toggle.header ? "bg-SlateBlue" : "bg-gray-300"
                          } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                        ></div>
                      </label>
                    </div>
                  </div>

                  <div className="mb-4 flex items-center justify-between">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Sheet title
                    </label>
                    <input
                      name="title"
                      value={microsoftData.title}
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Test"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-4 flex items-center justify-between">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Pinned header row
                    </label>
                    <div className="text-right">
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          name="Pinned"
                          readOnly
                          checked={toggle.Pinned}
                          onChange={(e) => handleMuteChange(e)}
                        />
                        <div
                          className={`w-11 h-6 ${
                            toggle.Pinned ? "bg-SlateBlue" : "bg-gray-300"
                          } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                        ></div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                  <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none">
                    <div className="text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-16 w-16 mx-auto text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 7V3h18v4M4 10h16v4H4v-4z"
                        />
                      </svg>
                      <p className="text-gray-500">App instance preview</p>
                      <p className="text-sm text-gray-400">
                        Please edit the app settings to get started
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MicrosoftExcelDetail;
