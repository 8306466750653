import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";

import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { MdSave } from "react-icons/md";
import { useSelector } from "react-redux";
import axios from "axios";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import AirQualityPreview from "./AirQualityPreview";
import { useDispatch } from "react-redux";
import { getAirQualityById, handleAddAirQualityApps } from "../../../Redux/AppsSlice";

export default function AirQualityDetails({ sidebarOpen, setSidebarOpen }) {
    const currentDate = new Date();
    const { user, userDetails } = useSelector((state) => state.root.auth);
    let api_key = "41b5176532e682fd8b4cb6a44e3bd1a4";
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [instanceName, setInstanceName] = useState(moment(currentDate).format("YYYY-MM-DD hh:mm"));
    const [edited, setEdited] = useState(false);
    const [loadFirst, setLoadFirst] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const [selectedTemperature, setSelectedTemperature] = useState("Celsius");
    const [display, SetDisplay] = useState("Air Quality and Heat");
    const [airquality, setAirQuality] = useState({
        Location: "Ahmedabad",
        Weatherdata: "",
        AirQuality: "",
    });
    const [showPreview, setShowPreview] = useState(false);
    const [loading, setLoading] = useState(false);
    const [GPSLocation, setGPSLocation] = useState({
        latitude: null,
        longitude: null,
    });
    const [checklocation, setCheckLocation] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        if (id) {
            toast.loading("Fetching Data....");
            dispatch(getAirQualityById(id)).then((res) => {
                if (res?.payload?.status === 200) {
                    setLoadFirst(true)
                    toast.remove();
                    const fetchData = res?.payload?.data
                    setInstanceName(fetchData?.instanceName)
                    setIsMuted(fetchData?.isScreenLocation)
                    setAirQuality({ Location: fetchData?.location })
                    setSelectedTemperature(fetchData?.temperatureUnit)
                    SetDisplay(fetchData?.indexesToDisplay)
                }
            })
        }
    }, [id]);

    useEffect(() => {
        const fetchWeatherData = async () => {
            if (!loadFirst) return;
            try {
                setLoading(true);
                setCheckLocation(true);
                if (!api_key) {
                    console.error("API key is missing");
                    return;
                }
                const data = {};
                const locations = airquality?.Location?.split(",") || [];
                const promises = locations.map(async (location) => {
                    if (!location) return null;
                    const apiUrl = isMuted
                        ? `https://api.openweathermap.org/data/2.5/forecast?lat=${GPSLocation?.latitude}&lon=${GPSLocation?.longitude}&appid=${api_key}&units=metric`
                        : `https://api.openweathermap.org/data/2.5/forecast?q=${location}&appid=${api_key}&units=metric`;
                    const response = await axios.get(apiUrl);
                    if (response?.data?.city?.coord) {
                        AirQuality(response.data.city.coord);
                    }
                    data[location] = response.data.list[0];
                });
                await Promise.all(promises);
                const minTempLocation = Object.keys(data).reduce((minLoc, loc) =>
                    data[loc]?.main?.temp < data[minLoc]?.main?.temp ? loc : minLoc
                );
                updatetemp(data[minTempLocation]);
            } catch (error) {
                console.error("Error fetching weather data:", error);
                setCheckLocation(false);
            } finally {
                setLoadFirst(false);
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
            }
        };
        fetchWeatherData();
    }, [airquality?.Location, selectedTemperature, api_key, loadFirst]);

    useEffect(() => {
        if (isMuted && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setGPSLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setAirQuality({ Location: "Ahemdabad", Weatherdata: "" });
                    setLoadFirst(true);
                },
                (error) => {
                    console.error("Error getting the location:", error);
                }
            );
        } else {
            setLoadFirst(true);
        }
    }, [isMuted]);

    const Mutechange = (e) => {
        setLoadFirst(true);
        setSelectedTemperature(e.target.value);
    };

    const handleMuteChange = () => {
        setIsMuted(!isMuted);
        setGPSLocation({ latitude: null, longitude: null });
        setAirQuality({ Location: "ahmedabad", Weatherdata: "" });
    };

    const handlInputeChange = (e) => {
        setLoadFirst(true);
        const { value, name } = e.target;
        setAirQuality((pre) => ({
            ...pre,
            [name]: value,
        }));
    };

    const handleOnSaveInstanceName = (e) => {
        if (!instanceName.replace(/\s/g, "").length) {
            toast.remove();
            return toast.error("Please enter at least minimum 1 character.");
        }
        setEdited(false);
    };

    const updatetemp = (data) => {
        try {
            setLoading(true);
            const convertTemperature = (temp, toUnit) => {
                switch (toUnit) {
                    case "Fahrenheit":
                        return (temp * 9) / 5 + 32;
                    default:
                        return temp;
                }
            };
            const convertedTemp = convertTemperature(
                data?.main?.temp,
                selectedTemperature
            );
            const currentWeather = data?.weather?.[0]?.description || "";
            const obj = { temp: convertedTemp.toFixed(2), currentWeather };
            setAirQuality((prev) => ({
                ...prev,
                Weatherdata: obj,
            }));
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        } catch (error) {
            console.error("Error in updatetemp:", error);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };

    const AirQuality = async ({ lat, lon }) => {
        try {
            setLoading(true);
            const apiUrl = `http://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=2d814062ffec95b11153853086226222`;
            const response = await axios.get(apiUrl);
            const data = response?.data?.list[0]?.main;
            setAirQuality((prev) => ({
                ...prev,
                AirQuality: data?.aqi,
            }));
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const onSubmit = () => {
        const Payload = {
            aqiAppID: id ? id : 0,
            instanceName: instanceName,
            isScreenLocation: isMuted,
            location: airquality?.Location,
            lattitude: GPSLocation?.latitude,
            longitude: GPSLocation?.longitude,
            temperatureUnit: selectedTemperature,
            indexesToDisplay: display,
            screens: "",
            screenIDs: "",
            maciDs: "",
            tags: ""
        }
        setSaveLoading(true);
        try {
            dispatch(handleAddAirQualityApps(Payload)).then((res) => {
                setSaveLoading(false);
                navigate('/Air-Quality')
            })
        } catch (error) {
            console.log('error :>> ', error);
            setSaveLoading(false);
        }
    }

    return (
        <>
            <div className="flex border-b border-gray">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <Navbar />
            </div>
            <div
                className={
                    userDetails?.isTrial &&
                        user?.userDetails?.isRetailer === false &&
                        !userDetails?.isActivePlan
                        ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
                        : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
                }
            >
                <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
                    <div className="px-6 page-contain">
                        <div>
                            <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                                <div className="flex items-center">
                                    {edited ? (
                                        <div className="flex items-center gap-2">
                                            <input
                                                type="text"
                                                className="w-full border border-primary rounded-md px-2 py-1"
                                                placeholder="Enter schedule name"
                                                value={instanceName}
                                                onChange={(e) => {
                                                    setInstanceName(e.target.value);
                                                }}
                                            />
                                            <MdSave
                                                onClick={() => handleOnSaveInstanceName()}
                                                className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                                                {instanceName}
                                            </h1>
                                            <button onClick={() => setEdited(true)}>
                                                <GoPencil className="ml-4 text-lg" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                                    <button
                                        className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                        onClick={() => {
                                            if (airquality?.Location === "") {
                                                toast.error("Please Enter any Location");
                                                return;
                                            }
                                            setShowPreview(!showPreview);
                                        }}
                                    >
                                        {showPreview ? "Edit" : "Preview"}
                                    </button>
                                    <button
                                        className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                        onClick={() => onSubmit()}
                                        disabled={saveLoading}
                                    >
                                        {saveLoading ? "Saving..." : "Save"}
                                    </button>
                                    <Link to="/Air-Quality">
                                        <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                                            <AiOutlineClose />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            {showPreview && (
                                <div className="w-full h-[35rem] mx-auto bg-black rounded-sm shadow-md border-4 border-black  flex items-center justify-center mt-5">
                                    <div className="w-full h-full flex items-center justify-center bg-cover bg-center text-white relative">
                                        <div className=" w-full h-full p-5 flex flex-col items-center justify-center ">
                                            <h2 className="text-lg text-left font-semibold lg:text-xl text-white uppercase mb-5 ">
                                                {airquality?.Location}
                                            </h2>
                                            <div
                                                className={`grid ${(display === "Air Quality" &&
                                                    display === "Heat") ||
                                                    display === "Air Quality and Heat"
                                                    ? "grid-cols-2"
                                                    : "grid-cols-1"
                                                    } gap-4 md:gap-6 lg:gap-8  w-full`}
                                            >
                                                {(display === "Air Quality" ||
                                                    display === "Air Quality and Heat") && (
                                                        <div className="bg-[#ffff00]  md:p-8 lg:p-5 rounded-xl shadow-xl text-center ">
                                                            <h2 className="text-lg font-semibold lg:text-xl text-black uppercase">
                                                                Air Quality
                                                            </h2>
                                                            <div className=" bg-[#00000026] p-5 py-8 mt-2 rounded-lg flex flex-col items-center justify-center h-60">
                                                                <p className="text-3xl font-bold  lg:text-6xl  text-black">
                                                                    {airquality?.AirQuality || ""}
                                                                </p>
                                                                <p className="text-lg mt-2 lg:text-xl lg:mt-4 text-black font-medium uppercase" >
                                                                    Moderate Air Quality
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                {(display === "Heat" || display === "Air Quality and Heat") && (
                                                    <div className="bg-[#ffff00]  md:p-8 lg:p-5 rounded-xl shadow-xl text-center">
                                                        <h2 className="text-lg font-semibold lg:text-xl text-black">
                                                            Heat Index
                                                        </h2>
                                                        <div className=" bg-[#00000026] p-5 py-8 mt-2 rounded-lg flex flex-col items-center justify-center h-60">
                                                            <p className="text-3xl font-bold  lg:text-6xl  text-black">
                                                                {airquality?.Weatherdata?.temp
                                                                    ? `${airquality?.Weatherdata?.temp}°${selectedTemperature === "Fahrenheit"
                                                                        ? "F"
                                                                        : "C"
                                                                    }`
                                                                    : "N/A"}
                                                            </p>
                                                            <p className="text-lg mt-2 lg:text-xl lg:mt-4 text-black font-medium uppercase" >
                                                                {airquality?.Weatherdata?.currentWeather || "N/A"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!showPreview && (
                                <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                                    <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                                        <div className="mb-4 flex items-center justify-between">
                                            <label
                                                htmlFor="countries"
                                                className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Use screen location
                                            </label>
                                            <div className="text-right">
                                                <label className="inline-flex relative items-center cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        className="sr-only peer"
                                                        readOnly
                                                        name="screen_location"
                                                        checked={isMuted}
                                                        onChange={(e) => handleMuteChange(e)}
                                                    />
                                                    <div
                                                        className={`w-11 h-6 ${isMuted ? "bg-SlateBlue" : "bg-gray-300"
                                                            } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                                                    ></div>
                                                    <div className="relative inline-block">
                                                        <button className="ml-2 text-gray-500 relative group">
                                                            ⓘ
                                                            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 -mb-1 w-56 bg-gray-700 text-white bg-black text-xs rounded-lg py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10 pointer-events-none">
                                                                A minimum of 1 and maximum of 75 photos can be
                                                                displayedF
                                                            </span>
                                                        </button>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        {!isMuted && (
                                            <div className="mb-4 flex items-center justify-between">
                                                <label
                                                    htmlFor="countries"
                                                    className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                >
                                                    Location*
                                                </label>
                                                <input
                                                    type="text"
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder=""
                                                    value={airquality?.Location}
                                                    name="Location"
                                                    onChange={handlInputeChange}
                                                />
                                            </div>
                                        )}
                                        <div className="mb-3 relative inline-flex items-center w-full">
                                            <label
                                                htmlFor="message"
                                                className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Temperature Unit*
                                            </label>
                                            <select
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                name="Temperature_Unit"
                                                value={selectedTemperature}
                                                onChange={(e) => Mutechange(e)}
                                            >
                                                <option value="Fahrenheit">Fahrenheit</option>
                                                <option value="Celsius">Celsius</option>
                                            </select>
                                        </div>
                                        <div className="mb-3 relative inline-flex items-center w-full">
                                            <label
                                                htmlFor="message"
                                                className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Index to display
                                            </label>
                                            <select
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                value={display}
                                                onChange={(e) => SetDisplay(e.target.value)}
                                            >
                                                <option value="Air Quality">Air Quality</option>
                                                <option value="Heat">Heat</option>
                                                <option value="Air Quality and Heat">Air Quality and Heat</option>
                                            </select>
                                            <div className="relative inline-block">
                                                <button className="ml-2 text-gray-500 relative group">
                                                    ⓘ
                                                    <span className="absolute bottom-full left-1/2 transform-translate-x-1/2 -mb-1 w-56 bg-gray-700 text-white bg-black text-xs rounded-lg py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10 pointer-events-none">
                                                        A minimum of 1 and maximum of 75 photos can be
                                                        displayedF
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                                        <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none">
                                            <div className="flex justify-center items-center h-full w-full bg-black text-white">
                                                {airquality?.Location !== "" &&
                                                    airquality?.AirQuality &&
                                                    airquality?.Weatherdata &&
                                                    (loading ? (
                                                        <div className="w-full h-fullself-start font-semibold text-2xl m-3">
                                                            <div className="flex text-center m-5 justify-center">
                                                                <svg
                                                                    aria-hidden="true"
                                                                    role="status"
                                                                    className="inline w-10 h-10 me-3 text-gray-200 animate-spin dark:text-gray-600"
                                                                    viewBox="0 0 100 101"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                        fill="currentColor"
                                                                    />
                                                                    <path
                                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                        fill="#1C64F2"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    ) : checklocation ? (
                                                        <div className=" w-full h-full p-5">
                                                            <h2 className="text-lg font-semibold lg:text-xl text-white uppercase mb-3">
                                                                {airquality?.Location}
                                                            </h2>
                                                            <div
                                                                className={`grid ${(display === "Air Quality" && display === "Heat") || display === "Air Quality and Heat" ? "grid-cols-2"
                                                                    : "grid-cols-1"
                                                                    } gap-4 md:gap-6 lg:gap-8`}
                                                            >
                                                                {(display === "Air Quality" ||
                                                                    display === "Air Quality and Heat") && (
                                                                        <div className="bg-[#ffff00]  md:p-8 lg:p-5 rounded-xl shadow-xl text-center ">
                                                                            <h2 className="text-lg font-semibold lg:text-xl text-black uppercase">
                                                                                Air Quality
                                                                            </h2>
                                                                            <div className=" bg-[#00000026] p-5 py-8 mt-2 rounded-lg flex flex-col items-center justify-center h-60">
                                                                                <p className="text-3xl font-bold  lg:text-6xl  text-black">
                                                                                    {airquality?.AirQuality || ""}
                                                                                </p>
                                                                                <p className="text-lg mt-2 lg:text-xl lg:mt-4 text-black font-medium uppercase" >
                                                                                    Moderate Air Quality
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {(display === "Heat" || display === "Air Quality and Heat") && (
                                                                    <div className="bg-[#ffff00]  md:p-8 lg:p-5 rounded-xl shadow-xl text-center">
                                                                        <h2 className="text-lg font-semibold lg:text-xl text-black">
                                                                            Heat Index
                                                                        </h2>
                                                                        <div className=" bg-[#00000026] p-5 py-8 mt-2 rounded-lg flex flex-col items-center justify-center h-60">
                                                                            <p className="text-3xl font-bold  lg:text-6xl  text-black">
                                                                                {airquality?.Weatherdata?.temp
                                                                                    ? `${airquality?.Weatherdata?.temp}°${selectedTemperature === "Fahrenheit"
                                                                                        ? "F"
                                                                                        : "C"
                                                                                    }`
                                                                                    : "N/A"}
                                                                            </p>
                                                                            <p className="text-lg mt-2 lg:text-xl lg:mt-4 text-black font-medium uppercase" >
                                                                                {airquality?.Weatherdata?.currentWeather ||
                                                                                    "N/A"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="flex w-full h-full">
                                                            <div className="w-1/3 bg-gray-400"></div>
                                                            <div className="w-2/3 flex items-center justify-center bg-white">
                                                                <div className="text-center">
                                                                    <h1 className="text-xl font-semibold text-gray-700">
                                                                        Sorry, we seem to be having some trouble...
                                                                    </h1>
                                                                    <p className="mt-2 text-gray-500">
                                                                        Please make sure that the specified location
                                                                        is correct.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};