import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import Embed_Img from "../../images/AppsImg/embed.svg";

export default function EmbedPreview({ setInstanceView, embedPreview }) {
    return (
        <div>
            <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                    <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
                        <div className="flex items-center">
                            <div>
                                <img
                                    src={Embed_Img}
                                    alt="Logo"
                                    className="cursor-pointer mx-auto h-10 w-10"
                                />
                            </div>
                            <div className="ml-3">
                                <h4 className="text-lg font-medium whitespace-wrap w-[900px]">{embedPreview?.instanceName}</h4>
                            </div>
                        </div>
                        <button
                            className="p-1 text-3xl"
                            onClick={() => setInstanceView(false)}
                        >
                            <AiOutlineCloseCircle />
                        </button>
                    </div>
                    <div className="w-[full] xl:h-[500px] lg:h-[300px] bg-gray-50 rounded-sm shadow-md border-4 border-black flex items-center justify-center min-h-[100px] overflow-hidden">
                        <iframe
                            src={embedPreview?.embedUrl}
                            height="100%"
                            width="100%"
                            title="Embedded Page"
                            className="rounded-sm border-2 border-gray-300"
                            style={{ transform: `scale(${embedPreview?.embedUrl.includes('https://www.youtube.com') ? 1 : embedPreview?.zoomFactor ? embedPreview?.zoomFactor : 1})` }}
                        ></iframe>
                    </div>
                    <div className="py-2 px-6">
                        <div className="flex items-center gap-2 w-full">
                            <div className="font-semibold w-fit">
                                Tags:-
                            </div>
                            <div className=" w-full">{embedPreview?.tags}</div>
                        </div>
                        <div>
                            <label className="font-semibold">
                                Screen Assign :
                            </label>
                            {embedPreview?.screenAssignName == "" ? " No Screen" : embedPreview?.screenAssignName}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
