import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'

const DatePreview = ({ modalRef, instanceName, setInstanceView, currentMonthName, firstDay, daysInMonth, now, currentDayName, showTags, screenAssignName }) => {
    return (
        <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
            <div ref={modalRef}>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                    <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
                        <div className="flex items-center">
                            <div>
                                <img
                                    src="https://back.disploy.com/App/date.svg"
                                    alt="Logo"
                                    className="cursor-pointer mx-auto h-10 w-10"
                                />
                            </div>
                            <div className="ml-3">
                                <h4 className="text-lg font-medium whitespace-wrap w-[900px]">{instanceName}</h4>
                            </div>
                        </div>
                        <button
                            className="p-1 text-3xl"
                            onClick={() => setInstanceView(false)}
                        >
                            <AiOutlineCloseCircle />
                        </button>
                    </div>
                    {/* custom-scrollbar */}
                    <div>
                        <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none ">
                            <div className="flex items-center h-[70%] bg-white shadow-lg w-[80%] py-5">
                                <div className="calendar py-3 p-4 ">
                                    <h5 className=" flex justify-center my-3   text-xl font-semibold text-black">
                                        {currentMonthName}
                                    </h5>
                                    <div className="grid grid-cols-7 gap-x-12 gap-y-6 text-center text-xl my-5 ">
                                        {Array.from({ length: firstDay }, (_, i) => (
                                            <div key={`empty-${i}`} className=" "></div>
                                        ))}

                                        {Array.from({ length: daysInMonth }, (_, i) => (
                                            <div
                                                key={i}
                                                className={` text-center  ${now === i + 1 ? " bg-[#f04d4e] text-white text-xl h-8 w-8 pt-0.5 rounded-full" : ""}`}
                                            >
                                                {i + 1}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex items-center justify-center h-[24rem] bg-[#f04d4e] w-[280px] ml-8">
                                    <div className="text-center">
                                        <h1 className="text-2xl font-semibold text-white  ">
                                            {currentDayName}
                                        </h1>
                                        <h1 className="text-9xl text-white mt-2 ">{now}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-2 px-6">
                        <div className="flex items-center gap-2 w-full">
                            <div className="font-semibold w-fit">
                                Tags:-
                            </div>
                            <div className=" w-full">{showTags}</div>
                        </div>
                        <div>
                            <label className="font-semibold">
                                Screen Assign :
                            </label>
                            {screenAssignName == "" ? " No Screen" : screenAssignName}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DatePreview
