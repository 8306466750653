import React from "react";
import { useState, useEffect, } from "react";
import moment from "moment";
import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineClose, } from "react-icons/ai";
import { MdSave } from "react-icons/md";
import { useSelector } from "react-redux";
import { getFlightSchedulesById, handleAddFlightSchedules } from "../../../Redux/AppsSlice";
import { useDispatch } from "react-redux";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";

export default function MeetTheTeamDetails({ sidebarOpen, setSidebarOpen }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const currentDate = new Date();
    const { user, token, userDetails } = useSelector((state) => state.root.auth);


    const [instanceName, setInstanceName] = useState(moment(currentDate).format("YYYY-MM-DD hh:mm"));
    const [edited, setEdited] = useState(false);
    const [ShowPreview, setShowPreview] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [data, setdata] = useState({
        airportCode: "",
        isArrival: true,
        pageSpeed: "Slow",
        displayFlights: "",
        animation: true,
    });
    const dispatch = useDispatch()

    useEffect(() => {
        if (id) {
            toast.loading("Fetching Data....");
            dispatch(getFlightSchedulesById(id)).then((res) => {
                if (res?.payload?.status === 200) {
                    toast.remove();
                    const fetchData = res?.payload?.data?.list
                    setdata(fetchData)
                    setInstanceName(fetchData?.instanceName)
                }
            })
        }
    }, [id]);

    const handleOnSaveInstanceName = (e) => {
        if (!instanceName.replace(/\s/g, "").length) {
            toast.remove();
            return toast.error("Please enter at least minimum 1 character.");
        }
        setEdited(false);
    };

    const onsubmit = () => {
        if (instanceName === "" || data?.airportCode === "") {
            toast.remove();
            return toast.error("Please fill all the details");
        }

        const Payload = {
            ...data,
            flightScheduleAppId: id ? id : 0,
            instanceName: instanceName,
        }
        setSaveLoading(true);
        toast.loading('Saving ...')

        try {
            dispatch(handleAddFlightSchedules(Payload)).then((res) => {
                toast.remove()
                setSaveLoading(false);
                navigate('/Flight-Schedules')
            })
        } catch (error) {
            console.log('error :>> ', error);
        }
    };

    return (
        <>
            <div className="flex border-b border-gray">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <Navbar />
            </div>
            <div
                className={
                    userDetails?.isTrial &&
                        user?.userDetails?.isRetailer === false &&
                        !userDetails?.isActivePlan
                        ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
                        : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
                }
            >
                <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
                    <div className="px-6 page-contain">
                        <div>
                            <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                                <div className="flex items-center">
                                    {edited ? (
                                        <div className="flex items-center gap-2">
                                            <input
                                                type="text"
                                                className="w-full border border-primary rounded-md px-2 py-1"
                                                placeholder="Enter Instance name"
                                                value={instanceName}
                                                onChange={(e) => {
                                                    setInstanceName(e.target.value);
                                                }}
                                            />
                                            <MdSave
                                                onClick={() => handleOnSaveInstanceName()}
                                                className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                                                {instanceName}
                                            </h1>
                                            <button onClick={() => setEdited(true)}>
                                                <GoPencil className="ml-4 text-lg" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                                    <button
                                        className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                        onClick={() => {
                                            setShowPreview(!ShowPreview);
                                        }}
                                    >
                                        {ShowPreview ? "Edit" : "Preview"}
                                    </button>
                                    <button
                                        className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                        onClick={() => onsubmit()}
                                        disabled={saveLoading}
                                    >
                                        {saveLoading ? "Saving..." : "Save"}
                                    </button>

                                    <Link to="/Flight-Schedules">
                                        <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                                            <AiOutlineClose />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            {/* {ShowPreview && (
                <div className="w-full lg:w-[80%] h-[70vh] m-auto  bg-[#505ac9] rounded-sm shadow-md border-4 border-black  flex items-center justify-center p-6 overflow-hidden clip-triangle mt-3">
                  {(Selectrespondid === "Web Form" && Question?.WebUrl) ||
                    (Selectrespondid !== "Web Form" && Question?.Email) ? (
                    <div className="flex flex-col items-center justify-center w-full h-full  bg-[#505ac9] p-5">
                      <div className="bg-white rounded-lg shadow-md flex items-center justify-center w-auto h-auto">
                        <QRCodeSVG
                          value={
                            Selectrespondid === "Web Form"
                              ? Question?.WebUrl
                              : Question?.Email
                          }
                          size={250}
                          fgColor="#000000"
                          bgColor="#ffffff"
                          className="p-4"
                        />
                      </div>

                      <p className="text-white mt-2 text-center" style={{ fontSize: "10px" }}>
                        Studio tracking is not supported for the URL provided
                      </p>

                      <p className="text-white text-lg md:text-xl font-bold mt-4 text-center">
                        {Question.Question === ""
                          ? "What would you like to see on this screen? Let us know"
                          : Question.Question}
                      </p>
                    </div>
                  ) : (
                    <div className="flex text-center m-5 justify-center">
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-10 h-10 me-3 text-gray-200 animate-spin dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="#1C64F2"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              )} */}
                            {!ShowPreview && (
                                <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                                    <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                                        <div className="mb-4 flex items-center justify-between placeHolderColor">
                                            <label
                                                className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Airport Code (IATA/ICAO)*
                                            </label>
                                            <input
                                                type="text"
                                                id="first_name"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="LAX"
                                                name="Question"
                                                value={data?.airportCode}
                                                onChange={(e) => setdata({ ...data, airportCode: e.target.value })}
                                            />
                                        </div>
                                        <div className="mb-4 flex items-center justify-between">
                                            <label
                                                htmlFor="Departure/Arrival"
                                                className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Departure/Arrival
                                            </label>
                                            <select
                                                onChange={(e) => { setdata({ ...data, isArrival: e.target.value === 'Arrivals' ? true : false }); }}
                                                name="Unit_Show"
                                                value={data?.isArrival ? 'Arrivals' : 'Departures'}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            >
                                                <option value="Arrivals">Arrivals</option>
                                                <option value="Departures">Departures</option>
                                            </select>
                                        </div>
                                        <div className="mb-4 flex items-center justify-between">
                                            <label
                                                htmlFor="Page Speed"
                                                className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Page Speed
                                            </label>

                                            <select
                                                onChange={(e) => setdata({ ...data, pageSpeed: e.target.value })}
                                                name="Unit_Show"
                                                value={data?.pageSpeed}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            >
                                                <option value="Slow">Slow</option>
                                                <option value="Medium">Medium</option>
                                                <option value="Fast">Fast</option>
                                            </select>
                                        </div>
                                        <div className="mb-4 flex items-center justify-between">
                                            <label
                                                htmlFor="countries"
                                                className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                            >
                                                Animations
                                            </label>
                                            <div className="text-right">
                                                <label className="inline-flex relative items-center cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        className="sr-only peer cursor-pointer"
                                                        readOnly
                                                        name="Continuous"
                                                        checked={data?.animation}
                                                        onChange={(e) => setdata({ ...data, animation: e.target.checked })}
                                                    />
                                                    <div
                                                        className={`w-11 h-6 ${data?.animation
                                                            ? "bg-SlateBlue"
                                                            : "bg-gray-300"
                                                            } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                                                    ></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                                        <div className="w-full h-[30rem]  bg-[white] rounded-sm shadow-md border-4 border-black flex items-center justify-center overscroll-none">

                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}



