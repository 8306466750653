import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Thumbs,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

export const CanvaPreview = ({
  canvaDataById,
  screenAssignName,
  showTags,
  instanceName,
  setInstanceView,
}) => {
  const embedUrl = () => {
    return canvaDataById?.url?.includes("embed") ? canvaDataById?.url : `${canvaDataById?.url}?embed`;
  };

  const finalUrl = embedUrl();
  return (
    <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
      <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-[1000px] h-[600px]">
        <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
          <div className="flex items-center">
            <div>
              <img
                src="https://back.disploy.com/app/canva.svg"
                alt="Logo"
                className="cursor-pointer mx-auto h-10 w-10"
              />
            </div>
            <div className="ml-3">
              <h4 className="text-lg font-medium whitespace-wrap ">
                {instanceName}
              </h4>
            </div>
          </div>
          <button
            className="p-1 text-3xl"
            onClick={() => setInstanceView(false)}
          >
            <AiOutlineCloseCircle />
          </button>
        </div>
        <Swiper
          centeredSlides={true}
          autoplay={{
            delay: canvaDataById?.TimeItemDuration * 1000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="mySwiper h-full w-full"
        >
          {[...Array(parseInt(canvaDataById?.Count || 1))].map((_, i) => (
            <SwiperSlide key={i}>
              <div className="relative w-[100%] h-[34rem] pb-0 shadow-md overflow-hidden">
                <iframe
                  loading="lazy"
                  className="absolute p-1 w-full h-full"
                  src={`${finalUrl}#${i + 1}`}
                  allowFullScreen
                  allow="fullscreen"
                ></iframe>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="py-2 px-6">
          <div className="flex items-center gap-2 w-full">
            <div className="font-semibold w-fit">Tags:-</div>
            <div className=" w-full">{showTags}</div>
          </div>
          <div>
            <label className="font-semibold">Screen Assign :</label>
            {screenAssignName ? screenAssignName : " No Screen"}
          </div>
        </div>
      </div>
    </div>
  );
};
