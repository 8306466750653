import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  ADD_CLOCK_APPS,
  ADD_COUNTUPTIME_APPS,
  DELETE_CLOCK_APPS,
  ADD_CURRENICE_APPS,
  ADD_DATE_APPS,
  ADD_FOLLOW_US_ON_FACEBOOK_APPS,
  ADD_GOOGLE_TRAFFIC_APPS,
  ALL_DELETE_CURRENICE,
  ALL_DELETE_DATE,
  ALL_DELETE_FOLLOW_US_ON_FACEBOOK,
  ALL_DELETE_GOOGLE_TRAFFIC,
  DELETE_CURRENICE_APPS,
  DELETE_DATE_APPS,
  DELETE_FOLLOW_US_ON_FACEBOOK,
  DELETE_GOOGLE_TRAFFIC,
  getUrl,
  postUrl,
  DELETE_DIGITAL_SIGNS,
  ALL_DELETE_DIGITAL_SIGNS,
  ADD_DIGITAL_SIGNS_APPS,
  DELETE_PAGE_SCROLL,
  ADD_PAGE_SCROLL_APPS,
  ALL_DELETE_PAGE_SCROLL,
  ADD_LIVE_NEWS_APPS,
  DELETE_LIVE_NEWS,
  ALL_DELETE_LIVE_NEWS,
  ADD_SPORTS_LIVE_SCORES_APPS,
  DELETE_SPORTS_LIVE_SCORES,
  ALL_DELETE_SPORTS_LIVE_SCORES,
  ADD_FOLLOW_US_APPS,
  DELETE_FOLLOW_US,
  ALL_DELETE_FOLLOW_US,
  ADD_SOCCER_SCORES_APPS,
  DELETE_SOCCER_SCORES,
  ALL_DELETE_SOCCER_SCORES,
  ADD_WALLSIO_APPS,
  DELETE_WALLSIO,
  ALL_DELETE_WALLSIO,
  ADD_STREAMA_APPS,
  DELETE_STREAMA,
  ALL_DELETE_STREAMA,
  ADD_POSTER_MY_WALL_APPS,
  DELETE_POSTER_MY_WALL,
  ALL_DELETE_POSTER_MY_WALL,
  ADD_BONUSLY_APPS,
  DELETE_BONUSLY,
  ALL_DELETE_BONUSLY,
  ADD_CANVA_APPS,
  DELETE_CANVA,
  ALL_DELETE_CANVA,
  ADD_GECKO_BOARD_APPS,
  DELETE_GECKO_BOARD,
  ALL_DELETE_GECKO_BOARD,
  ADD_YELP_APPS,
  DELETE_YELP,
  ALL_DELETE_YELP,
  ADD_NDTV_NEWS_APPS,
  DELETE_NDTV_NEWS,
  ALL_DELETE_NDTV_NEWS,
  ADD_HUFFPOST_APPS,
  DELETE_HUFFPOST,
  ALL_DELETE_HUFFPOST,
  ADD_FOX_NEWS_APPS,
  DELETE_FOX_NEWS,
  ALL_DELETE_FOX_NEWS,
  ADD_NYT_NEWS_APPS,
  DELETE_NYT_NEWS,
  ALL_DELETE_NYT_NEWS,
  ADD_TOI_NEWS_APPS,
  DELETE_TOI_NEWS,
  ALL_DELETE_TOI_NEWS,
  ADD_ESPN_NEWS_APPS,
  DELETE_ESPN_NEWS,
  ALL_DELETE_ESPN_NEWS,
  ADD_BBC_NEWS_APPS,
  DELETE_BBC_NEWS,
  ALL_DELETE_BBC_NEWS,
  ADD_REGIONAL_NEWS_APPS,
  DELETE_REGIONAL_NEWS,
  ALL_DELETE_REGIONAL_NEWS,
  ADD_WALL_STREET_NEWS_APPS,
  DELETE_WALL_STREET_NEWS,
  ALL_DELETE_WALL_STREET_NEWS,
  ADD_CALENDER_FOR_GOOGLE_APPS,
  DELETE_CALENDER_FOR_GOOGLE,
  ALL_DELETE_CALENDER_FOR_GOOGLE,
  ADD_TAGG_BOX_DISPLAY_APPS,
  DELETE_TAGG_BOX_DISPLAY,
  ALL_DELETE_TAGG_BOX_DISPLAY,
  DELETE_BUILDING_DIRECTORY_APPS,
  ADD_BUILDING_DIRECTORY_APPS,
  ADD_GOOGLE_REVIEW_APPS,
  DELETE_GOOGLE_REVIEW_APPS,
  DELETE_AQI_APPS,
  ADD_AQI_APPS,
  ADD_STOCK_EXCHANGE_APPS,
  DELETE_STOCK_EXCHANGE_APPS,
  ADD_GOOGLE_TRENDS_APPS,
  DELETE_GOOGLE_TRENDS_APPS,
  ADD_GOOGLE_ALERTS_APPS,
  DELETE_GOOGLE_ALERTS,
  ALL_DELETE_GOOGLE_ALERTS,
  ADD_GOOGLE_SHEETS_APPS,
  DELETE_GOOGLE_SHEETS,
  ALL_DELETE_GOOGLE_SHEETS,
  ADD_ON_THIS_DAY_APPS,
  DELETE_ON_THIS_DAY,
  ALL_DELETE_ON_THIS_DAY,
  ADD_SPORTS_NEWS_APPS,
  DELETE_SPORTS_NEWS,
  ALL_DELETE_SPORTS_NEWS,
  ADD_BUSINESS_AND_FINANCE_NEWS_APPS,
  DELETE_BUSINESS_AND_FINANCE_NEWS,
  ALL_DELETE_BUSINESS_AND_FINANCE_NEWS,
  ADD_ENTERTAINMENT_NEWS_APPS,
  DELETE_ENTERTAINMENT_NEWS,
  ALL_DELETE_ENTERTAINMENT_NEWS,
  ADD_HEADLINE_NEWS_APPS,
  DELETE_HEADLINE_NEWS,
  ALL_DELETE_HEADLINE_NEWS,
  ADD_FLIGHT_SCHEDULE_APPS,
  DELETE_FLIGHT_SCHEDULE_APPS,
  ADD_INSPIRATIONAL_QUOTES_APPS,
  DELETE_INSPIRATIONAL_QUOTES,
} from "../Pages/Api";
import axios from "axios";

export const handleGetYoutubeData = createAsyncThunk(
  "apps/handleGetYoutubeData",
  async ({ token }, { rejectWithValue, signal }) => {
    try {
      const { data } = await getUrl(`YoutubeApp/GetAllYoutubeApp`, {
        headers: {
          Authorization: token,
        },
        signal,
      });

      if (data?.status == 200) return data;
      else {
        toast.error(data?.message);
        return rejectWithValue(data?.message);
      }
    } catch (error) {
      rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const handleGetTextScrollData = createAsyncThunk(
  "apps/handleGetTextScrollData",
  async ({ token }, { rejectWithValue, signal }) => {
    try {
      const { data } = await getUrl(`YoutubeApp/GetAlltextScroll`, {
        headers: {
          Authorization: token,
        },
        signal,
      });
      if (data?.status == 200) return data;
      else {
        toast.error(data?.message);
        return rejectWithValue(data?.message);
      }
    } catch (error) {
      rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const handleGetWeatherData = createAsyncThunk(
  "apps/handleGetWeatherData",
  async ({ token }, { rejectWithValue, signal }) => {
    try {
      const { data } = await getUrl(`WeatherApp/GetWeatherApp`, {
        headers: {
          Authorization: token,
        },
        signal,
      });
      if (data?.status == 200) return data;
      else {
        toast.error(data?.message);
        return rejectWithValue(data?.message);
      }
    } catch (error) {
      rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const handleGetAllApps = createAsyncThunk(
  "apps/handleGetAllApps",
  async ({ token }, { rejectWithValue, signal }) => {
    try {
      const { data } = await getUrl(`YoutubeApp/GetAllApps`, {
        headers: {
          Authorization: token,
        },
        signal,
      });
      if (data?.status == 200) return data;
      else {
        toast.error(data?.message);
        return rejectWithValue(data?.message);
      }
    } catch (error) {
      rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const handleGetDigitalMenuData = createAsyncThunk(
  "apps/handleGetDigitalMenuData",
  async ({ token }, { rejectWithValue, signal }) => {
    try {
      const { data } = await getUrl(`YoutubeApp/GetAllYoutubeApp`, {
        headers: { Authorization: token },
        signal,
      });
      if (data?.status == 200) return data;
      else {
        toast.error(data?.message);
        return rejectWithValue(data?.message);
      }
    } catch (error) {
      rejectWithValue(error?.response?.data?.message);
    }
  }
);

// BuildingDirectory Apps
export const getBuildingDirectoryApps = createAsyncThunk("BuildingDirectoryApp/getBuildingDirectoryApps", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`BuildingDirectoryApp/GetAllBuildingDirectoryApp`, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
});

export const deleteBuildingDirectory = createAsyncThunk("BuildingDirectoryApp/deleteBuildingDirectory", async (Id, thunkAPI) => {
  try {
    const queryParams = new URLSearchParams({ buildingDirectoryAppId: Id }).toString();
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(`${DELETE_BUILDING_DIRECTORY_APPS}?${queryParams}`, {}, { headers: { Authorization: `Bearer ${token}` } });
    if (response.data.status) {
      return {
        status: true,
        message: response.data.message,
        data: response.data.data,
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getBuildingDirectoryById = createAsyncThunk("BuildingDirectoryApp/getBuildingDirectoryById", async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`BuildingDirectoryApp/SelectBuildingDirectoryAppById?buildingDirectoryAppId=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
});

export const handleAddBuildingDirectory = createAsyncThunk("BuildingDirectoryApp/handleAddBuildingDirectory", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(ADD_BUILDING_DIRECTORY_APPS, payload, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
});


// getDate Apps
export const getDateApps = createAsyncThunk(
  "DateApp/getDateApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`DateApp/GetAllDateApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // const response = await axios.get(GET_NOTIFICATIONS, { headers: { Authorization: `Bearer ${token}` } });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);

export const getDateById = createAsyncThunk(
  "DateApp/getDateById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`DateApp/SelectDateAppById?dateId=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);

export const deleteDate = createAsyncThunk(
  "DateApp/deleteDate",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ DateAppId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_DATE_APPS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const AlldeleteDate = createAsyncThunk(
  "DateApp/AlldeleteDate",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${ALL_DELETE_DATE}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const handleAddDateApps = createAsyncThunk(
  "DateApp/handleAddDateApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_DATE_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Currency Apps
export const getCurrencyApps = createAsyncThunk(
  "CurrenciesApp/getCurrencyApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`CurrenciesApp/GetAllCurrenciesApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);

export const getCurrencyById = createAsyncThunk(
  "CurrenciesApp/getDateById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `CurrenciesApp/SelectCurrenciesAppById?currenciesAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);

export const deleteCurrency = createAsyncThunk(
  "CurrenciesApp/deleteCurrency",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        currenciesAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_CURRENICE_APPS}?${queryParams}`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const handleAddCurrencyApps = createAsyncThunk(
  "CurrenciesApp/handleAddDateApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_CURRENICE_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const handleGetCountUpTimerData = createAsyncThunk(
  "apps/handleGetCountDownTimerData",
  async ({ token }, { rejectWithValue, signal }) => {
    try {
      const { data } = await getUrl(`CountUpTimerApp/GetAllCountUpTimerApp`, {
        headers: {
          Authorization: token,
        },
        signal,
      });
      if (data?.status == 200) return data;
      else {
        toast.error(data?.message);
        return rejectWithValue(data?.message);
      }
    } catch (error) {
      rejectWithValue(error?.response?.data?.message);
    }
  }
);
// export const handleGetCountDownTimerData = createAsyncThunk(
//   "apps/handleGetCountDownTimerData",
//   async ({ token }, { rejectWithValue, signal }) => {
//     try {
//       const { data } = await getUrl(`CountDownTimerApp/GetAllCountDownTimerApp`, {
//         headers: {
//           Authorization: token,
//         },
//         signal,
//       });
//       if (data?.status == 200) return data;
//       else {
//         toast.error(data?.message);
//         return rejectWithValue(data?.message);
//       }
//     } catch (error) {
//       rejectWithValue(error?.response?.data?.message);
//     }
//   }
// );
export const handleGetQuestionsData = createAsyncThunk(
  "apps/handleGetQuestionsData",
  async ({ token }, { rejectWithValue, signal }) => {
    try {
      const { data } = await getUrl(`AskAQuestionApp/GetAllAskAQuestionApp`, {
        headers: {
          Authorization: token,
        },
        signal,
      });
      if (data?.status == 200) return data;
      else {
        toast.error(data?.message);
        return rejectWithValue(data?.message);
      }
    } catch (error) {
      rejectWithValue(error?.response?.data?.message);
    }
  }
);
export const handleGetMicrosoftExcelData = createAsyncThunk(
  "apps/handleGetMicrosoftExcelData",
  async ({ token }, { rejectWithValue, signal }) => {
    try {
      const { data } = await getUrl(`MicrosoftExcelApp/SelectAll`, {
        headers: {
          Authorization: token,
        },
        signal,
      });
      if (data?.status == 200) return data;
      else {
        toast.error(data?.message);
        return rejectWithValue(data?.message);
      }
    } catch (error) {
      rejectWithValue(error?.response?.data?.message);
    }
  }
);

// export const handleGetEmbedData = createAsyncThunk(
//   "apps/handleGetEmbedData",
//   async ({ token }, { rejectWithValue, signal }) => {
//     try {
//       const { data } = await getUrl(`AskAQuestionApp/GetAllAskAQuestionApp`, {
//         headers: {
//           Authorization: token,
//         },
//         signal,
//       });
//       if (data?.status == 200) return data;
//       else {
//         toast.error(data?.message);
//         return rejectWithValue(data?.message);
//       }
//     } catch (error) {
//       rejectWithValue(error?.response?.data?.message);
//     }
//   }
// )

// Google Traffic App

export const getGoogleTrafficApps = createAsyncThunk(
  "GoogleTrafficApp/getGoogleTrafficApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`GoogleTrafficApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getGoogleTrafficById = createAsyncThunk(
  "GoogleTrafficApp/getGoogleTrafficById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `GoogleTrafficApp/Select?googleTrafficId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const deleteGoogleTraffic = createAsyncThunk(
  "GoogleTrafficApp/deleteGoogleTraffic",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        googleTrafficId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_GOOGLE_TRAFFIC}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteGoogleTraffic = createAsyncThunk(
  "GoogleTrafficApp/AllDeleteGoogleTraffic",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${ALL_DELETE_GOOGLE_TRAFFIC}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const handleAddGoogleTraffic = createAsyncThunk(
  "GoogleTrafficApp/handleAddGoogleTraffic",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_GOOGLE_TRAFFIC_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Follow Us On FacebookApp

export const getFollowUsOnFacebook = createAsyncThunk(
  "FollowUsOnFacebookApp/getFollowUsOnFacebook",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`FollowUsOnFacebookApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getFollowUsOnFacebookById = createAsyncThunk(
  "FollowUsOnFacebookApp/getFollowUsOnFacebookById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`FollowUsOnFacebookApp/Select?fbId=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const deleteFollowUsOnFacebook = createAsyncThunk(
  "FollowUsOnFacebookApp/deleteFollowUsOnFacebook",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ fbId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_FOLLOW_US_ON_FACEBOOK}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteFollowUsOnFacebook = createAsyncThunk(
  "FollowUsOnFacebookApp/AllDeleteFollowUsOnFacebook",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${ALL_DELETE_FOLLOW_US_ON_FACEBOOK}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const handleAddFollowUsOnFacebook = createAsyncThunk(
  "FollowUsOnFacebookApp/handleAddFollowUsOnFacebook",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        ADD_FOLLOW_US_ON_FACEBOOK_APPS,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Digital SignsApp

export const getDigitalSigns = createAsyncThunk(
  "DigitalSignsApp/getDigitalSigns",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`DigitalSignsApp/GetAllDigitalSignsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getDigitalSignsById = createAsyncThunk(
  "DigitalSignsApp/getDigitalSignsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `DigitalSignsApp/SelectDigitalSignsAppById?digitalSignsId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const deleteDigitalSigns = createAsyncThunk(
  "DigitalSignsApp/deleteDigitalSigns",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        digitalSignsId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_DIGITAL_SIGNS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteDigitalSigns = createAsyncThunk(
  "DigitalSignsApp/AllDeleteDigitalSigns",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_DIGITAL_SIGNS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const handleAddDigitalSigns = createAsyncThunk(
  "DigitalSignsApp/handleAddDigitalSigns",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_DIGITAL_SIGNS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Page ScrollApp
export const getPageScroll = createAsyncThunk(
  "PageScrollApp/getPageScroll",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`PageScrollApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getPageScrollById = createAsyncThunk(
  "PageScrollApp/getPageScrollById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`PageScrollApp/Select?pageScrollId=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddPageScroll = createAsyncThunk(
  "PageScrollApp/handleAddPageScroll",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_PAGE_SCROLL_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deletePageScroll = createAsyncThunk(
  "PageScrollApp/deletePageScroll",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ pageScrollId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_PAGE_SCROLL}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeletePageScroll = createAsyncThunk(
  "PageScrollApp/AllDeletePageScroll",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_PAGE_SCROLL}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//Live NewsApp
export const getLiveNews = createAsyncThunk(
  "LiveNewsApp/getLiveNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`LiveNewsApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getLiveNewsById = createAsyncThunk(
  "LiveNewsApp/getLiveNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`LiveNewsApp/Select?liveNewsId=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddLiveNews = createAsyncThunk(
  "LiveNewsApp/handleAddLiveNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_LIVE_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteLiveNews = createAsyncThunk(
  "LiveNewsApp/deleteLiveNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ liveNewsId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_LIVE_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteLiveNews = createAsyncThunk(
  "LiveNewsApp/AllDeleteLiveNews",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_LIVE_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//Sports Live Scores App
export const getSportsLiveScores = createAsyncThunk(
  "SportsLiveScoresApp/getSportsLiveScores",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`SportsLiveScoresApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getSportsLiveScoresById = createAsyncThunk(
  "SportsLiveScoresApp/getSportsLiveScoresById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `SportsLiveScoresApp/Select?liveScoresId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddSportsLiveScores = createAsyncThunk(
  "SportsLiveScoresApp/handleAddSportsLiveScores",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_SPORTS_LIVE_SCORES_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteSportsLiveScores = createAsyncThunk(
  "SportsLiveScoresApp/deleteSportsLiveScores",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ liveScoresId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_SPORTS_LIVE_SCORES}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteSportsLiveScores = createAsyncThunk(
  "SportsLiveScoresApp/AllDeleteSportsLiveScores",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_SPORTS_LIVE_SCORES}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//FollowUs App
export const getFollowUs = createAsyncThunk(
  "FollowUsApp/getFollowUsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`FollowUsApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getFollowUsById = createAsyncThunk(
  "FollowUsApp/getFollowUsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`FollowUsApp/Select?followUsAppId=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddFollowUs = createAsyncThunk(
  "FollowUsApp/handleAddFollowUs",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_FOLLOW_US_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteFollowUs = createAsyncThunk(
  "FollowUsApp/deleteFollowUs",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ followUsAppId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_FOLLOW_US}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteFollowUs = createAsyncThunk(
  "FollowUsApp/AllDeleteFollowUs",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_FOLLOW_US}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//Soccer Scores App
export const getSoccerScores = createAsyncThunk(
  "SoccerScoresApp/getSoccerScoresApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`SoccerScoresApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getSoccerScoresById = createAsyncThunk(
  "SoccerScoresApp/getSoccerScoresById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `SoccerScoresApp/Select?soccerScoresId=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddSoccerScores = createAsyncThunk(
  "SoccerScoresApp/handleAddSoccerScores",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_SOCCER_SCORES_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteSoccerScores = createAsyncThunk(
  "SoccerScoresApp/deleteSoccerScores",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        soccerScoresId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_SOCCER_SCORES}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteSoccerScores = createAsyncThunk(
  "SoccerScoresApp/AllDeleteSoccerScores",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_SOCCER_SCORES}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//Wallsio App
export const getWallIo = createAsyncThunk(
  "WallioApp/getWallioApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`WallsioApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getWallIoById = createAsyncThunk(
  "WallioApp/getWallioById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`WallsioApp/Select?wallsioAppId=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddWallIo = createAsyncThunk(
  "WallioApp/handleAddWallio",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_WALLSIO_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteWallIo = createAsyncThunk(
  "WallioApp/deleteWallio",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ wallsioAppId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_WALLSIO}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteWallIo = createAsyncThunk(
  "WallioApp/AllDeleteWallio",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_WALLSIO}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//Streama App
export const getStreaMa = createAsyncThunk(
  "StreamaApp/getStreamaApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`StreamaApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getStreaMaById = createAsyncThunk(
  "StreamaApp/getStreamaById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`StreamaApp/Select?streamaAppId=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddStreaMa = createAsyncThunk(
  "StreamaApp/handleAddStreama",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_STREAMA_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteStreaMa = createAsyncThunk(
  "StreamaApp/deleteStreama",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ streamaAppId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_STREAMA}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteStreaMa = createAsyncThunk(
  "StreamaApp/AllDeleteStreama",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_STREAMA}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//Poster My Wall App
export const getPosterMyWall = createAsyncThunk(
  "PosterMyWallApp/getPosterMyWallApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`PosterMyWallApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getPosterMyWallById = createAsyncThunk(
  "PosterMyWallApp/getPosterMyWallById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `PosterMyWallApp/Select?posterMyWallId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddPosterMyWall = createAsyncThunk(
  "PosterMyWallApp/handleAddPosterMyWall",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_POSTER_MY_WALL_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deletePosterMyWall = createAsyncThunk(
  "PosterMyWallApp/deletePosterMyWall",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        posterMyWallId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_POSTER_MY_WALL}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeletePosterMyWall = createAsyncThunk(
  "PosterMyWallApp/AllDeletePosterMyWall",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_POSTER_MY_WALL}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//Bonusly App
export const getBonusly = createAsyncThunk(
  "BonuslyApp/getBonuslyApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`BonuslyApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getBonuslyById = createAsyncThunk(
  "BonuslyApp/getBonuslyById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`BonuslyApp/Select?bonuslyAppId=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddBonusly = createAsyncThunk(
  "BonuslyApp/handleAddBonusly",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_BONUSLY_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteBonusly = createAsyncThunk(
  "BonuslyApp/deleteBonusly",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ bonuslyAppId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_BONUSLY}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteBonusly = createAsyncThunk(
  "BonuslyApp/AllDeleteBonusly",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_BONUSLY}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//Canva App
export const getCanva = createAsyncThunk(
  "CanvaApp/getCanvaApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`CanvaApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getCanvaById = createAsyncThunk(
  "CanvaApp/getCanvaById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`CanvaApp/Select?canvaAppId=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddCanva = createAsyncThunk(
  "CanvaApp/handleAddCanva",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_CANVA_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteCanva = createAsyncThunk(
  "CanvaApp/deleteCanva",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ canvaAppId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_CANVA}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteCanva = createAsyncThunk(
  "CanvaApp/AllDeleteCanva",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_CANVA}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//GeckoBoard App
export const getGeckoBoard = createAsyncThunk(
  "GeckoBoardApp/getGeckoBoardApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`GeckoBoardApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getGeckoBoardById = createAsyncThunk(
  "GeckoBoardApp/getGeckoBoardById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`GeckoBoardApp/Select?geckoBoardId=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddGeckoBoard = createAsyncThunk(
  "GeckoBoardApp/handleAddGeckoBoard",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_GECKO_BOARD_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteGeckoBoard = createAsyncThunk(
  "GeckoBoardApp/deleteGeckoBoard",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        geckoBoardId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_GECKO_BOARD}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteGeckoBoard = createAsyncThunk(
  "GeckoBoardApp/AllDeleteGeckoBoard",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_GECKO_BOARD}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//Yelp App
export const getYelp = createAsyncThunk(
  "YelpApp/getYelpsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`YelpApp/GetAllYelpApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getYelpById = createAsyncThunk(
  "YelpApp/getYelpById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `YelpApp/SelectYelpAppById?yelpAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const handleAddYelp = createAsyncThunk(
  "YelpApp/handleAddYelp",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_YELP_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteYelp = createAsyncThunk(
  "YelpApp/deleteYelp",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        yelpAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_YELP}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteYelp = createAsyncThunk(
  "YelpApp/AllDeleteYelp",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_YELP}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//NDTVNewsApp
export const getNDTVNewsApps = createAsyncThunk(
  "NDTVNewsApp/getNDTVNewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`NDTVNewsApp/GetAllNDTVNewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getNDTVNewsById = createAsyncThunk(
  "NDTVNewsApp/getNDTVNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `NDTVNewsApp/SelectNDTVNewsAppById?ndtvNewsAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddNDTVNews = createAsyncThunk(
  "NDTVNewsApp/handleAddNDTVNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_NDTV_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteNDTVNews = createAsyncThunk(
  "NDTVNewsApp/deleteNDTVNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        ndtvNewsAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_NDTV_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteNDTVNews = createAsyncThunk(
  "NDTVNewsApp/AllDeleteNDTVNews",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_NDTV_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//HuffpostApp
export const getHuffpostApps = createAsyncThunk(
  "HuffpostApp/getHuffpostApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`HuffpostApp/GetAllHuffpostApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getHuffpostById = createAsyncThunk(
  "HuffpostApp/getHuffpostById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `HuffpostApp/SelectHuffpostAppById?huffpostAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddHuffpost = createAsyncThunk(
  "HuffpostApp/handleAddHuffpost",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_HUFFPOST_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteHuffpost = createAsyncThunk(
  "HuffpostApp/deleteHuffpost",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        huffpostAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_HUFFPOST}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteHuffpost = createAsyncThunk(
  "HuffpostApp/AllDeleteHuffpost",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_HUFFPOST}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//FoxNewsApp
export const getFoxNewsApps = createAsyncThunk(
  "FoxNewsApp/getFoxNewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`FoxNewsApp/GetAllFoxNewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getFoxNewsById = createAsyncThunk(
  "FoxNewsApp/getFoxNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `FoxNewsApp/SelectFoxNewsAppById?foxNewsAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddFoxNews = createAsyncThunk(
  "FoxNewsApp/handleAddFoxNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_FOX_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteFoxNews = createAsyncThunk(
  "FoxNewsApp/deleteFoxNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        foxNewsAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_FOX_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteFoxNews = createAsyncThunk(
  "FoxNewsApp/AllDeleteFoxNews",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_FOX_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//NYTNewsApp
export const getNYTNewsApps = createAsyncThunk(
  "NYTNewsApp/getNYTNewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`NYTNewsApp/GetAllNYTNewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getNYTNewsById = createAsyncThunk(
  "NYTNewsApp/getNYTNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `NYTNewsApp/SelectNYTNewsAppById?nytNewsAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddNYTNews = createAsyncThunk(
  "NYTNewsApp/handleAddNYTNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_NYT_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteNYTNews = createAsyncThunk(
  "NYTNewsApp/deleteNYTNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        nytNewsAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_NYT_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteNYTNews = createAsyncThunk(
  "NYTNewsApp/AllDeleteNYTNews",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_NYT_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//TOINewsApp
export const getTOINewsApps = createAsyncThunk(
  "TOINewsApp/getTOINewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`TOINewsApp/GetAllTOINewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getTOINewsById = createAsyncThunk(
  "TOINewsApp/getTOINewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `TOINewsApp/SelectTOINewsAppById?toiNewsAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddTOINews = createAsyncThunk(
  "TOINewsApp/handleAddTOINews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_TOI_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteTOINews = createAsyncThunk(
  "TOINewsApp/deleteTOINews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        toiNewsAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_TOI_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteTOINews = createAsyncThunk(
  "TOINewsApp/AllDeleteTOINews",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_TOI_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//ESPNNewsApp
export const getESPNNewsApps = createAsyncThunk(
  "ESPNNewsApp/getESPNNewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`ESPNNewsApp/GetAllESPNNewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getESPNNewsById = createAsyncThunk(
  "ESPNNewsApp/getESPNNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `ESPNNewsApp/SelectESPNNewsAppById?espnNewsAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddESPNNews = createAsyncThunk(
  "ESPNNewsApp/handleAddESPNNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_ESPN_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteESPNNews = createAsyncThunk(
  "ESPNNewsApp/deleteESPNNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        espnNewsAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_ESPN_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteESPNNews = createAsyncThunk(
  "ESPNNewsApp/AllDeleteESPNNews",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_ESPN_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//BBCNewsApp
export const getBBCNewsApps = createAsyncThunk(
  "BBCNewsApp/getBBCNewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`BBCNewsApp/GetAllBBCNewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getBBCNewsById = createAsyncThunk(
  "BBCNewsApp/getBBCNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `BBCNewsApp/SelectBBCNewsAppById?bbcNewsAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddBBCNews = createAsyncThunk(
  "BBCNewsApp/handleAddBBCNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_BBC_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteBBCNews = createAsyncThunk(
  "BBCNewsApp/deleteBBCNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        bbcNewsAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_BBC_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteBBCNews = createAsyncThunk(
  "BBCNewsApp/AllDeleteBBCNews",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_BBC_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//RegionalNewsApp
export const getRegionalNewsApps = createAsyncThunk(
  "RegionalNewsApp/getRegionalNewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`RegionalNewsApp/GetAllRegionalNewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getRegionalNewsById = createAsyncThunk(
  "RegionalNewsApp/getRegionalNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `RegionalNewsApp/SelectRegionalNewsAppById?RegionalNewsAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddRegionalNews = createAsyncThunk(
  "RegionalNewsApp/handleAddRegionalNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_REGIONAL_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteRegionalNews = createAsyncThunk(
  "RegionalNewsApp/deleteRegionalNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        regionalNewsAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_REGIONAL_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  });
export const AllDeleteRegionalNews = createAsyncThunk(
  "RegionalNewsApp/AllDeleteRegionalNews",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_REGIONAL_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  });

//WallStreetJournalApp
export const getWallStreetJournalApps = createAsyncThunk(
  "WallStreetJournalApp/getWallStreetJournalApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `WallStreetJournalApp/GetAllWallStreetJournalApp`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getWallStreetJournalById = createAsyncThunk(
  "WallStreetJournalApp/getWallStreetJournalById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `WallStreetJournalApp/SelectWallStreetJournalAppById?WallStreetJournalAppID=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddWallStreetJournal = createAsyncThunk(
  "WallStreetJournalApp/handleAddWallStreetJournal",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_WALL_STREET_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteWallStreetJournal = createAsyncThunk(
  "WallStreetJournalApp/deleteWallStreetJournal",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        wallStreetJournalAppID: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_WALL_STREET_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteWallStreetJournal = createAsyncThunk(
  "WallStreetJournalApp/AllDeleteWallStreetJournal",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_WALL_STREET_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//CalenderForGoogleApp
export const getCalenderForGoogleApps = createAsyncThunk(
  "CalenderForGoogleApp/getCalenderForGoogleApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `CalenderForGoogleApp/GetAllCalenderForGoogleApp`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getCalenderForGoogleById = createAsyncThunk(
  "CalenderForGoogleApp/getCalenderForGoogleById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `CalenderForGoogleApp/GetCalenderForGoogleAppByID?CalenderForGoogleAppID=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddCalenderForGoogle = createAsyncThunk(
  "CalenderForGoogleApp/handleAddCalenderForGoogle",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_CALENDER_FOR_GOOGLE_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteCalenderForGoogle = createAsyncThunk(
  "CalenderForGoogleApp/deleteCalenderForGoogle",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        calenderForGoogleAppID: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_CALENDER_FOR_GOOGLE}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteCalenderForGoogle = createAsyncThunk(
  "CalenderForGoogleApp/AllDeleteCalenderForGoogle ",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_CALENDER_FOR_GOOGLE}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//TaggboxDisplayApp
export const getTaggboxDisplayApps = createAsyncThunk(
  "TaggboxDisplayApp/getTaggboxDisplayApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`TaggboxDisplayApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getTaggboxDisplayById = createAsyncThunk(
  "TaggboxDisplayApp/getTaggboxDisplayById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `TaggboxDisplayApp/Select?taggBoxDisplayId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddTaggboxDisplay = createAsyncThunk(
  "TaggboxDisplayApp/handleAddTaggboxDisplay",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_TAGG_BOX_DISPLAY_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const deleteTaggboxDisplay = createAsyncThunk(
  "TaggboxDisplayApp/deleteTaggboxDisplay",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        taggBoxDisplayId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_TAGG_BOX_DISPLAY}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteTaggboxDisplay = createAsyncThunk(
  "TaggboxDisplayApp/AllDeleteTaggboxDisplay ",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_TAGG_BOX_DISPLAY}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//Google Alerts App
export const getGoogleAlertApps = createAsyncThunk(
  "GoogleAlertApp/getGoogleAlertApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`GoogleAlertsApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getGoogleAlertById = createAsyncThunk(
  "GoogleAlertApp/getGoogleAlertById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `GoogleAlertsApp/Select?googleAlertsId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddGoogleAlert = createAsyncThunk(
  "GoogleAlertApp/handleAddGoogleAlert",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_GOOGLE_ALERTS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const deleteGoogleAlert = createAsyncThunk(
  "GoogleAlertApp/deleteGoogleAlert",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        googleAlertsId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_GOOGLE_ALERTS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteGoogleAlert = createAsyncThunk(
  "GoogleAlertApp/AllDeleteGoogleAlert ",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_GOOGLE_ALERTS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//SportsNewsApp
export const getSportsNewsApps = createAsyncThunk(
  "SportsNewsApp/getSportsNewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`SportsNewsApp/GetAllSportsNewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getSportsNewsById = createAsyncThunk(
  "SportsNewsApp/getSportsNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `SportsNewsApp/SelectSportsNewsAppById?sportsNewsId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddSportsNews = createAsyncThunk(
  "SportsNewsApp/handleAddSportsNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_SPORTS_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const deleteSportsNews = createAsyncThunk(
  "SportsNewsApp/deleteSportsNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        sportsNewsId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_SPORTS_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  });
export const AllDeleteSportsNews = createAsyncThunk(
  "SportsNewsApp/AllDeleteSportsNews ",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_SPORTS_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  });

//BusinessAndFinanceNewsApp
export const getBusinessAndFinanceNewsApps = createAsyncThunk(
  "BusinessAndFinanceNewsApp/getBusinessAndFinanceNewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`BusinessAndFinanceNewsApp/GetAllBusinessAndFinanceNewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getBusinessAndFinanceNewsById = createAsyncThunk(
  "BusinessAndFinanceNewsApp/getBusinessAndFinanceNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `BusinessAndFinanceNewsApp/SelectBusinessAndFinanceNewsAppById?businessAndFinanceNewsId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddBusinessAndFinanceNews = createAsyncThunk(
  "BusinessAndFinanceNewsApp/handleAddBusinessAndFinanceNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_BUSINESS_AND_FINANCE_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  });
export const deleteBusinessAndFinanceNews = createAsyncThunk(
  "BusinessAndFinanceNewsApp/deleteBusinessAndFinanceNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        businessAndFinanceNewsId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_BUSINESS_AND_FINANCE_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  });
export const AllDeleteBusinessAndFinanceNews = createAsyncThunk(
  "BusinessAndFinanceNewsApp/AllDeleteBusinessAndFinanceNews ",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_BUSINESS_AND_FINANCE_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  });

//EntertainmentNewsApp
export const getEntertainmentNewsApps = createAsyncThunk(
  "EntertainmentNewsApp/getEntertainmentNewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`EntertainmentNewsApp/GetAllEntertainmentNewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  });
export const getEntertainmentNewsById = createAsyncThunk(
  "EntertainmentNewsApp/getEntertainmentNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `EntertainmentNewsApp/SelectEntertainmentNewsAppById?entertainmentNewsId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  });
export const handleAddEntertainmentNews = createAsyncThunk(
  "EntertainmentNewsApp/handleAddEntertainmentNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_ENTERTAINMENT_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  });
export const deleteEntertainmentNews = createAsyncThunk(
  "EntertainmentNewsApp/deleteEntertainmentNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        entertainmentNewsId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_ENTERTAINMENT_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  });
export const AllDeleteEntertainmentNews = createAsyncThunk(
  "EntertainmentNewsApp/AllDeleteEntertainmentNews ",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_ENTERTAINMENT_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  });

//HeadlineNewsApp
export const getHeadlineNewsApps = createAsyncThunk(
  "HeadlineNewsApp/getHeadlineNewsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`HeadlineNewsApp/GetAllHeadlineNewsApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  });
export const getHeadlineNewsById = createAsyncThunk(
  "HeadlineNewsApp/getHeadlineNewsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `HeadlineNewsApp/SelectHeadlineNewsAppById?headlineNewsAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  });
export const handleAddHeadlineNews = createAsyncThunk(
  "HeadlineNewsApp/handleAddHeadlineNews",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_HEADLINE_NEWS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const deleteHeadlineNews = createAsyncThunk(
  "HeadlineNewsApp/deleteHeadlineNews",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        headlineNewsAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_HEADLINE_NEWS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  });
export const AllDeleteHeadlineNews = createAsyncThunk(
  "HeadlineNewsApp/AllDeleteHeadlineNews ",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_HEADLINE_NEWS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  });


//GoogleSheetsApp
export const getGoogleSheetsApps = createAsyncThunk(
  "GoogleSheetsApp/getGoogleSheetsApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`GoogleSheetsApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getGoogleSheetsById = createAsyncThunk(
  "GoogleSheetsApp/getGoogleSheetsById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `GoogleSheetsApp/Select?googleSheetsId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddGoogleSheets = createAsyncThunk(
  "GoogleSheetsApp/handleAddGoogleSheets",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_GOOGLE_SHEETS_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const deleteGoogleSheets = createAsyncThunk(
  "GoogleSheetsApp/deleteGoogleSheets",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        googleSheetsId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_GOOGLE_SHEETS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteGoogleSheets = createAsyncThunk(
  "GoogleSheetsApp/AllDeleteGoogleSheets ",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_GOOGLE_SHEETS}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//OnThisDayApp
export const getOnThisDayApps = createAsyncThunk(
  "OnThisDayApp/getOnThisDayApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`OnThisDayApp/SelectAll`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);
export const getOnThisDayById = createAsyncThunk(
  "OnThisDayApp/getOnThisDayById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `OnThisDayApp/Select?onThisDayAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const handleAddOnThisDay = createAsyncThunk(
  "OnThisDayApp/handleAddOnThisDay",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_ON_THIS_DAY_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);
export const deleteOnThisDay = createAsyncThunk(
  "OnThisDayApp/deleteOnThisDay",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({
        onThisDayAppId: Id,
      }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_ON_THIS_DAY}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const AllDeleteOnThisDay = createAsyncThunk(
  "OnThisDayApp/AllDeleteOnThisDay ",
  async (Id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(`${ALL_DELETE_ON_THIS_DAY}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);


// ClockApp
export const getClocksApps = createAsyncThunk(
  "ClockApp/getDgetClocksAppsateApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(`ClockApp/GetAllClockApp`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);

export const getClockById = createAsyncThunk(
  "ClockApp/getClockById",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await getUrl(
        `ClockApp/SelectClockAppById?clockAppId=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to fetch data");
      throw error;
    }
  }
);

export const handleAddClockApps = createAsyncThunk(
  "ClockApp/handleAddClockApps",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_CLOCK_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteClock = createAsyncThunk(
  "ClockApp/deleteClock",
  async (Id, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams({ clockAppId: Id }).toString();
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(
        `${DELETE_CLOCK_APPS}?${queryParams}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.status) {
        return {
          status: true,
          message: response.data.message,
          data: response.data.data,
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

// AirQuality
export const getAirQualityApps = createAsyncThunk("AQIApp/getAirQualityApps", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`AQIApp/GetAllAQIApp`, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
});

export const getAirQualityById = createAsyncThunk("AQIApp/getAirQualityById", async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`AQIApp/GetAQIAppByID?AQIAppID=${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
});

export const handleAddAirQualityApps = createAsyncThunk("AQIApp/handleAddAirQualityApps", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(ADD_AQI_APPS, payload, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteAirQuality = createAsyncThunk("AQIApp/deleteAirQuality", async (Id, thunkAPI) => {
  try {
    const queryParams = new URLSearchParams({ AQIAppID: Id }).toString();
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(`${DELETE_AQI_APPS}?${queryParams}`, {}, { headers: { Authorization: `Bearer ${token}` } });
    if (response.data.status) {
      return {
        status: true,
        message: response.data.message,
        data: response.data.data,
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}
);

//GoogleReviews
export const getGoogleReviewsApps = createAsyncThunk("GoogleReviews/getGoogleReviewsApps", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`GoogleReviewsApp/SelectAll`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
}
);

export const getGoogleReviewsById = createAsyncThunk("GoogleReviews/getGoogleReviewsById", async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`GoogleReviewsApp/Select?googleReviewsId=${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
}
);

export const handleAddGoogleReviews = createAsyncThunk("GoogleReviews/handleAddGoogleReviews", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(ADD_GOOGLE_REVIEW_APPS, payload, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    throw error;
  }
}
);

export const deleteGoogleReviews = createAsyncThunk("GoogleReviews/deleteGoogleReviews", async (Id, thunkAPI) => {
  try {
    const queryParams = new URLSearchParams({ googleReviewsId: Id }).toString();
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(`${DELETE_GOOGLE_REVIEW_APPS}?${queryParams}`, {}, { headers: { Authorization: `Bearer ${token}` } });
    if (response.data.status) {
      return {
        status: true,
        message: response.data.message,
        data: response.data.data,
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

// StockExchangeApp
export const getStockExchangeApps = createAsyncThunk("StockExchangeApp/getStockExchangeApps", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`StockExchangeApp/GetAllStockExchangeApp`, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
}
);

export const getStockExchangeById = createAsyncThunk("StockExchangeApp/getStockExchangeById", async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`StockExchangeApp/GetStockExchangeAppById?stockExchangeAppId=${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
}
);

export const handleAddStockExchange = createAsyncThunk("StockExchangeApp/handleAddStockExchange", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(ADD_STOCK_EXCHANGE_APPS, payload, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteStockExchange = createAsyncThunk("StockExchangeApp/deleteStockExchange", async (Id, thunkAPI) => {
  try {
    const queryParams = new URLSearchParams({ stockExchangeAppId: Id }).toString();
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(`${DELETE_STOCK_EXCHANGE_APPS}?${queryParams}`, {}, { headers: { Authorization: `Bearer ${token}` } });
    if (response.data.status) {
      return {
        status: true,
        message: response.data.message,
        data: response.data.data,
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

// GoogleTrendsApp
export const getGoogleTrendsApps = createAsyncThunk("GoogleTrendsApp/getGoogleTrendsApps", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`GoogleTrendsApp/SelectAll`, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
}
);

export const getGoogleTrendsById = createAsyncThunk("GoogleTrendsApp/getGoogleTrendsById", async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`GoogleTrendsApp/Select?googleTrendsId=${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
}
);

export const handleAddGoogleTrends = createAsyncThunk("GoogleTrendsApp/handleAddGoogleTrends", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(ADD_GOOGLE_TRENDS_APPS, payload, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteGoogleTrends = createAsyncThunk("GoogleTrendsApp/deleteGoogleTrends", async (Id, thunkAPI) => {
  try {
    const queryParams = new URLSearchParams({ googleTrendsId: Id }).toString();
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(`${DELETE_GOOGLE_TRENDS_APPS}?${queryParams}`, {}, { headers: { Authorization: `Bearer ${token}` } });
    if (response.data.status) {
      return {
        status: true,
        message: response.data.message,
        data: response.data.data,
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});
// FlightSchedulesApp
export const getFlightScheduleApps = createAsyncThunk("FlightScheduleApp/getFlightScheduleApps", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`FlightScheduleApp/SelectAll`, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
});

export const getFlightSchedulesById = createAsyncThunk("FlightScheduleApp/getFlightSchedulesById", async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`FlightScheduleApp/Select?flightScheduleId=${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
});

export const handleAddFlightSchedules = createAsyncThunk("FlightScheduleApp/handleAddFlightSchedules", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(ADD_FLIGHT_SCHEDULE_APPS, payload, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteFlightSchedules = createAsyncThunk("FlightScheduleApp/deleteFlightSchedules", async (Id, thunkAPI) => {
  try {
    const queryParams = new URLSearchParams({ flightScheduleId: Id }).toString();
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(`${DELETE_FLIGHT_SCHEDULE_APPS}?${queryParams}`, {}, { headers: { Authorization: `Bearer ${token}` } });
    if (response.data.status) {
      return {
        status: true,
        message: response.data.message,
        data: response.data.data,
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});

//InspirationalQuotesApp
export const getInspirationalQuotesApps = createAsyncThunk("InspirationalQuotesApp/getInspirationalQuotesApps", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`InspirationalQuotesApp/SelectAll`, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
});
export const getInspirationalQuotesById = createAsyncThunk("InspirationalQuotesApp/getInspirationalQuotesById", async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await getUrl(`InspirationalQuotesApp/Select?inspirationalQuotesId=${id}`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Failed to fetch data");
    throw error;
  }
});
export const handleAddInspirationalQuotes = createAsyncThunk("InspirationalQuotesApp/handleAddInspirationalQuotes", async (payload, thunkAPI) => {
  try {
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(ADD_INSPIRATIONAL_QUOTES_APPS, payload, { headers: { Authorization: `Bearer ${token}` }, });
    return response.data;
  } catch (error) {
    throw error;
  }
});
export const deleteInspirationalQuotes = createAsyncThunk("InspirationalQuotesApp/deleteInspirationalQuotes", async (Id, thunkAPI) => { 
  try {
    const queryParams = new URLSearchParams({ inspirationalQuotesId: Id }).toString();
    const token = thunkAPI.getState().root.auth.token;
    const response = await axios.post(`${DELETE_INSPIRATIONAL_QUOTES}?${queryParams}`, {}, { headers: { Authorization: `Bearer ${token}` } });
    if (response.data.status) {
      return {
        status: true,
        message: response.data.message,
        data: response.data.data,
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
});


export const handleAddCountUpTime = createAsyncThunk(
  "CountUpTimerApp/handleAddCountUpTime",
  async (payload, thunkAPI) => {
    try {
      const token = thunkAPI.getState().root.auth.token;
      const response = await axios.post(ADD_COUNTUPTIME_APPS, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  allApps: {
    loading: false,
    data: [],
    error: null,
  },
  youtube: {
    loading: false,
    youtubeData: [],
    error: null,
  },
  textScroll: {
    loading: false,
    textScrollData: [],
    error: null,
  },
  CountUpTimer: {
    loading: false,
    CountUpTimerData: [],
    error: null,
  },
  // CountDownTimer: {
  //   loading: false,
  //   CountDownTimerData: [],
  //   error: null,
  // },
  Questions: {
    loading: false,
    QuestionsData: [],
    error: null,
  },
  weather: {
    loading: false,
    weatherData: [],
    error: null,
  },
  DigitalMenu: {
    loading: false,
    DigitalMenuData: [],
    error: null,
  },
  MicrosoftExcel: {
    loading: false,
    MicrosoftExcelData: [],
    error: null,
  },
  // Embed: {
  //   loading: false,
  //   EmbedData: [],
  //   error: null,
  // },
  allAppsData: [],
  DateApps: [],
  clockApps: [],
  GoogleTrafficApp: [],
  FollowUsOnFacebookApp: [],
  DigitalSignsApp: [],
  PageScrollApp: [],
  LiveNewsApp: [],
  SportsLiveScoresApp: [],
  FollowUsApp: [],
  SoccerScoresApp: [],
  WallIoApp: [],
  StreamaApp: [],
  PosterMyWallApp: [],
  BonuslyApp: [],
  CanvaApp: [],
  GeckoBoardApp: [],
  YelpApp: [],
  NDTVNewsApp: [],
  HuffpostApp: [],
  FoxNewsApp: [],
  NYTNewsApp: [],
  TOINewsApp: [],
  ESPNNewsApp: [],
  BBCNewsApp: [],
  RegionalNewsApp: [],
  WallStreetNewsApp: [],
  CalenderForGoogleApp: [],
  TaggBoxDisplayApp: [],
  allBuildingDirectory: [],
  GoogleReviews: [],
  AQIdata: [],
  stockExchange: [],
  GoogleAlertsApp: [],
  GoogleSheetsApp: [],
  OnThisDayApp: [],
  SportsNewsApp: [],
  BusinessAndFinanceApp: [],
  EntertainmentNewsApp: [],
  HeadlineNewsApp : [],
  InspirationalQuotesApp: [],
};

const AppsSlice = createSlice({
  name: "apps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get youtube data
    builder.addCase(
      handleGetYoutubeData.pending,
      (state, { payload, meta, type }) => {
        state.youtube.loading = true;
        state.youtube.error = null;
        state.youtube.youtubeData = [];
      }
    );
    builder.addCase(
      handleGetYoutubeData.fulfilled,
      (state, { payload, meta }) => {
        state.youtube.loading = false;
        state.youtube.youtubeData = payload?.data ? payload?.data : [];
        state.allAppsData =
          payload?.data.length > 0
            ? [...state.allAppsData, ...payload?.data]
            : [];
        state.error = null;
      }
    );
    builder.addCase(handleGetYoutubeData.rejected, (state, { payload }) => {
      state.youtube.loading = false;
      state.youtube.error = payload ?? null;
      state.youtube.youtubeData = [];
    });

    //get textscroll data
    builder.addCase(
      handleGetTextScrollData.pending,
      (state, { payload, meta, type }) => {
        state.textScroll.loading = true;
        state.textScroll.error = null;
      }
    );
    builder.addCase(
      handleGetTextScrollData.fulfilled,
      (state, { payload, meta }) => {
        state.textScroll.loading = false;
        state.textScroll.textScrollData = payload?.data ? payload?.data : [];
        state.allAppsData =
          payload?.data.length > 0
            ? [...state.allAppsData, ...payload?.data]
            : [];
        state.error = null;
      }
    );
    builder.addCase(handleGetTextScrollData.rejected, (state, { payload }) => {
      state.textScroll.loading = false;
      state.textScroll.error = payload ?? null;
      state.textScroll.textScrollData = [];
    });

    //get weather data
    builder.addCase(
      handleGetWeatherData.pending,
      (state, { payload, meta, type }) => {
        state.weather.loading = true;
        state.weather.error = null;
      }
    );
    builder.addCase(
      handleGetWeatherData.fulfilled,
      (state, { payload, meta }) => {
        state.weather.loading = false;
        state.weather.weatherData = payload?.data ? payload?.data : [];
        state.allAppsData =
          payload?.data.length > 0
            ? [...state.allAppsData, ...payload?.data]
            : [];
        state.error = null;
      }
    );
    builder.addCase(handleGetWeatherData.rejected, (state, { payload }) => {
      state.weather.loading = false;
      state.weather.error = payload ?? null;
      state.weather.weatherData = [];
    });

    //get all apps
    builder.addCase(
      handleGetAllApps.pending,
      (state, { payload, meta, type }) => {
        state.allApps.loading = true;
        state.allApps.error = null;
      }
    );
    builder.addCase(handleGetAllApps.fulfilled, (state, { payload, meta }) => {
      state.allApps.loading = false;
      state.allApps.data = payload?.data ?? [];
      state.allAppserror = null;
    });
    builder.addCase(handleGetAllApps.rejected, (state, { payload }) => {
      state.allApps.loading = false;
      state.allApps.error = payload ?? null;
      state.allApps.data = [];
    });
    //get Questions apps
    builder.addCase(
      handleGetQuestionsData.pending,
      (state, { payload, meta, type }) => {
        state.Questions.loading = true;
        state.Questions.error = null;
      }
    );
    builder.addCase(
      handleGetQuestionsData.fulfilled,
      (state, { payload, meta }) => {
        state.Questions.loading = false;
        state.Questions.QuestionsData = payload?.data ? payload?.data : [];
        state.allAppsData =
          payload?.data.length > 0
            ? [...state.allAppsData, ...payload?.data]
            : [];
        state.error = null;
      }
    );

    builder.addCase(handleGetQuestionsData.rejected, (state, { payload }) => {
      state.Questions.loading = false;
      state.Questions.error = payload ?? null;
      state.Questions.data = [];
    });

    //get MicrosoftExcel apps
    builder.addCase(
      handleGetMicrosoftExcelData.pending,
      (state, { payload, meta, type }) => {
        state.MicrosoftExcel.loading = true;
        state.MicrosoftExcel.error = null;
      }
    );
    builder.addCase(
      handleGetMicrosoftExcelData.fulfilled,
      (state, { payload, meta }) => {
        state.MicrosoftExcel.loading = false;
        state.MicrosoftExcel.MicrosoftExcelData = payload?.data
          ? payload?.data
          : [];
        state.allAppsData =
          payload?.data.length > 0
            ? [...state.allAppsData, ...payload?.data]
            : [];
        state.error = null;
      }
    );

    builder.addCase(
      handleGetMicrosoftExcelData.rejected,
      (state, { payload }) => {
        state.MicrosoftExcel.loading = false;
        state.MicrosoftExcel.error = payload ?? null;
        state.MicrosoftExcel.data = [];
      }
    );
    //get Embed apps
    // builder.addCase(
    //   handleGetEmbedData.pending,
    //   (state, { payload, meta, type }) => {
    //     state.Embed.loading = true;
    //     state.Embed.error = null;
    //   }
    // );
    // builder.addCase(handleGetEmbedData.fulfilled, (state, { payload, meta }) => {
    //   state.Embed.loading = false;
    //   state.Embed.EmbedData = payload?.data
    //     ? payload?.data
    //     : [];
    //   state.allAppsData =
    //     payload?.data.length > 0
    //       ? [...state.allAppsData, ...payload?.data]
    //       : [];
    //   state.error = null;
    // }
    // );

    // builder.addCase(handleGetEmbedData.rejected, (state, { payload }) => {
    //   state.Embed.loading = false;
    //   state.Embed.error = payload ?? null;
    //   state.Embed.data = [];
    // });

    //get Digital Menu data
    builder.addCase(
      handleGetDigitalMenuData.pending,
      (state, { payload, meta, type }) => {
        state.DigitalMenu.loading = true;
        state.DigitalMenu.error = null;
      }
    );
    builder.addCase(
      handleGetDigitalMenuData.fulfilled,
      (state, { payload, meta }) => {
        state.DigitalMenu.loading = false;
        state.DigitalMenu.DigitalMenuData = payload?.data ? payload?.data : [];
        state.error = null;
      }
    );
    builder.addCase(handleGetDigitalMenuData.rejected, (state, { payload }) => {
      state.DigitalMenu.loading = false;
      state.DigitalMenu.error = payload ?? null;
      state.DigitalMenu.DigitalMenuData = [];
    });

    builder.addCase(getBuildingDirectoryApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getBuildingDirectoryApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.allBuildingDirectory = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getBuildingDirectoryApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteBuildingDirectory.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteBuildingDirectory.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteBuildingDirectory.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getBuildingDirectoryById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getBuildingDirectoryById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.allBuildingDirectory = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getBuildingDirectoryById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddBuildingDirectory.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddBuildingDirectory.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddBuildingDirectory.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(getDateApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getDateApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.DateApps = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getDateApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getDateById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getDateById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.DateApps = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getDateById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteDate.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteDate.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteDate.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddDateApps.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddDateApps.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddDateApps.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AlldeleteDate.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AlldeleteDate.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AlldeleteDate.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteCurrency.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteCurrency.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteCurrency.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getCurrencyApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getCurrencyApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.DateApps = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getCurrencyApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });
    // google review 
    builder.addCase(getGoogleReviewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getGoogleReviewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.GoogleReviews = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGoogleReviewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getGoogleReviewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getGoogleReviewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.GoogleReviews = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGoogleReviewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddGoogleReviews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddGoogleReviews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddGoogleReviews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(deleteGoogleReviews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteGoogleReviews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteGoogleReviews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    // AQIApp

    builder.addCase(getAirQualityApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getAirQualityApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.AQIdata = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getAirQualityApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getAirQualityById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getAirQualityById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.AQIdata = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getAirQualityById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddAirQualityApps.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddAirQualityApps.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddAirQualityApps.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(deleteAirQuality.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteAirQuality.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteAirQuality.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    // stockExchangeApp
    builder.addCase(getStockExchangeApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getStockExchangeApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.stockExchange = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getStockExchangeApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getStockExchangeById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getStockExchangeById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getStockExchangeById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddStockExchange.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddStockExchange.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddStockExchange.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(deleteStockExchange.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteStockExchange.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteStockExchange.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    // stockExchangeApp
    builder.addCase(getGoogleTrendsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getGoogleTrendsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGoogleTrendsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getGoogleTrendsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getGoogleTrendsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGoogleTrendsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddGoogleTrends.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddGoogleTrends.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddGoogleTrends.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(deleteGoogleTrends.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteGoogleTrends.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteGoogleTrends.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    // FlightScheduleApp
    builder.addCase(getFlightScheduleApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getFlightScheduleApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getFlightScheduleApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getFlightSchedulesById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getFlightSchedulesById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getFlightSchedulesById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddFlightSchedules.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddFlightSchedules.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddFlightSchedules.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(deleteFlightSchedules.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteFlightSchedules.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteFlightSchedules.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //get CountUpTimer data
    builder.addCase(
      handleGetCountUpTimerData.pending,
      (state, { payload, meta, type }) => {
        state.CountUpTimer.loading = true;
        state.CountUpTimer.error = null;
        state.CountUpTimer.CountUpTimerData = [];
      }
    );
    builder.addCase(
      handleGetCountUpTimerData.fulfilled,
      (state, { payload, meta }) => {
        state.CountUpTimer.loading = false;
        state.CountUpTimer.CountUpTimerData = payload?.data
          ? payload?.data
          : [];
        state.allAppsData =
          payload?.data.length > 0
            ? [...state.allAppsData, ...payload?.data]
            : [];
        state.error = null;
      }
    );
    builder.addCase(
      handleGetCountUpTimerData.rejected,
      (state, { payload }) => {
        state.CountUpTimer.loading = false;
        state.CountUpTimer.error = payload ?? null;
        state.CountUpTimer.CountUpTimerData = [];
      }
    );

    //google traffic app
    builder.addCase(getGoogleTrafficApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getGoogleTrafficApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.GoogleTrafficApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGoogleTrafficApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getGoogleTrafficById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getGoogleTrafficById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.GoogleTrafficApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGoogleTrafficById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteGoogleTraffic.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteGoogleTraffic.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteGoogleTraffic.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddGoogleTraffic.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddGoogleTraffic.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddGoogleTraffic.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteGoogleTraffic.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteGoogleTraffic.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteGoogleTraffic.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Follow Us On Facebook
    builder.addCase(getFollowUsOnFacebook.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getFollowUsOnFacebook.fulfilled, (state, { payload }) => {
      state.status = true;
      state.FollowUsOnFacebookApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getFollowUsOnFacebook.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getFollowUsOnFacebookById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(
      getFollowUsOnFacebookById.fulfilled,
      (state, { payload }) => {
        state.status = true;
        state.FollowUsOnFacebookApp = payload?.data;
        state.token = payload?.data?.token;
      }
    );
    builder.addCase(getFollowUsOnFacebookById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteFollowUsOnFacebook.pending, (state) => {
      state.status = false;
    });
    builder.addCase(
      deleteFollowUsOnFacebook.fulfilled,
      (state, { payload }) => {
        state.status = true;
        state.data = payload?.data;
        state.token = payload?.data?.token;
      }
    );
    builder.addCase(deleteFollowUsOnFacebook.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddFollowUsOnFacebook.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddFollowUsOnFacebook.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddFollowUsOnFacebook.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteFollowUsOnFacebook.pending, (state) => {
      state.status = false;
    });
    builder.addCase(
      AllDeleteFollowUsOnFacebook.fulfilled,
      (state, { payload }) => {
        state.status = true;
        state.data = payload?.data;
        state.token = payload?.data?.token;
      }
    );
    builder.addCase(AllDeleteFollowUsOnFacebook.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Digital SignsApp
    builder.addCase(getDigitalSigns.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getDigitalSigns.fulfilled, (state, { payload }) => {
      state.status = true;
      state.DigitalSignsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getDigitalSigns.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getDigitalSignsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getDigitalSignsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.DigitalSignsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getDigitalSignsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteDigitalSigns.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteDigitalSigns.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteDigitalSigns.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddDigitalSigns.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddDigitalSigns.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddDigitalSigns.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteDigitalSigns.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteDigitalSigns.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteDigitalSigns.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Page ScrollApp
    builder.addCase(getPageScroll.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getPageScroll.fulfilled, (state, { payload }) => {
      state.status = true;
      state.PageScrollApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getPageScroll.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getPageScrollById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getPageScrollById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.PageScrollApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getPageScrollById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deletePageScroll.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deletePageScroll.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deletePageScroll.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddPageScroll.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddPageScroll.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddPageScroll.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeletePageScroll.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeletePageScroll.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeletePageScroll.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Live NewsApp
    builder.addCase(getLiveNews.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getLiveNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.LiveNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getLiveNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getLiveNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getLiveNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.LiveNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getLiveNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteLiveNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteLiveNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteLiveNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddLiveNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddLiveNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddLiveNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteLiveNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteLiveNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteLiveNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Sports Live ScoresApp
    builder.addCase(getSportsLiveScores.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getSportsLiveScores.fulfilled, (state, { payload }) => {
      state.status = true;
      state.SportsLiveScoresApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getSportsLiveScores.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getSportsLiveScoresById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getSportsLiveScoresById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.SportsLiveScoresApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getSportsLiveScoresById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteSportsLiveScores.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteSportsLiveScores.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteSportsLiveScores.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddSportsLiveScores.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddSportsLiveScores.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddSportsLiveScores.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteSportsLiveScores.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteSportsLiveScores.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });

    builder.addCase(AllDeleteSportsLiveScores.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Follow Us App
    builder.addCase(getFollowUs.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getFollowUs.fulfilled, (state, { payload }) => {
      state.status = true;
      state.FollowUsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getFollowUs.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getFollowUsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getFollowUsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.FollowUsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getFollowUsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteFollowUs.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteFollowUs.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteFollowUs.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddFollowUs.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddFollowUs.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddFollowUs.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteFollowUs.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteFollowUs.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteFollowUs.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Soccer Scores App
    builder.addCase(getSoccerScores.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getSoccerScores.fulfilled, (state, { payload }) => {
      state.status = true;
      state.SoccerScoresApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getSoccerScores.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getSoccerScoresById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getSoccerScoresById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.SoccerScoresApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getSoccerScoresById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteSoccerScores.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteSoccerScores.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteSoccerScores.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddSoccerScores.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddSoccerScores.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddSoccerScores.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteSoccerScores.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteSoccerScores.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteSoccerScores.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //WallsIo App
    builder.addCase(getWallIo.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getWallIo.fulfilled, (state, { payload }) => {
      state.status = true;
      state.WallIoApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getWallIo.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getWallIoById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getWallIoById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.WallIoApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getWallIoById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteWallIo.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteWallIo.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteWallIo.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddWallIo.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddWallIo.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddWallIo.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteWallIo.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteWallIo.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteWallIo.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Streama App
    builder.addCase(getStreaMa.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getStreaMa.fulfilled, (state, { payload }) => {
      state.status = true;
      state.StreamaApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getStreaMa.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getStreaMaById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getStreaMaById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.StreamaApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getStreaMaById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteStreaMa.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteStreaMa.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteStreaMa.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddStreaMa.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddStreaMa.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddStreaMa.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteStreaMa.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteStreaMa.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteStreaMa.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    // Poster My Wall App
    builder.addCase(getPosterMyWall.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getPosterMyWall.fulfilled, (state, { payload }) => {
      state.status = true;
      state.PosterMyWallApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getPosterMyWall.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getPosterMyWallById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getPosterMyWallById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.PosterMyWallApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getPosterMyWallById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deletePosterMyWall.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deletePosterMyWall.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deletePosterMyWall.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddPosterMyWall.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddPosterMyWall.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddPosterMyWall.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeletePosterMyWall.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeletePosterMyWall.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeletePosterMyWall.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    // Bonusly App
    builder.addCase(getBonusly.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getBonusly.fulfilled, (state, { payload }) => {
      state.status = true;
      state.BonuslyApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getBonusly.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getBonuslyById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getBonuslyById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.BonuslyApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getBonuslyById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteBonusly.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteBonusly.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteBonusly.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddBonusly.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddBonusly.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddBonusly.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteBonusly.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteBonusly.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteBonusly.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Canva App
    builder.addCase(getCanva.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getCanva.fulfilled, (state, { payload }) => {
      state.status = true;
      state.CanvaApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getCanva.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getCanvaById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getCanvaById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.CanvaApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getCanvaById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteCanva.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteCanva.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteCanva.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddCanva.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddCanva.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddCanva.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteCanva.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteCanva.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteCanva.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    // GeckoBoard App
    builder.addCase(getGeckoBoard.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getGeckoBoard.fulfilled, (state, { payload }) => {
      state.status = true;
      state.GeckoBoardApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGeckoBoard.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getGeckoBoardById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getGeckoBoardById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.GeckoBoardApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGeckoBoardById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteGeckoBoard.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteGeckoBoard.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteGeckoBoard.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddGeckoBoard.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddGeckoBoard.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddGeckoBoard.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteGeckoBoard.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteGeckoBoard.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteGeckoBoard.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Yelp App
    builder.addCase(getYelp.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getYelp.fulfilled, (state, { payload }) => {
      state.status = true;
      state.YelpApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getYelp.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getYelpById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getYelpById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.YelpApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getYelpById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteYelp.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteYelp.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteYelp.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddYelp.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddYelp.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddYelp.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteYelp.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteYelp.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteYelp.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //NDTVNews App
    builder.addCase(getNDTVNewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getNDTVNewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.NDTVNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getNDTVNewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getNDTVNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getNDTVNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.NDTVNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getNDTVNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteNDTVNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteNDTVNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteNDTVNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddNDTVNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddNDTVNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddNDTVNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteNDTVNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteNDTVNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteNDTVNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //HuffpostApp
    builder.addCase(getHuffpostApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getHuffpostApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.HuffpostApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getHuffpostApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getHuffpostById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getHuffpostById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.HuffpostApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getHuffpostById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteHuffpost.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteHuffpost.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteHuffpost.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddHuffpost.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddHuffpost.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddHuffpost.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteHuffpost.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteHuffpost.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteHuffpost.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //NYTNewsApp
    builder.addCase(getNYTNewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getNYTNewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.NYTNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getNYTNewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getNYTNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getNYTNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.NYTNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getNYTNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteNYTNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteNYTNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteNYTNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddNYTNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddNYTNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddNYTNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteNYTNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteNYTNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteNYTNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //FoxNewsApp
    builder.addCase(getFoxNewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getFoxNewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.FoxNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getFoxNewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getFoxNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getFoxNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.FoxNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getFoxNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteFoxNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteFoxNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteFoxNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddFoxNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddFoxNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddFoxNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteFoxNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteFoxNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteFoxNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //TOINewsApp
    builder.addCase(getTOINewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getTOINewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.TOINewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getTOINewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getTOINewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getTOINewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.TOINewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getTOINewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteTOINews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteTOINews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteTOINews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddTOINews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddTOINews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddTOINews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteTOINews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteTOINews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteTOINews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //ESPNNewsApp
    builder.addCase(getESPNNewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getESPNNewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.ESPNNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getESPNNewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getESPNNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getESPNNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.ESPNNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getESPNNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteESPNNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteESPNNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteESPNNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddESPNNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddESPNNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddESPNNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteESPNNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteESPNNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteESPNNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //BBCNewsApp
    builder.addCase(getBBCNewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getBBCNewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.BBCNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getBBCNewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getBBCNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getBBCNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.BBCNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getBBCNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteBBCNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteBBCNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteBBCNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddBBCNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddBBCNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddBBCNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteBBCNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteBBCNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteBBCNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //RegionalNewsApp
    builder.addCase(getRegionalNewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getRegionalNewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.RegionalNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getRegionalNewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getRegionalNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getRegionalNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.RegionalNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getRegionalNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteRegionalNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteRegionalNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteRegionalNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddRegionalNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddRegionalNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddRegionalNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteRegionalNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteRegionalNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteRegionalNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //WallStreetJournalApp
    builder.addCase(getWallStreetJournalApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(
      getWallStreetJournalApps.fulfilled,
      (state, { payload }) => {
        state.status = true;
        state.WallStreetNewsApp = payload?.data;
        state.token = payload?.data?.token;
      }
    );
    builder.addCase(getWallStreetJournalApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getWallStreetJournalById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(
      getWallStreetJournalById.fulfilled,
      (state, { payload }) => {
        state.status = true;
        state.WallStreetNewsApp = payload?.data;
        state.token = payload?.data?.token;
      }
    );
    builder.addCase(getWallStreetJournalById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteWallStreetJournal.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteWallStreetJournal.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteWallStreetJournal.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddWallStreetJournal.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddWallStreetJournal.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddWallStreetJournal.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteWallStreetJournal.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteWallStreetJournal.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteWallStreetJournal.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //CalenderForGoogleApp
    builder.addCase(getCalenderForGoogleApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(
      getCalenderForGoogleApps.fulfilled,
      (state, { payload }) => {
        state.status = true;
        state.CalenderForGoogleApp = payload?.data;
        state.token = payload?.data?.token;
      }
    );
    builder.addCase(getCalenderForGoogleApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getCalenderForGoogleById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(
      getCalenderForGoogleById.fulfilled,
      (state, { payload }) => {
        state.status = true;
        state.CalenderForGoogleApp = payload?.data;
        state.token = payload?.data?.token;
      }
    );
    builder.addCase(getCalenderForGoogleById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteCalenderForGoogle.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteCalenderForGoogle.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteCalenderForGoogle.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddCalenderForGoogle.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddCalenderForGoogle.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddCalenderForGoogle.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteCalenderForGoogle.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteCalenderForGoogle.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteCalenderForGoogle.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //TaggboxDisplayApp
    builder.addCase(getTaggboxDisplayApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getTaggboxDisplayApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.TaggBoxDisplayApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getTaggboxDisplayApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getTaggboxDisplayById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getTaggboxDisplayById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.TaggBoxDisplayApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getTaggboxDisplayById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteTaggboxDisplay.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteTaggboxDisplay.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteTaggboxDisplay.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddTaggboxDisplay.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddTaggboxDisplay.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddTaggboxDisplay.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteTaggboxDisplay.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteTaggboxDisplay.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteTaggboxDisplay.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Google Alerts
    builder.addCase(getGoogleAlertApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getGoogleAlertApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.GoogleAlertsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGoogleAlertApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getGoogleAlertById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getGoogleAlertById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.GoogleAlertsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGoogleAlertById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteGoogleAlert.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteGoogleAlert.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteGoogleAlert.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddGoogleAlert.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddGoogleAlert.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddGoogleAlert.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteGoogleAlert.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteGoogleAlert.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteGoogleAlert.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //Google Sheets 
    builder.addCase(getGoogleSheetsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getGoogleSheetsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.GoogleSheetsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGoogleSheetsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getGoogleSheetsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getGoogleSheetsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.GoogleSheetsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getGoogleSheetsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteGoogleSheets.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteGoogleSheets.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteGoogleSheets.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddGoogleSheets.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddGoogleSheets.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddGoogleSheets.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteGoogleSheets.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteGoogleSheets.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteGoogleSheets.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //OnThisDay App
    builder.addCase(getOnThisDayApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getOnThisDayApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.OnThisDayApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getOnThisDayApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getOnThisDayById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getOnThisDayById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.OnThisDayApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getOnThisDayById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteOnThisDay.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteOnThisDay.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteOnThisDay.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddOnThisDay.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddOnThisDay.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddOnThisDay.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteOnThisDay.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteOnThisDay.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteOnThisDay.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //SportsNewsApp
    builder.addCase(getSportsNewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getSportsNewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.SportsNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getSportsNewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getSportsNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getSportsNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.SportsNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getSportsNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteSportsNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteSportsNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteSportsNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddSportsNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddSportsNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddSportsNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteSportsNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteSportsNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteSportsNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //BusinessAndFinanceNewsApp
    builder.addCase(getBusinessAndFinanceNewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getBusinessAndFinanceNewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.BusinessAndFinanceApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getBusinessAndFinanceNewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getBusinessAndFinanceNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getBusinessAndFinanceNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.BusinessAndFinanceApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getBusinessAndFinanceNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteBusinessAndFinanceNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteBusinessAndFinanceNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteBusinessAndFinanceNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddBusinessAndFinanceNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddBusinessAndFinanceNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddBusinessAndFinanceNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteBusinessAndFinanceNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteBusinessAndFinanceNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteBusinessAndFinanceNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //EntertainmentNewsApp
    builder.addCase(getEntertainmentNewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getEntertainmentNewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.EntertainmentNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getEntertainmentNewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getEntertainmentNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getEntertainmentNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.EntertainmentNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getEntertainmentNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteEntertainmentNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteEntertainmentNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteEntertainmentNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddEntertainmentNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddEntertainmentNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddEntertainmentNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteEntertainmentNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteEntertainmentNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteEntertainmentNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });


    //HeadlineNewsApp
    builder.addCase(getHeadlineNewsApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getHeadlineNewsApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.HeadlineNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getHeadlineNewsApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getHeadlineNewsById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getHeadlineNewsById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.HeadlineNewsApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getHeadlineNewsById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteHeadlineNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteHeadlineNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteHeadlineNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddHeadlineNews.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(handleAddHeadlineNews.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddHeadlineNews.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(AllDeleteHeadlineNews.pending, (state) => {
      state.status = false;
    });
    builder.addCase(AllDeleteHeadlineNews.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(AllDeleteHeadlineNews.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    //InspirationalQuotesApp
    builder.addCase(getInspirationalQuotesApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getInspirationalQuotesApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.InspirationalQuotesApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getInspirationalQuotesApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(getInspirationalQuotesById.pending, (state) => {
      state.status = false;
    });
    builder.addCase(getInspirationalQuotesById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.InspirationalQuotesApp = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getInspirationalQuotesById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteInspirationalQuotes.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteInspirationalQuotes.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteInspirationalQuotes.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddInspirationalQuotes.pending, (state) => {  
      state.status = "loading";
    });
    builder.addCase(handleAddInspirationalQuotes.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });
    builder.addCase(handleAddInspirationalQuotes.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });


    //get CountDownTimer data
    // builder.addCase(
    //   handleGetCountDownTimerData.pending,
    //   (state, { payload, meta, type }) => {
    //     state.CountDownTimer.loading = true;
    //     state.CountDownTimer.error = null;
    //     state.CountDownTimer.CountDownTimerData = [];
    //   }
    // );
    // builder.addCase(
    //   handleGetCountDownTimerData.fulfilled,
    //   (state, { payload, meta }) => {
    //     state.CountDownTimer.loading = false;
    //     state.CountDownTimer.CountDownTimerData = payload?.data
    //       ? payload?.data
    //       : [];
    //     state.allAppsData =
    //       payload?.data.length > 0
    //         ? [...state.allAppsData, ...payload?.data]
    //         : [];
    //     state.error = null;
    //   }
    // );
    // builder.addCase(
    //   handleGetCountDownTimerData.rejected,
    //   (state, { payload }) => {
    //     state.CountDownTimer.loading = false;
    //     state.CountDownTimer.error = payload ?? null;
    //     state.CountDownTimer.CountDownTimerData = [];
    //   }
    // );

    /// clock App
    builder.addCase(getClocksApps.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getClocksApps.fulfilled, (state, { payload }) => {
      state.status = true;
      state.clockApps = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getClocksApps.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });
    builder.addCase(getClockById.pending, (state) => {
      state.status = null;
    });
    builder.addCase(getClockById.fulfilled, (state, { payload }) => {
      state.status = true;
      state.clockApps = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(getClockById.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddClockApps.pending, (state) => {
      state.status = "loading";
    });

    builder.addCase(handleAddClockApps.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });

    builder.addCase(handleAddClockApps.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });

    builder.addCase(deleteClock.pending, (state) => {
      state.status = false;
    });
    builder.addCase(deleteClock.fulfilled, (state, { payload }) => {
      state.status = true;
      state.data = payload?.data;
      state.token = payload?.data?.token;
    });
    builder.addCase(deleteClock.rejected, (state, action) => {
      state.status = false;
      state.error = action.error.message;
    });

    builder.addCase(handleAddCountUpTime.pending, (state) => {
      state.status = "loading";
    });

    builder.addCase(handleAddCountUpTime.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    });

    builder.addCase(handleAddCountUpTime.rejected, (state, action) => {
      state.status = "failed";
      toast.error = action.payload.message;
    });
  },
});

export const { } = AppsSlice.actions;

export default AppsSlice.reducer;
