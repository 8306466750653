import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";

const AddOrEditTagPopup = ({
  setShowTagModal,
  tags,
  setTags,
  handleTagsUpdate,
  from,
  handleUpdateTagsOfComposition,
  setTagUpdateScreeen,
  setUpdateTagComposition,
  handleUpadteScheduleTags,
  setUpdateTagSchedule,
  handleUpdateTagsYoutube,
  handleUpdateTagsTextScroll,
  handleUpdateTagsDitigitalMenu,
  setUpdateTextscrollTag,
  setUpdateTagYoutube,
  handleUpdateTagsWeather,
  setUpdateTagWeather,
  setUpdateDigitalMenuTag,
  handleUpadteWeatherScheduleTags,
  handleUpdateTagsCountUpTimer,
  setUpdateTagCountUpTimer,
  handleUpdateTagsQuestions,
  setUpdateTagQuestions,
  handleUpdateTagsCountDownTimer,
  setUpdateTagCountDownTimer,
  handleUpdateTagsMicrosoft,
  setUpdateTagMicrosoft,
  handleUpdateTagsEmbed,
  setUpdateTagEmbed,
  action,
  setUpdateTagDate,
  setUpdateTagGoogleTraffic,
  setUpdateTagFollowUsOnFacebook,
  handleUpdateTagsDate,
  handleUpdateTagsClock,
  setUpdateTagClock,
  handleUpdateTagsCurrencies,
  setUpdateTagCurrencies,
  handleUpdateTagsGoogleTraffic,
  handleUpdateTagsFollowUsOnFacebook,
  handleUpdateTagsDigitalSigns,
  setUpdateTagDigitalSigns,
  handleUpdateTagsPageScroll,
  setUpdateTagPageScroll,
  handleUpdateTagsLiveNews,
  setUpdateTagLiveNews,
  handleUpdateTagsSportsLiveScores,
  setUpdateTagSportsLiveScores,
  handleUpdateTagsFollowUs,
  setUpdateTagFollowUs,
  handleUpdateTagsNoticeBoard,
  setUpdateNoticeBoardTag,
  handleUpdateTagsSoccerScores,
  setUpdateTagSoccerScores,
  handleUpdateTagsWallsIo,
  setUpdateTagWallsIo,
  handleUpdateTagsStreaMa,
  setUpdateTagStreaMa,
  handleUpdateTagsPosterMyWall,
  setUpdateTagPosterMyWall,
  handleUpdateTagsBonusly,
  setUpdateTagBonusly,
  handleUpdateTagsCanva,
  setUpdateTagCanva,
  handleUpdateTagsGeckoBoard,
  setUpdateTagGeckoBoard,
  handleUpdateTagsYelp,
  setUpdateTagYelp,
  handleUpdateTagsWorld_Clock,
  setUpdateWorld_ClockTag,
  handleUpdateTagsNDTVNews,
  setUpdateTagNdtvNews,
  handleUpdateTagsHuffpost,
  setUpdateTagHuffpost,
  handleUpdateTagsFoxNews,
  setUpdateTagFoxNews,
  handleUpdateTagsNYTNews,
  setUpdateTagNytNews,
  handleUpdateTagsTOINews,
  setUpdateTagToiNews,
  handleUpdateTagsESPNNews,
  setUpdateTagEspnNews,
  handleUpdateTagsBBCNews,
  setUpdateTagBbcNews,
  handleUpdateTagsRegionalNews,
  setUpdateTagRegionalNews,
  handleUpdateTagsWallStreetNews,
  setUpdateTagWallStreetNews,
  handleUpdateTagsTaggBox,
  setUpdateTagTaggBox,
  handleUpdateTagsGoogleReviews,
  setUpdateTagGoogleReviews,
  handleUpdateTagsAQI,
  setUpdateTagAQI,
  handleUpdateTagsStockExchange,
  setUpdateTagStockExchange,
  handleUpdateTagsGoogleTrends,
  setUpdateTagGoogleTrends,
  handleUpdateTagsBuildingDirectory,
  setUpdateTagBuildingDirectory,
  handleUpdateTagsGoogleAlerts,
  setUpdateTagGoogleAlerts,
  handleUpdateTagsGoogleSheets,
  setUpdateTagGoogleSheets,
  handleUpdateTagsOnThisDay,
  setUpdateTagOnThisDay,
  handleUpdateTagsSportsNews,
  setUpdateTagSportsNews,
  handleUpdateTagsBusinessAndFinance,
  setUpdateTagBusinessAndFinance,
  handleUpdateTagsEntertainmentNews,
  setUpdateTagEntertainmentNews,
  handleUpdateTagsFlightSchedule,
  setUpdateTagFlightSchedule,
  handleUpdateTagsHeadlineNews,
  setUpdateTagHeadlineNews,
  handleUpdateTagsInspirationalQuotes,
  setUpdateTagInspirationalQuotes,
}) => {
  const [tagValue, setTagValue] = useState("");

  const inputRef = useRef(null);

  function handleClickOutside() {
    setShowTagModal(false);
    if (action !== "create") {
      setTags([]);
    }
    if (from === "screen") {
      setTagUpdateScreeen(null);
    }
    if (from === "composition") {
      setUpdateTagComposition(null);
    }
    if (from === "youtube") {
      setUpdateTagYoutube(null);
    }
    if (from === "weather") {
      setUpdateTagWeather(null);
    }
    if (from === "textscroll") {
      setUpdateTextscrollTag(null);
    }
    if (from === "digitalMenu") {
      setUpdateDigitalMenuTag(null);
    }
    if (from === "Date") {
      setUpdateTagDate(null);
    }
    if (from === "GoogleTraffic") {
      setUpdateTagGoogleTraffic(null);
    }
    if (from === "FollowUsOnFacebook") {
      setUpdateTagFollowUsOnFacebook(null);
    }
    if (from === "DigitalSigns") {
      setUpdateTagDigitalSigns(null);
    }
    if (from === "PageScroll") {
      setUpdateTagPageScroll(null);
    }
    if (from === "LiveNews") {
      setUpdateTagLiveNews(null);
    }
    if (from === "SportsLiveScores") {
      setUpdateTagSportsLiveScores(null);
    }
    if (from === "FollowUs") {
      setUpdateTagFollowUs(null);
    }
    if (from === "OnThisDay") {
      setUpdateTagOnThisDay(null);
    }
    if (from === "SportsNews") {
      setUpdateTagSportsNews(null);
    }
    if (from === "InspirationalQuotes") {
      setUpdateTagInspirationalQuotes(null);
    }
    if (from === "BusinessAndFinance") {
      setUpdateTagBusinessAndFinance(null);
    }
    if (from === "EntertainmentNews") {
      setUpdateTagEntertainmentNews(null);
    }
    if (from === "HeadlineNews") {
      setUpdateTagHeadlineNews(null);
    }
    if (from === "SoccerScores") {
      setUpdateTagSoccerScores(null);
    }
    if (from === "WallsIo") {
      setUpdateTagWallsIo(null);
    }
    if (from === "StreaMa") {
      setUpdateTagStreaMa(null);
    }
    if (from === "PosterMyWall") {
      setUpdateTagPosterMyWall(null);
    }
    if (from === "Bonusly") {
      setUpdateTagBonusly(null);
    }
    if (from === "Canva") {
      setUpdateTagCanva(null);
    }
    if (from === "GeckoBoard") {
      setUpdateTagGeckoBoard(null);
    }
    if (from === "TaggBoxDisplay") {
      setUpdateTagTaggBox(null);
    }
    if (from === "Yelp") {
      setUpdateTagYelp(null);
    }
    if (from === "NDTVNews") {
      setUpdateTagNdtvNews(null);
    }
    if (from === "FoxNews") {
      setUpdateTagFoxNews(null);
    }
    if (from === "Huffpost") {
      setUpdateTagHuffpost(null);
    }
    if (from === "NYTNews") {
      setUpdateTagNytNews(null);
    }
    if (from === "TOINews") {
      setUpdateTagToiNews(null);
    }
    if (from === "ESPNNews") {
      setUpdateTagEspnNews(null);
    }
    if (from === "BBCNews") {
      setUpdateTagBbcNews(null);
    }
    if (from === "RegionalNews") {
      setUpdateTagRegionalNews(null);
    }
    if (from === "WallStreetNews") {
      setUpdateTagWallStreetNews(null);
    }
    if (from === "countup") {
      setUpdateTagCountUpTimer(null);
    }
    if (from === "question") {
      setUpdateTagQuestions(null);
    }
    if (from === "countDownTimer") {
      setUpdateTagCountDownTimer(null);
    }
    if (from === "microsoftExcel") {
      setUpdateTagMicrosoft(null);
    }
    if (from === "embed") {
      setUpdateTagEmbed(null);
    }
    if (from === "Currencies") {
      setUpdateTagCurrencies(null);
    }
    if (from === "GoogleAlerts") {
      setUpdateTagGoogleAlerts(null);
    }
    if (from === "GoogleSheets") {
      setUpdateTagGoogleSheets(null);
    }
    if (from === "Clock") {
      setUpdateTagClock(null);
    }
    if (from === "NoticeBoard") {
      setUpdateNoticeBoardTag(null);
    }
    if (from === "World_Clock") {
      setUpdateWorld_ClockTag(null);
    }
    if (from === "BuildingDirectory") {
      setUpdateTagBuildingDirectory(null);
    }
    if (from === "GoogleReviews") {
      setUpdateTagGoogleReviews(null);
    }
    if (from === "AQI") {
      setUpdateTagAQI(null);
    }
    if (from === "StockExchange") {
      setUpdateTagStockExchange(null);
    }
    if (from === "GoogleTrends") {
      setUpdateTagGoogleTrends(null);
    }
    if (from === "FlightSchedule") {
      setUpdateTagFlightSchedule(null);
    }
  }

  const handleAddTag = (e) => {
    e.preventDefault();

    if (!tagValue.replace(/\s/g, "").length) {
      toast.remove();
      return toast.error("Please enter some text.");
    }
    toast.remove();
    setTags([...tags, tagValue]);
    setTagValue("");
    if (from === "screen") {
      handleTagsUpdate([...tags, tagValue].join(","));
    }
    if (from === "composition") {
      return handleUpdateTagsOfComposition([...tags, tagValue].join(","));
    }
    if (from === "schedule") {
      return handleUpadteScheduleTags([...tags, tagValue].join(","));
    }
    if (from === "weather-schedule") {
      return handleUpadteWeatherScheduleTags([...tags, tagValue].join(","));
    }
    if (from === "youtube") {
      return handleUpdateTagsYoutube([...tags, tagValue].join(","));
    }
    if (from === "weather") {
      return handleUpdateTagsWeather([...tags, tagValue].join(","));
    }
    if (from === "textscroll") {
      return handleUpdateTagsTextScroll([...tags, tagValue].join(","));
    }
    if (from === "digitalMenu") {
      return handleUpdateTagsDitigitalMenu([...tags, tagValue].join(","));
    }
    if (from === "countup") {
      return handleUpdateTagsCountUpTimer([...tags, tagValue].join(","));
    }
    if (from === "question") {
      return handleUpdateTagsQuestions([...tags, tagValue].join(","));
    }
    if (from === "InspirationalQuotes") {
      return handleUpdateTagsInspirationalQuotes([...tags, tagValue].join(","));
    }
    if (from === "countDownTimer") {
      return handleUpdateTagsCountDownTimer([...tags, tagValue].join(","));
    }
    if (from === "microsoftExcel") {
      return handleUpdateTagsMicrosoft([...tags, tagValue].join(","));
    }
    if (from === "embed") {
      return handleUpdateTagsEmbed([...tags, tagValue].join(","));
    }
    if (from === "Date") {
      return handleUpdateTagsDate([...tags, tagValue].join(","));
    }
    if (from === "GoogleTraffic") {
      return handleUpdateTagsGoogleTraffic([...tags, tagValue].join(","));
    }
    if (from === "OnThisDay") {
      return handleUpdateTagsOnThisDay([...tags, tagValue].join(","));
    }
    if (from === "SportsNews") {
      return handleUpdateTagsSportsNews([...tags, tagValue].join(","));
    }
    if (from === "EntertainmentNews") {
      return handleUpdateTagsEntertainmentNews([...tags, tagValue].join(","));
    }
    if (from === "BusinessAndFinance") {
      return handleUpdateTagsBusinessAndFinance([...tags, tagValue].join(","));
    }
    if (from === "HeadlineNews") {
      return handleUpdateTagsHeadlineNews([...tags, tagValue].join(","));
    }
    if (from === "FollowUsOnFacebook") {
      return handleUpdateTagsFollowUsOnFacebook([...tags, tagValue].join(","));
    }
    if (from === "DigitalSigns") {
      return handleUpdateTagsDigitalSigns([...tags, tagValue].join(","));
    }
    if (from === "PageScroll") {
      return handleUpdateTagsPageScroll([...tags, tagValue].join(","));
    }
    if (from === "LiveNews") {
      return handleUpdateTagsLiveNews([...tags, tagValue].join(","));
    }
    if (from === "SportsLiveScores") {
      return handleUpdateTagsSportsLiveScores([...tags, tagValue].join(","));
    }
    if (from === "FollowUs") {
      return handleUpdateTagsFollowUs([...tags, tagValue].join(","));
    }
    if (from === "SoccerScores") {
      return handleUpdateTagsSoccerScores([...tags, tagValue].join(","));
    }
    if (from === "WallsIo") {
      return handleUpdateTagsWallsIo([...tags, tagValue].join(","));
    }
    if (from === "StreaMa") {
      return handleUpdateTagsStreaMa([...tags, tagValue].join(","));
    }
    if (from === "PosterMyWall") {
      return handleUpdateTagsPosterMyWall([...tags, tagValue].join(","));
    }
    if (from === "Bonusly") {
      return handleUpdateTagsBonusly([...tags, tagValue].join(","));
    }
    if (from === "Canva") {
      return handleUpdateTagsCanva([...tags, tagValue].join(","));
    }
    if (from === "GeckoBoard") {
      return handleUpdateTagsGeckoBoard([...tags, tagValue].join(","));
    }
    if (from === "Yelp") {
      return handleUpdateTagsYelp([...tags, tagValue].join(","));
    }
    if (from === "TaggBoxDisplay") {
      return handleUpdateTagsTaggBox([...tags, tagValue].join(","));
    }
    if (from === "NDTVNews") {
      return handleUpdateTagsNDTVNews([...tags, tagValue].join(","));
    }
    if (from === "FoxNews") {
      return handleUpdateTagsFoxNews([...tags, tagValue].join(","));
    }
    if (from === "HuffPost") {
      return handleUpdateTagsHuffpost([...tags, tagValue].join(","));
    }
    if (from === "NYTNews") {
      return handleUpdateTagsNYTNews([...tags, tagValue].join(","));
    }
    if (from === "TOINews") {
      return handleUpdateTagsTOINews([...tags, tagValue].join(","));
    }
    if (from === "ESPNNews") {
      return handleUpdateTagsESPNNews([...tags, tagValue].join(","));
    }
    if (from === "BBCNews") {
      return handleUpdateTagsBBCNews([...tags, tagValue].join(","));
    }
    if (from === "RegionalNews") {
      return handleUpdateTagsRegionalNews([...tags, tagValue].join(","));
    }
    if (from === "WallStreetNews") {
      return handleUpdateTagsWallStreetNews([...tags, tagValue].join(","));
    }
    if (from === "GoogleAlerts") {
      return handleUpdateTagsGoogleAlerts([...tags, tagValue].join(","));
    }
    if (from === "GoogleSheets") {
      return handleUpdateTagsGoogleSheets([...tags, tagValue].join(","));
    }
    if (from === "Currencies") {
      return handleUpdateTagsCurrencies([...tags, tagValue].join(","));
    }
    if (from === "Clock") {
      return handleUpdateTagsClock([...tags, tagValue].join(","));
    }
    if (from === "NoticeBoard") {
      return handleUpdateTagsNoticeBoard([...tags, tagValue].join(","));
    }
    if (from === "World_Clock") {
      return handleUpdateTagsWorld_Clock([...tags, tagValue].join(","));
    }
    if (from === "BuildingDirectory") {
      return handleUpdateTagsBuildingDirectory([...tags, tagValue].join(","));
    }
    if (from === "GoogleReviews") {
      return handleUpdateTagsGoogleReviews([...tags, tagValue].join(","));
    }
    if (from === "AQI") {
      return handleUpdateTagsAQI([...tags, tagValue].join(","));
    }
    if (from === "StockExchange") {
      return handleUpdateTagsStockExchange([...tags, tagValue].join(","));
    }
    if (from === "GoogleTrends") {
      return handleUpdateTagsGoogleTrends([...tags, tagValue].join(","));
    }
    if (from === "FlightSchedule") {
      return handleUpdateTagsFlightSchedule([...tags, tagValue].join(","));
    }
  };

  const handleDeleteTag = (val) => {
    const newTags = tags.filter((tag) => tag !== val);
    setTags(newTags);
    if (from === "screen") {
      handleTagsUpdate(newTags.join(","));
    }
    if (from === "composition") {
      return handleUpdateTagsOfComposition(newTags.join(","));
    }
    if (from === "schedule") {
      return handleUpadteScheduleTags(newTags.join(","));
    }
    if (from === "weather-schedule") {
      return handleUpadteWeatherScheduleTags(newTags.join(","));
    }
    if (from === "youtube") {
      return handleUpdateTagsYoutube(newTags.join(","));
    }
    if (from === "weather") {
      return handleUpdateTagsWeather(newTags.join(","));
    }
    if (from === "textscroll") {
      return handleUpdateTagsTextScroll(newTags.join(","));
    }
    if (from === "digitalMenu") {
      return handleUpdateTagsDitigitalMenu(newTags.join(","));
    }
    if (from === "countup") {
      return handleUpdateTagsCountUpTimer(newTags.join(","));
    }
    if (from === "question") {
      return handleUpdateTagsQuestions(newTags.join(","));
    }
    if (from === "countDownTimer") {
      return handleUpdateTagsCountDownTimer(newTags.join(","));
    }
    if (from === "microsoftExcel") {
      return handleUpdateTagsMicrosoft(newTags.join(","));
    }
    if (from === "embed") {
      return handleUpdateTagsEmbed(newTags.join(","));
    }
    if (from === "InspirationalQuotes") {
      return handleUpdateTagsInspirationalQuotes(newTags.join(","));
    }
    if (from === "Date") {
      return handleUpdateTagsDate(newTags.join(","));
    }
    if (from === "GoogleTraffic") {
      return handleUpdateTagsGoogleTraffic(newTags.join(","));
    }
    if (from === "TaggBoxDisplay") {
      return handleUpdateTagsTaggBox(newTags.join(","));
    }
    if (from === "FollowUsOnFacebook") {
      return handleUpdateTagsFollowUsOnFacebook(newTags.join(","));
    }
    if (from === "DigitalSigns") {
      return handleUpdateTagsDigitalSigns(newTags.join(","));
    }
    if (from === "PageScroll") {
      return handleUpdateTagsPageScroll(newTags.join(","));
    }
    if (from === "LiveNews") {
      return handleUpdateTagsLiveNews(newTags.join(","));
    }
    if (from === "SportsLiveScores") {
      return handleUpdateTagsSportsLiveScores(newTags.join(","));
    }
    if (from === "FollowUs") {
      return handleUpdateTagsFollowUs(newTags.join(","));
    }
    if (from === "SoccerScores") {
      return handleUpdateTagsSoccerScores(newTags.join(","));
    }
    if (from === "WallsIo") {
      return handleUpdateTagsWallsIo(newTags.join(","));
    }
    if (from === "StreaMa") {
      return handleUpdateTagsStreaMa(newTags.join(","));
    }
    if (from === "OnThisDay") {
      return handleUpdateTagsOnThisDay(newTags.join(","));
    }
    if (from === "SportsNews") {
      return handleUpdateTagsSportsNews(newTags.join(","));
    }
    if (from === "EntertainmentNews") {
      return handleUpdateTagsEntertainmentNews(newTags.join(","));
    }
    if (from === "BusinessAndFinance") {
      return handleUpdateTagsBusinessAndFinance(newTags.join(","));
    }
    if (from === "HeadlineNews") {
      return handleUpdateTagsHeadlineNews(newTags.join(","));
    }
    if (from === "PosterMyWall") {
      return handleUpdateTagsPosterMyWall(newTags.join(","));
    }
    if (from === "Bonusly") {
      return handleUpdateTagsBonusly(newTags.join(","));
    }
    if (from === "Canva") {
      return handleUpdateTagsCanva(newTags.join(","));
    }
    if (from === "GeckoBoard") {
      return handleUpdateTagsGeckoBoard(newTags.join(","));
    }
    if (from === "Yelp") {
      return handleUpdateTagsYelp(newTags.join(","));
    }
    if (from === "NDTVNews") {
      return handleUpdateTagsNDTVNews(newTags.join(","));
    }
    if (from === "FoxNews") {
      return handleUpdateTagsFoxNews(newTags.join(","));
    }
    if (from === "HuffPost") {
      return handleUpdateTagsHuffpost(newTags.join(","));
    }
    if (from === "NYTNews") {
      return handleUpdateTagsNYTNews(newTags.join(","));
    }
    if (from === "TOINews") {
      return handleUpdateTagsTOINews(newTags.join(","));
    }
    if (from === "ESPNNews") {
      return handleUpdateTagsESPNNews(newTags.join(","));
    }
    if (from === "BBCNews") {
      return handleUpdateTagsBBCNews(newTags.join(","));
    }
    if (from === "RegionalNews") {
      return handleUpdateTagsRegionalNews(newTags.join(","));
    }
    if (from === "WallStreetNews") {
      return handleUpdateTagsWallStreetNews(newTags.join(","));
    }
    if (from === "Currencies") {
      return handleUpdateTagsCurrencies(newTags.join(","));
    }
    if (from === "Clock") {
      return handleUpdateTagsClock(newTags.join(","));
    }
    if (from === "GoogleAlerts") {
      return handleUpdateTagsGoogleAlerts(newTags.join(","));
    }
    if (from === "GoogleSheets") {
      return handleUpdateTagsGoogleSheets(newTags.join(","));
    }
    if (from === "NoticeBoard") {
      return handleUpdateTagsNoticeBoard(newTags.join(","));
    }
    if (from === "World_Clock") {
      return handleUpdateTagsWorld_Clock(newTags.join(","));
    }
    if (from === "BuildingDirectory") {
      return handleUpdateTagsBuildingDirectory(newTags.join(","));
    }
    if (from === "GoogleReviews") {
      return handleUpdateTagsGoogleReviews(newTags.join(","));
    }
    if (from === "AQI") {
      return handleUpdateTagsAQI(newTags.join(","));
    }
    if (from === "StockExchange") {
      return handleUpdateTagsStockExchange(newTags.join(","));
    }
    if (from === "GoogleTrends") {
      return handleUpdateTagsGoogleTrends(newTags.join(","));
    }
    if (from === "FlightSchedule") {
      return handleUpdateTagsFlightSchedule(newTags.join(","));
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <div
        onClick={() => handleClickOutside(false)}
        className="inset-0 fixed z-9990 bg-black/50"
      ></div>
      <div className=" bg-white z-9999 space-y-3 overflow-y-scroll hide_scrollbar fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 min-w-80vw max-h-[50vh] max-w-[50vw] min-h-[50vh] min-w-[50vw] p-5 rounded-lg">
        <div className="flex items-center justify-between w-full bg-white sticky -top-6 p-2">
          <h3 className="text-left font-semibold text-3xl sticky top-0 bg-white w-full">
            Add Tags
          </h3>
          <button onClick={() => handleClickOutside(false)}>
            <AiOutlineCloseCircle className="text-2xl" />
          </button>
        </div>
        <div className="flex items-center gap-3 flwro w-full h-full flex-wrap overflow-y-scroll hide_scrollbar">
          <ul className="flex items-center gap-3 flex-wrap flex-initial h-full overflow-y-scroll hide_scrollbar">
            {tags?.length > 0 &&
              tags.map((tag, index) => (
                <li
                  key={index}
                  className="flex items-center gap-1 border border-black/40 rounded-lg p-1"
                >
                  {tag}
                  <AiOutlineClose
                    size={10}
                    className=" cursor-pointer text-black w-5 h-5 bg-lightgray p-1"
                    onClick={() => handleDeleteTag(tag)}
                  />
                </li>
              ))}
          </ul>
          <form
            onSubmit={(e) => handleAddTag(e)}
            className="flex-initial w-fit"
          >
            <input
              ref={inputRef}
              type="text"
              className="border h-auto rounded-lg p-1 w-full"
              onChange={(e) => setTagValue(e.target.value)}
              value={tagValue}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default AddOrEditTagPopup;
