import React, { useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { PageNumber } from '../../Components/Common/Common';
import { BsEyeFill } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import ShowAssetImageModal from '../../Components/Assests/ShowAssetImageModal';

export default function CustomerDetailsModal({ setCustomerModal, type, advertisementDetails }) {
    const [AssetURl, setAssetURl] = useState(null);
    const [showImageAssetModal, setShowImageAssetModal] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = advertisementDetails?.screenInfo?.slice(indexOfFirstItem, indexOfLastItem);
    return (
        <div>
            <div className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-9990 outline-none focus:outline-none">
                <div className="w-auto my-6 mx-auto lg:max-w-4xl md:max-w-xl sm:max-w-sm xs:max-w-xs">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col min-w-[500px] bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-4 px-6 border-b border-[#A7AFB7] rounded-t text-black">
                            <h3 className="lg:text-lg md:text-lg sm:text-base xs:text-sm font-medium">
                                {type === 'Details' ? 'Client Information' : 'Screen Information'}
                            </h3>
                            <button className="p-1 text-xl ml-8" onClick={() => setCustomerModal(false)}>
                                <AiOutlineCloseCircle className="text-2xl" />
                            </button>
                        </div>
                        {type === 'Details' && (
                            <div className='pt-3'>
                                <div className="p-5">
                                    <div className="w-full mb-5 border-b">
                                        <label className="text-gray-500 ml-1 lg:text-base md:text-base sm:text-xs xs:text-xs font-medium">Name :</label>
                                        <label className=" ml-1 lg:text-base md:text-base sm:text-xs xs:text-xs font-medium">{advertisementDetails?.name}</label>
                                    </div>
                                    <div className="w-full mb-5 border-b">
                                        <label className="text-gray-500 ml-1 lg:text-base md:text-base sm:text-xs xs:text-xs font-medium">Phone Number :</label>
                                        <label className=" ml-1 lg:text-base md:text-base sm:text-xs xs:text-xs font-medium">{advertisementDetails?.phoneNumber}</label>
                                    </div>
                                    <div className="w-full mb-5 border-b">
                                        <label className="text-gray-500 ml-1 lg:text-base md:text-base sm:text-xs xs:text-xs font-medium">Email :</label>
                                        <label className=" ml-1 lg:text-base md:text-base sm:text-xs xs:text-xs font-medium">{advertisementDetails?.email}</label>
                                    </div>
                                </div>
                            </div>
                        )}
                        {type === 'Listing' && (
                            <div className='px-7'>
                                <div className="clear-both">
                                    <div className="bg-white rounded-xl mt-8 shadow screen-section ">
                                        <div className=" mt-5 overflow-x-scroll sc-scrollbar sm:rounded-lg ">
                                            <table
                                                className="screen-table w-full  lg:table-auto md:table-auto sm:table-auto xs:table-auto"
                                                cellPadding={15}
                                            >
                                                <thead>
                                                    <tr className="items-center table-head-bg">
                                                        <th className="text-[#5A5881] text-base font-semibold w-fit text-center ">
                                                            Screen ID
                                                        </th>
                                                        <th className="text-[#5A5881] text-base font-semibold w-fit text-center">
                                                            Location
                                                        </th>
                                                        <th className="text-[#5A5881] text-base font-semibold w-fit text-center">
                                                            Total Duration
                                                        </th>
                                                        <th className="text-[#5A5881] text-base font-semibold w-fit text-center">
                                                            Assets
                                                        </th>
                                                        {/* <th className="text-[#5A5881] text-base font-semibold w-fit text-center">
                                                            Resolution
                                                        </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentItems?.length > 0 &&
                                                        currentItems?.map((item, index) => (
                                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-center" key={index}>
                                                                <td className="px-6 py-4 ">
                                                                    {item?.screenID}
                                                                    <div className="ml-2 relative inline-flex items-center justify-center w-5 h-5 overflow-hidden bg-[#57a670] rounded-full dark:bg-gray-600">
                                                                        <span className="font-medium text-xs text-white text-gray-600 dark:text-gray-300">{item?.isHorizontal ? 'H' : 'V'}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 py-4 w-[400px]">{item?.location}</td>
                                                                <td className="px-6 py-4">{item?.totalDuration}</td>
                                                                <td className="text-[#5E5E5E] text-center">
                                                                    <div className="flex justify-center gap-4">
                                                                        <div
                                                                            data-tip
                                                                            data-for="View"
                                                                            className="cursor-pointer text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-xl p-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                                            onClick={() => {
                                                                                setShowImageAssetModal(true)
                                                                                setAssetURl({
                                                                                    assetType: item?.assetType,
                                                                                    assetFolderPath: item?.assets
                                                                                })
                                                                            }}
                                                                        >
                                                                            <BsEyeFill />
                                                                            <ReactTooltip
                                                                                id="View"
                                                                                place="bottom"
                                                                                type="warning"
                                                                                effect="solid"
                                                                            >
                                                                                <span>View</span>
                                                                            </ReactTooltip>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    {advertisementDetails?.screenInfo?.length === 0 && (
                                                        <tr>
                                                            <td colSpan={15}>
                                                                <div className="flex text-center m-5 justify-center">
                                                                    <span className="text-2xl font-semibold py-2 px-4 rounded-full me-2 text-black">
                                                                        No Data Available
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        {advertisementDetails?.screenInfo?.length !== 0 && (
                                            <div className="flex lg:flex-row lg:justify-between md:flex-row md:justify-between sm:flex-row sm:justify-between flex-col justify-end p-5 gap-3">
                                                <div className="flex items-center">
                                                    <span className="text-gray-500">{`Total ${advertisementDetails?.screenInfo?.length} Screens`}</span>
                                                </div>
                                                <div className="flex justify-end">
                                                    <select className='px-1 mr-2 border border-gray rounded-lg'
                                                        value={itemsPerPage}
                                                        onChange={(e) => { setItemsPerPage(e.target.value); setCurrentPage(1) }}
                                                    >
                                                        {PageNumber.map((x) => (
                                                            <option key={x} value={x}>{x}</option>
                                                        ))}
                                                    </select>
                                                    <button
                                                        onClick={() => setCurrentPage(currentPage - 1)}
                                                        disabled={currentPage === 1}
                                                        className="flex cursor-pointer hover:bg-white hover:text-primary items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 "
                                                    >
                                                        <svg
                                                            className="w-3.5 h-3.5 me-2 rtl:rotate-180"
                                                            aria-hidden="true"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 14 10"
                                                        >
                                                            <path
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M13 5H1m0 0 4 4M1 5l4-4"
                                                            />
                                                        </svg>
                                                        {/* {sidebarOpen ? "Previous" : ""} */}
                                                    </button>
                                                    <div className="flex items-center me-3">
                                                        <span className="text-gray-500">{`Page ${currentPage} of ${Math.ceil(advertisementDetails?.screenInfo?.length / itemsPerPage)}`}</span>
                                                    </div>
                                                    <button
                                                        onClick={() => setCurrentPage(currentPage + 1)}
                                                        disabled={currentPage === Math.ceil(advertisementDetails?.screenInfo?.length / itemsPerPage)}
                                                        className="flex hover:bg-white hover:text-primary cursor-pointer items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 "
                                                    >
                                                        {/* {sidebarOpen ? "Next" : ""} */}
                                                        <svg
                                                            className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                                                            aria-hidden="true"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 14 10"
                                                        >
                                                            <path
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M1 5h12m0 0L9 1m4 4L9 9"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {showImageAssetModal && (
                <ShowAssetImageModal
                    setImageAssetModal={setAssetURl}
                    setShowImageAssetModal={setShowImageAssetModal}
                    showImageAssetModal={showImageAssetModal}
                    imageAssetModal={AssetURl}
                />
            )}
        </div>
    )
}
