import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaCloudUploadAlt, FaTrashAlt } from 'react-icons/fa';

const NoticeBoard_Background = ({ selectedAsset, setSelectedAsset, setWhitetext, setDarkOverlay, setbackground, BackGroundModelClose, darkOverlay, whitetext, setOpenModal, Uploadsetbackgroundimg }) => {

    const RemoveBackground = () => {
        setSelectedAsset(0)
        setbackground(0)
    }

    return (
        <>
            <div
                id="default-modal"
                tabIndex="-1"
                aria-hidden="true"
                className="fixed top-0 right-0 left-0 z-9990 flex justify-center items-center w-full h-full m-0 md:inset-0 max-h-full bg-black bg-opacity-50"
            >
                <div className="relative p-4 w-full max-w-4xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                        <div className="flex items-center justify-between p-3 md:p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Upload Background Image
                            </h3>
                            <AiOutlineCloseCircle
                                className="text-4xl text-primary cursor-pointer"
                                onClick={() => { BackGroundModelClose() }}
                            />
                        </div>
                        <div className="p-6">
                            <div className='flex flex-col  justify-center m-3 items-center cursor-pointer'>
                                {
                                    selectedAsset === 0 ?
                                        <div className="flex justify-between items-center w-[80%] p-3 rounded-lg border border-1 mb-5" onClick={() => setOpenModal(true)}>
                                            <div className=' w-[89%]'>
                                                <h3 className="text-lg font-semibold">Please upload an image</h3>
                                                <p className="text-sm text-gray-500">
                                                    JPG or PNG at a resolution of <strong>1920 * 1080</strong>
                                                </p>
                                                <p className="text-xs text-gray-400">
                                                    (Images larger than 2MB will take longer to load)
                                                </p>
                                            </div>

                                            <FaCloudUploadAlt className="mx-auto text-gray-400 text-4xl" />

                                        </div> :
                                        <div className="flex justify-between items-center w-[80%] p-3 rounded-lg border border-1 mb-5" >
                                            <div className=' w-[89%]'>
                                                <h3 className="text-base font-semibold" onClick={() => setOpenModal(true)}>{selectedAsset?.assetFolderPath}</h3>
                                                <p className="text-sm text-gray-500"
                                                    onClick={RemoveBackground}
                                                >
                                                    Click to Remove
                                                </p>
                                            </div>
                                            <FaTrashAlt className="mx-auto text-gray-400 text-4xl" onClick={RemoveBackground} />
                                        </div>
                                }

                                <div className="flex justify-between items-center w-[80%] p-3 rounded-lg border border-1 mb-5">
                                    <div>
                                        <h3 className="text-lg font-semibold">Dark Overlay</h3>
                                        <p className="text-sm text-gray-500">
                                            To make text easier to read, we recommend leaving it white. If you
                                            turn this off, the text will be the same color as the theme.
                                        </p>
                                    </div>
                                    <label className="flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            className="sr-only"
                                            name='darkOverlay'
                                            checked={darkOverlay}
                                            onChange={(e) => setDarkOverlay(!darkOverlay)}
                                        />
                                        <div className={`w-10 h-6 bg-gray-300 rounded-full p-1 transition  ${darkOverlay ? "bg-SlateBlue" : 'bg-gray-300'}`}>
                                            <div className={`h-4 w-4 bg-white rounded-full shadow-md transform transition ${darkOverlay ? 'translate-x-4' : 'translate-x-0'}`} />
                                        </div>
                                    </label>
                                </div>
                                <div className="flex justify-between items-center w-[80%] p-3 rounded-lg border border-1">
                                    <div>
                                        <h3 className="text-lg font-semibold">White Text</h3>
                                        <p className="text-sm text-gray-500">
                                            To make text easier to read, we recommend leaving it white. If you
                                            turn this off, the text will be the same color as the theme.
                                        </p>
                                    </div>
                                    <label className="flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            className="sr-only"
                                            checked={whitetext}
                                            name='whitetext'
                                            onChange={(e) => setWhitetext(!whitetext)}
                                        />
                                        <div className={`w-10 h-6 bg-gray-300 rounded-full p-1 transition  ${whitetext ? "bg-SlateBlue" : 'bg-gray-300'}`}>
                                            <div className={`h-4 w-4 bg-white rounded-full shadow-md transform transition ${whitetext ? 'translate-x-4' : 'translate-x-0'}`} />
                                        </div>

                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center justify-center gap-2 border-t border-black mb-3 ">
                            <button
                                type="button"
                                className="w-40 text-[#FFFFFF] bg-SlateBlue not-italic font-medium rounded-full py-3.5 text-center text-base mt-4 hover:bg-primary border border-SlateBlue hover:border-white"
                                onClick={() => BackGroundModelClose()}
                            >
                                Cancel
                            </button>
                            <button

                                className="w-40 text-[#FFFFFF] bg-SlateBlue not-italic font-medium rounded-full py-3.5 text-center text-base mt-4 hover:bg-primary border border-SlateBlue hover:border-white"
                                onClick={() => Uploadsetbackgroundimg()}
                            >
                                Save
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default NoticeBoard_Background