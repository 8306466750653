import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

export const PosterMyWallPreview = ({
  instanceName,
  setInstanceView,
  showTags,
  screenAssignName,
  posterMyWallDataById,
}) => {
  return (
    <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
      <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-[1000px] h-[600px]">
        <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
          <div className="flex items-center">
            <div>
              <img
                src="https://back.disploy.com/app/postermywall.svg"
                alt="Logo"
                className="cursor-pointer mx-auto h-10 w-10"
              />
            </div>
            <div className="ml-3">
              <h4 className="text-lg font-medium whitespace-wrap ">
                {instanceName}
              </h4>
            </div>
          </div>
          <button
            className="p-1 text-3xl"
            onClick={() => setInstanceView(false)}
          >
            <AiOutlineCloseCircle />
          </button>
        </div>
        <div className="md:w-[576px] md:h-[324px] sm:w-[384px] sm:h-[216px] lg:w-[860px] lg:h-[440px]  flex items-center justify-center overflow-hidden scrollbar-hide ">
          <div className="w-[200px] h-[250px] flex items-center justify-center ">
            <div
            className="PosterWallPreview-PosterMyWall"
              dangerouslySetInnerHTML={{
                __html: posterMyWallDataById?.url,
              }}
            />
          </div>
        </div>
        <div className="py-2 px-6">
          <div className="flex items-center gap-2 w-full">
            <div className="font-semibold w-fit">Tags:-</div>
            <div className=" w-full">{showTags}</div>
          </div>
          <div>
            <label className="font-semibold">Screen Assign :</label>
            {screenAssignName ? screenAssignName : " No Screen"}
          </div>
        </div>
      </div>
    </div>
  );
};
