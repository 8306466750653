import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { MdSave } from "react-icons/md";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { COUNTDownTIMER_INSTANCE_ADD_URL, GET_COUNTDownTIMER_BY_ID } from "../../Pages/Api";
import axios from "axios";
import { FaThumbsUp } from "react-icons/fa6";
import { IoBalloonSharp, IoCheckmarkCircle } from "react-icons/io5";
import DefaultIMG from "../../images/AppsImg/configure-app-e7ce6957.svg";
import { FaSmile } from "react-icons/fa";


const CountDownTimerDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const currentDate = new Date();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user, token, userDetails } = useSelector((state) => state.root.auth);
  const authToken = `Bearer ${token}`;
  const [instanceName, setInstanceName] = useState(
    moment(currentDate).format("YYYY-MM-DD hh:mm")
  );
  const [edited, setEdited] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [countDown, setcountDown] = useState({
    Title: "",
    Year: "",
    Month: "",
    Day: "",
    Hour: "",
    Minute: "",
    Second: "",
    Style: "Flip",
    Date_Format: "1",
    Unit_Show: "1",
    Message: "",
    Icon: "Thumbs_up",
  });


  const [result, setResult] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [saveLoading, setSaveLoading] = useState(false);
  const [checktime, setCheckTime] = useState(false);

  const handleOnSaveInstanceName = (e) => {
    if (!instanceName.replace(/\s/g, "").length) {
      toast.remove();
      return toast.error("Please enter at least minimum 1 character.");
    }
    setEdited(false);
  };

  const handlInputeChange = (e) => {
    const { name, value } = e.target;
    setcountDown((Pre) => ({
      ...Pre,
      [name]: value,
    }));
  };

  useEffect(() => {
    const isValidDate =
      countDown.Day !== "" && countDown.Month !== "" && countDown.Year !== "";

    const inputDate = new Date(
      countDown.Year,
      countDown.Month - 1,
      countDown.Day,
      countDown.Hour || 0,
      countDown.Minute || 0
    );

    const updateCountdown = () => {
      const currentDate = new Date();
      const diffInMs = inputDate - currentDate;

      if (diffInMs <= 0) {

        clearInterval(interval);
        setResult({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        setCheckTime(true);
        return;
      }

      const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

      setResult({ days, hours, minutes, seconds });
    };

    let interval;

    if (isValidDate) {
      if (inputDate > new Date()) {
        interval = setInterval(updateCountdown, 1000);
        setCheckTime(false);
      } else {
        setCheckTime(true); // Date is in the past
      }
    } else {
      setCheckTime(true); // Invalid input date
    }

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [countDown]);

  const date_format = (() => {
    switch (countDown?.Date_Format) {
      case "1":
        return `${countDown?.Month}/${countDown?.Day}/${countDown?.Year}`;
      case "2":
        return `${countDown?.Day}/${countDown?.Month}/${countDown?.Year}`;
      case "3":
        return `${countDown?.Year}/${countDown?.Month}/${countDown?.Day}`; // Example: Year.Month.Day format
      default:
        return "Invalid Format"; // Fallback for unexpected values
    }
  })();

  const handleInsertCountDown = async () => {
    try {
      if (!countDown?.Title.replace(/\s/g, "").length) {
        toast.remove();
        return toast.error("Please enter the title.");
      } else if (!countDown?.Year || countDown?.Year === '') {
        return toast.error("Please enter the year.");
      } else if (!countDown?.Month || countDown?.Month === '') {
        return toast.error("Please enter the month.");
      } else if (!countDown?.Day || countDown?.Day === '') {
        return toast.error("Please enter the day.");
      } else if (!countDown?.Message || countDown?.Message === '') {
        return toast.error("Please enter the completion message.");
      }
      const data = JSON.stringify({
        countDownTimerAppId: id || 0,
        instanceName: instanceName,
        title: countDown?.Title,
        year: countDown?.Year,
        month: countDown?.Month,
        day: countDown?.Day,
        hour: countDown?.Hour,
        minute: countDown?.Minute,
        style: countDown?.Style,
        dateFormat: countDown?.Date_Format,
        unitToShow: countDown?.Unit_Show,
        completionMessage: countDown?.Message,
        completionIcon: countDown?.Icon,
      });
      setSaveLoading(true);
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: COUNTDownTIMER_INSTANCE_ADD_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (window.history.length === 1) {
            toast.success(
              id
                ? "Count Down Updated successfully!"
                : "Count Down added successfully!"
            );
            localStorage.setItem("isWindowClosed", "true");
            window.close();
          } else {
            navigate("/Count-Down-Timer");
          }
          setSaveLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setSaveLoading(false);
        });

    } catch (error) {
      console.log("error", error);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    const FetchDataById = async () => {
      try {
        if (id) {
          const config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${GET_COUNTDownTIMER_BY_ID}?countDownTimerId=${id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: authToken,
            },
          };
          axios.request(config).then((response) => {
            const data = response?.data?.data;
            setcountDown((pre) => ({
              Title: data?.title,
              Year: data?.year,
              Month: data?.month,
              Day: data?.day,
              Hour: data?.hour,
              Minute: data?.minute,
              Style: data?.style,
              Date_Format: data?.dateFormat,
              Unit_Show: data?.unitToShow,
              Message: data?.completionMessage,
              Icon: data?.completionIcon,
            }));

            setInstanceName(data?.instanceName);
          });
        }
      } catch (error) { }
    };
    FetchDataById();
  }, [id]);

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
            user?.userDetails?.isRetailer === false &&
            !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div>
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter schedule name"
                        value={instanceName}
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      if (countDown?.Title !== "" && countDown?.Message !== "")
                        return setShowPreview(!showPreview);
                      toast.error("Please Feel Details");
                    }}
                  >
                    {showPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => { handleInsertCountDown(); }}
                    disabled={saveLoading}
                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>

                  <Link to="/Count-Down-Timer">
                    <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>
              {showPreview && (
                <div className="w-[85%] h-[35rem]  flex flex-col lg:flex-row bg-white shadow-lg rounded-lg h-full mx-auto mt-10 ">
                  <div className="w-[100%] h-[35rem] bg-gray-50 rounded-sm shadow-md border-[5px] border-black flex items-center justify-center">
                    {countDown?.Message !== "" &&
                      countDown?.Year > 2018 &&
                      countDown?.Month <= 12 &&
                      countDown?.Month !== '' &&
                      countDown?.Day !== '' &&
                      countDown?.Day <= 31 &&
                      countDown?.Hour <= 24 &&
                      countDown?.Minute <= 59 ? (
                      !checktime ? (
                        <div className="w-full h-full bg-[#fdce0b] flex flex-col items-center justify-center">
                          {countDown?.Style === "Flip" && (
                            <>
                              <div >
                                <p className=" flex items-center justify-center font-medium text-center text-[#2d313a] text-4xl">
                                  {countDown?.Title}
                                </p>
                                <p className="text-[#4b5563c2] text-center text-xl"> {date_format}</p>
                              </div>
                              <div className="flex space-x-4 mt-10">
                                <div className="flip-clock-item">
                                  <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                    {String(result?.days).padStart(2, "0")}
                                  </div>
                                  <div className="text-center text-[#4b5563c2] text-lg uppercase font-semibold mt-3">
                                    Days
                                  </div>
                                </div>

                                {(countDown.Unit_Show === "1" ||
                                  countDown.Unit_Show === "2") && (
                                    <div className="flip-clock-item">
                                      <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                        {String(result?.hours).padStart(2, "0")}
                                      </div>
                                      <div className="text-center text-[#4b5563c2] text-lg uppercase font-semibold mt-3">
                                        Hours
                                      </div>
                                    </div>
                                  )}

                                {countDown.Unit_Show === "1" && (
                                <>
                                  <div className="flip-clock-item">
                                    <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                      {String(result?.minutes).padStart(2, "0")}
                                    </div>
                                    <div className="text-center text-[#4b5563c2] text-lg uppercase font-semibold mt-3">
                                      Minutes
                                    </div>
                                  </div>
                                  <div className="flip-clock-item">
                                    <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                      {String(result?.seconds).padStart(2, "0")}
                                    </div>
                                    <div className="text-center text-[#4b5563c2] text-lg uppercase font-semibold mt-3">
                                      Seconds
                                    </div>
                                  </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                          {countDown?.Style === "Minimal" && (
                            <div className=" flex items-center w-full h-full ">
                              {result?.days > 0 && (
                                <>
                                  <div className=" w-[40%] flex items-center justify-center h-full">
                                    <h1 style={{ fontSize: "150px" }}>
                                      {String(result?.days).padStart(2, "0")}
                                    </h1>
                                  </div>
                                  <div>
                                    <h1 className="flex items-center justify-center text-lg font-bold">
                                      DAYS LEFT UNTIL
                                    </h1>
                                    <h1 className=" text-xl">
                                      {countDown?.Title}
                                    </h1>
                                  </div>
                                </>
                              )}
                              {result?.days === 0 && result?.hours > 0 && (
                                <>
                                  <div className=" w-[40%] flex items-center justify-center h-full">
                                    <h1 className="text-[#2d313a] text-[150px] font-semibold">
                                      {String(result?.hours).padStart(2, "0")}
                                    </h1>
                                  </div>
                                  <div>
                                    <h1 className="flex items-center justify-center text-lg font-bold text-[#2d313a]">
                                      HOURS LEFT UNTIL
                                    </h1>
                                    <h1 className=" text-xl text-[#2d313a]">{countDown?.Title}</h1>
                                  </div>
                                </>
                              )}
                              {result?.days === 0 &&
                                result?.hours === 0 &&
                                result?.minutes > 0 && (
                                  <>
                                    <div className=" w-[40%] flex items-center justify-center h-full">
                                      <h1 style={{ fontSize: "150px" }}>
                                        {String(result?.minutes).padStart(2, "0")}
                                      </h1>
                                    </div>
                                    <div>
                                      <h1 className="flex items-center justify-center text-lg font-bold">
                                        MINUTES LEFT UNTIL
                                      </h1>
                                      <h1 className=" text-xl">
                                        {countDown?.Title}
                                      </h1>
                                    </div>
                                  </>
                                )}
                              {result?.days === 0 &&
                                result?.hours === 0 &&
                                result?.minutes === 0 && (
                                  <>
                                    <div className=" w-[40%] flex items-center justify-center h-full">
                                      <h1 style={{ fontSize: "150px" }}>
                                        {String(result?.seconds).padStart(2, "0")}
                                      </h1>
                                    </div>
                                    <div>
                                      <h1 className="flex items-center justify-center text-lg font-bold">
                                        SECONDS LEFT UNTIL
                                      </h1>
                                      <h1 className=" text-xl">
                                        {countDown?.Title}
                                      </h1>
                                    </div>
                                  </>
                                )}

                              <div></div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="w-full h-full bg-[#fdce0b] flex  flex-col items-center justify-center text-center p-4">
                          <div className="h-16 w-16 bg-[#2d313a] rounded-full flex items-center justify-center mb-10">
                            {countDown?.Icon === 'Thumbs_up' && <FaThumbsUp size={35} className="text-[#fdce0b]" />}
                            {countDown?.Icon === 'Balloons' && <IoBalloonSharp size={35} className="text-[#fdce0b]" />}
                            {countDown?.Icon === 'Smile' && <FaSmile size={35} className="text-[#fdce0b]" />}
                            {countDown?.Icon === 'Tick ' && <IoCheckmarkCircle size={35} className="text-[#fdce0b]" />}
                          </div>
                          <p className="text-2xl font-semibold text-[#2d313a]">{countDown?.Message}</p>
                        </div>
                      )
                    ) : (
                      <div className="text-center">
                        <img src={DefaultIMG} alt="" className="w-52 mx-auto mb-5" />
                        <p className="text-gray-500">App instance preview</p>
                        <p className="text-sm text-gray-400">
                          Please edit the app settings to get started
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {!showPreview && (
                <div className="flex flex-col lg:flex-row rounded-lg h-full gap-3">
                  <div className="w-full lg:w-2/4 pr-0 lg:pr-4 mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Title*
                      </label>
                      <input
                        type="text"
                        className=" placeholder-red-300 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Office party"
                        name="Title"
                        value={countDown?.Title}
                        onChange={handlInputeChange}
                      />
                    </div>
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Year*
                      </label>
                      <input
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Format: 2020"
                        value={countDown?.Year}
                        name="Year"
                        onChange={handlInputeChange}
                      />
                    </div>
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Month*
                      </label>
                      <input
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Format: 12"
                        value={countDown?.Month}
                        name="Month"
                        onChange={handlInputeChange}
                      />
                    </div>
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Day*
                      </label>

                      <input
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Format: 31"
                        value={countDown?.Day}
                        name="Day"
                        onChange={handlInputeChange}
                        required={countDown?.Day <= 31}
                      />
                    </div>
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Hour
                      </label>

                      <input
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Format: 23 (Optional)"
                        value={countDown?.Hour}
                        name="Hour"
                        onChange={handlInputeChange}
                      />
                    </div>
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Minute
                      </label>
                      <input
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Format: 59 (Optional)"
                        value={countDown?.Minute}
                        name="Minute"
                        onChange={handlInputeChange}
                      />
                    </div>
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Second
                      </label>
                      <input
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Format: 59 (Optional)"
                        value={countDown?.Second}
                        name="Second"
                        onChange={handlInputeChange}
                      />
                    </div>

                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Style
                      </label>
                      <select
                        onChange={handlInputeChange}
                        name="Style"
                        value={countDown?.Style}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="Flip">Flip</option>
                        <option value="Minimal">Minimal</option>
                      </select>
                    </div>
                    {countDown?.Style === "Flip" && (
                      <>
                        <div className="mb-4 flex items-center justify-between">
                          <label
                            htmlFor="countries"
                            className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Date format
                          </label>

                          <select
                            onChange={handlInputeChange}
                            name="Date_Format"
                            value={countDown?.Date_Format}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            <option value="1">Month/Day/Year</option>
                            <option value="2">Day/Month/Year</option>
                            <option value="3">Year/Month/Day</option>
                          </select>
                        </div>
                        <div className="mb-4 flex items-center justify-between">
                          <label
                            htmlFor="countries"
                            className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Units to Show
                          </label>

                          <select
                            onChange={handlInputeChange}
                            name="Unit_Show"
                            value={countDown?.Unit_Show}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            <option value="1">Days, hours and minutes</option>
                            <option value="2">Days and hours</option>
                            <option value="3">Only days</option>
                          </select>
                        </div>
                      </>
                    )}

                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Completion message*
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Party time!"
                        value={countDown?.Message}
                        name="Message"
                        onChange={handlInputeChange}
                      />
                    </div>
                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Completion icon*
                      </label>

                      <select
                        onChange={handlInputeChange}
                        name="Icon"
                        value={countDown?.Icon}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="Thumbs_up">Thumbs up</option>
                        <option value="Balloons">Balloons</option>
                        <option value="Smile">Smile</option>
                        <option value="Tick ">Tick</option>
                        <option value="Nothing">Nothing</option>
                      </select>
                    </div>
                  </div>

                  <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                    <div className="w-full h-full bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none ">
                      {countDown?.Message !== "" &&
                        countDown?.Year > 2018 &&
                        countDown?.Month <= 12 &&
                        countDown?.Month !== '' &&
                        countDown?.Day !== '' &&
                        countDown?.Day <= 31 &&
                        countDown?.Hour <= 24 &&
                        countDown?.Minute <= 59 ? (
                        !checktime ? (
                          <div className="w-full h-full bg-[#fdce0b] flex flex-col items-center justify-center">
                            {countDown?.Style === "Flip" && (
                              <>
                                <div >
                                  <p className=" flex items-center justify-center font-medium text-center text-[#2d313a] text-4xl">
                                    {countDown?.Title}
                                  </p>
                                  <p className="text-[#4b5563c2] text-center text-xl"> {date_format}</p>
                                </div>
                                <div className="flex space-x-4 mt-10">
                                  <div className="flip-clock-item">
                                    <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                      {String(result?.days).padStart(2, "0")}
                                    </div>
                                    <div className="text-center text-[#4b5563c2] text-lg uppercase font-semibold mt-3">
                                      Days
                                    </div>
                                  </div>

                                  {(countDown.Unit_Show === "1" ||
                                    countDown.Unit_Show === "2") && (
                                      <div className="flip-clock-item">
                                        <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                          {String(result?.hours).padStart(2, "0")}
                                        </div>
                                        <div className="text-center text-[#4b5563c2] text-lg uppercase font-semibold mt-3">
                                          Hours
                                        </div>
                                      </div>
                                    )}

                                  {countDown.Unit_Show === "1" && (
                                  <>
                                    <div className="flip-clock-item">
                                      <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                        {String(result?.minutes).padStart(2, "0")}
                                      </div>
                                      <div className="text-center text-[#4b5563c2] text-lg uppercase font-semibold mt-3">
                                        Minutes
                                      </div>
                                    </div>
                                    <div className="flip-clock-item">
                                      <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                        {String(result?.seconds).padStart(2, "0")}
                                      </div>
                                      <div className="text-center text-[#4b5563c2] text-lg uppercase font-semibold mt-3">
                                        Seconds
                                      </div>
                                    </div>
                                    </>
                                  )}
                                  {countDown.Unit_Show === "1" && (
                                    <div className="flip-clock-item">
                                      <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                        {String(result?.seconds).padStart(2, "0")}
                                      </div>
                                      <div className="text-center text-[#4b5563c2] text-lg uppercase font-semibold mt-3">
                                        Second
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                            {countDown?.Style === "Minimal" && (
                              <div className=" flex items-center w-full h-full ">
                                {result?.days > 0 && (
                                  <>
                                    <div className=" w-[40%] flex items-center justify-center h-full">
                                      <h1 style={{ fontSize: "150px" }}>
                                        {String(result?.days).padStart(2, "0")}
                                      </h1>
                                    </div>
                                    <div>
                                      <h1 className="flex items-center justify-center text-lg font-bold">
                                        DAYS LEFT UNTIL
                                      </h1>
                                      <h1 className=" text-xl">
                                        {countDown?.Title}
                                      </h1>
                                    </div>
                                  </>
                                )}
                                {result?.days === 0 && result?.hours > 0 && (
                                  <>
                                    <div className=" w-[40%] flex items-center justify-center h-full">
                                      <h1 className="text-[#2d313a] text-[150px] font-semibold">
                                        {String(result?.hours).padStart(2, "0")}
                                      </h1>
                                    </div>
                                    <div>
                                      <h1 className="flex items-center justify-center text-lg font-bold text-[#2d313a]">
                                        HOURS LEFT UNTIL
                                      </h1>
                                      <h1 className=" text-xl text-[#2d313a]">{countDown?.Title}</h1>
                                    </div>
                                  </>
                                )}
                                {result?.days === 0 &&
                                  result?.hours === 0 &&
                                  result?.minutes > 0 && (
                                    <>
                                      <div className=" w-[40%] flex items-center justify-center h-full">
                                        <h1 style={{ fontSize: "150px" }}>
                                          {String(result?.minutes).padStart(2, "0")}
                                        </h1>
                                      </div>
                                      <div>
                                        <h1 className="flex items-center justify-center text-lg font-bold">
                                          MINUTES LEFT UNTIL
                                        </h1>
                                        <h1 className=" text-xl">
                                          {countDown?.Title}
                                        </h1>
                                      </div>
                                    </>
                                  )}
                                {result?.days === 0 &&
                                  result?.hours === 0 &&
                                  result?.minutes === 0 && (
                                    <>
                                      <div className=" w-[40%] flex items-center justify-center h-full">
                                        <h1 style={{ fontSize: "150px" }}>
                                          {String(result?.seconds).padStart(2, "0")}
                                        </h1>
                                      </div>
                                      <div>
                                        <h1 className="flex items-center justify-center text-lg font-bold">
                                          SECONDS LEFT UNTIL
                                        </h1>
                                        <h1 className=" text-xl">
                                          {countDown?.Title}
                                        </h1>
                                      </div>
                                    </>
                                  )}

                                <div></div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="w-full h-full bg-[#fdce0b] flex  flex-col items-center justify-center text-center p-4">
                            <div className="h-16 w-16 bg-[#2d313a] rounded-full flex items-center justify-center mb-10">
                              {countDown?.Icon === 'Thumbs_up' && <FaThumbsUp size={35} className="text-[#fdce0b]" />}
                              {countDown?.Icon === 'Balloons' && <IoBalloonSharp size={35} className="text-[#fdce0b]" />}
                              {countDown?.Icon === 'Smile' && <FaSmile size={35} className="text-[#fdce0b]" />}
                              {countDown?.Icon === 'Tick ' && <IoCheckmarkCircle size={35} className="text-[#fdce0b]" />}
                            </div>
                            <p className="text-2xl font-semibold text-[#2d313a]">{countDown?.Message}</p>
                          </div>
                        )
                      ) : (
                        <div className="text-center">
                          <img src={DefaultIMG} alt="" className="w-52 mx-auto mb-5" />
                          <p className="text-gray-500">App instance preview</p>
                          <p className="text-sm text-gray-400">
                            Please edit the app settings to get started
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountDownTimerDetails;
