import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { darkTheme, lightTheme } from "../../Common/Common";
import { GoogleMap, LoadScript, TrafficLayer } from "@react-google-maps/api";
import axios from "axios";
import GoogleTraffic_Img from "../../../images/AppsImg/google-traffic.svg";

export const GoogleTrafficPreview = ({
  setInstanceView,
  instanceName,
  showTags,
  screenAssignName,
  previewKey,
  googleTrafficDataById,
}) => {
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [Loading, setLoading] = useState(false);

  const fetchLocation = async () => {
    if (googleTrafficDataById?.location) {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            googleTrafficDataById?.location
          )}&key=${googleTrafficDataById?.googleApikey}`
        );

        if (response.data.results.length > 0) {
          const { lat, lng } = response.data.results[0].geometry.location;
          setLocation({ lat, lng });
        } else {
          setLocation({ lat: 0, lng: 0 });
        }
      } catch (error) {
        console.error("Error fetching the location:", error);
        setLocation({ lat: 0, lng: 0 });
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchLocation();
  }, []);
  return (
    <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
      <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-[1000px] h-[600px]">
        <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
          <div className="flex items-center">
            <div>
              <img
                src={GoogleTraffic_Img}
                // src="https://app-registry-assets.screencloudapp.com/icons/img_app_icon_currency.png"
                alt="Logo"
                className="cursor-pointer mx-auto h-10 w-10"
              />
            </div>
            <div className="ml-3">
              <h4 className="text-lg font-medium whitespace-wrap ">
                {instanceName}
              </h4>
            </div>
          </div>
          <button
            className="p-1 text-3xl"
            onClick={() => setInstanceView(false)}
          >
            <AiOutlineCloseCircle />
          </button>
        </div>

        <LoadScript googleMapsApiKey={googleTrafficDataById?.googleApikey}>
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
            }}
          >
            {location.lat !== 0 && location.lng !== 0 ? (
              <GoogleMap
                center={location}
                zoom={parseInt(googleTrafficDataById?.zoomLevel)}
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                }}
                options={{
                  styles:
                    googleTrafficDataById?.theme === "true"
                      ? darkTheme
                      : lightTheme,
                  disableDefaultUI: false,
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  trafficLayer: true,
                }}
              >
                <TrafficLayer />
              </GoogleMap>
            ) : (
              <div className="flex items-center justify-center h-full w-full">
                <p>Enter Valid APi Key</p>
              </div>
            )}
            <h1
              className=""
              style={{
                position: "absolute",
                top: "85%",
                left: "10px",
                color: "#fff",
                zIndex: 1000,
              }}
            >
              Traffic: {googleTrafficDataById?.location}
            </h1>
          </div>
        </LoadScript>

        <div className="py-2 px-6">
          <div className="flex items-center gap-2 w-full">
            <div className="font-semibold w-fit">Tags:-</div>
            <div className=" w-full">{showTags}</div>
          </div>
          <div>
            <label className="font-semibold">Screen Assign :</label>
            {screenAssignName ? screenAssignName : " No Screen"}
          </div>
        </div>
      </div>
    </div>
  );
};
