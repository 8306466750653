
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TbAppsFilled } from "react-icons/tb";
import { MdArrowBackIosNew, MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin5Line, RiDeleteBinLine } from "react-icons/ri";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
//import EmbedImg from "../../images/Assets/Embed.png";
import Embed_Img from "../../images/AppsImg/embed.svg";
import axios from "axios";
import AddOrEditTagPopup from "../AddOrEditTagPopup";
import ScreenAssignModal from "../ScreenAssignModal";
import { Embed_ADD_TAG, Embed_ALL_DELETE, Embed_ASSIGN_SECREEN, Embed_SINGLE_DELETE, GET_All_EmbedData, GET_Embed_BY_ID } from "../../Pages/Api";
import { socket } from "../../App";
import toast from "react-hot-toast";
import { AiOutlineCloseCircle } from "react-icons/ai";
import EmbedPreview from "./EmbedPreview";



const Embed = ({ sidebarOpen, setSidebarOpen }) => {
  const { token, userDetails, user } = useSelector((state) => state.root.auth);
  const authToken = `Bearer ${token}`;
  const navigate = useNavigate();
  const appDropdownRef = useRef(null);
  const addScreenRef = useRef(null);
  const [selectAll, setselectAll] = useState(false);
  const [instanceID, setInstanceID] = useState();
  const [appDropDown, setAppDropDown] = useState(null);
  const [loading, setLoading] = useState(false);

  const [tags, setTags] = useState([]);
  const [instanceView, setInstanceView] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [updateTagEmbed, setUpdateTagEmbed] = useState(null);
  const [addScreenModal, setAddScreenModal] = useState(false);
  const [selectdata, setSelectData] = useState({});
  const [selectedScreens, setSelectedScreens] = useState([]);
  const [selectScreenModal, setSelectScreenModal] = useState(false);
  const [screenSelected, setScreenSelected] = useState([]);
  const [embedData, setEmbedData] = useState([])
  const [embedPreview, setEmbedPreview] = useState({});

  const handleSelectAll = () => {
    setselectAll((prev) => !prev);
  };

  const handleAppDropDownClick = (id) => {
    setInstanceID(id);
    if (appDropDown === id) {
      setAppDropDown(null);
    } else {
      setAppDropDown(id);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        appDropdownRef.current &&
        !appDropdownRef.current.contains(event?.target)
      ) {
        setAppDropDown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleCheckboxChange = (instanceId) => {
    const updatedInstance = embedData.map((embed) =>
      embed.embedAppId === instanceId
        ? {
          ...embed,
          isChecked: !embed.isChecked,
        }
        : embed
    );

    setEmbedData(updatedInstance);

    const allChecked = updatedInstance.every(
      (embed) => embed.isChecked
    );

    setselectAll(allChecked);
  };

  const FetchData = () => {
    setLoading(true);
    axios
      .get(GET_All_EmbedData, {
        headers: {
          Authorization: authToken,
        },
      })
      .then((response) => {
        const fetchedData = response.data.data;
        setLoading(false);
        setEmbedData(fetchedData);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching deleted data:", error);
      });
  };

  useEffect(() => {
    FetchData();
  }, []);

  const handleUpdateTagsEmbed = (tags) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Embed_ADD_TAG}?embedAppId=${updateTagEmbed?.embedAppId
        }&tags=${tags.length === 0 ? "" : tags}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.status == 200) {
          const updatedData = embedData.map((item) => {
            if (item?.embedAppId === updateTagEmbed?.embedAppId) {
              return { ...item, tags: tags };
            } else {
              return item;
            }
          });

          setEmbedData(updatedData);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const handleUpdateScreenAssign = (screenIds, macids) => {
    let idS = "";
    for (const key in screenIds) {
      if (screenIds[key] === true) {
        idS += `${key},`;
      }
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Embed_ASSIGN_SECREEN}?embedAppId=${instanceID}&screenId=${idS}`,
      headers: {
        Authorization: authToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status == 200) {
          const Params = {
            id: socket.id,
            connection: socket.connected,
            macId: macids,
          };
          socket.emit("ScreenConnected", Params);
          setTimeout(() => {
            toast.remove();
            setSelectScreenModal(false);
            setAddScreenModal(false);
            FetchData()
          }, 1000);
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handelDeleteInstance = (id) => {
    if (!window.confirm("Are you sure?")) return;
    let data = JSON.stringify({
      embedAppId: id,
      operation: "Delete",
    });
    let config = {
      method: "post",
      url: `${Embed_SINGLE_DELETE}?embedAppId=${id}`,
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    toast.loading("Deleting...");
    axios
      .request(config)
      .then((response) => {
        const updatedInstanceData = embedData.filter(
          (instanceData) => instanceData.embedAppId !== id
        );
        setEmbedData(updatedInstanceData);
      })
      .catch((error) => {
        toast.remove();
        console.log(error);
      });
  };

  const handelDeleteAllInstance = () => {
    if (!window.confirm("Are you sure?")) return;
    const data = {};
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: Embed_ALL_DELETE,
      headers: {
        Authorization: authToken,
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setselectAll(false);
        setEmbedData([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleFetchEmbedById = (id) => {
    toast.loading("Fetching Data....");
    if (id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${GET_Embed_BY_ID}?embedAppId=${id}`,
        headers: {
          Authorization: authToken,
        },
      };
      axios.request(config).then((response) => {
        toast.dismiss();
        const data = response?.data?.data;
        const regex = /<iframe.*?src=["'](.*?)["'].*?>/;
        const match = data?.embedCode ? data?.embedCode.match(regex) : '';
        if (match && match[1]) {
          setEmbedPreview({
            ...data,
            screenAssignName: data?.screens,
            embedUrl: match[1]
          });
        }
        setInstanceView(true);
      });
    }
  }



  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial && !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 pt-10 px-5"
            : "lg:pt-24 md:pt-24 pt-10 px-5 "
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="lg:flex lg:justify-between sm:block items-center">
            <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
              Apps
            </h1>
            <div className="lg:flex">
              <Link to="/Embeddetail">
                <button className="flex items-center bg-SlateBlue text-white rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2 text-base sm:text-sm mr-3 hover:bg-primary">
                  <TbAppsFilled className="text-2xl mr-2" />
                  New Instance
                </button>
              </Link>
              <Link to="/apps">
                <button className="flex items-center bg-SlateBlue text-white rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2 text-base sm:text-sm mr-3 hover:bg-primary">
                  <MdArrowBackIosNew className="text-2xl mr-2" />
                  Back
                </button>
              </Link>
            </div>
          </div>
          <div className="mt-5 mb-5">
            <div className="shadow-md bg-white rounded-lg p-5">
              <div className="flex justify-between items-center">
                <h1 className="font-medium text-xl text-[#001737] ">Embed</h1>
                <div className="flex items-center">
                  <button
                    onClick={handelDeleteAllInstance}
                    style={{ display: selectAll ? "block" : "none" }}
                    className="w-8 h-8 ml-2 border-primary items-center border-2 rounded-full px-1 text-2xl hover:bg-SlateBlue">
                    <RiDeleteBinLine className="text-xl" />
                  </button>
                  {embedData?.length > 0 && (
                    <button className="sm:ml-2 xs:ml-1 mt-1">
                      <input
                        type="checkbox"
                        className="h-7 w-7"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </button>
                  )}
                </div>
              </div>

              {loading ? (
                <div className="flex text-center m-5 justify-center">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-10 h-10 me-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                </div>
              ) : embedData?.length > 0 ? (
                <div className=" grid grid-cols-12 gap-4 mt-5">
                  {embedData?.map((item) => (
                    <div
                      key={""}
                      className="xl:col-span-2 lg:col-span-3 md:col-span-4 sm:col-span-12"
                    >
                      <div className="shadow-md bg-[#EFF3FF] rounded-lg h-full">
                        <div className="relative flex justify-between">
                          <button className="float-right p-2">
                            <input
                              className="h-5 w-5"
                              type="checkbox"
                              style={{
                                display: selectAll ? "block" : "none",
                              }}
                              checked={true}
                              onChange={() =>
                                handleCheckboxChange(item?.embedAppId)
                              }
                            />
                          </button>
                          <div className="relative">
                            <button className="float-right">
                              <BiDotsHorizontalRounded
                                className="text-2xl"
                                onClick={() =>
                                  handleAppDropDownClick(
                                    item?.embedAppId
                                  )
                                }
                              />
                            </button>
                            {appDropDown === item.embedAppId && (
                              <div ref={appDropdownRef} className="appdw">
                                <ul className="space-y-2">
                                  <li
                                    onClick={() =>
                                      navigate(
                                        `/Embeddetail/${item?.embedAppId}`
                                      )
                                    }
                                    className="flex text-sm items-center cursor-pointer"
                                  >
                                    <MdOutlineEdit className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                    Edit
                                  </li>
                                  <li
                                    className="flex text-sm items-center cursor-pointer"
                                    onClick={() => {
                                      setAddScreenModal(true);

                                      setSelectData(item);
                                    }}
                                  >
                                    <FiUpload className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                    Set to Screen
                                  </li>
                                  {/* <li className="flex text-sm items-center">
                                    <MdPlaylistPlay className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                    Add to Playlist
                                  </li> */}

                                  <li
                                    className="flex text-sm items-center cursor-pointer"
                                    onClick={() =>
                                      handelDeleteInstance(
                                        item?.embedAppId
                                      )
                                    }
                                  >
                                    <RiDeleteBin5Line className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                    Delete
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-center clear-both pb-8">
                          <img
                            src={Embed_Img}
                            alt="Logo"
                            className="cursor-pointer mx-auto h-20 w-20"
                            onClick={() => {
                              handleFetchEmbedById(item?.embedAppId);

                            }}
                          />
                          <h4 className="text-lg font-medium mt-3">
                            {item.instanceName}
                          </h4>
                          <h4
                            onClick={() => {
                              item?.tags !== null &&
                                item?.tags !== undefined &&
                                item?.tags !== ""
                                ? setTags(item?.tags?.split(","))
                                : setTags([]);
                              setShowTagModal(true);
                              setUpdateTagEmbed(item);
                            }}
                            className="text-sm font-normal cursor-pointer"
                          >
                            Add tags +
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No Embed data available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {showTagModal && (
        <AddOrEditTagPopup
          setShowTagModal={setShowTagModal}
          tags={tags}
          setTags={setTags}
          handleUpdateTagsEmbed={handleUpdateTagsEmbed}
          from="embed"
          setUpdateTagEmbed={setUpdateTagEmbed}
        />
      )}

      {addScreenModal && (
        <div className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-9990 outline-none focus:outline-none">
          <div
            ref={addScreenRef}
            className="w-auto my-6 mx-auto lg:max-w-4xl md:max-w-xl sm:max-w-sm xs:max-w-xs"
          >
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-4 px-6 border-b border-[#A7AFB7] rounded-t text-black">
                <div className="flex items-center">
                  <h3 className="lg:text-lg md:text-lg sm:text-base xs:text-sm font-medium">
                    Select the screen to set the App
                  </h3>
                </div>
                <button
                  className="p-1 text-xl ml-8"
                  onClick={() => setAddScreenModal(false)}
                >
                  <AiOutlineCloseCircle className="text-2xl" />
                </button>
              </div>
              <div className="flex justify-center p-9 ">
                <p className="break-words w-[280px] text-base text-black text-center">
                  New Embed App Instance would be applied. Do you want to
                  proceed?
                </p>
              </div>
              <div className="pb-6 flex justify-center">
                <button
                  className="bg-primary text-white px-8 py-2 rounded-full"
                  onClick={() => {
                    if (selectdata?.screenIDs) {
                      let arr = [selectdata?.screenIDs];
                      let newArr = arr[0]
                        .split(",")
                        .map((item) => parseInt(item.trim()));
                      setSelectedScreens(newArr);
                    }
                    setSelectScreenModal(true);
                    setAddScreenModal(false);
                  }}
                >
                  OK
                </button>

                <button
                  className="bg-primary text-white px-4 py-2 rounded-full ml-3"
                  onClick={() => setAddScreenModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* add screen modal end */}
      {selectScreenModal && (
        <ScreenAssignModal
          setAddScreenModal={setAddScreenModal}
          setSelectScreenModal={setSelectScreenModal}
          handleUpdateScreenAssign={handleUpdateScreenAssign}
          selectedScreens={selectedScreens}
          setSelectedScreens={setSelectedScreens}
          screenSelected={screenSelected}
          sidebarOpen={sidebarOpen}
        />
      )}
      {instanceView && (
        <EmbedPreview
          setInstanceView={setInstanceView}
          embedPreview={embedPreview}
        />
      )}
    </>
  )
}

export default Embed