import { QRCodeSVG } from 'qrcode.react';
import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import BuildingDirectoryIMG from '../../../images/AppsImg/building-directory.svg'

export default function BuildingDirectoryPreview({ setInstanceView, buildingDirectoryData }) {
  return (
    <div className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-9990 outline-none focus:outline-none">
      <div >
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
          <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
            <div className="flex items-center">
              <div>
                <img src={BuildingDirectoryIMG} className="w-10" alt='NoticeBoard' />
              </div>
              <div className="ml-3">
                <h4 className="text-lg font-medium">
                  {buildingDirectoryData?.instanceName}
                </h4>
              </div>
            </div>
            <button
              className="p-1 text-3xl"
              onClick={() => {
                setInstanceView(false);
              }}
            >
              <AiOutlineCloseCircle />
            </button>
          </div>

          <div className="md:w-[576px] md:h-[320px] sm:w-[384px] sm:h-[210px] lg:w-[960px] lg:h-[530px] w-72 h-72 flex items-center">
            <div className="w-full h-full flex flex-col lg:flex-row bg-white shadow-lg rounded-lg ">
              <div className="h-full w-full  bg-gray-50 rounded-sm shadow-md border-4 bg-slate-800 border-black flex items-center justify-center vertical-scroll-inner">
                <div className="bg-gray-800 h-full w-full p-5">
                  <div className="flex justify-between items-center mb-3">
                    <h1 className="text-white text-3xl">
                      {buildingDirectoryData?.directoryName}
                    </h1>
                    {(buildingDirectoryData?.appAssetURL) && (
                      <img
                        src={buildingDirectoryData?.appAssetURL}
                        alt="Logo"
                        className="w-20 h-20 object-cover rounded"
                      />
                    )}

                    <div className="text-white text-right mb-8">
                      <p className="mb-2">{buildingDirectoryData?.qrTitle}</p>
                      {buildingDirectoryData?.QR_Url && (
                        <div className="bg-white rounded-lg shadow-md flex items-center justify-center w-auto h-auto">
                          <QRCodeSVG
                            value={buildingDirectoryData?.qrurl}
                            size={150}
                            fgColor="#000000"
                            bgColor="#ffffff"
                            className="p-4"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 ">

                    {buildingDirectoryData?.floors?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`mb-5 p-5 ${item.isHighlight ? "bg-[#eabf00]" : ""}  w-full gap-3`}>
                          <div>
                            <h2 className="text-white text-xl mb-4">
                              {item?.floorName}
                            </h2>
                            <hr className="text-[#cdcdcd]"></hr>
                            {item?.rooms?.map((items, index) => {
                              return (
                                <div key={index} >
                                  <div className="flex justify-between items-center bg-gray-700 p-4 rounded-lg ">
                                    <div className="flex items-center space-x-4">
                                      {(items?.roomAssetURL) && (
                                        <img
                                          src={items?.Logo?.assetFolderPath || items?.roomAssetURL}
                                          alt="Logo"
                                          className="w-12 h-12 object-cover rounded"
                                        />
                                      )}
                                      <p className="text-white">{items?.roomName}</p>
                                    </div>
                                    {items?.roomNo && (
                                      <h2 className={`font-semibold px-4 py-2 rounded-md ${item?.isHighlight ? 'bg-[#2e323b] text-[#eabf00]' : 'bg-[#eabf00] text-[#2e323b]'}`}>{items?.roomNo}</h2>
                                    )}
                                  </div>
                                  <hr className="text-[#cdcdcd]" />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-6">
            <div className="flex items-center gap-2 w-full">
              <div className="font-semibold w-fit">Tags:-</div>
              <div className=" w-full">
                {buildingDirectoryData?.tags}
              </div>
            </div>
            <div>
              <label className="font-semibold">
                Screen Assign :
              </label>
              {buildingDirectoryData?.ScreenAssignName == ""
                ? " No Screen"
                : buildingDirectoryData?.ScreenAssignName}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
