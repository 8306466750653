import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getPageScrollById,
  handleAddPageScroll,
} from "../../../Redux/AppsSlice";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import moment from "moment";
import { MdSave } from "react-icons/md";
import { GoPencil } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";

export const PageScrollDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, userDetails, token } = useSelector((state) => state.root.auth);
  const authToken = `Bearer ${token}`;
  const [edited, setEdited] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [instanceName, setInstanceName] = useState(
    moment().format("YYYY-MM-DD hh:mm A")
  );
  const [loading, setLoading] = useState(false);
  const [pageScroll, setPageScroll] = useState({
    Url: "",
    ScrollSpeed: "",
    PageHeight: "",
    DisplayUrl: Boolean(false),
  });
  const { id } = useParams();

  const scrollValue = useMemo(() => {
    switch (pageScroll?.ScrollSpeed) {
      case "verySlow":
        return 100;
      case "slow":
        return 200;
      case "normal":
        return 250;
      case "fast":
        return 500;
      case "veryFast":
        return 1000;
      default:
        return 150;
    }
  }, [pageScroll?.ScrollSpeed]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setPageScroll((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleDisplayChange = (e) => {
    const { name, checked } = e.target;
    setPageScroll((pre) => ({
      ...pre,
      [name]: checked,
    }));
  };
  const handleOnSaveInstanceName = () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    setEdited(false);
  };

  const onSubmit = async () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    let Payload = {
      pageScrollAppId: id ? id : 0,
      instanceName: instanceName,
      pageHeightPixels: pageScroll?.PageHeight,
      scrollSpeed: pageScroll?.ScrollSpeed,
      url: pageScroll?.Url,
      isDisplayURL: pageScroll?.DisplayUrl,
    };
    toast.loading("Saving ...");
    setSaveLoading(true);
    try {
      await dispatch(handleAddPageScroll(Payload)).then((res) => {
        toast.remove();
        setSaveLoading(false);
        navigate("/Page-Scroll");
      });
    } catch (error) {
      console.log("error :>> ", error);
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    if(id){
      dispatch(getPageScrollById(id))
        .then((response) => {
          if (response?.payload?.status === 200) {
            const data = response?.payload?.data?.list;
            setPageScroll((pre) => ({
              Url: data?.url,
              ScrollSpeed: data?.scrollSpeed,
              PageHeight: data?.pageHeightPixels,
              DisplayUrl: data?.isDisplayURL,
            }));
            setInstanceName(data?.instanceName);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [id, dispatch]);

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
            user?.userDetails?.isRetailer === false &&
            !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div className="lg:flex lg:justify-between sm:block my-4 items-center">
              <div className="flex items-center">
                {edited ? (
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      className="w-full border border-primary rounded-md px-2 py-1"
                      placeholder="Enter Instance name"
                      value={instanceName}
                      onChange={(e) => {
                        setInstanceName(e.target.value);
                      }}
                    />
                    <MdSave
                      onClick={() => handleOnSaveInstanceName()}
                      className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                      {instanceName}
                    </h1>
                    <button onClick={() => setEdited(true)}>
                      <GoPencil className="ml-4 text-lg" />
                    </button>
                  </div>
                )}
              </div>
              <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                <button
                  className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                  onClick={() => {
                    if (
                      pageScroll.Url &&
                      pageScroll.ScrollSpeed &&
                      pageScroll.PageHeight
                    )
                      return setShowPreview(!showPreview);
                    toast.error("Please Enter All Value");
                  }}
                >
                  {showPreview ? "Edit" : "Preview"}
                </button>
                <button
                  className="flex  align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                  onClick={() => {
                    if (
                      pageScroll.Url &&
                      pageScroll.ScrollSpeed &&
                      pageScroll.PageHeight
                    )
                      return onSubmit();
                    toast.error("Please Enter All Value");
                  }}
                  disabled={saveLoading}
                >
                  {saveLoading ? "Saving..." : "Save"}
                </button>
                <Link to="/Page-Scroll">
                  <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                    <AiOutlineClose />
                  </button>
                </Link>
              </div>
            </div>

            {showPreview ? (
              <div className="w-full h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center mt-5">
                <iframe
                  className="w-full h-full"
                  src={pageScroll?.Url}
                  allow="autoplay; fullscreen; clipboard-write;"
                ></iframe>
              </div>
            ) : (
              <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      URL*
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      name="Url"
                      placeholder="Url"
                      value={pageScroll.Url}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="message"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Page Height (pixels)*
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="number"
                      placeholder="e.g. 3000"
                      name="PageHeight"
                      value={pageScroll?.PageHeight}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="message"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Scroll Speed*
                    </label>
                    <select
                      id="countries"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="ScrollSpeed"
                      value={pageScroll.ScrollSpeed}
                      onChange={handleChange}
                    >
                      {/* <option value="" disabled>Select Scroll Speed</option> */}
                      <option value="verySlow">Very Slow</option>
                      <option value="slow">Slow</option>
                      <option value="normal">Normal</option>
                      <option value="fast">Fast</option>
                      <option value="veryFast">Very Fast</option>
                    </select>
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Issue displaying URL?
                    </label>
                    <div className="text-right">
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          readOnly
                          name="DisplayUrl"
                          checked={pageScroll?.DisplayUrl}
                          onChange={handleDisplayChange}
                        />
                        <div
                          className={`w-11 h-6 ${pageScroll?.DisplayUrl
                            ? "bg-SlateBlue"
                            : "bg-gray-300"
                            } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                        ></div>
                        <div className="relative inline-block">
                          <button className="ml-2 text-gray-500 relative group">
                            ⓘ
                            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 -mb-1 w-56 bg-gray-700 text-white bg-black text-xs rounded-lg py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10 pointer-events-none">
                              if enabled subtitles will be show when
                              possible.Not all sources provide subtitle
                            </span>
                          </button>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                  <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none">
                    {pageScroll?.Url &&
                      pageScroll?.ScrollSpeed &&
                      pageScroll?.PageHeight ? (
                      loading ? (
                        ""
                      ) : (
                        <div className="md:w-[576px] md:h-[324px] sm:w-[384px] sm:h-[216px] lg:w-[860px] lg:h-[440px] w-72 h-72 flex items-center justify-center overflow-hidden scrollbar-hide ">
                          <iframe
                            className="w-full h-full"
                            src={pageScroll?.Url}
                            allow="autoplay; fullscreen; clipboard-write;"
                          ></iframe>
                        </div>
                      )
                    ) : (
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-16 w-16 mx-auto text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M3 7V3h18v4M4 10h16v4H4v-4z"
                          />
                        </svg>
                        <p className="text-gray-500">App instance preview</p>
                        <p className="text-sm text-gray-400">
                          Please edit the app settings to get started
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
