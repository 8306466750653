
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TbAppsFilled } from "react-icons/tb";
import { MdArrowBackIosNew, MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin5Line, RiDeleteBinLine } from "react-icons/ri";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import { deleteCurrency, getCurrencyApps, getCurrencyById } from "../../../Redux/AppsSlice";
import Loading from "../../Loading";
import SweetAlert from "../../BookYourSlot/SweetAlert";
import CurrencyImg from "../../../images/AppsImg/currencies.svg"
import toast from "react-hot-toast";
import { ADD_CURRENICE_TAGS, ALL_DELETE_CURRENICE, ASSIGN_CURRENICE_SCREEN } from "../../../Pages/Api";
import axios from "axios";
import AddOrEditTagPopup from "../../AddOrEditTagPopup";
import CurrenciesPreview from "./CurrenciesPreview";
import { countryList } from "../../Common/Common";
import ScreenAssignModal from "../../ScreenAssignModal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { socket } from "../../../App";


export default function CurrencyApp({ sidebarOpen, setSidebarOpen }) {
    const { userDetails, token } = useSelector((state) => state.root.auth);
    const authToken = `Bearer ${token}`;

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const appDropdownRef = useRef(null);
    const [selectAll, setselectAll] = useState(false);
    const [instanceID, setInstanceID] = useState();
    const [appDropDown, setAppDropDown] = useState(null);
    const [Currencies, setCurrencies] = useState([]);
    const [sidebarload, setSidebarLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadFirst, setloadFirst] = useState(true);
    const [showTagModal, setShowTagModal] = useState(false);
    const [tags, setTags] = useState([]);
    const [updateTagCurrencies, setUpdateTagCurrencies] = useState(null);
    const [instanceView, setInstanceView] = useState(false);
    const [editCurrencies, setEditCurrencies] = useState({});
    const [selectScreenModal, setSelectScreenModal] = useState(false);
    const [addScreenModal, setAddScreenModal] = useState(false);
    const [selectedScreens, setSelectedScreens] = useState([]);
    const [selectdata, setSelectData] = useState({});
    const [screenSelected, setScreenSelected] = useState([]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                appDropdownRef.current &&
                !appDropdownRef.current.contains(event?.target)
            ) {
                setAppDropDown(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (loadFirst) {
            setSidebarLoad(true)
            dispatch(getCurrencyApps({})).then((res) => {
                setCurrencies(res?.payload?.data)
                setSidebarLoad(false)
                setLoading(false)
            })
            setloadFirst(false)
        }
    }, [dispatch, loadFirst]);

    const handleSelectAll = () => {
        const updatedInstance = Currencies.map((item) => ({
            ...item,
            isChecked: !selectAll,
        }));
        setCurrencies(updatedInstance);
        setselectAll(!selectAll);
    };


    const handleCheckboxChange = (instanceId) => {
        const updatedInstance = Currencies?.map((item) =>
            item.currenciesAppId === instanceId
                ? { ...item, isChecked: !item.isChecked, }
                : item
        );

        setCurrencies(updatedInstance);
        const allChecked = updatedInstance.every((date) => date.isChecked);
        setselectAll(allChecked);
    };

    const handleAppDropDownClick = (id) => {
        setInstanceID(id);
        if (appDropDown === id) {
            setAppDropDown(null);
        } else {
            setAppDropDown(id);
        }
    };

    const handlerDelete = async (id) => {
        try {
            const result = await SweetAlert.confirm("Are you sure?", "Are you sure you want to delete this!");
            if (result?.isConfirmed) {
                dispatch(deleteCurrency(id)).then((res) => {
                    if (res?.payload?.status === true) {
                        setloadFirst(true)
                        SweetAlert.success("Deleted successfully");
                    }
                });
            }
        } catch (error) {
            console.error("Error:", error);
            SweetAlert.error("An error occurred");
        }
    }

    const handelDeleteAllInstance = () => {
        if (!window.confirm("Are you sure?")) return;
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: ALL_DELETE_CURRENICE,
            headers: { Authorization: authToken },
        };

        toast.loading("Deleting...");
        axios
            .request(config)
            .then(() => {
                toast.remove();
                setselectAll(false);
                setCurrencies([]);
            })
            .catch((error) => {
                toast.remove();
                console.log(error);
            });
    };

    const handleUpdateTagsCurrencies = (tags) => {

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${ADD_CURRENICE_TAGS}?currenciesAppId=${updateTagCurrencies?.currenciesAppId}&Tags=${tags.length === 0 ? "" : tags}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: authToken,
            },
        };

        axios
            .request(config)
            .then((response) => {
                if (response?.data?.status === 200) {
                    const updatedData = Currencies.map((item) => {
                        if (item?.currenciesAppId === updateTagCurrencies?.currenciesAppId) {
                            return { ...item, tags: tags };
                        } else {
                            return item;
                        }
                    });
                    setCurrencies(updatedData);
                }
            }).catch((error) => {
                console.log(error);
            });
    };


    const fetchCurrenciesbyID = (id) => {
        toast.loading("Fetching Data....");
        dispatch(getCurrencyById(id)).then((res) => {
            if (res?.payload?.status === 200) {
                const fetchData = res?.payload?.data
                setScreenSelected(fetchData?.list?.screens?.split(","));
                setTimeout(() => {
                    toast.dismiss();
                    convertCurrency(fetchData)
                    setInstanceView(true);
                }, 1000);
                setEditCurrencies(fetchData)
            }
        }).catch((error) => {
            console.log('error :>> ', error);
            toast.remove();
        })
    }

    const convertCurrency = async (EditData) => {
        try {
            const response = await fetch(
                `https://api.exchangerate-api.com/v4/latest/${EditData?.baseCurrency?.toUpperCase()}`
            );
            const data = await response.json();
            const responseMetadata = await fetch('https://openexchangerates.org/api/currencies.json');
            const dataMetadata = await responseMetadata.json();

            const results = EditData?.convertCurrency?.split(",")
                .map((currency) => currency.trim())
                .filter((currency) => currency !== "")
                .map((currency) => {
                    const rate = data?.rates[currency?.toUpperCase()];
                    const result = rate ? EditData?.unit * rate : 0;
                    const currencyName = dataMetadata[currency.toUpperCase()] || currency;
                    return {
                        currencyName: currencyName,
                        currencyCode: currency?.toUpperCase(),
                        total: result,
                        flagUrl: `https://flagsapi.com/${countryList[currency.toUpperCase()]}/flat/64.png`,
                    };
                });
            setEditCurrencies((pre) => ({
                ...pre,
                baseCurrencyName: dataMetadata[EditData?.baseCurrency?.toUpperCase()],
                Currencies: results,
            }));
        } catch (error) {
            console.error("Error during currency conversion:", error);
        }
    };

    const handleUpdateScreenAssign = (screenIds, macIds) => {
        let ids = "";
        for (const key in screenIds) {
            if (screenIds[key] === true) {
                ids += `${key},`;
            }
        }
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${ASSIGN_CURRENICE_SCREEN}?currenciesAppId=${instanceID}&screenId=${ids}`,
            headers: {
                Authorization: authToken,
            },
        };
        toast.loading("Saving...");

        axios
            .request(config)
            .then((response) => {
                if (response.data.status === 200) {
                    toast.remove()
                    const Params = {
                        id: socket.id,
                        connection: socket.connected,
                        macId: macIds,
                    };
                    socket.emit("ScreenConnected", Params);
                }
                setSelectScreenModal(false);
                setAddScreenModal(false);
                setloadFirst(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            {sidebarload && <Loading />}
            <div className="flex border-b border-gray">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <Navbar />
            </div>

            <div
                className={
                    userDetails?.isTrial && !userDetails?.isActivePlan
                        ? "lg:pt-32 md:pt-32 pt-10 px-5"
                        : "lg:pt-24 md:pt-24 pt-10 px-5 "
                }
            >
                <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
                    <div className="lg:flex lg:justify-between sm:block items-center">
                        <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                            Apps
                        </h1>
                        <div className="lg:flex">
                            <Link to="/CurrenciesDetails">
                                <button className="flex items-center bg-SlateBlue text-white rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2 text-base sm:text-sm mr-3 hover:bg-primary">
                                    <TbAppsFilled className="text-2xl mr-2" />
                                    New Instance
                                </button>
                            </Link>
                            <Link to="/apps">
                                <button className="flex items-center bg-SlateBlue text-white rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2 text-base sm:text-sm mr-3 hover:bg-primary">
                                    <MdArrowBackIosNew className="text-2xl mr-2" />
                                    Back
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="mt-5 mb-5">
                        <div className="shadow-md bg-white rounded-lg p-5">
                            <div className="flex justify-between items-center">
                                <h1 className="font-medium text-xl text-[#001737] "> Currencies</h1>
                                <div className="flex items-center">
                                    {selectAll && (
                                        <button className="w-8 h-8 ml-2 border-primary items-center border-2 rounded-full px-1 text-2xl hover:bg-SlateBlue" onClick={handelDeleteAllInstance}>
                                            <RiDeleteBinLine className="text-xl" />
                                        </button>
                                    )}
                                    {Currencies?.length > 0 && (
                                        <button className="sm:ml-2 xs:ml-1 mt-1">
                                            <input
                                                type="checkbox"
                                                className="h-7 w-7 cursor-pointer"
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                            />
                                        </button>
                                    )}
                                </div>
                            </div>

                            {loading ? (
                                <div className="flex text-center m-5 justify-center">
                                    <svg
                                        aria-hidden="true"
                                        role="status"
                                        className="inline w-10 h-10 me-3 text-gray-200 animate-spin dark:text-gray-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="#1C64F2"
                                        />
                                    </svg>
                                </div>
                            ) : Currencies && Currencies?.length > 0 ? (
                                <div className=" grid grid-cols-12 gap-4 mt-5">
                                    {Currencies?.map((item, index) => (
                                        <div
                                            key={index}
                                            className="xl:col-span-2 lg:col-span-3 md:col-span-4 sm:col-span-12"
                                        >
                                            <div className="shadow-md bg-[#EFF3FF] rounded-lg h-full">
                                                <div className="relative flex justify-between">
                                                    <button className="float-right p-2">
                                                        <input
                                                            className="h-5 w-5 cursor-pointer"
                                                            type="checkbox"
                                                            style={{
                                                                display: selectAll ? "block" : "none",
                                                            }}
                                                            checked={item.isChecked || false}
                                                            onChange={() => handleCheckboxChange(item?.currenciesAppId)}

                                                        />
                                                    </button>
                                                    <div className="relative">
                                                        <button className="float-right">
                                                            <BiDotsHorizontalRounded
                                                                className="text-2xl"
                                                                onClick={() => handleAppDropDownClick(item?.currenciesAppId)}
                                                            />
                                                        </button>
                                                        {appDropDown === item?.currenciesAppId && (
                                                            <div ref={appDropdownRef} className="appdw">
                                                                <ul className="space-y-2">
                                                                    <li
                                                                        onClick={() => navigate(`/CurrenciesDetails/${item?.currenciesAppId}`)}
                                                                        className="flex text-sm items-center cursor-pointer"
                                                                    >
                                                                        <MdOutlineEdit className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                                                        Edit
                                                                    </li>
                                                                    <li
                                                                        className="flex text-sm items-center cursor-pointer"
                                                                        onClick={() => {
                                                                            setAddScreenModal(true);
                                                                            setSelectData(item);
                                                                        }}
                                                                    >
                                                                        <FiUpload className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                                                        Set to Screen
                                                                    </li>
                                                                    <li
                                                                        className="flex text-sm items-center cursor-pointer"
                                                                        onClick={() =>
                                                                            handlerDelete(item?.currenciesAppId)
                                                                        }
                                                                    >
                                                                        <RiDeleteBin5Line className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                                                        Delete
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="text-center clear-both pb-8">
                                                    <img
                                                        src={CurrencyImg}
                                                        alt="Logo"
                                                        className="cursor-pointer mx-auto h-20 w-20"
                                                        onClick={() => {
                                                            fetchCurrenciesbyID(item?.currenciesAppId)

                                                        }}
                                                    />
                                                    <h4 className="text-lg font-medium mt-3">
                                                        {item?.instanceName}
                                                    </h4>
                                                    <h4
                                                        onClick={() => {
                                                            item?.tags !== null &&
                                                                item?.tags !== undefined &&
                                                                item?.tags !== ""
                                                                ? setTags(item?.tags?.split(","))
                                                                : setTags([]);
                                                            setShowTagModal(true);
                                                            setUpdateTagCurrencies(item);
                                                        }}
                                                        className="text-sm font-normal cursor-pointer"
                                                    >
                                                        Add tags +
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="text-center">No Currencies data available.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div >

            {addScreenModal && (
                <div className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-9990 outline-none focus:outline-none">
                    <div
                        className="w-auto my-6 mx-auto lg:max-w-4xl md:max-w-xl sm:max-w-sm xs:max-w-xs"
                    >
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-4 px-6 border-b border-[#A7AFB7] border-slate-200 rounded-t text-black">
                                <div className="flex items-center">
                                    <h3 className="lg:text-lg md:text-lg sm:text-base xs:text-sm font-medium">
                                        Select the screen to set the App
                                    </h3>
                                </div>
                                <button
                                    className="p-1 text-xl ml-8"
                                    onClick={() => setAddScreenModal(false)}
                                >
                                    <AiOutlineCloseCircle className="text-2xl" />
                                </button>
                            </div>
                            <div className="flex justify-center p-9 ">
                                <p className="break-words w-[280px] text-base text-black text-center">
                                    New Currencies App Instance would be applied. Do you want to
                                    proceed?
                                </p>
                            </div>
                            <div className="pb-6 flex justify-center">
                                <button
                                    className="bg-primary text-white px-8 py-2 rounded-full"
                                    onClick={() => {
                                        if (selectdata?.screenIDs) {
                                            let arr = [selectdata?.screenIDs];
                                            let newArr = arr[0]
                                                .split(",")
                                                .map((item) => parseInt(item.trim()));
                                            setSelectedScreens(newArr);
                                        }
                                        setSelectScreenModal(true);
                                        setAddScreenModal(false);
                                    }}
                                >
                                    OK
                                </button>

                                <button
                                    className="bg-primary text-white px-4 py-2 rounded-full ml-3"
                                    onClick={() => setAddScreenModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showTagModal && (
                <AddOrEditTagPopup
                    setShowTagModal={setShowTagModal}
                    tags={tags}
                    setTags={setTags}
                    handleUpdateTagsCurrencies={handleUpdateTagsCurrencies}
                    from="Currencies"
                    setUpdateTagCurrencies={setUpdateTagCurrencies}
                />
            )}

            {instanceView && (
                <CurrenciesPreview
                    setInstanceView={setInstanceView}
                    editCurrencies={editCurrencies}
                />
            )}
            {selectScreenModal && (
                <ScreenAssignModal
                    setAddScreenModal={setAddScreenModal}
                    setSelectScreenModal={setSelectScreenModal}
                    handleUpdateScreenAssign={handleUpdateScreenAssign}
                    selectedScreens={selectedScreens}
                    setSelectedScreens={setSelectedScreens}
                    screenSelected={screenSelected}
                    sidebarOpen={sidebarOpen}
                />
            )}
        </>
    )
}

