import React, { useState } from 'react'
import ReportDialog from '../../Components/Reports/ReportDialog'
import AdminSidebar from '../AdminSidebar'
import AdminNavbar from '../AdminNavbar'
import { TbFileReport } from 'react-icons/tb'

export default function Reports({ sidebarOpen, setSidebarOpen }) {

    const [selectedReport, setSelectedReport] = useState(null);
    const [modelVisible, setModelVisible] = useState(false);

    const handleReportClick = (report) => {
        setSelectedReport(report);
        setModelVisible(true);
    };

    const toggleModal = () => {
        setModelVisible(!modelVisible);
    };


    return (
        <>
            <div className="flex border-b border-gray">
                <AdminSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <AdminNavbar />
            </div>
            <div className={"lg:pt-24 md:pt-24 pt-10 px-5 page-contain"}>
                <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
                    <div className="lg:flex lg:justify-between sm:block xs:block  items-center">
                        <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                            Reports
                        </h1>
                    </div>

                    <div className="grid grid-cols-12 gap-4 mt-5">
                        <div
                            className="lg:col-span-4 md:col-span-4 sm:col-span-6 xs:col-span-12 text-center drop-shadow-md flex flex-col bg-white rounded-xl p-5 lg:h-56 md:h-72 h-56 cursor-pointer"
                            onClick={() => handleReportClick("bookslotreports")}
                        >
                            <div className="reportbox text-center ">
                                <div>
                                    <TbFileReport className="lg:text-7xl md:text-7xl sm:text-6xl xs:text-7xl text-primary text-center mx-auto bg-white rounded-2xl lg:p-2 md:p-2 sm:p-2 xs:p-2 drop-shadow-xl  " />
                                </div>
                                <div>
                                    <h3 className="text-base font-medium  lg:mt-5 md:mt-5 sm:mt-3 xs:mt-3 mb-2">
                                        Bookslot Reports
                                    </h3>
                                </div>
                                <div>
                                    <p className="text-sm font-normal max-w-[250px] mx-auto">
                                        The report shows the number of times the asset file has been played.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modelVisible && (
                <ReportDialog
                    toggleModal={toggleModal}
                    selectedReport={selectedReport}
                />
            )}

        </>
    )
}
