import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { WORLD_CLOCK_ADD, WORLD_CLOCK_ADD_TAGS, WORLD_CLOCK_DELETE, WORLD_CLOCK_GET, WORLD_CLOCK_GET_BYID, WORLD_CLOCK_SETSCREEN } from "../Pages/Api";


export const AddWordClockData = createAsyncThunk(
    "WorldClockApp/AddWordClockData",
    async ({ authToken, data }, { rejectWithValue }) => {
        try {
            const response = await axios.post(WORLD_CLOCK_ADD, data, {
                headers: {
                    Authorization: authToken,
                    "Content-Type": "application/json",
                },
            });
            return response.data;
        } catch (error) {
            console.error("API Error:", error);
            return rejectWithValue(
                error.response?.data || {
                    message: "An error occurred",
                    status: error.response?.status,
                }
            );
        }
    }
);
export const GetWordClockData = createAsyncThunk("WorldClockApp/GetWordClockData", async ({ authToken }, { rejectWithValue }) => {
    try {
        const response = await axios.get(WORLD_CLOCK_GET, {
            headers: {
                Authorization: authToken,
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error("API Error:", error);
        return rejectWithValue(
            error.response?.data || {
                message: "An error occurred",
                status: error.response?.status,
            }
        );
    }
}
);
export const DeleteWordClockData = createAsyncThunk(
    "WorldClockApp/DeleteWordClockData",
    async ({ authToken, id }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${WORLD_CLOCK_DELETE}?worldClockAppId=${id}`,
                {},
                {
                    headers: {
                        Authorization: authToken,
                        "Content-Type": "application/json",
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error("API Error:", error);
            return rejectWithValue(
                error.response?.data || {
                    message: "An error occurred",
                    status: error.response?.status,
                }
            );
        }
    }
);

export const GetByIdWordClockData = createAsyncThunk(
    "WorldClockApp/GetByIdWordClockData",
    async ({ authToken, id }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${WORLD_CLOCK_GET_BYID}worldClockAppId=${id}`, {
                headers: {
                    Authorization: authToken,
                    "Content-Type": "application/json",
                },
            });
            return response.data;
        } catch (error) {
            console.error("API Error:", error);
            return rejectWithValue(
                error.response?.data || {
                    message: "An error occurred",
                    status: error.response?.status,
                }
            );
        }
    }
);

// Add Tags
export const AddTagsWordClockData = createAsyncThunk(
    "WorldClockApp/AddTagsWordClockData",
    async ({ authToken, id, tags }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${WORLD_CLOCK_ADD_TAGS}?worldClockAppId=${id}&tags=${tags}`, {},
                {
                    headers: {
                        Authorization: authToken,
                        "Content-Type": "application/json",
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error("API Error:", error);
            return rejectWithValue(
                error.response?.data || {
                    message: "An error occurred",
                    status: error.response?.status,
                }
            );
        }
    }
);

const initialState = {
    allWorldClockData: [],
    status: "idle",
    error: null,
    success: null,
    message: null,
    type: null,
};

const WorldClockSlice = createSlice({
    name: "World_Clock",
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.error = null;
            state.message = null;
            state.status = null;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetWordClockData.pending, (state) => {
                state.status = null;
            })
            .addCase(GetWordClockData.fulfilled, (state, action) => {
                state.status = null;
                state.allWorldClockData = action.payload?.data;
            })
            .addCase(GetWordClockData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export const { resetStatus } = WorldClockSlice.actions;

export default WorldClockSlice.reducer;
