import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { getUrl, postUrl } from "../Pages/Api";

export const AddBankDetails = createAsyncThunk("UserMaster/AddcommissionRate", async (payload, thunkAPI) => {
    try {
        const token = thunkAPI.getState().root.auth.token;
        const response = await postUrl('UserMaster/AddorUpdateBankDetails', { data: payload, headers: { Authorization: `Bearer ${token}` }, });
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const getBankDetailsbyUser = createAsyncThunk("UserMaster/getBankDetailsbyUser", async (payload, thunkAPI) => {
    try {
        const token = thunkAPI.getState().root.auth.token;
        const response = await getUrl('UserMaster/GetBankDetailsOfUser', { headers: { Authorization: `Bearer ${token}` }, });
        return response.data;
    } catch (error) {
        console.log("error", error);
        toast.error('Failed to fetch data');
        throw error;
    }
});

const initialState = {
    loading: false,
    bankDetails: null,
    error: null,
    data: null,
    message: "",
    status: null,

};

const BankSlice = createSlice({
    name: "Banking",
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.error = null;
            state.message = null;
            state.status = null;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(getBankDetailsbyUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        })

        builder.addCase(getBankDetailsbyUser.fulfilled, (state, action) => {
            state.loading = false;
            state.bankDetails = action.payload.data;
            state.token = action?.data?.token;
        })

        builder.addCase(getBankDetailsbyUser.rejected, (state, action) => {
            state.loading = false;
            state.bankDetails = null;
            state.message = action.error.message || "Failed to data";
        })


        // update bankDetails
        builder.addCase(AddBankDetails.pending, (state,) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(AddBankDetails.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.bankDetails = payload?.data;
            state.error = null;
        });
        builder.addCase(AddBankDetails.rejected, (state, { payload }) => {
            state.loading = false;
            state.bankDetails = null;
            state.error = payload ?? null;
        });
    },
});

export const { resetStatus } = BankSlice.actions;

export default BankSlice.reducer;
