import React from "react";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineClose, } from "react-icons/ai";
import { MdSave } from "react-icons/md";
import { useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import {
  GET_Questions_BY_ID,
  Questions_INSTANCE_ADD_URL,
} from "../../Pages/Api";
import axios from "axios";


export const QuestionsDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const { id } = useParams();
  const history = useNavigate();
  const currentDate = new Date();
  const { user, token, userDetails } = useSelector((state) => state.root.auth);
  const authToken = `Bearer ${token}`;

  const [instanceName, setInstanceName] = useState(moment(currentDate).format("YYYY-MM-DD hh:mm"));
  const [Question, setQuestion] = useState({
    Question: "",
    usersrespond: "Web Form",
    Email: "",
    WebUrl: "",
  });
  const [edited, setEdited] = useState(false);
  const [Selectrespondid, setSelectrespondid] = useState("Web Form");
  const [Loding, setLoding] = useState(false);
  const [ShowPreview, setShowPreview] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    setLoding(true);
    setTimeout(() => {
      setLoding(false);
    }, 2000);
  }, [Question]);

  useEffect(() => {
    if (id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${GET_Questions_BY_ID}?questionAppId=${id}`,
        headers: {
          Authorization: authToken,
        },
      };
      axios.request(config).then((response) => {
        const data = response?.data?.data;

        setQuestion({
          Question: data?.question,
          usersrespond: data?.respond,
          WebUrl: data?.respond === "Web Form" ? data?.url : "",
          Email: data?.respond === "Email" ? data?.url : "",
        });

        setSelectrespondid(data?.respond === "Web Form" ? "Web Form" : "Email");
      });
    }
  }, [id]);

  const handleOnSaveInstanceName = (e) => {
    if (!instanceName.replace(/\s/g, "").length) {
      toast.remove();
      return toast.error("Please enter at least minimum 1 character.");
    }
    setEdited(false);
  };

  const handalselect = (e) => {
    const { value } = e.target;

    setSelectrespondid(value);
    setQuestion((prev) => ({
      ...prev,
      usersrespond: value
    }));
  };

  const handlChange = (e) => {
    const { value, name } = e.target;
    setQuestion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addQuestionsApp = () => {
    if (instanceName === "" && Question === "") {
      toast.remove();
      return toast.error("Please fill all the details");
    }

    const data = {
      askAQuestionAppId: id ? id : 0,
      instanceName: instanceName,
      question: Question?.Question,
      respond: Question?.usersrespond,
      url: Question?.usersrespond === "Web Form" ? Question?.WebUrl : Question?.Email,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: Questions_INSTANCE_ADD_URL,
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setSaveLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (window.history.length === 1) {
          localStorage.setItem("isWindowClosed", "true");
          window.close();
        } else {
          history("/Ask-A-Question");
        }
        setSaveLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSaveLoading(false);
      });
  };

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
            user?.userDetails?.isRetailer === false &&
            !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div>
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter schedule name"
                        value={instanceName}
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      setShowPreview(!ShowPreview);
                    }}
                  >
                    {ShowPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => addQuestionsApp()}
                    disabled={""}
                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>

                  <Link to="/Ask-A-Question">
                    <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>
              {ShowPreview && (
                <div className="w-full lg:w-[80%] h-[70vh] m-auto  bg-[#505ac9] rounded-sm shadow-md border-4 border-black  flex items-center justify-center p-6 overflow-hidden clip-triangle mt-3">
                  {(Selectrespondid === "Web Form" && Question?.WebUrl) ||
                    (Selectrespondid !== "Web Form" && Question?.Email) ? (
                    <div className="flex flex-col items-center justify-center w-full h-full  bg-[#505ac9] p-5">
                      <div className="bg-white rounded-lg shadow-md flex items-center justify-center w-auto h-auto">
                        <QRCodeSVG
                          value={
                            Selectrespondid === "Web Form"
                              ? Question?.WebUrl
                              : Question?.Email
                          }
                          size={250}
                          fgColor="#000000"
                          bgColor="#ffffff"
                          className="p-4"
                        />
                      </div>

                      <p className="text-white mt-2 text-center" style={{ fontSize: "10px" }}>
                        Studio tracking is not supported for the URL provided
                      </p>

                      <p className="text-white text-lg md:text-xl font-bold mt-4 text-center">
                        {Question.Question === ""
                          ? "What would you like to see on this screen? Let us know"
                          : Question.Question}
                      </p>
                    </div>
                  ) : (
                    <div className="flex text-center m-5 justify-center">
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-10 h-10 me-3 text-gray-200 animate-spin dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="#1C64F2"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              )}
              {!ShowPreview && (
                <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                  <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Title:*
                      </label>

                      <input
                        type="text"
                        id="first_name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Title .."
                        name="Question"
                        value={Question.Question}
                        onChange={handlChange}
                      />
                    </div>
                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="message"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        How should users respond?
                      </label>
                      <select
                        id="countries"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        defaultValue=""
                        value={Question.usersrespond}
                        onChange={handalselect}
                      >
                        <option value="Web Form" >Web Form</option>
                        <option value="Email" >Email</option>
                      </select>
                    </div>

                    {Selectrespondid === "Web Form" ? (
                      <div className="mb-4 flex items-center justify-between placeHolderColor">
                        <label
                          className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Web Form Url
                        </label>

                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="https://disploy.com/faqs"
                          name="WebUrl"
                          value={Question.WebUrl}
                          onChange={handlChange}
                        />
                      </div>
                    ) : (
                      <div className="mb-4 flex items-center justify-between">
                        <label
                          htmlFor="countries"
                          className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Email address
                        </label>

                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="mailto:"
                          name="Email"
                          value={Question?.Email}
                          onChange={handlChange}
                        />
                      </div>
                    )}
                    <div className="flex items-start p-4 bg-gray-50 rounded-md border-l-4 border-yellow-500">
                      <p className="text-gray-700 italic">
                        If you have selected email, please paste your email
                        address after the 'mailto:' prefix.
                      </p>
                    </div>
                  </div>

                  <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                    {Loding && (
                      <div className="w-full h-[30rem]  rounded-sm shadow-md border-4 border-black  bg-[#505ac9] flex items-center justify-center overscroll-none">
                        <div className="flex text-center m-5 justify-center">
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline w-10 h-10 me-3 text-gray-200 animate-spin dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="#1C64F2"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                    {!Loding && (
                      <div className="w-full h-[30rem]  bg-[#505ac9] rounded-sm shadow-md border-4 border-black flex items-center justify-center overscroll-none">
                        {(Selectrespondid === "Web Form" && Question?.WebUrl) ||
                          (Selectrespondid !== "Web Form" && Question?.Email) ? (
                          <div className="flex flex-col items-center justify-center w-full h-full bg-[#505ac9] p-5">
                            <div className="bg-white rounded-lg shadow-md flex items-center justify-center w-auto h-auto animate-bounce-once-up">
                              <QRCodeSVG
                                value={
                                  Selectrespondid === "Web Form"
                                    ? Question.WebUrl
                                    : Question.Email
                                }
                                size={250}
                                fgColor="#000000"
                                bgColor="#ffffff"
                                className="p-4"
                              />
                            </div>

                            <p
                              className="text-white mt-2 text-center "
                              style={{ fontSize: "10px" }}
                            >
                              Studio tracking is not supported for the URL
                              provided
                            </p>

                            <p className="text-white text-lg md:text-xl font-bold mt-4 text-center">
                              {Question?.Question === ""
                                ? "What would you like to see on this screen? Let us know"
                                : Question?.Question}
                            </p>
                          </div>
                        ) : (
                          <div className="flex text-center m-5 justify-center">
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline w-10 h-10 me-3 text-gray-200 animate-spin dark:text-gray-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="#1C64F2"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
