import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';

export default function BuildingDirectoryQR({ toggleModal, addFloor, setAddFloor }) {
    return (
        <div>
            <div
                id="default-modal"
                tabIndex="-1"
                aria-hidden="true"
                className="fixed top-0 right-0 left-0 z-9990 flex justify-center items-center w-full h-full m-0 md:inset-0 max-h-full bg-black bg-opacity-50"
            >
                <div className="relative p-4 w-full max-w-xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-3 md:p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                QR Code
                            </h3>
                            <AiOutlineCloseCircle
                                className="text-4xl text-primary cursor-pointer"
                                onClick={() => {
                                    toggleModal();
                                }}
                            />
                        </div>
                        <div className="p-6">

                            <div className="h-52 overflow-y-auto">
                                <div className="flex justify-between m-3 items-center placeHolderColor">
                                    <span>URL</span>
                                    <input
                                        type="text"
                                        className="w-60 relative border border-black rounded-md p-3"
                                        placeholder='http://example.com'
                                        value={addFloor?.QR_Url}
                                        onChange={(e) => setAddFloor({ ...addFloor, QR_Url: e.target.value })}
                                    />
                                </div>
                                <div className="flex justify-between m-3 items-center placeHolderColor">
                                    <span>Title</span>
                                    <input
                                        type="text"
                                        placeholder='e.g.Download our Map'
                                        className="w-60 relative border border-black rounded-md p-3"
                                        value={addFloor?.QR_Title}
                                        onChange={(e) => setAddFloor({ ...addFloor, QR_Title: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-center gap-2 border-t border-black">
                                <button
                                    type="button"
                                    className="w-40 text-[#FFFFFF] bg-SlateBlue not-italic font-medium rounded-full py-3.5 text-center text-base mt-4 hover:bg-primary border border-SlateBlue hover:border-white"
                                    onClick={() => toggleModal()}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="w-40 text-[#FFFFFF] bg-SlateBlue not-italic font-medium rounded-full py-3.5 text-center text-base mt-4 hover:bg-primary border border-SlateBlue hover:border-white"
                                    onClick={() => toggleModal()}
                                >
                                    Save
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
