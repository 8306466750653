import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import Img from '../../../images/AppsImg/close.png'
import { countryList } from '../../Common/Common'


export default function CurrenciesPreview({ setInstanceView, editCurrencies }) {
    return (
        <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
            <div>
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-[1000px] h-[600px]">
                    <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
                        <div className="flex items-center">
                            <div>
                                <img
                                    src="https://back.disploy.com/App/currencies.svg"
                                    alt="Logo"
                                    className="cursor-pointer mx-auto h-10 w-10"
                                />
                            </div>
                            <div className="ml-3">
                                <h4 className="text-lg font-medium whitespace-wrap ">{editCurrencies?.instanceName}</h4>
                            </div>
                        </div>
                        <button
                            className="p-1 text-3xl"
                            onClick={() => setInstanceView(false)}
                        >
                            <AiOutlineCloseCircle />
                        </button>
                    </div>

                    <div className="bg-gray-50 rounded-sm  flex items-center justify-center  border w-full h-full" >
                        <div className=" bg-black  w-full h-full flex flex-col justify-between py-14 ">
                            <div className="flex justify-center">
                                <h1 className="mb-3 text-3xl font-medium text-white">
                                    {editCurrencies?.pageTitle}
                                </h1>
                            </div>
                            <div className="w-full  h-[15rem] vertical-scroll-inner bg-gray-700 flex justify-center items-center rounded-md p-7 ">
                                <div className="flex flex-wrap justify-center items-center gap-4 ">
                                    {editCurrencies?.Currencies?.length > 0 &&
                                        editCurrencies?.Currencies
                                            ?.filter((item) => item?.currencyCode !== editCurrencies?.baseCurrency?.toUpperCase())
                                            ?.map((item, index) => (
                                                <>
                                                    <div
                                                        key={index}
                                                        className="w-[250px] h-[60px] bg-slate-600 rounded-lg flex items-center justify-between p-3 shadow-md"
                                                    >
                                                        <div className='flex items-center gap-3'>
                                                            <img
                                                                src={item?.total === 0 ? Img : item?.flagUrl}
                                                                alt="Flag"
                                                                className="h-10 rounded-md"
                                                            />
                                                            <div>
                                                                <p className="text-white text-lg font-semibold">
                                                                    {item?.currencyCode}
                                                                </p>
                                                                <p className="text-gray-300 text-xs font-semibold">
                                                                    {item?.currencyName}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="text-white text-lg font-semibold">
                                                                {item?.total}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <p className="flex items-center text-xl font-normal text-white">
                                    <img
                                        className="h-10 mr-2"
                                        src={`https://flagsapi.com/${countryList[editCurrencies?.baseCurrency?.toUpperCase()] || ""}/flat/64.png`}
                                        alt="flag"
                                    />
                                    {editCurrencies?.unit} {editCurrencies?.baseCurrency} ({editCurrencies?.baseCurrencyName})
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="py-2 px-6">
                        <div className="flex items-center gap-2 w-full">
                            <div className="font-semibold w-fit">
                                Tags:-
                            </div>
                            <div className=" w-full">{editCurrencies?.tags}</div>
                        </div>
                        <div>
                            <label className="font-semibold">
                                Screen Assign :
                            </label>
                            {editCurrencies?.screens == "" ? " No Screen" : editCurrencies?.screens}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
