import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'react-phone-number-input';

const AddEditUser = ({
    editMode,
    setAddUserModal,
    setUserName,
    setFirstName,
    setLastName,
    setPhoneNumber,
    setEmail,
    setSelectedUserType,
    setIsActive,
    setEditMode,
    setEditUserId,
    setShowPassword,
    showPassword,
    selectedUserType,
    userName,
    firstName,
    lastName,
    phoneNumber,
    email,
    password,
    userTypeData,
    isActive,
    handleCheckboxChange,
    handleInsertUser,
    setPassword,
    emailError,
    passError,
    usernameError,
    setPassError,
    setUsernameError,
    setEmailError,
    phoneError,
    firstError,
    lastError,
    setPhoneError,
    setLastError,
    setFirstError,
    setUserTypeError,
    userTypeError, onClose
}) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const { control } = useForm();
    return (
        <>

            <div
                id="default-modal"
                tabIndex="-1"
                aria-hidden="true"
                className="fixed top-0 right-0 left-0 z-9990 flex justify-center items-center w-full h-full m-0 md:inset-0 max-h-full bg-black bg-opacity-50"
            >
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="relative p-4 lg:w-[500px] md:w-[500px] sm:w-full max-h-full">
                            {/* Modal content */}
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                {/* Modal header */}
                                <div className="flex items-center justify-between p-3 md:p-4 border-b rounded-t dark:border-gray-600 ">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        {editMode ? "Edit" : "Add"} User
                                    </h3>
                                    <AiOutlineCloseCircle
                                        className="text-4xl text-primary cursor-pointer"
                                        onClick={() => {
                                            onClose()
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className="p-4">
                                        <div className='flex flex-row items-center gap-2'>
                                            <div className='w-full'>
                                                <input
                                                    type="text"
                                                    placeholder="User Name"
                                                    className="formInput"
                                                    value={userName}
                                                    onChange={(e) => setUserName(e.target.value)}
                                                />
                                                {userName?.length <= 0 && usernameError && (
                                                    <span className='error'>This field is required.</span>
                                                )}
                                            </div>
                                            <div className='w-full'>
                                                <input
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="formInput"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />
                                                {firstName?.length <= 0 && firstError && (
                                                    <span className='error'>This field is required.</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className='flex flex-row items-center gap-2'>
                                            <div className='w-full'>
                                                <input
                                                    type="text"
                                                    placeholder="Last Name"
                                                    className="formInput mt-4"
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                />
                                                {lastName?.length <= 0 && lastError && (
                                                    <span className='error'>This field is required.</span>
                                                )}
                                            </div>
                                            <div className='w-full'>
                                                <select
                                                    onChange={(e) => setSelectedUserType(e.target.value)}
                                                    value={selectedUserType}
                                                    className="formSelectInput mt-4"
                                                >
                                                    <option value="">Select User Type</option>
                                                    {userTypeData?.map((user) => (
                                                        <option key={user?.userTypeID} value={user?.userTypeID}>
                                                            {user?.userType}
                                                        </option>
                                                    ))}
                                                </select>
                                                {!selectedUserType && userTypeError && (
                                                    <span className='error'>This field is required.</span>
                                                )}
                                            </div>
                                        </div>

                                        {!editMode && (
                                            <>
                                                <div className='flex flex-row items-center gap-2 '>
                                                    <div className='w-full'>
                                                        <input
                                                            type="email"
                                                            placeholder="Email"
                                                            className="formInput mt-4"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                        {emailError && (email?.length <= 0 ?
                                                            <span span className='error'>This field is required.</span> :
                                                            !(emailRegex?.test(email)) && <span className='error'>Invalid Email Address.</span>
                                                            // <span className='error'>Invalid Email Address.</span>
                                                        )}
                                                    </div>
                                                    <div className='w-full'>
                                                        <div className="relative">
                                                            <input
                                                                type={showPassword ? "text" : "password"}
                                                                placeholder="Password"
                                                                className="formInput mt-4"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                            />
                                                            <div className="absolute right-5 bottom-5">
                                                                {showPassword ? (
                                                                    <BsFillEyeFill
                                                                        onClick={() => setShowPassword(!showPassword)}
                                                                    />
                                                                ) : (
                                                                    <BsFillEyeSlashFill
                                                                        onClick={() => setShowPassword(!showPassword)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        {passError && (password?.length <= 0 ?
                                                            <span span className='error'>This field is required.</span> :
                                                            !(passwordRegex?.test(password)) && <span className='error'>Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {/*      <input
                                            type="number"
                                            placeholder="Phone Number"
                                            className="formInput mt-4"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />*/}
                                        <div className='mt-4'>
                                            <Controller
                                                name="phone"
                                                control={control}
                                                rules={{
                                                    validate: (value) => isValidPhoneNumber(value),
                                                }}
                                                render={({ field: { onChange, value, country } }) => (
                                                    <PhoneInput
                                                        country={"in"}
                                                        onChange={(phoneNumber) => {
                                                            const formattedNumber = "+" + phoneNumber;
                                                            onChange(formattedNumber); // Update the value directly
                                                            setPhoneNumber(formattedNumber); // Update the state to reflect the phone number
                                                        }}
                                                        value={value || phoneNumber}
                                                        autocompleteSearch={true}
                                                        countryCodeEditable={false}
                                                        enableSearch={true}
                                                        inputStyle={{
                                                            width: "100%",
                                                            background: "white",
                                                            padding: "25px 0 25px 3rem",
                                                            borderRadius: "10px",
                                                            fontSize: "1rem",
                                                            border: "1px solid #000",
                                                        }}
                                                        dropdownStyle={{
                                                            color: "#000",
                                                            fontWeight: "600",
                                                            padding: "0px 0px 0px 10px",
                                                        }}
                                                    />
                                                )}
                                            />
                                            {phoneError && !(isValidPhoneNumber(phoneNumber)) && (
                                                <span className='error'>Invalid Phone Number.</span>
                                            )}
                                            {/* {phoneError && (phoneNumber?.length <= 0 ?
                                                <span className='error'>This field is required.</span> : phoneNumber?.length <= 12 &&
                                                <span className='error'>Invalid Phone Number.</span>
                                            )} */}
                                        </div>



                                        <div className="mt-5 flex items-center">
                                            <input
                                                className="border border-primary mr-3 ml-1 rounded h-6 w-6 cursor-pointer"
                                                type="checkbox"
                                                checked={isActive}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label>Is Active </label>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center p-4 md:p-4 border-t border-gray-200 rounded-b dark:border-gray-600 gap-2">
                                        <button
                                            className="bg-white text-primary text-base px-6 py-3 border border-primary  shadow-md rounded-full hover:bg-primary hover:text-white mr-2"
                                            type="button"
                                            onClick={() => { onClose() }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-primary text-white text-base px-8 py-3 border border-primary shadow-md rounded-full "
                                            type="button"
                                            onClick={handleInsertUser}
                                        >
                                            {editMode ? "Update" : "Save"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AddEditUser
