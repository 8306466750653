import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
export const FollowUsOnFacebookPreview = ({
  followUsOnFacebookDataById,
  showTags,
  screenAssignName,
  setInstanceView,
  instanceName,
}) => {
  return (
    <div className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-9990 outline-none focus:outline-none">
      <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-[1000px] h-[600px]">
        <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
          <div className="flex items-center">
            <div>
              <img
                src="https://back.disploy.com/app/follow-us-on-facebook.svg"
                alt="Logo"
                className="cursor-pointer mx-auto h-10 w-10"
              />
            </div>
            <div className="ml-3">
              <h4 className="text-lg font-medium whitespace-wrap ">
                {instanceName}
              </h4>
            </div>
          </div>
          <button
            className="p-1 text-3xl"
            onClick={() => setInstanceView(false)}
          >
            <AiOutlineCloseCircle />
          </button>
        </div>
        <div className="bg-black md:w-[576px] md:h-[324px] sm:w-[384px] sm:h-[216px] lg:w-[960px] lg:h-[540px] w-72 h-72 flex items-center justify-center overflow-hidden scrollbar-hide ">
          <div className="text-center flex animate-bounce-once-up">
            <div className="bg-blue-500 w-[40vh] h-[25rem] text-center items-center content-center">
              <img
                src="https://layout-builder.apps.screencloud.com/static/media/facebook-follow-us.51e4dd37.png"
                alt="Facebook Follow Us"
              />
            </div>
            <div className="bg-blue-500 w-[40vh] h-[25rem] items-center content-center text-center">
              <div>
                <p>{followUsOnFacebookDataById?.pageTitle}</p>
                <div className="flex flex-col items-center justify-center w-full h-full p-5">
                  <div className="bg-white rounded-lg shadow-md flex items-center justify-center w-auto h-auto">
                    <QRCodeSVG
                      value={followUsOnFacebookDataById?.facebookURL}
                      size={150}
                      fgColor="#000000"
                      bgColor="#ffffff"
                      className="p-4"
                    />
                  </div>

                  <p className="text-white text-lg md:text-xl font-bold mt-4 text-center">
                    Follow us on Facebook
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-2 px-6">
          <div className="flex items-center gap-2 w-full">
            <div className="font-semibold w-fit">Tags:-</div>
            <div className=" w-full">{showTags}</div>
          </div>
          <div>
            <label className="font-semibold">Screen Assign :</label>
            {screenAssignName ? screenAssignName : " No Screen"}
          </div>
        </div>
      </div>
    </div>
  );
};
