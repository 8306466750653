import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  AiOutlineClose,

} from "react-icons/ai";
import { MdSave } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { handleAddCountUpTime } from "../../Redux/AppsSlice";
import { Clock } from "@sujitsimon/react-flipclock";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

const CountUpTimerDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const currentDate = new Date();
  const { id } = useParams()
  const dispatch = useDispatch()

  const { user, token, userDetails } = useSelector((state) => state.root.auth);
  const [saveLoading, setSaveLoading] = useState(false);
  const navigate = useNavigate()

  const [instanceName, setInstanceName] = useState(moment(currentDate).format("YYYY-MM-DD hh:mm"));
  const [edited, setEdited] = useState(false);

  const [CountUp, setCountUp] = useState({
    Title: "",
    Start_time: "1",
    Day: "",
    hour: "",
    year: "",
    month: "",
    minute: "",
    Style: "Flip",
  });
  const [startTime, setStartTime] = useState(null);
  const [result, setResult] = useState({ days: "00", hours: "00", minutes: "00", seconds: "00" });
  const [error, setError] = useState(false);
  const [showPreview, setShowPreview] = useState(false);


  const handleOnSaveInstanceName = (e) => {
    if (!instanceName.replace(/\s/g, "").length) {
      toast.remove();
      return toast.error("Please enter at least minimum 1 character.");
    }
    setEdited(false);
  };

  const handlInputeChange = (e) => {
    const { value, name } = e.target;
    setCountUp((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const startCountUp = () => {
    setStartTime(Date.now());
  };

  useEffect(() => {
    const isValidDate =
      CountUp.Day !== "" &&
      CountUp.month !== "" &&
      CountUp.year !== ""
    // CountUp.hour !== "" &&
    // CountUp.minute !== "";


    const isBeforeOrSameTime = () => {
      const inputDate = new Date(
        CountUp.year,
        CountUp.month - 1,
        CountUp.Day,
        CountUp.hour,
        CountUp.minute
      );
      return inputDate <= new Date();
    };

    let interval;

    if (CountUp.Start_time === "2" ? isValidDate : startTime !== null) {
      if (CountUp.Start_time === "2" ? isBeforeOrSameTime() : true) {
        interval = setInterval(() => {
          const inputDate = new Date(
            CountUp.year,
            CountUp.month - 1,
            CountUp.Day,
            CountUp.hour,
            CountUp.minute
          );
          const currentDate = new Date();

          const diffInMs = Math.abs(
            CountUp.Start_time === "1"
              ? currentDate - startTime
              : currentDate - inputDate
          );

          const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

          const hours = Math.floor(
            (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (diffInMs % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

          setResult({ days, hours, minutes, seconds });
        }, 1000);

        setError(false);
        return () => clearInterval(interval);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  }, [CountUp, startTime]);

  const handlesave = async () => {

    const Payload = {
      countUpTimerAppId: id ? id : 0,
      instanceName: instanceName,
      title: CountUp?.Title,
      startTime: CountUp?.Start_time || 0,
      startDay: CountUp?.Day || 0,
      startHour: CountUp?.hour || 0,
      startYear: CountUp?.year || 0,
      startMonth: CountUp?.month || 0,
      startMinute: CountUp?.minute || 0,
      style: CountUp?.Style,
      startTimer: startTime || "2024-11-26 04:05",
      // userID: 0,
      // screens: "string",
      // screenIDs: "string",
      // maciDs: "string",
      // tags: "string"
    }
    // return
    setSaveLoading(true);
    toast.loading('Saving ...')
    try {
      await dispatch(handleAddCountUpTime(Payload))
      toast.remove()
      setSaveLoading(false);
      toast.remove()
      navigate('/Clock')
    } catch (error) {
      console.log('error :>> ', error);
      setSaveLoading(false);
    }
  }

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
            user?.userDetails?.isRetailer === false &&
            !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div>
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter schedule name"
                        value={instanceName}
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      if (CountUp?.Title === "") {
                        return toast.error("Please Enter Title");
                      }
                      setShowPreview(!showPreview);
                    }}
                  >
                    {showPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => handlesave()}
                  >
                    {/* {saveLoading ? "Saving..." : "Save"} */}
                    Save
                  </button>

                  <Link to="/Count-Up-Timer">
                    <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>
              {showPreview && (
                <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg h-full ">
                  <div className="w-[100%] h-[35rem] bg-gray-50 rounded-sm shadow-md border-[6px] border-black flex items-center justify-center overflow-hidden">
                    {!error ? (
                      <div className="w-full h-full bg-yellow-300 flex flex-col items-center justify-center">
                        {CountUp?.Style === "Flip" ? (<>
                          <div className="">
                            <p>{CountUp?.Title}</p>
                          </div>
                          <div className="flex space-x-4 mt-10">
                            <div className="flip-clock-item">
                              <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                {String(result?.days).padStart(2, "0")}
                              </div>
                              <div className="text-center text-gray-600 text-sm">
                                Days
                              </div>
                            </div>
                            <div className="flip-clock-item">
                              <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                {String(result?.hours).padStart(2, "0")}
                              </div>
                              <div className="text-center text-gray-600 text-sm">
                                Hours
                              </div>
                            </div>
                            <div className="flip-clock-item">
                              <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                {String(result?.minutes).padStart(2, "0")}
                              </div>
                              <div className="text-center text-gray-600 text-sm">
                                Minutes
                              </div>
                            </div>
                            <div className="flip-clock-item">
                              <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                {String(result?.seconds).padStart(2, "0")}
                              </div>
                              <div className="text-center text-gray-600 text-sm">
                                Seconds
                              </div>
                            </div>
                          </div>
                        </>) : (<div className=" flex items-center w-full h-full">
                          {
                            result?.days > 0 && (<> <div className=" w-[40%] flex items-center justify-center h-full">
                              <h1 style={{ fontSize: "150px" }}>{String(result?.days).padStart(2, "0")}</h1>
                            </div>
                              <div>
                                <h1 className="flex items-center justify-center text-lg">
                                  day
                                </h1>
                                <h1 className=" text-xl">{CountUp?.Title}</h1>
                              </div></>)
                          }
                          {
                            result?.days === 0 && result?.hours > 0 && (<> <div className=" w-[40%] flex items-center justify-center h-full">
                              <h1 style={{ fontSize: "150px" }}>{String(result?.hours).padStart(2, "0")}</h1>
                            </div>
                              <div>
                                <h1 className="flex items-center justify-center text-lg">
                                  hours
                                </h1>
                                <h1 className=" text-xl">{CountUp?.Title}</h1>
                              </div></>)
                          }
                          {
                            result?.days === 0 && result?.hours === 0 && (<> <div className=" w-[40%] flex items-center justify-center h-full">
                              <h1 style={{ fontSize: "150px" }}>{String(result?.minutes).padStart(2, "0")}</h1>
                            </div>
                              <div>
                                <h1 className="flex items-center justify-center text-lg">
                                  minutes
                                </h1>
                                <h1 className=" text-xl">{CountUp?.Title}</h1>
                              </div></>)
                          }

                          <div>

                          </div>
                        </div>)}
                      </div>
                    ) : (
                      <div className="w-full h-full bg-[#fdce0b] flex items-center justify-center text-center p-4">
                        <div>
                          <p className="text-lg font-semibold">
                            Sorry, the start time given is in the future or has
                            not been set.
                          </p>
                          <p className="mt-2 text-md">
                            Please reconfigure the application again.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!showPreview && (
                <div className="flex flex-col lg:flex-row rounded-lg h-full gap-3">
                  <div className="w-full lg:w-2/4 pr-0 lg:pr-4 mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Title*
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Days without accident"
                        value={CountUp?.Title}
                        name="Title"
                        onChange={handlInputeChange}
                      />
                    </div>

                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Start time
                      </label>

                      <select
                        onChange={handlInputeChange}
                        name="Start_time"
                        value={CountUp?.Start_time}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="1">Start the timer now</option>
                        <option value="2">Select a start date</option>
                      </select>
                    </div>
                    {CountUp?.Start_time === "1" && (
                      <div className="mb-4 flex items-center justify-between placeHolderColor">
                        <label
                          htmlFor="countries"
                          className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Start Timer
                        </label>
                        <div className="text-right">
                          <button
                            onClick={startCountUp}
                            disabled={CountUp.Title === ""}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-3/2  p-2.5"
                          >
                            Start/Reset
                          </button>
                        </div>
                      </div>
                    )}
                    {CountUp?.Start_time === "2" && (
                      <>
                        <div className="mb-4 flex items-center justify-between placeHolderColor">
                          <label
                            htmlFor="countries"
                            className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Start Day
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="1"
                            value={CountUp?.Day}
                            name="Day"
                            onChange={handlInputeChange}
                          />
                        </div>
                        <div className="mb-4 flex items-center justify-between placeHolderColor">
                          <label
                            htmlFor="countries"
                            className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Start hour (optional)
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="16"
                            value={CountUp?.hour}
                            name="hour"
                            onChange={handlInputeChange}
                          />
                        </div>
                        <div className="mb-4 flex items-center justify-between placeHolderColor">
                          <label
                            htmlFor="countries"
                            className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Start year
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="2024"
                            value={CountUp?.year}
                            name="year"
                            onChange={handlInputeChange}
                          />
                        </div>
                        <div className="mb-4 flex items-center justify-between placeHolderColor">
                          <label
                            htmlFor="countries"
                            className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Start month
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="1"
                            value={CountUp?.month}
                            name="month"
                            onChange={handlInputeChange}
                          />
                        </div>
                        <div className="mb-4 flex items-center justify-between placeHolderColor">
                          <label
                            htmlFor="countries"
                            className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Start minute (optional)
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="45"
                            value={CountUp?.minute}
                            name="minute"
                            onChange={handlInputeChange}
                          />
                        </div>
                      </>
                    )}
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Style*
                      </label>
                      <select
                        value={CountUp?.Style}
                        name="Style"
                        onChange={handlInputeChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="Flip">Flip</option>
                        <option value="Minimal">Minimal</option>
                      </select>
                    </div>
                  </div>

                  <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                    <div className="w-full h-full bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none ">
                      {CountUp?.Title === "" ? (
                        <div className="text-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-16 w-16 mx-auto text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 7V3h18v4M4 10h16v4H4v-4z"
                            />
                          </svg>
                          <p className="text-gray-500">App instance preview</p>
                          <p className="text-sm text-gray-400">
                            Please edit the app settings to get started
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      {CountUp?.Title !== "" ? (
                        !error ? (
                          <div className="w-full h-full bg-[#fdce0b] flex flex-col items-center justify-center">
                            {CountUp?.Style === "Flip" ? (<>
                              <div className="">
                                <p>{CountUp?.Title}</p>
                              </div>
                              <div className="flex space-x-4 mt-10">
                                <div className="flip-clock-item">
                                  <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                    {String(result?.days).padStart(2, "0")}
                                  </div>
                                  <div className="text-center text-gray-600 text-sm">
                                    Days
                                  </div>
                                </div>
                                <div className="flip-clock-item">
                                  <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                    {String(result?.hours).padStart(2, "0")}
                                  </div>
                                  <div className="text-center text-gray-600 text-sm">
                                    Hours
                                  </div>
                                </div>
                                <div className="flip-clock-item">
                                  <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                    {String(result?.minutes).padStart(2, "0")}
                                  </div>
                                  <div className="text-center text-gray-600 text-sm">
                                    Minutes
                                  </div>
                                </div>
                                <div className="flip-clock-item">
                                  <div className="bg-white border border-gray-300 rounded-lg p-6 text-5xl text-center">
                                    {String(result?.seconds).padStart(2, "0")}
                                  </div>
                                  <div className="text-center text-gray-600 text-sm">
                                    Seconds
                                  </div>
                                </div>
                              </div>
                            </>) : (<div className=" flex items-center w-full h-full ">

                              {
                                result?.days > 0 && (<> <div className=" w-[40%] flex items-center justify-center h-full">
                                  <h1 style={{ fontSize: "150px" }}>{String(result?.days).padStart(2, "0")}</h1>
                                </div>
                                  <div>
                                    <h1 className="flex items-center justify-center text-lg">
                                      day
                                    </h1>
                                    <h1 className=" text-xl">{CountUp?.Title}</h1>
                                  </div></>)
                              }
                              {
                                result?.days === 0 && result?.hours > 0 && (<> <div className=" w-[40%] flex items-center justify-center h-full">
                                  <h1 style={{ fontSize: "150px" }}>{String(result?.hours).padStart(2, "0")}</h1>
                                </div>
                                  <div>
                                    <h1 className="flex items-center justify-center text-lg">
                                      hours
                                    </h1>
                                    <h1 className=" text-xl">{CountUp?.Title}</h1>
                                  </div></>)
                              }
                              {
                                result?.days === 0 && result?.hours === 0 && (<> <div className=" w-[40%] flex items-center justify-center h-full">
                                  <h1 style={{ fontSize: "150px" }}>{String(result?.minutes).padStart(2, "0")}</h1>
                                </div>
                                  <div>
                                    <h1 className="flex items-center justify-center text-lg">
                                      minutes
                                    </h1>
                                    <h1 className=" text-xl">{CountUp?.Title}</h1>
                                  </div></>)
                              }
                            </div>)}

                          </div>
                        ) : (
                          <div className="w-full h-full bg-[#fdce0b] flex items-center justify-center text-center p-4">
                            <div>
                              <p className="text-lg font-semibold">
                                Sorry, the start time given is in the future or
                                has not been set.
                              </p>
                              <p className="mt-2 text-md">
                                Please reconfigure the application again.
                              </p>
                            </div>
                          </div>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountUpTimerDetails;
