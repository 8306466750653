import React, { useEffect } from "react";
import { useState, } from "react";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { MdSave, MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import "leaflet/dist/leaflet.css";
import { HiOutlineViewList } from "react-icons/hi";
import Swal from "sweetalert2";
import { QRCodeSVG } from "qrcode.react";
import DigitalMenuAssets from "../DigitalMenuAssets";
import { useDispatch } from "react-redux";
import { getBuildingDirectoryById, handleAddBuildingDirectory } from "../../../Redux/AppsSlice";
import moment from "moment";
import BuildingDirectoryQR from "./BuildingDirectoryQR";

export default function BuildingDirectoryDetails({ sidebarOpen, setSidebarOpen }) {


    const { user, token, userDetails } = useSelector((state) => state.root.auth);
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const currentDate = new Date();

    const [edited, setEdited] = useState(false);
    const [addFloor, setAddFloor] = useState({
        MainLogo: "",
        directoryName: "",
        Qrdetals: { Title: "", QR_Url: "" },
        Floor: [{
            Floorname: "UNNAMED FLOOR",
            allItem: [{ name: "", roomNo: "", Logo: "", },],
            show: false,
            Highlight: false,
        }],
    });

    const [openModal, setOpenModal] = useState(false);
    const [selectCategory, setSelectCategory] = useState("");
    const [selectItem, setSelectItem] = useState("");
    const [selectedAsset, setSelectedAsset] = useState("");
    const [assetPreview, setAssetPreview] = useState("");
    const [ShowOR, setShowOR] = useState(false);
    const [assetPreviewPopup, setAssetPreviewPopup] = useState(false);

    const [showPreview, setShowPreview] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [instanceName, setInstanceName] = useState(moment(currentDate).format("YYYY-MM-DD hh:mm"));

    const handleOnSaveInstanceName = (e) => {
        if (!instanceName.replace(/\s/g, "").length) {
            toast.remove();
            return toast.error("Please enter at least minimum 1 character.");
        }
        setEdited(false);
    };

    const handleAddFloor = () => {

        setAddFloor({
            ...addFloor, Floor: [...addFloor?.Floor, {
                Floorname: "UNNAMED FLOOR",
                allItem: [{ name: "", roomNo: "", Logo: "", },],
                show: true,
            },]
        })
    };

    const handleAddItem = (floorIndex) => {

        setAddFloor((prev) => {
            const updatedAddFloor = { ...prev };
            const updatedFloors = [...updatedAddFloor.Floor];
            const updatedFloor = { ...updatedFloors[floorIndex] };
            const updatedAllItems = [...updatedFloor.allItem];

            const newItem = {
                name: "",
                roomNo: "",
                Logo: "",
            };
            updatedAllItems.push(newItem);

            updatedFloor.allItem = updatedAllItems;
            updatedFloors[floorIndex] = updatedFloor;

            updatedAddFloor.Floor = updatedFloors;

            return updatedAddFloor;
        });
    };

    const handleDeleteItem = (floorIndex, itemIndex) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete this item?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                toast.loading("Deleting...");

                setTimeout(() => {
                    setAddFloor((prev) => {
                        const updatedAddFloor = { ...prev };
                        const updatedFloors = [...updatedAddFloor.Floor];

                        const updatedFloor = { ...updatedFloors[floorIndex] };
                        const updatedItems = [...updatedFloor.allItem];

                        updatedItems.splice(itemIndex, 1);

                        updatedFloor.allItem = updatedItems;
                        updatedFloors[floorIndex] = updatedFloor;

                        updatedAddFloor.Floor = updatedFloors;

                        return updatedAddFloor;
                    });

                    // Remove the loading toast
                    toast.dismiss();
                    toast.success("Item deleted successfully!");
                }, 1000);
            }
        });
    };

    const handleDeleteCategory = (floorIndex) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete this floor?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                toast.loading("Deleting...");

                setTimeout(() => {
                    setAddFloor((prevAddFloor) => {
                        const updatedAddFloor = { ...prevAddFloor };
                        const updatedFloors = [...updatedAddFloor.Floor];

                        if (updatedFloors?.length > 1) {
                            const filteredFloors = updatedFloors.filter(
                                (item, index) => index !== floorIndex
                            );

                            return {
                                ...updatedAddFloor,
                                Floor: filteredFloors,
                            };
                        } else {
                            const hiddenFloors = updatedFloors.map((floor) => ({
                                ...floor,
                                show: false,
                            }));

                            return {
                                ...updatedAddFloor,
                                Floor: hiddenFloors,
                            };
                        }
                    });

                    toast.dismiss();
                    toast.success("Floor deleted successfully!");
                }, 1000);
            }
        });
    };

    const handleNameChange = (floorIndex, itemIndex, value) => {
        setAddFloor((prev) => {
            const updatedAddFloor = { ...prev };
            const updatedFloors = [...updatedAddFloor.Floor];

            const updatedFloor = { ...updatedFloors[floorIndex] };
            const updatedItems = [...updatedFloor.allItem];

            updatedItems[itemIndex] = { ...updatedItems[itemIndex], name: value };

            updatedFloor.allItem = updatedItems;
            updatedFloors[floorIndex] = updatedFloor;

            updatedAddFloor.Floor = updatedFloors;

            return updatedAddFloor;
        });
    };

    const handleRoomNoChange = (floorIndex, itemIndex, value) => {
        setAddFloor((prev) => {
            const updatedAddFloor = { ...prev };
            const updatedFloors = [...updatedAddFloor.Floor];
            const updatedFloor = { ...updatedFloors[floorIndex] };
            const updatedItems = [...updatedFloor.allItem];
            updatedItems[itemIndex] = { ...updatedItems[itemIndex], roomNo: value };
            updatedFloor.allItem = updatedItems;
            updatedFloors[floorIndex] = updatedFloor;
            updatedAddFloor.Floor = updatedFloors;
            return updatedAddFloor;
        });
    };

    const handleFloorName = (floorIndex, value) => {
        setAddFloor((prev) => {
            const updatedAddFloor = { ...prev };
            const updatedFloors = [...updatedAddFloor.Floor];
            const updatedFloor = { ...updatedFloors[floorIndex] };
            updatedFloor.Floorname = value;
            updatedFloors[floorIndex] = updatedFloor;
            updatedAddFloor.Floor = updatedFloors;
            return updatedAddFloor;
        });
    };

    const handleAssetAdd = (asset) => {
        setSelectedAsset(asset);
        setAssetPreview(asset);
    };

    const handleDeleteLogo = (floorIndex, itemIndex) => {
        setAddFloor((prev) => {
            const updatedAddFloor = { ...prev };
            const updatedFloors = [...updatedAddFloor.Floor];
            const updatedFloor = { ...updatedFloors[floorIndex] };
            const updatedItems = [...updatedFloor.allItem];
            updatedItems[itemIndex].Logo = "";
            updatedFloor.allItem = updatedItems;
            updatedFloors[floorIndex] = updatedFloor;
            updatedAddFloor.Floor = updatedFloors;
            return updatedAddFloor;
        });
    };

    const HandleSubmitAsset = () => {
        setAddFloor((prevState) => {
            const updatedAddFloor = { ...prevState }; // Copy the entire addFloor object
            const updatedCategories = [...updatedAddFloor.Floor]; // Copy the Floor array
            if (selectCategory !== "" && selectItem !== "") {
                const updatedCategory = { ...updatedCategories[selectCategory] };
                const updatedItems = [...updatedCategory.allItem];
                updatedItems[selectItem] = {
                    ...updatedItems[selectItem],
                    Logo: selectedAsset,
                };
                updatedCategory.allItem = updatedItems;
                updatedCategories[selectCategory] = updatedCategory;
                updatedAddFloor.Floor = updatedCategories;
                return updatedAddFloor;
            } else {
                updatedAddFloor.MainLogo = selectedAsset;
                return updatedAddFloor;
            }
        });

        setOpenModal(false);
        setSelectCategory("");
        setSelectItem("");
    };

    const handleDeleteMainLogo = () => {
        setAddFloor((prev) => {
            const updatedAddFloor = { ...prev };
            updatedAddFloor.MainLogo = "";
            updatedAddFloor.appAssetURL = "";
            return updatedAddFloor;
        });
    };

    const handleCustomize = () => {
        setShowOR(true);
    };

    const toggleModal = () => {
        setShowOR(!ShowOR);
    };

    const handlecheckHighlight = (index, checked) => {
        setAddFloor((prevState) => {
            const updatedAddFloor = { ...prevState };
            const updatedFloors = [...updatedAddFloor.Floor];
            updatedFloors[index] = { ...updatedFloors[index], Highlight: checked };
            updatedAddFloor.Floor = updatedFloors;
            return updatedAddFloor;
        });
    };

    const handleDragOverForWithinFloorlist = (event, targetIndex) => {
        event.preventDefault();
    };

    const handleDragStartForDivToDiv = (event, Floor, FloorIndex) => {
        event.dataTransfer.setData("Floor", JSON.stringify(Floor));
        event.dataTransfer.setData("FloorIndex", FloorIndex.toString());
    };

    const handleDropForWithinFloorlist = (event, targetIndex) => {
        event.preventDefault();
        if (event?.dataTransfer?.getData("Floor")) {
            const draggedCategory = JSON.parse(event?.dataTransfer?.getData("Floor"));
            const sourceIndex = parseInt(event?.dataTransfer?.getData("FloorIndex"));

            setAddFloor((prevState) => {
                const updatedAddFloor = { ...prevState };

                const updatedFloorList = [...updatedAddFloor.Floor];

                updatedFloorList.splice(sourceIndex, 1);

                updatedFloorList.splice(targetIndex, 0, draggedCategory);

                updatedAddFloor.Floor = updatedFloorList;

                return updatedAddFloor;
            });
        }
    };

    const handleDragStartWithinlist = (event, items, itemIndex) => {
        event.dataTransfer.setData("items", JSON.stringify(items));
        event.dataTransfer.setData("itemIndex", itemIndex.toString());
    };

    const handleDropForWithinlist = (event, index, indexs) => {
        event.preventDefault();
        if (index !== undefined || indexs !== "undefined") {
            if (event?.dataTransfer?.getData("items")) {
                const draggedItem = JSON?.parse(event?.dataTransfer?.getData("items"));
                const sourceIndex = parseInt(event?.dataTransfer?.getData("itemIndex"));

                setAddFloor((pre) => {
                    const updatedAddFloor = { ...pre };
                    const updatedFloorList = [...updatedAddFloor.Floor];
                    const targetFloor = { ...updatedFloorList[index] };
                    targetFloor.allItem.splice(sourceIndex, 1);
                    targetFloor.allItem.splice(indexs, 0, draggedItem);
                    return updatedAddFloor;
                });
            }
        }
    };


    // ======================================================


    useEffect(() => {
        if (id) {
            toast.loading('Fetching ....')
            dispatch(getBuildingDirectoryById(id)).then((res) => {
                if (res?.payload?.status === 200) {
                    toast.remove()
                    const fetchData = res?.payload?.data;
                    setAddFloor({
                        ...fetchData,
                        directoryName: fetchData?.directoryName,
                        appAssetURL: fetchData?.appAssetURL,
                        QR_Url: fetchData?.qrurl,
                        QR_Title: fetchData?.qrTitle,
                        tags: fetchData?.tags,
                        Floor: fetchData?.floors.map((x) => ({
                            buildingDirectoryAppFloorsID: x?.buildingDirectoryAppFloorsID,
                            buildingDirectoryAppID: x?.buildingDirectoryAppID,
                            Floorname: x?.floorName,
                            Highlight: x?.isHighlight,
                            allItem: x?.rooms?.length > 0 && x?.rooms?.map((room) => ({
                                buildingDirectoryAppRoomsID: room?.buildingDirectoryAppRoomsID,
                                buildingDirectoryAppFloorsID: room?.buildingDirectoryAppFloorsID,
                                buildingDirectoryAppID: room?.buildingDirectoryAppID,
                                name: room?.roomName,
                                roomNo: room?.roomNo,
                                roomAssetURL: room?.roomAssetURL,
                                roomLogo_AssetID: room?.Logo?.assetID
                            }))
                        }))
                    })
                    setInstanceName(fetchData?.instanceName);
                }
            })
        }
    }, [id]);

    const onSumbit = async () => {
        try {
            setSaveLoading(true);

            const payload = {
                buildingDirectoryAppId: id ? id : 0,
                instanceName: instanceName,
                directoryName: addFloor?.directoryName,
                qrurl: addFloor?.QR_Url || "",
                qrTitle: addFloor?.QR_Title || "",
                logO_AssetID: addFloor?.MainLogo?.assetID,
                floors: addFloor?.Floor?.length > 0 && addFloor?.Floor?.map((floor) => ({
                    buildingDirectoryAppFloorsID: 0,
                    buildingDirectoryAppID: 0,
                    floorName: floor?.Floorname,
                    isHighlight: floor?.Highlight,
                    rooms: floor?.allItem?.length > 0 && floor?.allItem?.map((room) => ({
                        buildingDirectoryAppRoomsID: 0,
                        buildingDirectoryAppFloorsID: 0,
                        buildingDirectoryAppID: 0,
                        roomName: room?.name,
                        roomNo: room?.roomNo,
                        roomLogo_AssetID: room?.Logo?.assetID
                    }))
                })),
                userID: 0,
                screens: "",
                screenIDs: "",
                maciDs: "",
                tags: addFloor?.tags
            };

            const response = await dispatch(handleAddBuildingDirectory(payload))
            if (response?.payload?.status === 200) {
                toast.success(
                    id
                        ? "Building Directory updated successfully!"
                        : "Building Directory added successfully!"
                );
                if (window.history.length === 1) {
                    localStorage.setItem("isWindowClosed", "true");
                    window.close();
                } else {
                    navigate("/Building-Directory");
                }
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setSaveLoading(false);
        }
    };


    return (
        <>
            <div className="flex border-b border-gray">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <Navbar />
            </div>
            <div
                className={
                    userDetails?.isTrial &&
                        user?.userDetails?.isRetailer === false &&
                        !userDetails?.isActivePlan
                        ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
                        : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
                }
            >
                <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
                    <div className="px-6 page-contain">
                        <div>
                            <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                                <div className="flex items-center">
                                    {edited ? (
                                        <div className="flex items-center gap-2">
                                            <input
                                                type="text"
                                                className="w-full border border-primary rounded-md px-2 py-1"
                                                placeholder="Enter schedule name"
                                                value={instanceName}
                                                onChange={(e) => {
                                                    setInstanceName(e.target.value);
                                                }}
                                            />
                                            <MdSave
                                                onClick={() => handleOnSaveInstanceName()}
                                                className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                                                {instanceName}
                                            </h1>
                                            <button onClick={() => setEdited(true)}>
                                                <GoPencil className="ml-4 text-lg" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                                    <button
                                        className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                        onClick={() => { setShowPreview(!showPreview); }}
                                    >
                                        {showPreview ? "Edit" : "Preview"}
                                    </button>
                                    <button
                                        className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                        onClick={onSumbit}
                                        disabled={saveLoading}
                                    >
                                        {saveLoading ? "Saving..." : "Save"}
                                    </button>
                                    <Link to="/Building-Directory">
                                        <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                                            <AiOutlineClose />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            {showPreview && (
                                <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg h-full">
                                    <div className="w-[100%] h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 bg-slate-800 border-black flex items-center justify-center vertical-scroll-inner">
                                        <div className="bg-gray-800 h-full w-full p-5">
                                            <div className="flex justify-between items-center mb-3">
                                                <h1 className="text-white text-3xl">
                                                    {addFloor?.directoryName}
                                                </h1>
                                                {(addFloor?.MainLogo || addFloor?.appAssetURL) && (
                                                    <img
                                                        src={addFloor?.MainLogo?.assetFolderPath || addFloor?.appAssetURL}
                                                        alt="Logo"
                                                        className="w-20 h-20 object-cover rounded"
                                                    />
                                                )}

                                                <div className="text-white text-right mb-8">
                                                    <p className="mb-2">{addFloor?.QR_Title}</p>
                                                    {addFloor?.QR_Url && (
                                                        <div className="bg-white rounded-lg shadow-md flex items-center justify-center w-auto h-auto">
                                                            <QRCodeSVG
                                                                value={addFloor?.QR_Url}
                                                                size={150}
                                                                fgColor="#000000"
                                                                bgColor="#ffffff"
                                                                className="p-4"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 ">

                                                {addFloor?.Floor?.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`mb-5 p-5 ${item.Highlight ? "bg-[#eabf00]" : ""}  w-full gap-3`}>
                                                            <div>
                                                                <h2 className="text-white text-xl mb-4">
                                                                    {item?.Floorname}
                                                                </h2>
                                                                <hr className="text-[#cdcdcd]"></hr>
                                                                {item?.allItem?.map((items, index) => {
                                                                    return (
                                                                        <div key={index} >
                                                                            <div className="flex justify-between items-center bg-gray-700 p-4 rounded-lg ">
                                                                                <div className="flex items-center space-x-4">
                                                                                    {(items?.Logo || items?.roomAssetURL) && (
                                                                                        <img
                                                                                            src={items?.Logo?.assetFolderPath || items?.roomAssetURL}
                                                                                            alt="Logo"
                                                                                            className="w-12 h-12 object-cover rounded"
                                                                                        />
                                                                                    )}
                                                                                    <p className="text-white">{items?.name}</p>
                                                                                </div>
                                                                                {items?.roomNo && (
                                                                                    <h2 className={`font-semibold px-4 py-2 rounded-md ${item?.Highlight ? 'bg-[#2e323b] text-[#eabf00]' : 'bg-[#eabf00] text-[#2e323b]'}`}>{items?.roomNo}</h2>
                                                                                )}
                                                                            </div>
                                                                            <hr className="text-[#cdcdcd]" />
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!showPreview && (
                                <div className="grid grid-cols-12 gap-4 h-full">
                                    <div className="lg:col-span-12 md:col-span-12 sm:col-span-12 xs:col-span-12 shadow-md bg-white rounded-lg p-5  items-center">
                                        <div className="flex justify-between items-center flex-wrap bg-[#232c36] py-5 w-full px-3 mb-3">
                                            <div className=" ">
                                                <input
                                                    type="text"
                                                    className="w-full border border-primary rounded-md px-2 py-1 "
                                                    placeholder="Name this Directory"
                                                    value={addFloor.directoryName}
                                                    onChange={(e) => {
                                                        setAddFloor({ ...addFloor, directoryName: e.target.value })
                                                    }}
                                                />
                                            </div>
                                            <div className="flex justify-center  gap-3 flex-wrap">
                                                <a
                                                    className="hover:bg-white bg-[#56616c] cursor-pointer hover:text-primary text-base px-8 py-3 border border-[#56616c]  shadow-md rounded-full  text-white"
                                                    onClick={handleCustomize}
                                                >
                                                    QR Code
                                                </a>
                                                {!(addFloor.MainLogo || addFloor?.appAssetURL) ? (
                                                    <a
                                                        className="hover:bg-white bg-[#56616c] cursor-pointer hover:text-primary text-base px-8 py-3 border border-[#56616c]  shadow-md rounded-full  text-white"
                                                        onClick={() => {
                                                            setOpenModal(true);
                                                        }}
                                                    >
                                                        ADD Logo
                                                    </a>
                                                ) : (
                                                    <div className="flex items-center gap-4 justify-center">
                                                        <img
                                                            src={addFloor?.MainLogo?.assetFolderPath || addFloor?.appAssetURL}
                                                            className="w-12 h-12"
                                                        />
                                                        <a
                                                            className="cursor-pointer underline text-white"
                                                            onClick={handleDeleteMainLogo}
                                                        >
                                                            Remove
                                                        </a>
                                                    </div>
                                                )}

                                                <button
                                                    type="submit"
                                                    className="hover:bg-white bg-[#56616c] cursor-pointer hover:text-primary text-base px-8 py-3 border border-[#56616c]  shadow-md rounded-full  text-white"
                                                    onClick={() => {
                                                        handleAddFloor();
                                                    }}
                                                >
                                                    Add Floor
                                                </button>
                                            </div>
                                        </div>

                                        {addFloor &&
                                            addFloor?.Floor?.map((floor, index) => {
                                                return (
                                                    <>
                                                        <fieldset className={` ${addFloor?.Floor?.length >= 2 ? 'border border-solid border-gray-300 p-3 mb-8 mt-[40px]' : ''}`}>
                                                            <legend ></legend>
                                                            <legend className={`flex items-center justify-between mt-[-35px]   ${addFloor?.Floor?.length >= 2 ? 'block' : 'hidden'}`}>
                                                                <div className="cursor-pointer text-xl flex gap-3 rounded-full px-2 py-2 text-white text-center bg-white">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="Highlight"
                                                                        checked={floor?.Highlight}
                                                                        onChange={(e) =>
                                                                            handlecheckHighlight(
                                                                                index,
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                    />
                                                                    <p className=" text-black text-sm">
                                                                        Highlight
                                                                    </p>
                                                                </div>
                                                                <div className=" px-5 bg-white">
                                                                    <input
                                                                        type="text"
                                                                        value={floor?.Floorname}
                                                                        className="w-56 relative border-0 border-black rounded-md p-2 uppercase text-center"
                                                                        onChange={(e) =>
                                                                            handleFloorName(index, e.target.value)
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="flex justify-center gap-4 bg-white  px-3">
                                                                    {addFloor?.Floor?.length >= 2 && (
                                                                        <div
                                                                            className="cursor-pointer text-xl flex gap-3 rounded-full px-2 py-2 text-white text-center bg-[#FF0000]"
                                                                            onClick={() => handleDeleteCategory(index)}
                                                                        >
                                                                            <MdDeleteForever />
                                                                        </div>
                                                                    )}

                                                                    <div
                                                                        className="cursor-pointer text-xl flex gap-3 rounded-full px-2 py-2 text-white text-center bg-blue-400"
                                                                        draggable
                                                                        onDragStart={(event) =>
                                                                            handleDragStartForDivToDiv(
                                                                                event,
                                                                                floor,
                                                                                index
                                                                            )
                                                                        }
                                                                        onDrop={(event) =>
                                                                            handleDropForWithinFloorlist(
                                                                                event,
                                                                                index
                                                                            )
                                                                        }
                                                                        onDragOver={(event) =>
                                                                            handleDragOverForWithinFloorlist(event, index)
                                                                        }
                                                                    >
                                                                        <HiOutlineViewList />
                                                                    </div>
                                                                </div>
                                                            </legend>

                                                            <div className="clear-both bg-white rounded-xl mt-8 shadow screen-section  sc-scrollbar rounded-lg  mt-5 ">
                                                                <div className=" sc-scrollbar">
                                                                    <table
                                                                        className="screen-table w-full bg-white lg:table-auto md:table-auto sm:table-auto xs:table-auto"
                                                                        cellPadding={15}
                                                                    >
                                                                        <thead className="table-head-bg digital-menu-table-th uppercase">
                                                                            <tr className="items-center table-head-bg ">
                                                                                <th className="text-[#5A5881] text-base text-center font-semibold ">
                                                                                    Room / Office name
                                                                                </th>
                                                                                <th className="text-[#5A5881] text-base font-semibold  text-center">
                                                                                    ROOM NO./DIRECTION
                                                                                </th>
                                                                                <th className="text-[#5A5881] text-base font-semibold  text-center ">
                                                                                    LOGO
                                                                                </th>
                                                                                <th className="text-[#5A5881] text-base font-semibold  text-center ">
                                                                                    Actions
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {floor?.allItem &&
                                                                                floor?.allItem.map((items, indexs) => {
                                                                                    return (
                                                                                        <tr key={indexs} className="border-b text-center border-b-[#E4E6FF]">
                                                                                            <td className="text-[#5E5E5E] placeHolderColor">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    value={items?.name}
                                                                                                    className=" relative border-0 border-black rounded-md p-3"
                                                                                                    placeholder="Item Name"
                                                                                                    onChange={(e) =>
                                                                                                        handleNameChange(
                                                                                                            index,
                                                                                                            indexs,
                                                                                                            e.target.value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </td>

                                                                                            <td className="text-[#5E5E5E] text-center placeHolderColor">
                                                                                                <input
                                                                                                    type="number"
                                                                                                    value={items.roomNo}
                                                                                                    className=" relative border-0 text-center border-black rounded-md p-3"
                                                                                                    placeholder="0"
                                                                                                    onChange={(e) =>
                                                                                                        handleRoomNoChange(
                                                                                                            index,
                                                                                                            indexs,
                                                                                                            e.target.value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </td>

                                                                                            <td className="text-[#5E5E5E] text-center">
                                                                                                {(items?.Logo === "" || items?.roomAssetURL === '') ? (
                                                                                                    <a
                                                                                                        className="cursor-pointer underline"
                                                                                                        onClick={() => {
                                                                                                            setSelectCategory(index);
                                                                                                            setSelectItem(indexs);
                                                                                                            setOpenModal(true);
                                                                                                        }}
                                                                                                    >
                                                                                                        Upload
                                                                                                    </a>
                                                                                                ) : (
                                                                                                    <div className="flex items-center gap-4 justify-center">
                                                                                                        <img src={items?.Logo?.assetFolderPath || items?.roomAssetURL} className="w-24 h-16" alt="" />
                                                                                                        <a
                                                                                                            className="cursor-pointer underline"
                                                                                                            onClick={() => { handleDeleteLogo(index, indexs) }}
                                                                                                        >
                                                                                                            Remove
                                                                                                        </a>
                                                                                                    </div>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                <div className="flex justify-center gap-4">
                                                                                                    {addFloor?.Floor[0]?.allItem?.length >= 2 && (
                                                                                                            <div
                                                                                                                className="cursor-pointer text-xl flex gap-3 rounded-full px-2 py-2 text-white text-center bg-[#FF0000]"
                                                                                                                onClick={() =>
                                                                                                                    handleDeleteItem(
                                                                                                                        index,
                                                                                                                        indexs
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <MdDeleteForever />
                                                                                                            </div>
                                                                                                        )}
                                                                                                    <div
                                                                                                        className="cursor-pointer text-xl flex gap-3 rounded-full px-2 py-2 text-white text-center bg-blue-400"
                                                                                                        draggable
                                                                                                        onDragStart={(event) =>
                                                                                                            handleDragStartWithinlist(
                                                                                                                event,
                                                                                                                items,
                                                                                                                indexs
                                                                                                            )
                                                                                                        }
                                                                                                        onDrop={(event) =>
                                                                                                            handleDropForWithinlist(
                                                                                                                event,
                                                                                                                index,
                                                                                                                indexs
                                                                                                            )
                                                                                                        }
                                                                                                        onDragOver={(event) =>
                                                                                                            handleDragOverForWithinFloorlist(
                                                                                                                event,
                                                                                                                indexs
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <HiOutlineViewList />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className="flex justify-end items-center mr-3 mt-2">
                                                                <button
                                                                    className="border flex items-center gap-2 border-primary rounded-full px-3 py-2 not-italic font-medium"
                                                                    onClick={() => handleAddItem(index)}
                                                                >
                                                                    Add Item
                                                                </button>
                                                            </div>
                                                        </fieldset >
                                                    </>


                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {ShowOR && (
                <BuildingDirectoryQR
                    toggleModal={toggleModal}
                    addFloor={addFloor}
                    setAddFloor={setAddFloor}
                />
            )}

            {openModal && (
                <DigitalMenuAssets
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    setAssetPreviewPopup={setAssetPreviewPopup}
                    selectedAsset={selectedAsset}
                    handleAssetAdd={handleAssetAdd}
                    assetPreviewPopup={assetPreviewPopup}
                    assetPreview={assetPreview}
                    HandleSubmitAsset={HandleSubmitAsset}
                />
            )}
        </>
    );
};