import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";

export default function GoogleTrendsDetails({ sidebarOpen, setSidebarOpen }) {
  const { id } = useParams();
  const currentDate = new Date();

  const [instanceName, setInstanceName] = useState(moment(currentDate).format("YYYY-MM-DD hh:mm"));
  const [data, setData] = useState({ title: "", embedCode: "" });
  const [showPreview, setShowPreview] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const trendsContainerRef = useRef(null);

  useEffect(() => {
    if (id) {
      toast.loading("Fetching Data....");
    }
  }, [id]);

  // Dynamically load the Google Trends Embed script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://ssl.gstatic.com/trends_nrtr/3937_RC01/embed_loader.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // document.body.removeChild(script);
    };
  }, []);

  const renderTrendsChart = () => {
    if (window.trends && window.trends.embed && trendsContainerRef.current) {
      window.trends.embed.renderExploreWidget("TIMESERIES", {
        comparisonItem: [
          {
            keyword: data?.title || 'kgf 2', // Make this dynamic based on your input
            geo: "IN", // Example geo code, can be dynamic as well
            time: "now 1-d", // Set time dynamically
          },
        ],
        category: 0,
        property: "",
      }, {
        exploreQuery: `q=${encodeURIComponent(data?.title || 'kgf 2')}&date=now%201-d&geo=IN&hl=en`,
        guestPath: "https://trends.google.com:443/trends/embed/",
      });
    }
  };

  // Trigger the chart rendering once data and showPreview are available
  useEffect(() => {
    if (data?.embedCode && trendsContainerRef.current) {
      // renderTrendsChart(); // Trigger the chart rendering only when preview is enabled
    }
  }, [data?.embedCode,]);

  const addQuestionsApp = () => {
    if (instanceName === "" && data?.title === "" && data?.embedCode === "") {
      toast.remove();
      return toast.error("Please fill all the details");
    }

    const Payload = {
      googleTrendsAppId: id ? id : 0,
      instanceName: instanceName,
      title: data?.title,
      embedCode: data?.embedCode,
    };

    setSaveLoading(true);
    toast.loading('Saving ...');
    // Perform save logic here
  };

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div className="lg:pt-24 md:pt-24 pt-10 px-5 page-contain">
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div className="lg:flex lg:justify-between sm:block my-4 items-center">
              <div className="flex items-center">
                <h1 className="text-[#001737] lg:text-2xl">{instanceName}</h1>
              </div>
              <div className="flex md:mt-5 lg:mt-0">
                <button
                  className="flex align-middle bg-SlateBlue text-white items-center border rounded-full lg:px-6 sm:px-5 py-2.5"
                  onClick={() => setShowPreview(!showPreview)}
                >
                  {showPreview ? "Edit" : "Preview"}
                </button>
                <button
                  className="flex align-middle bg-SlateBlue text-white sm:mt-2 items-center border rounded-full lg:px-6 sm:px-5 py-2.5"
                  onClick={addQuestionsApp}
                  disabled={saveLoading}
                >
                  {saveLoading ? "Saving..." : "Save"}
                </button>
              </div>
            </div>

            {!showPreview && (
              <div className="flex flex-col lg:flex-row gap-8">
                <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                  <div className="mb-4 flex items-center justify-between">
                    <label className="w-2/5 mb-2 text-sm font-medium text-gray-900">Title*</label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5"
                      placeholder="Title"
                      value={data?.title}
                      onChange={(e) => setData({ ...data, title: e.target.value })}
                    />
                  </div>
                  <div className="mb-4 flex items-center justify-between">
                    <label className="w-2/5 mb-2 text-sm font-medium text-gray-900">Embed Code</label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5"
                      placeholder="Embed Code"
                      value={data?.embedCode}
                      onChange={(e) => setData({ ...data, embedCode: e.target.value })}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-2/4 h-[30rem] flex items-center justify-center bg-white shadow-lg rounded-xl p-5">
                  {/* <iframe
                    id="trends-widget-2"
                    src="https://trends.google.com:443/trends/embed/explore/TIMESERIES?req=%7B%22comparisonItem%22%3A%5B%7B%22keyword%22%3A%22bitcoin%22%2C%22geo%22%3A%22US%22%2C%22time%22%3A%22today%2012-m%22%7D%5D%2C%22category%22%3A0%2C%22property%22%3A%22%22%7D&amp;tz=-480&amp;eq=q%3Dbrexit%26geo%3DUS%26date%3Dtoday%2012-m"
                    width="100%"
                    height="300px"
                    frameborder="0"
                  /> */}
                  <div
                    ref={trendsContainerRef}
                    id="trends-embed-chart"
                    className="w-full h-full bg-white rounded-lg shadow-md flex items-center justify-center"
                    style={{ minHeight: "10rem" }} 
                  >
                    {data?.embedCode && renderTrendsChart()}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
