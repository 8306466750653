import React, { useEffect, useRef, useState } from 'react'
import Sidebar from '../../Sidebar'
import Navbar from '../../Navbar'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AiOutlineClose, AiOutlineStar } from 'react-icons/ai'
import { GoPencil } from 'react-icons/go'
import { useSelector } from 'react-redux'
import { MdSave } from 'react-icons/md'
import { Autocomplete, useLoadScript } from '@react-google-maps/api'
import axios from 'axios'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { CiStar } from "react-icons/ci";
import { GiRoundStar } from "react-icons/gi";
import { BsStarHalf } from 'react-icons/bs'
import { QRCodeSVG } from 'qrcode.react'
import { IoIosCall } from 'react-icons/io'
import { FcGoogle } from 'react-icons/fc'
import moment from 'moment'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { getGoogleReviewsById, handleAddGoogleReviews } from '../../../Redux/AppsSlice'

export default function GoogleReviewsDetails({ sidebarOpen, setSidebarOpen }) {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDL9J82iDhcUWdQiuIvBYa0t5asrtz3Swk', // Replace with your API key
        libraries: ['places'],
    });
    const navigate = useNavigate()
    const currentDate = new Date();
    const dispatch = useDispatch()
    const { id } = useParams()

    const { user, token, userDetails } = useSelector((state) => state.root.auth);
    const [showPreview, setShowPreview] = useState(false);
    const [edited, setEdited] = useState(false);
    const autocompleteRef = useRef(null);
    const [instanceName, setInstanceName] = useState(moment(currentDate).format("YYYY-MM-DD hh:mm"));
    const [ratingFilter, setratingFilter] = useState('All');
    const [PlaceDetails, setPlaceDetails] = useState();
    const [locationName, setlocationName] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        if (id) {
            toast.loading("Fetching Data....");
            dispatch(getGoogleReviewsById(id)).then((res) => {
                if (res?.payload?.status === 200) {
                    toast.remove();
                    const fetchData = res?.payload?.data?.obj
                    setInstanceName(fetchData?.instanceName)
                    setratingFilter(fetchData?.ratingFilter)
                    // setlocationName(fetchData?.location)
                }
            })
        }
    }, [id, dispatch]);

    const onPlaceChanged = () => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();
            if (place) {
                setPlaceDetails(place)
                setlocationName(place.formatted_address)
            }
        }
    };

    const renderStars = (rating, type) => {
        const fullStars = Math.floor(rating);
        const halfStars = rating % 1 >= 0.5 ? 1 : 0;
        const emptyStars = 5 - (fullStars + halfStars);
        const stars = [];

        for (let i = 0; i < fullStars; i++) {
            stars.push(<GiRoundStar key={`full-${i}`} className="text-[#ff8c00]" size={`${type === 'Rating' ? '25' : 35}`} />);
        }

        if (halfStars === 1) {
            stars.push(<BsStarHalf key="half" className="text-[#ff8c00]" size={`${type === 'Rating' ? '25' : 35}`} />);
        }
        for (let i = 0; i < emptyStars; i++) {
            stars.push(<AiOutlineStar key={`empty-${i}`} className="text-[#ff8c00]" size={`${type === 'Rating' ? '28' : 38}`} />);
        }

        return stars;
    };

    const handleOnSaveInstanceName = (e) => {
        if (!instanceName.replace(/\s/g, "").length) {
            toast.remove();
            return toast.error("Please enter at least minimum 1 character.");
        }
        setEdited(false);
    };

    const onSubmit = () => {
        if (locationName === "") {
            return toast.error("Please Enter location");
        }
        const Payload = {
            googleReviewsAppId: id ? id : 0,
            instanceName: instanceName,
            location: locationName,
            ratingFilter: ratingFilter,
            screens: "",
            screenIDs: "",
            maciDs: "",
            tags: ""
        }
        setSaveLoading(true);
        try {
            dispatch(handleAddGoogleReviews(Payload)).then((res) => {
                setSaveLoading(false);
                navigate('/Google-Reviews')
            })
        } catch (error) {
            console.log('error :>> ', error);
            setSaveLoading(false);
        }
    }


    if (!isLoaded) return;

    return (
        <div>
            <div className="flex border-b border-gray">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <Navbar />
            </div>

            <div
                className={
                    userDetails?.isTrial &&
                        user?.userDetails?.isRetailer === false &&
                        !userDetails?.isActivePlan
                        ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
                        : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
                }
            >
                <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
                    <div className="px-6 page-contain">
                        <div>
                            <div className="lg:flex lg:justify-between sm:block my-4 items-center relative z-10">
                                <div className="flex items-center">
                                    {edited ? (
                                        <div className="flex items-center gap-2">
                                            <input
                                                type="text"
                                                className="w-full border border-primary rounded-md px-2 py-1"
                                                placeholder="Enter InstanceName"
                                                value={instanceName}
                                                onChange={(e) => {
                                                    setInstanceName(e.target.value);
                                                }}
                                            />
                                            <MdSave
                                                onClick={() => handleOnSaveInstanceName()}
                                                className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                                                {instanceName}
                                            </h1>
                                            <button
                                                onClick={() => setEdited(true)}
                                            >
                                                <GoPencil className="ml-4 text-lg" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                                    <button
                                        className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                        onClick={() => {
                                            if (locationName === "") {
                                                return toast.error("Please Enter location");
                                            }
                                            setShowPreview(!showPreview);
                                        }}
                                    >
                                        {showPreview ? "Edit" : "Preview"}
                                    </button>
                                    <button
                                        className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                                        onClick={() => onSubmit()}
                                        disabled={saveLoading}
                                    >
                                        {saveLoading ? "Saving..." : "Save"}
                                    </button>

                                    <Link to="/Google-Reviews">
                                        <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                                            <AiOutlineClose />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            {showPreview && (
                                <div className="w-full h-[42rem] bg-gray-50 rounded-sm shadow-md border-4 border-black  flex items-center justify-center mt-5">
                                    {PlaceDetails?.reviews?.length > 0 ? (
                                        <Swiper
                                            modules={[Autoplay]}
                                            centeredSlides={true}
                                            slidesPerView={1}
                                            allowTouchMove={false}
                                            loop={false}
                                            autoplay={{
                                                delay: 10000,
                                                disableOnInteraction: false,
                                            }}
                                        >
                                            {PlaceDetails?.reviews && PlaceDetails?.reviews?.length > 0 && PlaceDetails?.reviews
                                                .filter((review) => {
                                                    if (ratingFilter === "All") {
                                                        return true;
                                                    }
                                                    if (ratingFilter === "5") {
                                                        return review?.rating === 5;
                                                    }
                                                    if (ratingFilter === "4+") {
                                                        return review?.rating >= 4;
                                                    }
                                                    if (ratingFilter === "3+") {
                                                        return review?.rating >= 3;
                                                    }
                                                    if (ratingFilter === "3 or less") {
                                                        return review?.rating <= 3;
                                                    }
                                                    return true;
                                                })
                                                .map((review, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div
                                                            className="w-full h-full flex items-center justify-center bg-cover bg-center text-white relative bg-white"
                                                        >
                                                            <div className="inset-0 text-center w-full h-full">
                                                                <div className='px-20 my-8'>
                                                                    <div className='flex items-center gap-3 mb-5'>
                                                                        <img className="h-auto max-w-[50px] border rounded-full shadow-xl dark:shadow-gray-800"
                                                                            src={review?.profile_photo_url}
                                                                            alt='' />
                                                                        <h2 className="text-3xl text-black">{review?.author_name}</h2>

                                                                    </div>
                                                                    <div className={`overflow-hidden ${review?.text?.length > 800 ? "auto-scroll" : ""} w-full break-words`}>
                                                                        <p
                                                                            className="text-3xl text-black font-bold leading-10 text-start"
                                                                            // style={{
                                                                            //     animationDuration: review?.text?.length > 800 ? "50s" : "0s",
                                                                            //     animationDelay: review?.text?.length > 800 ? "2s" : "0s",
                                                                            // }}
                                                                        >
                                                                            {review?.text}
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                                <div className="flex gap-1 py-3 px-2 bg-white absolute bottom-[180px] w-full" style={{ padding: "10px 0 10px 5rem" }}>
                                                                    {renderStars(review?.rating)}
                                                                </div>
                                                                <div className='text-black w-full bg-[#f4f4f6] flex justify-between items-center px-20 py-10 mb-0 absolute  bottom-0'>
                                                                    <div>
                                                                        <h1 className='font-bold text-left'>{PlaceDetails?.name}</h1>
                                                                        <p className='text-xs'>{PlaceDetails?.formatted_address}</p>
                                                                        {PlaceDetails?.formatted_phone_number && (
                                                                            <p className='text-xs  flex items-center gap-y-2 justify-end '>
                                                                                <span><IoIosCall /></span>
                                                                                {PlaceDetails?.formatted_phone_number}</p>
                                                                        )}
                                                                        <div className="flex gap-1 mt-3 text-sm items-center">
                                                                            <FcGoogle size={25} />  {renderStars(PlaceDetails?.rating, 'Rating')} <span className='font-bold  text-[#808080]'>based on all reviews</span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <QRCodeSVG
                                                                            value={PlaceDetails?.url}
                                                                            size={100}
                                                                            fgColor="#000000"
                                                                            bgColor="#ffffff"
                                                                            className="p-4"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                        </Swiper>
                                    ) : (
                                        <div className='text-black m-auto'>
                                            <h1 className='font-medium text-3xl mb-10'>{locationName}</h1>
                                            <p className='text-2xl'>There is no reviews found. Please check back later.</p>
                                        </div>
                                    )}
                                </div>

                            )}
                            {!showPreview && (
                                <div className='w-8/12 m-auto flex items-center justify-center absolute top-0 bottom-0 left-0 right-0 flex-col'>
                                    <Autocomplete
                                        onLoad={(ref) => (autocompleteRef.current = ref)}
                                        onPlaceChanged={onPlaceChanged}
                                        renderMenu={item => (
                                            <div className="dropdown">
                                                {item}
                                            </div>
                                        )}
                                    >
                                        <input type="text"
                                            placeholder="Type in your location here to get the reviews of your business..."
                                            className='appearance-none border border-[#D5E3FF] rounded py-2 px-3 shadow-xl w-[430px]'
                                            value={locationName}
                                            onChange={(e) => { setlocationName(e.target.value); }}
                                        />
                                    </Autocomplete>
                                    <div className="my-5 m-auto inline-flex items-center w-full justify-center gap-8">
                                        <label
                                            htmlFor="message"
                                            className="text-lg font-medium text-gray-900 dark:text-white"
                                        >
                                            Rating filter
                                        </label>
                                        <select
                                            className="bg-gray-50 font-semibold border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={ratingFilter}
                                            onChange={(e) => setratingFilter(e.target.value)}
                                        >
                                            <option value="All">All</option>
                                            <option value="5">5</option>
                                            <option value="4+">4+</option>
                                            <option value="3+">3+</option>
                                            <option value="3 or less">3 or less</option>
                                        </select>
                                    </div>
                                    <p className='text-md text-center'>Google only provides the five most helpful reviews at this time.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
