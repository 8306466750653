import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../Sidebar";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TbAppsFilled } from "react-icons/tb";
import { MdArrowBackIosNew, MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin5Line, RiDeleteBinLine } from "react-icons/ri";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
import clock from "../../../images/AppsImg/clock.svg";
import { deleteClock, getClockById, getClocksApps } from "../../../Redux/AppsSlice";
import Loading from "../../Loading";
import SweetAlert from "../../BookYourSlot/SweetAlert";
import axios from "axios";
import { ADD_CLOCK_TAGS, ALL_CLOCK_DATE, ASSIGN_CLOCK_SCREEN } from "../../../Pages/Api";
import AddOrEditTagPopup from "../../AddOrEditTagPopup";
import toast from "react-hot-toast";
import ClockPreview from "./ClockPreview";
import { socket } from "../../../App";
import ScreenAssignModal from "../../ScreenAssignModal";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Clock = ({ sidebarOpen, setSidebarOpen }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { userDetails, user, token } = useSelector((state) => state.root.auth);
  const authToken = `Bearer ${token}`;
  const appDropdownRef = useRef(null);

  const [selectAll, setselectAll] = useState(false);
  const [instanceID, setInstanceID] = useState();
  const [appDropDown, setAppDropDown] = useState(null);
  const [loadFirst, setloadFirst] = useState(true);
  const [sidebarload, setSidebarLoad] = useState(false);
  const [clocksData, setClocksData] = useState([]);
  const [updateTag, setUpdateTag] = useState(null);
  const [tags, setTags] = useState([]);
  const [showTagModal, setShowTagModal] = useState(false);
  const [instanceView, setInstanceView] = useState(false);
  const [EditClockData, setEditClockData] = useState();
  const [selectScreenModal, setSelectScreenModal] = useState(false);
  const [addScreenModal, setAddScreenModal] = useState(false);
  const [selectedScreens, setSelectedScreens] = useState([]);
  const [selectdata, setSelectData] = useState({});
  const [screenSelected, setScreenSelected] = useState([]);

  useEffect(() => {
    if (loadFirst) {
      setSidebarLoad(true)
      dispatch(getClocksApps({})).then((res) => {
        setClocksData(res?.payload?.data)
        setSidebarLoad(false)
      })
      setloadFirst(false)
    }
  }, [dispatch, loadFirst]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        appDropdownRef.current &&
        !appDropdownRef.current.contains(event?.target)
      ) {
        setAppDropDown(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleSelectAll = () => {
    setselectAll((prev) => !prev);
  };

  const handleAppDropDownClick = (id) => {
    setInstanceID(id);
    if (appDropDown === id) {
      setAppDropDown(null);
    } else {
      setAppDropDown(id);
    }
  };

  const handlerDeleteClock = async (id) => {
    try {
      const result = await SweetAlert.confirm("Are you sure?", "Are you sure you want to delete this!");
      if (result?.isConfirmed) {
        dispatch(deleteClock(id)).then((res) => {
          if (res?.payload?.status === true) {
            setloadFirst(true)
            SweetAlert.success("Deleted successfully");
          }
        });
      }
    } catch (error) {
      console.error("Error:", error);
      SweetAlert.error("An error occurred");
    }
  }

  const handleUpdateTags = (tags) => {

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${ADD_CLOCK_TAGS}?clockAppId=${updateTag?.clockAppId}&tags=${tags.length === 0 ? "" : tags}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.status === 200) {
          const updatedData = clocksData?.length > 0 && clocksData?.map((item) => {
            if (item?.clockAppId === updateTag?.clockAppId) {
              return { ...item, tags: tags };
            } else {
              return item;
            }
          });
          setClocksData(updatedData);
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const handelDeleteAllInstance = () => {
    if (!window.confirm("Are you sure?")) return;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: ALL_CLOCK_DATE,
      headers: { Authorization: authToken },
    };

    toast.loading("Deleting...");
    axios
      .request(config)
      .then(() => {
        toast.remove();
        setselectAll(false);
        setClocksData([]);
      })
      .catch((error) => {
        toast.remove();
        console.log(error);
      });
  };

  const handleCheckboxChange = (instanceId) => {
    const updatedInstance = clocksData?.map((date) =>
      date.clockAppId === instanceId
        ? {
          ...date,
          isChecked: !date.isChecked,
        }
        : date
    );

    setClocksData(updatedInstance);
    const allChecked = updatedInstance.every((date) => date.isChecked);
    setselectAll(allChecked);
  };

  const fetchclockbyID = (id) => {
    toast.loading("Fetching Data....");

    dispatch(getClockById(id)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.remove();
        const fetchData = res?.payload?.data
        setEditClockData(fetchData)
        setInstanceView(true);
        setScreenSelected(fetchData?.list?.screens?.split(","));

      }
    })
  }

  const handleUpdateScreenAssign = (screenIds, macIds) => {
    let ids = "";
    for (const key in screenIds) {
      if (screenIds[key] === true) {
        ids += `${key},`;
      }
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${ASSIGN_CLOCK_SCREEN}?clockAppId=${instanceID}&screenId=${ids}`,
      headers: {
        Authorization: authToken,
      },
    };
    toast.loading("Saving...");

    axios
      .request(config)
      .then((response) => {
        if (response.data.status === 200) {
          toast.remove()
          const Params = {
            id: socket.id,
            connection: socket.connected,
            macId: macIds,
          };
          socket.emit("ScreenConnected", Params);
        }
        setSelectScreenModal(false);
        setAddScreenModal(false);
        setloadFirst(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {sidebarload && <Loading />}
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial && !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 pt-10 px-5"
            : "lg:pt-24 md:pt-24 pt-10 px-5 "
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="lg:flex lg:justify-between sm:block items-center">
            <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
              Apps
            </h1>
            <div className="lg:flex">
              <Link to="/Clockdetail">
                <button className="flex items-center bg-SlateBlue text-white rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2 text-base sm:text-sm mr-3 hover:bg-primary">
                  <TbAppsFilled className="text-2xl mr-2" />
                  New Instance
                </button>
              </Link>
              <Link to="/apps">
                <button className="flex items-center bg-SlateBlue text-white rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2 text-base sm:text-sm mr-3 hover:bg-primary">
                  <MdArrowBackIosNew className="text-2xl mr-2" />
                  Back
                </button>
              </Link>
            </div>
          </div>
          <div className="mt-5 mb-5">
            <div className="shadow-md bg-white rounded-lg p-5">
              <div className="flex justify-between items-center">
                <h1 className="font-medium text-xl text-[#001737] ">Clock</h1>
                <div className="flex items-center">
                  {selectAll && (
                    <button className="w-8 h-8 ml-2 border-primary items-center border-2 rounded-full px-1 text-2xl hover:bg-SlateBlue" onClick={handelDeleteAllInstance}>
                      <RiDeleteBinLine className="text-xl" />
                    </button>
                  )}
                  <button className="sm:ml-2 xs:ml-1 mt-1">
                    <input
                      type="checkbox"
                      className="h-7 w-7 cursor-pointer"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </button>
                </div>
              </div>

              <div className=" grid grid-cols-12 gap-4 mt-5">
                {clocksData?.length > 0 ?
                  clocksData.map((item, index) => (
                    <div
                      key={index}
                      className="xl:col-span-2 lg:col-span-3 md:col-span-4 sm:col-span-12"
                    >
                      <div className="shadow-md bg-[#EFF3FF] rounded-lg h-full">
                        <div className="relative flex justify-between">
                          <button className="float-right p-2">
                            <input
                              className="h-5 w-5 cursor-pointer"
                              type="checkbox"
                              style={{
                                display: selectAll ? "block" : "none",
                              }}
                              checked={true}
                              onChange={() =>
                                handleCheckboxChange(item?.clockAppId)

                              }
                            />
                          </button>
                          <div className="relative">
                            <button className="float-right">
                              <BiDotsHorizontalRounded
                                className="text-2xl"
                                onClick={() => handleAppDropDownClick(item?.clockAppId)}
                              />
                            </button>
                            {appDropDown === item?.clockAppId && (
                              <div ref={appDropdownRef} className="appdw">
                                <ul className="space-y-2">
                                  <li
                                    onClick={() => navigate(`/Clockdetail/${item?.clockAppId}`)}
                                    className="flex text-sm items-center cursor-pointer"
                                  >
                                    <MdOutlineEdit className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                    Edit
                                  </li>
                                  <li
                                    className="flex text-sm items-center cursor-pointer"
                                    onClick={() => {
                                      setAddScreenModal(true);
                                      setSelectData(item);

                                    }}
                                  >
                                    <FiUpload className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                    Set to Screen
                                  </li>
                                  <li
                                    className="flex text-sm items-center cursor-pointer"
                                    onClick={() =>
                                      handlerDeleteClock(item?.clockAppId)
                                    }
                                  >
                                    <RiDeleteBin5Line className="mr-2 min-w-[1.5rem] min-h-[1.5rem]" />
                                    Delete
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-center clear-both pb-8">
                          <img
                            src='https://back.disploy.com/App/5f2e7668-49a2-4302-b088-533865bfaaa9.svg'
                            alt="Clock"
                            className="cursor-pointer mx-auto h-20 w-20"
                            onClick={() => {
                              fetchclockbyID(item?.clockAppId);
                            }}
                          />
                          <h4 className="text-lg font-medium mt-3">{item?.instanceName}</h4>
                          <h4
                            onClick={() => {
                              item?.tags !== null &&
                                item?.tags !== undefined &&
                                item?.tags !== ""
                                ? setTags(item?.tags?.split(","))
                                : setTags([]);
                              setShowTagModal(true);
                              setUpdateTag(item);

                            }}
                            className="text-sm font-normal cursor-pointer"
                          >
                            Add tags +
                          </h4>
                        </div>
                      </div>
                    </div>
                  )) :
                  <p className="text-center">No Clock data available.</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {addScreenModal && (
        <div className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-9990 outline-none focus:outline-none">
          <div
            className="w-auto my-6 mx-auto lg:max-w-4xl md:max-w-xl sm:max-w-sm xs:max-w-xs"
          >
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-4 px-6 border-b border-[#A7AFB7] border-slate-200 rounded-t text-black">
                <div className="flex items-center">
                  <h3 className="lg:text-lg md:text-lg sm:text-base xs:text-sm font-medium">
                    Select the screen to set the App
                  </h3>
                </div>
                <button
                  className="p-1 text-xl ml-8"
                  onClick={() => setAddScreenModal(false)}
                >
                  <AiOutlineCloseCircle className="text-2xl" />
                </button>
              </div>
              <div className="flex justify-center p-9 ">
                <p className="break-words w-[280px] text-base text-black text-center">
                  New Clock App Instance would be applied. Do you want to
                  proceed?
                </p>
              </div>
              <div className="pb-6 flex justify-center">
                <button
                  className="bg-primary text-white px-8 py-2 rounded-full"
                  onClick={() => {
                    if (selectdata?.screenIDs) {
                      let arr = [selectdata?.screenIDs];
                      let newArr = arr[0]
                        .split(",")
                        .map((item) => parseInt(item.trim()));
                      setSelectedScreens(newArr);
                    }
                    setSelectScreenModal(true);
                    setAddScreenModal(false);
                  }}
                >
                  OK
                </button>

                <button
                  className="bg-primary text-white px-4 py-2 rounded-full ml-3"
                  onClick={() => setAddScreenModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showTagModal && (
        <AddOrEditTagPopup
          setShowTagModal={setShowTagModal}
          tags={tags}
          setTags={setTags}
          handleUpdateTagsClock={handleUpdateTags}
          from="Clock"
          setUpdateTagClock={setUpdateTag}
        />
      )}
      {instanceView && (
        <ClockPreview
          setInstanceView={setInstanceView}
          EditClockData={EditClockData}
        />
      )}

      {selectScreenModal && (
        <ScreenAssignModal
          setAddScreenModal={setAddScreenModal}
          setSelectScreenModal={setSelectScreenModal}
          handleUpdateScreenAssign={handleUpdateScreenAssign}
          selectedScreens={selectedScreens}
          setSelectedScreens={setSelectedScreens}
          screenSelected={screenSelected}
          sidebarOpen={sidebarOpen}
        />
      )}
    </>
  );
};

export default Clock;
