import React from "react";
import { useState, useEffect, } from "react";
import moment from "moment";
import { GoPencil } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { MdSave } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { AddWordClockData, GetByIdWordClockData } from "../../../Redux/WorldClockSlice";
import { AiOutlineClose } from "react-icons/ai";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import { AnalogClock } from '@hoseinh/react-analog-clock';
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';

const ct = require("countries-and-timezones");

export default function WorldClockDetails({ sidebarOpen, setSidebarOpen }) {
  const currentDate = new Date();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useNavigate();
  const { user, token, userDetails } = useSelector((state) => state.root.auth);
  const authToken = `Bearer ${token}`;
  const GoogleAPIkey = "AIzaSyDL9J82iDhcUWdQiuIvBYa0t5asrtz3Swk";
  const [instanceName, setInstanceName] = useState(moment(currentDate).format("YYYY-MM-DD hh:mm"));
  const [edited, setEdited] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [location, setLocation] = useState([
    {
      location: "",
      plaseholder: "New York,us",
      formatted_address: "",
    },
    {
      location: "",
      plaseholder: "Bankok,Thailend",
      formatted_address: "",
    },
    {
      location: "",
      plaseholder: "",
      formatted_address: "",
    },
    {
      location: "",
      plaseholder: "",
      formatted_address: "",
    },
    {
      location: "",
      plaseholder: "",
      formatted_address: "",
    },
    {
      location: "",
      plaseholder: "",
      formatted_address: "",
    },
  ]);
  const [clocktype, setClockType] = useState("analog");
  const [filterclock, setFilterClock] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);

  const FetchDataById = async () => {
    try {
      if (id) {
        const response = await dispatch(GetByIdWordClockData({ authToken, id })).unwrap();
        if (response?.status === 200) {
          const data = response?.data?.list;
          setLocation((prev) =>
            prev?.map((item, index) => ({
              ...item,
              location: data[`location${index + 1}`] || "",
            }))
          );
          setTriggerFetch(true);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    FetchDataById();
  }, [id]);

  const handleOnSaveInstanceName = (e) => {
    if (!instanceName.replace(/\s/g, "").length) {
      toast.remove();
      return toast.error("Please enter at least minimum 1 character.");
    }
    setEdited(false);
  };

  const handlInputeChange = (e, index) => {
    const { value } = e.target;
    setClockType(value);
  };

  const handleLocation = (e, index) => {
    const { value, name } = e.target;
    setLoading(true);
    setLocation((prev) => {
      const updatedLocations = [...prev];
      updatedLocations[index] = {
        ...updatedLocations[index],
        [name]: value,
      };
      return updatedLocations;
    });
    setTriggerFetch(true);
    setLoading(false);
  };

  const fetchLocation = async () => {
    if (GoogleAPIkey) {
      try {
        location?.map(async (items, index) => {
          if (items?.location !== "") {
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                items?.location
              )}&key=${GoogleAPIkey}`
            );
            if (response?.data?.results?.length > 0) {
              const { lat, lng } =
                response?.data?.results[0]?.geometry?.location;
              const geocoder = new window.google.maps.Geocoder();
              const countrycode = await new Promise((resolve, reject) => {
                geocoder.geocode(
                  { location: { lat, lng } },
                  (results, status) => {
                    if (
                      status === window.google.maps.GeocoderStatus.OK &&
                      results[0]
                    ) {
                      const country = results[0].address_components.find(
                        (component) => component.types.includes("country")
                      );
                      resolve(country ? country?.short_name : null);
                    } else {
                      resolve(null);
                    }
                  }
                );
              });
              const country = ct.getCountry(countrycode);
              const timezone = country?.timezones[0];
              setLocation((prev) => {
                const updatedGeocode = [...prev];
                updatedGeocode[index] = {
                  ...updatedGeocode[index],
                  timezone: timezone,
                  formatted_address:
                    response?.data?.results[0]?.formatted_address
                      ?.split(",")[0]
                      ?.trim(),
                };
                return updatedGeocode;
              });
            } else {
              setLocation((prev) => {
                const updatedGeocode = [...prev];
                updatedGeocode[index] = {
                  ...updatedGeocode[index],
                  timezone: "",
                  formatted_address: "",
                };
                return [...updatedGeocode];
              });
            }
          } else {
            setLocation((prev) => {
              const updatedGeocode = [...prev];
              updatedGeocode[index] = {
                ...updatedGeocode[index],
                formatted_address: "",
              };
              return [...updatedGeocode];
            });
          }
        });
      } catch (error) {
        console.error("Error fetching the location:", error);
      }
    }
  };

  useEffect(() => {
    fetchLocation();
    setTriggerFetch(false);
  }, [triggerFetch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLocation((prev) =>
        prev.map((item) => {
          if (!item?.location) {
            return item;
          }
          const timezone = item.timezone || "Asia/Kolkata";
          const currentTime = moment().tz(timezone);
          return {
            ...item,
            hours:
              clocktype === "digital24"
                ? currentTime.format("HH")
                : currentTime.format("hh"),
            minutes: currentTime.format("mm"),
            second: currentTime.format("ss"),
            ampm: clocktype === "digital24" ? "" : currentTime.format("A"),
          };
        })
      );
    }, 1000);
    return () => clearInterval(intervalId);
  }, [clocktype]);

  useEffect(() => {
    const filterdata = location?.filter((items, index) => items?.location !== "");
    setFilterClock(filterdata);
  }, [location]);

  const handleInsertWorldClock = async () => {
    try {
      const data = JSON.stringify({
        worldClockAppId: id || 0,
        instanceName: instanceName,
        clockType: clocktype,
        location1: location[0].location,
        location2: location[1].location,
        location3: location[2].location,
        location4: location[3].location,
        location5: location[4].location,
        location6: location[5].location,
      });
      setSaveLoading(true);
      const response = await dispatch(
        AddWordClockData({ authToken, data })
      ).unwrap();
      if (response?.status === 200) {
        toast.success(
          id
            ? "World Clock updated successfully!"
            : "World Clock added successfully!"
        );
        if (window.history.length === 1) {
          localStorage.setItem("isWindowClosed", "true");
          window.close();
        } else {
          history("/World-Clock");
        }
      } else {
        console.error("Unexpected response:", response);
        toast.error("Failed to World Clock");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setSaveLoading(false);
    }
  };




  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
            user?.userDetails?.isRetailer === false &&
            !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div>
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter schedule name"
                        value={instanceName}
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      if (!filterclock?.length > 0) {
                        toast.error("Please Enter any Location");
                        return;
                      }
                      setShowPreview(!showPreview);
                    }}
                  >
                    {showPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      if (!filterclock?.length > 0) {
                        return toast.error("Please Enter any Location");
                      }
                      handleInsertWorldClock();
                    }}
                    disabled={saveLoading}
                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>

                  <Link to="/World-Clock">
                    <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>
              {showPreview && (
                <div className=" w-[85%] h-[45rem]  flex flex-col lg:flex-row bg-white shadow-lg rounded-lg mx-auto mt-5 ">
                  <div className="w-[100%] h-[45rem] border-[5px] border-gray-800 flex items-center justify-center flex-wrap gap-8 bg-gray-100 p-2 shadow-2xl bg-[#b3b4b53b]">
                    {filterclock?.length > 0 && filterclock?.map((clock, index) => {
                      return clocktype === "analog" ? (
                        <div
                          key={index}
                          className="text-center flex flex-col justify-center items-center text-gray-800 px-4 hover:scale-105 transition-transform duration-300 w-[25%]"
                        >
                          <div className="text-2xl font-bold">
                            {(clock?.hours && clock?.minutes && clock?.second) && (
                              <Clock
                                key={index}
                                value={`${clock?.hours}:${clock?.minutes}:${clock?.second}`}
                                format="HH:mm:ss"
                                size={200}
                                renderNumbers={true}
                                secondHandLength={80}
                                secondHandOppositeLength={20}
                                secondHandWidth={2}
                                useMillisecondPrecision={true}
                                backgroundColor="black"
                                className=' text-sm font-medium bg-[#f4f4f4] text-black  rounded-full border-4'
                              />
                            )}
                          </div>
                          <div className="text-sm text-gray-600 mt-2 w-[100px] max-w-[100px]">
                            {clock?.formatted_address || "Local time"}
                          </div>
                        </div>
                      ) : clocktype === "digital24" ? (
                        <div
                          key={index}
                          className="text-center flex flex-col justify-center items-center  text-gray-800 px-4 hover:scale-105 transition-transform duration-300 w-[25%] "
                        >
                          <div className="text-[55px] font-semibold flex items-center text-[#222222]">
                            <span>
                              {clock?.hours}:{clock?.minutes}
                            </span>
                            <p className="text-sm ml-2">{clock?.ampm}</p>
                          </div>
                          <div className="text-[1.5rem] text-[#797979] mt-2  font-semibold">
                            {clock?.formatted_address || "Local time"}
                          </div>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className="text-center flex flex-col justify-center items-center text-gray-800 px-4 hover:scale-105 transition-transform duration-300 w-[25%] "
                        >
                          <div className="text-[55px] font-semibold flex items-center text-[#222222]">
                            <span>
                              {clock?.hours}:{clock?.minutes}
                            </span>
                            <span className="text-sm mt-3">{clock?.ampm}</span>
                          </div>
                          <div className="text-[1.5rem] text-[#797979] mt-2  font-semibold ">
                            {clock?.formatted_address || "Local time"}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                </div>
              )}
              {!showPreview && (
                <div className="flex flex-col lg:flex-row rounded-lg h-full gap-3">
                  <div className="w-full lg:w-2/4 pr-0 lg:pr-4 mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                    <div className="mb-3 relative inline-flex items-center w-full">
                      <label
                        htmlFor="message"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Clock type
                      </label>
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={clocktype}
                        onChange={handlInputeChange}
                      >
                        <option value="digital24">Digital 24 hour clock</option>
                        <option value="digital12">Digital am/pm clock</option>
                        <option value="analog">Analog clock</option>
                      </select>
                    </div>
                    {location?.map((items, index) => (
                      <div className="mb-4 flex items-center justify-between">
                        <label
                          htmlFor="countries"
                          className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Location {index + 1} {index === 0 ? "*" : ""}
                        </label>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder={items?.plaseholder}
                          value={items?.location}
                          name="location"
                          onChange={(e) => handleLocation(e, index)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="w-full lg:w-2/4 h-[full] flex items-center justify-center lg:mt-0 bg-white shadow-lg p-5">
                    <div className="w-[100%]  border-[5px] border-gray-800 flex items-center justify-center flex-wrap gap-8 bg-gray-100 p-2 shadow-2xl bg-[#b3b4b53b] w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none ">
                      {filterclock?.length > 0 && filterclock?.map((clock, index) =>
                        clocktype === "analog" ? (
                          // console.log('clock :>> ', clock),
                          <div
                            key={index}
                            className="text-center flex flex-col justify-center items-center text-gray-800 px-4 hover:scale-105 transition-transform duration-300 w-[25%]"
                          >
                            <div className="text-2xl font-bold">
                              {/* <AnalogClock
                                showMinuteHand={true}
                                showSecondHand={true}
                                showBorder={true}
                                showHandBase={true}
                                smooth={false}
                                whiteNumbers={true}
                                square={false}
                                numbersType="numbersAndLines"
                                borderColor="#000000"
                                handBaseColor="red"
                                handColor={{ hour: "white", minute: "white", second: "#e74c3c" }}
                                handThickness={{ hour: "3px", minute: "3px", second: "2px" }}
                                handLength={{ hour: "53px", minute: "65px", second: "65px" }}
                                size="150px"
                                backgroundColor="black"
                                seconds={clock?.second}
                                minutes={clock?.minutes}
                                hours={clock?.hours}
                              /> */}

                              {(clock?.hours && clock?.minutes && clock?.second) && (
                                <Clock
                                  key={index}
                                  // value={clock}
                                  value={`${clock?.hours}:${clock?.minutes}:${clock?.second}`}
                                  // value={'9:30:20'}
                                  format="HH:mm:ss"
                                  size={150}
                                  renderNumbers={true}
                                  secondHandLength={80}
                                  secondHandOppositeLength={20}
                                  useMillisecondPrecision={true}
                                  // numbersType="numbersAndLines"
                                  backgroundColor="black"
                                  className=' text-sm font-medium bg-[#f4f4f4] text-black  rounded-full border-4'
                                />
                              )}
                            </div>
                            <div className="text-sm text-gray-600 mt-2 w-[100px] max-w-[100px] ">
                              {clock?.formatted_address || "Local time"}
                            </div>
                          </div>
                        ) : clocktype === "digital24" ? (
                          <div
                            key={index}
                            className="text-center flex flex-col justify-center items-center  text-gray-800 px-4 hover:scale-105 transition-transform duration-300 w-[25%] "
                          >
                            <div className="text-[35px] font-semibold flex items-center text-[#222222]">
                              <span>
                                {clock?.hours}:{clock?.minutes}
                              </span>
                              <p className="text-sm ml-2">{clock?.ampm}</p>
                            </div>
                            <div className="text-[1.5rem] text-[#797979] mt-2  font-semibold">
                              {clock?.formatted_address || "Local time"}
                            </div>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className="text-center flex flex-col justify-center items-center text-gray-800 px-4 hover:scale-105 transition-transform duration-300 w-[25%] "
                          >
                            <div className="text-[35px] font-semibold flex items-center text-[#222222]">
                              <span>
                                {clock?.hours}:{clock?.minutes}
                              </span>
                              <span className="text-sm mt-3">{clock?.ampm}</span>
                            </div>
                            <div className="text-[1.5rem] text-[#797979] mt-2  font-semibold ">
                              {clock?.formatted_address || "Local time"}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

