import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { GoPencil } from "react-icons/go";
import { MdSave } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  getHeadlineNewsById,
  handleAddHeadlineNews,
} from "../../../Redux/AppsSlice";
import { calculateTimeDifference, HeadlineCategoryOptions, HeadlineNewsTypeOption, onThisDayStaticData } from "../../Common/Common";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";
import { GET_NEWS_DATA } from "../../../Pages/Api";
import { handleGetNewsData } from "../../../Redux/CommonSlice";

export const HeadlineNewsDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, userDetails, token } = useSelector((state) => state.root.auth);
  const authToken = `Bearer ${token}`;
  const { id } = useParams();
  const [page, setPage] = useState(id ? 2 : 1);
  const [edited, setEdited] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [getNewsData, setGetNewsData] = useState([]);
  console.log('getNewsData', getNewsData)
  const [instanceName, setInstanceName] = useState(
    moment().format("YYYY-MM-DD hh:mm A")
  );
  const [headlineNews, setHeadlineNews] = useState({
    newsType: "BBC",
    category: "World",
    postVisiblity: false,
    visiblityDays: "15",
    isQR: false,
    isResume: false,
    postDuration: {
      select: "default",
      second: "15",
    },
  });

  const handleChange = (e) => {
    const { value, name } = e.target;

    if (name === "newsType") {
      setHeadlineNews((prev) => ({
        ...prev,
        [name]: value,
        category: HeadlineCategoryOptions[value][0],
      }));
    } else {
      setHeadlineNews((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const handleMuteChange = (e) => {
    const { name, checked } = e.target;
    setHeadlineNews((pre) => ({
      ...pre,
      [name]: checked,
    }));
  };
  const handlePost_DurationChange = (e) => {
    const { value, name } = e.target;
    if (name === "select") {
      let duration = "";
      if (value === "slow") {
        duration = 30;
      } else if (value === "default") {
        duration = 15;
      } else if (value === "fast") {
        duration = 5;
      }
      setHeadlineNews((prev) => ({
        ...prev,
        postDuration: {
          ...prev.postDuration,
          [name]: value,
          second: duration,
        },
      }));
    } else {
      setHeadlineNews((prev) => ({
        ...prev,
        postDuration: {
          ...prev.postDuration,
          [name]: value,
        },
      }));
    }
  };
  const handleOnSaveInstanceName = () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    setEdited(false);
  };
  const onSubmit = async () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    let payload = {
      headlineNewsAppId: id ? id : 0,
      instanceName: instanceName,
      visiblityDays: headlineNews?.visiblityDays,
      postVisiblity: headlineNews?.postVisiblity,
      category: headlineNews?.category,
      newsType: headlineNews?.newsType,
      isQR: headlineNews?.isQR,
      isResume: headlineNews?.isResume,
      durationType: headlineNews?.postDuration?.select,
      duration: headlineNews?.postDuration?.second,
    };
    toast.loading("Saving ...");
    setSaveLoading(true);
    try {
      await dispatch(handleAddHeadlineNews(payload)).then((res) => {
        toast.remove();
        setSaveLoading(false);
        navigate("/Headline-News");
      });
    } catch (error) {
      console.log("error :>> ", error);
      setSaveLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      dispatch(getHeadlineNewsById(id))
        .then((response) => {
          if (response?.payload?.status === 200) {
            const data = response?.payload?.data;
            setHeadlineNews((pre) => ({
              ...pre,
              newsType: data?.newsType || "",
              category: data?.category || "",
              postVisiblity: data?.postVisiblity || false,
              visiblityDays: data?.visiblityDays || "",
              isQR: data?.isQR || false,
              isResume: data?.isResume || false,
              postDuration: {
                select: data?.durationType || "",
                second: data?.duration || "",
              },
            }));
            setInstanceName(data?.instanceName);
            setPage(2);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [id, dispatch]);

  const checkUrl = () => {
    const valid = headlineNews?.newsType;
    if (valid) {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${GET_NEWS_DATA}?name=${headlineNews?.newsType} ${headlineNews?.category}`,
        headers: {
          Authorization: authToken,
        },
      };
      dispatch(handleGetNewsData({ config }))
        .then((res) => {
          setGetNewsData(res?.payload?.data?.items)
          setPage(2);
        })
        .catch((error) => {
          console.log('error', error)
        })

    } else {
      toast.error("Please Enter All Values");
    }
  };

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
            user?.userDetails?.isRetailer === false &&
            !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div>
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter Instance name"
                        value={instanceName}
                        onChange={(e) => {
                          setInstanceName(e.target.value);
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      if (headlineNews?.newsType && headlineNews?.category)
                        return setShowPreview(!showPreview);
                      toast.error("Please Enter All Value");
                    }}
                    style={{ display: page === 1 ? "none" : "block" }}
                  >
                    {showPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    className="flex  align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      if (headlineNews?.newsType && headlineNews?.category)
                        return onSubmit();
                      toast.error("Please Enter All Value");
                    }}
                    disabled={saveLoading}
                    style={{ display: page === 1 ? "none" : "block" }}
                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>
                  <Link to="/Headline-News">
                    <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>
              {showPreview ? (
                <div className="w-full h-[45rem] p-3 bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center mt-5">
                  <Swiper
                    modules={[Autoplay]}
                    centeredSlides={true}
                    slidesPerView={1}
                    allowTouchMove={false}
                    loop={false}
                    autoplay={{
                      delay: headlineNews?.postDuration?.second * 1000,
                      disableOnInteraction: false,
                    }}
                    onSlideChange={(swiper) => {
                      if (swiper.isEnd) {
                        if (headlineNews?.isResume === true) {
                          swiper.autoplay.stop();
                        } else {
                          swiper.autoplay.start();
                        }
                      }
                    }}
                  >
                    {getNewsData?.map(
                      (items, index) => (
                        <SwiperSlide key={index}>
                          <div className="absolute inset-0 bg-sky-100 flex justify-start text-left items-center">
                            {items?.image ? (
                              <>
                                <div>
                                  <div
                                    className="flex items-center justify-center"
                                    style={{ width: "50%" }}
                                  >
                                    <img
                                      src={items?.image}
                                      alt="Headline News Image"
                                      className="w-full h-full"
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{ width: "50%" }}
                                  className="mx-14"
                                >
                                  <h1 className="text-4xl font-bold mb-5 leading-10">
                                    {items.title}
                                  </h1>
                                  <p className="text-md text-gray-600 mb-5">
                                    {calculateTimeDifference(items?.date_published)}
                                  </p>
                                  <p className="text-4xl font-normal">{items.content_text}</p>
                                  <div className="flex align-center justify-between mt-5 ml-10 mr-10">
                                    <div className="justify-start flex">
                                      {headlineNews?.isResume && (
                                        <div
                                          className="flex items-center justify-center"
                                          style={{ width: "50%" }}
                                        >
                                          <div className="w-32 h-10 rounded-full bg-[#f13e2e] flex justify-center items-center p-6 m-2.5 text-md text-white font-semibold ml-10">
                                            {`${headlineNews?.newsType} ${headlineNews?.category}`}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="flex justify-end">
                                      {headlineNews?.isQR && (
                                        <QRCodeSVG
                                          value={items?.url}
                                          size={100}
                                          fgColor="#000000"
                                          bgColor="#ffffff"
                                          className="p-4"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="flex items-center justify-center"
                                  style={{ width: "30%" }}
                                >
                                  <img
                                    src="https://back.disploy.com/app/headline-news.svg"
                                    alt="Google Alerts Icon"
                                    className="w-16 h-16"
                                  />
                                </div>
                                <div className="ml-10 mr-10">
                                  <h1 className="text-2xl font-bold mb-5">
                                    {items.title}
                                  </h1>
                                  <p className="text-sm text-gray-600 mb-5">
                                    {items?.updatedTime}
                                  </p>
                                  <p className="text-xl">{items.content}</p>
                                  <div className="flex justify-end">
                                    {headlineNews?.isQR && (
                                      <QRCodeSVG
                                        value={items?.qrCode}
                                        size={100}
                                        fgColor="#000000"
                                        bgColor="#ffffff"
                                        className="p-4"
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </div>
              ) : page === 1 ? (
                <div className="flex flex-col items-center justify-center min-h-[45rem] rounded-lg bg-white">
                  <div className="text-center p-8 bg-white ">
                    <div className="flex justify-center mb-4">
                      <img
                        src="https://back.disploy.com/app/headline-news.svg"
                        alt="Headline Icon"
                        className="w-16 h-16"
                      />
                    </div>
                    <h2 className="text-2xl font-normal mb-2">
                      Display Headline News on your digital screens
                    </h2>
                    <p className="text-gray-600">
                      To get started, please select a feed below, and click
                      continue
                      <br />
                      For more information, visit our
                      <a
                        href="https://screencloud.com/apps/news?tab=guide"
                        target="_blank"
                        className="text-blue-500 ml-1"
                      >
                        Help Documentation
                      </a>
                    </p>
                    <div className="flex justify-center gap-4 my-8">
                      <select
                        className="bg-gray-50 font-semibold text-md border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[180px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="text"
                        name="newsType"
                        value={headlineNews?.newsType}
                        onChange={handleChange}
                      >
                        {HeadlineNewsTypeOption?.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      {HeadlineCategoryOptions[headlineNews.newsType]?.length > 0 && (
                        <select
                          className="bg-gray-50 w-[180px] font-semibold border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="text"
                          name="category"
                          value={headlineNews?.category}
                          onChange={handleChange}
                        >
                          {HeadlineCategoryOptions[headlineNews.newsType]?.map(
                            (option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            )
                          )}
                        </select>
                      )}
                    </div>
                    <div>
                      <button
                        className="bg-yellow-500 font-semibold text-lg text-white px-6 py-2 rounded-md hover:bg-yellow-600 transition"
                        onClick={checkUrl}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                  <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg h-full w-full">
                    <div className="w-full lg:w-[50%] h-[35rem] mt-4 lg:mt-0">
                      <div className=" w-[90%] h-[100px] ml-6 mt-10 overflow-hidden">
                        <div className=" flex  ">
                          <img
                            className="w-7 h-7 mr-2"
                            src="https://back.disploy.com/app/headline-news.svg"
                          ></img>
                          <h2>{headlineNews?.newsType}</h2>
                        </div>
                        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                      </div>
                      <div className="ml-6">
                        <h5>
                          For more information please refer to our{" "}
                          <a
                            className="text-blue-500"
                            href="https://screencloud.com/apps/news?tab=guide"
                            target="_blank"
                          >
                            help guide
                          </a>
                        </h5>
                      </div>
                    </div>
                    <div className="border-r-0 lg:border-r-2 border-gray-300 h-auto mx-4 hidden lg:block"></div>
                    <div className="w-full lg:w-[50%] pr-0 lg:pr-4 mb-4 lg:mb-0 p-5">
                      <div className="text-gray-500">Display options for :</div>
                      <div className="flex justify-start items-center mt-5 mb-5">
                        <img
                          src="https://back.disploy.com/app/headline-news.svg"
                          alt="on this day img"
                          className="w-8 h-8"
                        />
                        <div className="text-2xl font-medium ml-5">
                          Headline News
                        </div>
                      </div>
                      <div className="mb-4 flex items-center justify-between">
                        <label
                          htmlFor="countries"
                          className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Post visibility (in days)
                        </label>
                        <div className="text-right">
                          <label className="inline-flex relative items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="sr-only peer"
                              readOnly
                              name="postVisiblity"
                              checked={headlineNews?.postVisiblity}
                              onChange={(e) => handleMuteChange(e)}
                            />
                            <div
                              className={`w-11 h-6 ${headlineNews?.postVisiblity
                                ? "bg-SlateBlue"
                                : "bg-gray-300"
                                } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                            ></div>
                          </label>
                        </div>
                      </div>
                      <div className="mb-4 flex items-center justify-between">
                        <input
                          type="number"
                          className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${headlineNews?.postVisiblity === false
                            ? "cursor-not-allowed"
                            : ""
                            }`}
                          placeholder="300"
                          disabled={!headlineNews?.postVisiblity}
                          value={headlineNews?.visiblityDays}
                          name="visiblityDays"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-4 flex items-center justify-between">
                        <label
                          htmlFor="countries"
                          className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Show a QR code linking to the original post
                        </label>
                        <div className="text-right">
                          <label className="inline-flex relative items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="sr-only peer"
                              readOnly
                              name="isQR"
                              checked={headlineNews?.isQR}
                              onChange={(e) => handleMuteChange(e)}
                            />
                            <div
                              className={`w-11 h-6 ${headlineNews?.isQR
                                ? "bg-SlateBlue"
                                : "bg-gray-300"
                                } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                            ></div>
                          </label>
                        </div>
                      </div>
                      <div className="mb-4 flex items-center justify-between">
                        <label
                          htmlFor="countries"
                          className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Resume playback from last post displayed
                        </label>
                        <div className="text-right">
                          <label className="inline-flex relative items-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="sr-only peer"
                              readOnly
                              name="isResume"
                              checked={headlineNews.isResume}
                              onChange={(e) => handleMuteChange(e)}
                            />
                            <div
                              className={`w-11 h-6 ${headlineNews.isResume
                                ? "bg-SlateBlue"
                                : "bg-gray-300"
                                } rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`}
                            ></div>
                          </label>
                        </div>
                      </div>
                      <div className="mb-4 flex items-center justify-between">
                        <label
                          htmlFor="countries"
                          className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Select post duration
                        </label>
                        <div className=" flex">
                          <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={"default"}
                            name="select"
                            value={headlineNews.postDuration.select}
                            onChange={handlePost_DurationChange}
                          >
                            <option value="slow">Slow</option>
                            <option value="default">Default</option>
                            <option value="fast">Fast</option>
                            <option value="manual">Manual</option>
                          </select>
                          <input
                            type="number"
                            className={`bg-gray-50 ml-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${headlineNews?.postDuration.select !== "manual"
                              ? "cursor-not-allowed"
                              : ""
                              }`}
                            placeholder={
                              headlineNews.postDuration.select !== "manual"
                                ? ""
                                : "Second"
                            }
                            disabled={
                              headlineNews.postDuration.select !== "manual"
                            }
                            value={headlineNews.postDuration.second}
                            name="second"
                            onChange={handlePost_DurationChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
