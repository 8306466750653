import React from 'react'

export default function NoticeBoard_Icons({ RemoveIconsModel, handelIconsSave }) {
    return (
        <div
            id="default-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed top-0 right-0 left-0 z-9990 flex justify-center items-center w-full h-full m-0 md:inset-0 max-h-full bg-black bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-4xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-3 md:p-4 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Select an icon</h3>
                    </div>
                    <div className="p-6">

                    </div>
                    <div className="flex items-center justify-center gap-2 border-t border-black">
                        <button
                            type="button"
                            className="w-40 text-[#FFFFFF] bg-SlateBlue not-italic font-medium rounded-full py-3.5 text-center text-base mt-4 hover:bg-primary border border-SlateBlue hover:border-white"
                            onClick={() => RemoveIconsModel()}
                        >
                            Cancel
                        </button>
                        <button

                            className="w-40 text-[#FFFFFF] bg-SlateBlue not-italic font-medium rounded-full py-3.5 text-center text-base mt-4 hover:bg-primary border border-SlateBlue hover:border-white"
                            onClick={() => handelIconsSave()}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
