import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { NewsTheme, NewsTopic } from "../../Common/Common";
export const NYTNewsPreview = ({
  nytNewsDataById,
  screenAssignName,
  showTags,
  instanceName,
  setInstanceView,
}) => {
  return (
    <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
      <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-[1000px] h-[600px]">
        <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
          <div className="flex items-center">
            <div>
              <img
                src="https://back.disploy.com/app/NYT-news.svg"
                alt="Logo"
                className="cursor-pointer mx-auto h-10 w-10"
              />
            </div>
            <div className="ml-3">
              <h4 className="text-lg font-medium whitespace-wrap ">
                {instanceName}
              </h4>
            </div>
          </div>
          <button
            className="p-1 text-3xl"
            onClick={() => setInstanceView(false)}
          >
            <AiOutlineCloseCircle />
          </button>
        </div>
        <Swiper
          modules={[Pagination, Autoplay]}
          centeredSlides={true}
          slidesPerView={1}
          autoplay={{
            delay: nytNewsDataById?.durationInSec * 1000,
            disableOnInteraction: false,
          }}
        >
          {NewsTopic?.[nytNewsDataById.topic]?.map((slide, index) => (
            <SwiperSlide key={index}>
              <div
                className="w-full h-full flex items-center justify-center bg-cover bg-center text-white relative"
                style={{
                  backgroundImage: NewsTheme[nytNewsDataById.theme],
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center p-5 text-center">
                  <div>
                    <h2 className="text-3xl font-bold">{slide.news}</h2>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="py-2 px-6">
          <div className="flex items-center gap-2 w-full">
            <div className="font-semibold w-fit">Tags:-</div>
            <div className=" w-full">{showTags}</div>
          </div>
          <div>
            <label className="font-semibold">Screen Assign :</label>
            {screenAssignName ? screenAssignName : " No Screen"}
          </div>
        </div>
      </div>
    </div>
  );
};
