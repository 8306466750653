import { Clock, StopWatch } from '@sujitsimon/react-flipclock'
import moment from 'moment';
import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import clock from "../../../images/AppsImg/clock.svg";

export default function ClockPreview({ setInstanceView, EditClockData }) {
  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const dayOfWeek = moment().format("ddd");
  const month = moment().format("MM");
  const date = moment().format("DD");
  const AmPm = moment().format("A");
  const formattedTime = moment(moment()).format(EditClockData?.iS12HourFormat ? EditClockData?.isDisplaySecound ? "hh:mm:ss " : "hh:mm " : EditClockData?.isDisplaySecound ? "HH:mm:ss" : "HH:mm");

  return (
    <div className="bg-black bg-opacity-50 justify-center items-center flex fixed inset-0 z-9990 outline-none focus:outline-none">
      <div >
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
          <div className="flex items-center justify-between p-5 border-b border-[#A7AFB7]  rounded-t">
            <div className="flex items-center">
              <div>
                <img
                  src='https://back.disploy.com/App/5f2e7668-49a2-4302-b088-533865bfaaa9.svg'
                  alt="Logo"
                  className="cursor-pointer mx-auto h-10 w-10"
                />
              </div>
              <div className="ml-3">
                <h4 className="text-lg font-medium whitespace-wrap w-[900px]">{EditClockData?.instanceName}</h4>
              </div>
            </div>
            <button
              className="p-1 text-3xl"
              onClick={() => setInstanceView(false)}
            >
              <AiOutlineCloseCircle />
            </button>
          </div>
          <div>
            <div className="w-full xl:h-[500px] lg:h-[500px] mx-auto bg-gray-50 rounded-sm shadow-md border-4 border-black flex items-center justify-center min-h-[100px] overflow-hidden">
              {EditClockData?.clockStyle === "Digital" && (
                <div className=" flex flex-col">
                  <div className="flex items-center space-x-4">
                    <div className="date-section">
                      {EditClockData?.isDisplayDate && (
                        <div className=" ml-1 mb-3">
                          <div className=" sm:text-sm xl:text-lg text-gray-500">
                            MONTH
                          </div>
                          <div className="text-3xl text-black font-semibold">
                            {month}
                          </div>
                          <div className=" sm:text-sm xl:text-lg text-gray-500">
                            DAY
                          </div>
                          <div className="text-3xl text-black font-semibold">
                            {date}
                          </div>
                        </div>
                      )}
                    </div>
                    <p
                      className=" text-[6rem] font-bold font-digital"
                      style={{ fontFamily: "digital" }}
                    >
                      {formattedTime}
                    </p>

                    {EditClockData?.iS12HourFormat ? (
                      <span className="xl:text-lg sm:text-sm text-gray-700 mb-[6rem]">
                        {AmPm}
                      </span>
                    ) : null}
                  </div>

                  <div className="flex space-x-2 items-center justify-center mt-3">
                    {daysOfWeek.map((day, index) => (
                      <span
                        key={index}
                        className={`text-xs sm:text-sm xl:text-lg ${day === dayOfWeek.toUpperCase()
                          ? "text-blue-500 font-semibold"
                          : ""
                          }`}
                      >
                        {day}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              {EditClockData?.clockStyle === "Simple" && (
                <div className="flex ">
                  {EditClockData?.iS12HourFormat ? (
                    <span className="xl:text-lg sm:text-sm text-gray-700 mb-7">{AmPm}</span>
                  ) : null}

                  <p className="sm:text-3xl xl:text-6xl md:text-4xl">
                    {formattedTime}
                  </p>

                  {/* {EditClockData?.Displaydate && (
                    <div className="border-r-0 lg:border-r-2 border-gray-300 h-auto mx-4 hidden lg:block"></div>
                  )} */}

                  <div className="date-section">
                    {EditClockData?.isDisplayDate && (
                      <div>
                        <div className="border-r-0 lg:border-r-2 border-gray-300 h-auto mx-4 hidden lg:block"></div>

                        <div className="day sm:text-sm xl:text-lg">
                          {dayOfWeek}
                        </div>
                        <div className="date sm:text-sm xl:text-xl">
                          {date}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {EditClockData?.clockStyle === "Flip" && (
                <div className=" flex flex-col flip_clock">
                  <Clock
                    date={'05:27'}
                    // date={formattedTime.toString()}
                    countDownTime={{ hours: 0, minutes: 0, seconds: 10 }}
                    config={{
                      height: '100px',
                      backgroundColor: '#241623',
                      textColor: '#fff',
                      format: `${EditClockData?.iS12HourFormat ? '12h' : '24h'}`, // Set the format to 24h if supported
                    }}
                  />
                  <div className="flex space-x-2 items-center justify-center mt-3">
                    {daysOfWeek.map((day, index) => (
                      <span
                        key={index}
                        className={`text-xs sm:text-sm xl:text-lg ${day === dayOfWeek.toUpperCase()
                          ? "text-blue-500 font-semibold"
                          : ""
                          }`}
                      >
                        {day}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="py-2 px-6">
            <div className="flex items-center gap-2 w-full">
              <div className="font-semibold w-fit">
                Tags:-
              </div>
              <div className=" w-full">{EditClockData?.tags}</div>
            </div>
            <div>
              <label className="font-semibold">
                Screen Assign :
              </label>
              {EditClockData?.screens === "" ? " No Screen" : EditClockData?.screens}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

