import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

// live URl:- back.disploy.com

// export const baseUrl = "https://disploystage.disploy.com/api/";
export const baseUrl = "https://back.disploy.com/api/";

// export const baseUrl = "http://192.168.1.254:5656/api/";


// Test Mode
// export const stripePromise = loadStripe("pk_test_51JIxSzLmxyI3WVNYq18V5tZgnJ3kAeWqwobpP2JLyax9zkwjdOFKyHp85ch29mKeqhqyHTr4uIgTvsKkYPxTcEWQ00EyadI8qy");

// Live Mode
export const stripePromise = loadStripe("pk_live_51JIxSzLmxyI3WVNYdQLd2H64i4dU3EA0kHoDGWyu1MCxL6xfgCBFw2CP9qhkH1FGNWwkHosH4Jlx3xfIbRdhxsAw00wvPSXFZM");

export const INFOMAIL = 'info@disploy.com';
export const PHONENUMBERDISPLOY = '+91 801027719';

export const paypalOptions = {
  'client-id': 'Ac2YPb7PQ_z1TMuvWYOmWT1iafYTeVW9DubrVCxX0ub-se4zgR2D3qh4dKo8a6neF6b-vO2aDjH_CPWT', // Replace with your actual client ID
  currency: 'USD',
};

export const postUrl = axios.create({
  baseURL: baseUrl,
  method: "post",
});

export const deleteUrl = axios.create({
  baseURL: baseUrl,
  method: "delete",
});

export const getUrl = axios.create({
  baseURL: baseUrl,
  method: "get",
});

export const LOGIN_URL = `${baseUrl}Register/Login`;
export const UPDATE_USER = `${baseUrl}UserMaster/AddOrgUserMaster`;
export const UPDATE_USER_ORG = `${baseUrl}UserMaster/UpdateOrgUserMaster`;
export const ADD_REGISTER_URL = `${baseUrl}UserMaster/AddOrganizationSignups`;
export const All_REGISTER_URL = `${baseUrl}Register/GetAllRegister`;
export const GET_ALL_CURRENCIES = `${baseUrl}Register/GetAllCurrency`;
export const GET_ALL_LANGUAGES = `${baseUrl}Register/GetAllLanguage`;
export const SELECT_BY_ID_USERDETAIL = `${baseUrl}Register/SelectByID`;
export const ALL_SCREEN_URL = `${baseUrl}Screen/GetAllScreen`;
export const ALL_MAPSTORE_GET = `${baseUrl}Cascading/SelectByStore`;
export const GET_ALL_COUNTRY = `${baseUrl}Cascading/GetAllCountry`;
export const GET_SELECT_BY_STATE = `${baseUrl}Cascading/SelectByState`;
export const GET_SELECT_BY_CITY = `${baseUrl}Cascading/SelectByCity`;
export const DELETE_USER = `${baseUrl}Register/DeleteUser`;
export const SCREEN_GROUP = `${baseUrl}GroupScreen/AddGroupScreen`;
export const SELECT_ALL_SCREENGROUP = `${baseUrl}GroupScreen/SelectAll`;
export const UPDATE_NEW_SCREEN = `${baseUrl}NewScreen/UpdateNewScreen`;
export const GET_SCREEN_TYPE = `${baseUrl}NewScreen/GetAllScreenType`;
export const SELECT_BY_USER_SCREENDETAIL = `${baseUrl}NewScreen/SelectByUserScreen`;
export const SELECT_BY_SCREENID_SCREENDETAIL = `${baseUrl}NewScreen/SelectByScreen`;
export const GET_ALL_SCREEN_RESOLUTION = `${baseUrl}NewScreen/GetAllScreenResolution`;
export const GET_ALL_SCREEN_ORIENTATION = `${baseUrl}NewScreen/GetAllScreenOrientation`;
export const GET_CONVERT_ADVERTISEMENT = `${baseUrl}NewScreen/ConvertToAdvertisementScreen`;
export const GET_ALL_ADVERTISEMENT = `${baseUrl}NewScreen/GetAllAdvertisementScreen`;
export const GET_TIMEZONE = `${baseUrl}NewScreen/GetAllTimeZone`;
export const GET_TIMEZONE_TOKEN = `${baseUrl}EventMaster/GetAllTimeZone`;
export const GET_USER_SCREENDETAIL_MERGE = `${baseUrl}MergeScreen/GetAllUserScreenToMerge`;
export const GET_USER_SCREENDETAIL_GROUP = `${baseUrl}GroupScreen/GetAllUserScreenToGroup`;


export const GET_ALL_FILES = `${baseUrl}AssetMaster/GetAll`;
export const ALL_FILES_UPLOAD = `${baseUrl}AssetMaster/AssetUpload`;
export const GOOGLE_DRIVE = `${baseUrl}GoogleDrive/GoogleDrive`;
export const OTP_VERIFY = `${baseUrl}NewScreen/NewScreenVerify`;
export const GET_ALL_EVENTS = `${baseUrl}EventMaster/GetAllEvent`;
export const ADD_EVENT = `${baseUrl}EventMaster/AddEvent`;
export const GET_ALL_SCHEDULE = `${baseUrl}ScheduleMaster/GetAllSchedule`;
export const ADD_SCHEDULE = `${baseUrl}ScheduleMaster/AddSchedule`;
export const DELETE_SCHEDULE = `${baseUrl}ScheduleMaster/DeleteSchedule`;
export const UPDATE_SCREEN_ASSIGN = `${baseUrl}ScheduleMaster/UpdateAssignScreen`;
export const SCHEDULE_EVENT_SELECT_BY_ID = `${baseUrl}EventMaster/SelectByID`;
export const SCHEDULE_SELECT_BY_ID = `${baseUrl}ScheduleMaster/SelectByID`;
export const UPDATED_SCHEDULE_DATA = `${baseUrl}EventMaster/SelectAllGetScheduleList`;
export const UPDATE_TIMEZONE = `${baseUrl}ScheduleMaster/UpdateTimeZone`;
export const SIGNAL_R = `https://disploystage.disploy.com/chatHub`;
export const GET_ALL_TRASHDATA = `${baseUrl}Trash/GetAllTrash`;
export const ADD_TRASH = `${baseUrl}Trash/AddTrash`;
export const SINGL_DELETED_TRASH = `${baseUrl}Trash/singlDeletedTrash`;
export const All_DELETED_TRASH = `${baseUrl}Trash/AllDeletedTrash`;
export const RESTORE_TRASH = `${baseUrl}Trash/restoreTrash`;
export const CREATE_NEW_FOLDER = `${baseUrl}AssetMaster/FolderMaster`;
export const GET_ALL_NEW_FOLDER = `${baseUrl}ImageVideoDoc/GetAllFolder`;
export const MOVE_TO_FOLDER = `${baseUrl}AssetMaster/Move`;
export const FetchdataFormFolder = `${baseUrl}ImageVideoDoc/SelectByFolder`;
export const DeleteAllData = `${baseUrl}ImageVideoDoc/DeleteAll`;
export const GET_ALL_APPS = `${baseUrl}YoutubeApp/GetAllApps`;
export const YOUTUBE_INSTANCE_ADD_URL = `${baseUrl}YoutubeApp/AddYoutubeApp`;
export const GET_ALL_YOUTUBEDATA = `${baseUrl}YoutubeApp/GetAllYoutubeApp`;
export const GET_YOUTUBEDATA_BY_ID = `${baseUrl}YoutubeApp/SelectByYouTubeId`;
export const YOUTUBEDATA_ALL_DELETE = `${baseUrl}YoutubeApp/DeleteAllYoutubeApp`;
export const SELECT_BY_LIST = `${baseUrl}LayoutMaster/SelectByList`;
export const UPDATE_TRIAL_DAY = `${baseUrl}Register/UpdateTrialDay`;
export const SCROLL_TYPE_OPTION = `${baseUrl}YoutubeApp/AllScrollType`;
export const SCROLL_ADD_TEXT = `${baseUrl}YoutubeApp/AddTextScroll`;
export const SCROLLDATA_BY_ID = `${baseUrl}YoutubeApp/SelectByTextScrollId`;
export const GET_ALL_TEXT_SCROLL_INSTANCE = `${baseUrl}YoutubeApp/GetAlltextScroll`;
export const DELETE_ALL_TEXT_SCROLL = `${baseUrl}YoutubeApp/DeleteAllTextScroll`;
export const SELECT_BY_ASSET_ID = `${baseUrl}AssetMaster/SelectByAssetID`;
export const ADDPLAYLIST = `${baseUrl}CompositionMaster/AddCompositionMaster`;
export const ADDSUBPLAYLIST = `${baseUrl}CompositionMaster/AddSubCompositionMaster`;
export const GET_ALL_COMPOSITIONS = `${baseUrl}CompositionMaster/GetAllCompositionMaster`;
export const DELETE_COMPOSITION_BY_ID = `${baseUrl}CompositionMaster/DeleteCompositionByID`;
export const DELETE_ALL_COMPOSITIONS = `${baseUrl}CompositionMaster/DeleteAllComposition`;
export const CUSTOM_COMPOSITION = `${baseUrl}LayoutMaster/AddLayout`;
export const SELECT_BY_COMPOSITION_LAYOUT_ID = `${baseUrl}CompositionMaster/CheckLayoutInComposition`;
export const DELETE_COMPOSITION_LAYOUT_BY_ID = `${baseUrl}CompositionMaster/DeleteCustomComposition`;
export const DELETE_COMPOSITION = `${baseUrl}CompositionMaster/DeleteComposition`;
export const GET_ALL_TAGS = `${baseUrl}CompositionMaster/GetAllTag`;
export const GET_CURRENT_ASSET = `${baseUrl}EventMaster/GetCurrentAsset`;
export const COMPOSITION_BY_ID = `${baseUrl}CompositionMaster/SelectCompositionByID`;
export const SCREEN_PREVIEW = `${baseUrl}NewScreen/GetRegisterOtp`;
export const FORGOTPASSWORD = `${baseUrl}UserMaster/ForgotPassword`;
export const CHNAGE_PASSWORD = `${baseUrl}UserMaster/ResetPassword?`;
export const UPDATE_PASSWORD = `${baseUrl}UserMaster/UpdatePassword?`;
export const GET_USER_BY_USERROLE = `${baseUrl}OrganizationUsersRole/GetUserbyUserRole`;
export const ASSIGN_TEXTSCROLL_TO_SCREEN = `${baseUrl}YoutubeApp/AssignTextScrollToScreen`;
export const ADD_TEXTSCROLL_TAGS = `${baseUrl}YoutubeApp/AddTextScrollTags`;
export const SELECT_BY_TEXTSCROLL_ID = `${baseUrl}YoutubeApp/SelectByTextScrollId`;
export const ASSIGN_YOUTUBE_TO_SCREEN = `${baseUrl}YoutubeApp/AssignYoutubeToScreen`;
export const ADD_YOUTUBE_TAGS = `${baseUrl}YoutubeApp/AddYoutubeTags`;
export const ASSIGN_ASSET_TO_SCREEN = `${baseUrl}AssetMaster/AssignAssetToScreen`;
export const GET_ASSET_DETAILS = `${baseUrl}AssetMaster/GetAssetDetails`;
export const DELETE_ALL_ASSET = `${baseUrl}AssetMaster/DeleteAllAsset`;
export const ASSIGN_COMPOSITION_TO_SCREEN = `${baseUrl}CompositionMaster/AssignCompoitiontoScreen`;
export const GET_DEFAULT_ASSET = `${baseUrl}UserMaster/GetDefaultAsset`;
export const GET_EMERGENCY_ASSET = `${baseUrl}UserMaster/GetEmergencyAsset`;
export const SAVE_DEFAULT_ASSET = `${baseUrl}UserMaster/SaveDefaultAsset`;
export const SAVE_EMERGENCY_ASSET = `${baseUrl}UserMaster/SaveEmergencyAsset`;
export const ADD_STORAGE = `${baseUrl}Storage/AddStorage`;
export const ADD_UPDATE_ORGANIZATION_USER_ROLE = `${baseUrl}OrganizationUsersRole/AddUpdateOrganizationUsersRole`;
export const GET_ORG_USERS = `${baseUrl}UserMaster/GetOrgUsers`;
export const GET_USER_SCREEN_DETAILS = `${baseUrl}UserMaster/GetUsetScreenDetails`;
export const DELETE_ORG_USER = `${baseUrl}UserMaster/DeleteOrgUser`;
export const GET_TIME_ZONE = `${baseUrl}ScheduleMaster/GetTimeZoneCurrentTime`
export const GET_REFERRAL_CODE = `${baseUrl}AdsCustomer/CheckRefCodeExistsORNOT`
export const GET_NEWS_DATA = `${baseUrl}Common/GetNewsDataFromTheUrl`
// Weather App Api

export const WEATHER_APP = `${baseUrl}WeatherApp/AddWeatherApp`;
export const GET_All_WEATHER = `${baseUrl}WeatherApp/GetWeatherApp`;
export const GET_WEATHER_BY_ID = `${baseUrl}WeatherApp/GetWeatherApp?`;
export const WEATHER_ASSIGN_SECREEN = `${baseUrl}WeatherApp/AssignWeatherToScreen?`;
export const WEATHER_ADD_TAG = `${baseUrl}WeatherApp/AddWeatherTags?`;
export const USER_ROLE_GET = `${baseUrl}OrganizationUsersRole/ListOfModule`;
export const USER_ROLE_COMBINE = `${baseUrl}OrganizationUsersRole/GetUserRolesCombine`;
export const SCREEN_DELETE_ALL = `${baseUrl}NewScreen/DeleteAllScreenByIds`;

// countUpTimer App Api
export const COUNTUPTIMER_INSTANCE_ADD_URL = `${baseUrl}CountUpTimerApp/AddUpdateCountUpTimerApp`;
export const GET_All_countUpTimer = `${baseUrl}CountUpTimerApp/GetAllCountUpTimerApp`;
export const COUNTUPTIMER_SINGLE_DELETE = `${baseUrl}CountUpTimerApp/DeleteCountUpTimerApp`;
export const COUNTUPTIMER_ALL_DELETE = `${baseUrl}CountUpTimerApp/DeleteAllCountUpTimerApp`
export const GET_COUNTUPTIMER_BY_ID = `${baseUrl}CountUpTimerApp/SelectCountUpTimerAppById`
export const COUNTUPTIMER_ADD_TAG = `${baseUrl}CountUpTimerApp/AddCountUpTimerAppTags?`
export const COUNTUPTIMER_ASSIGN_SECREEN = `${baseUrl}CountUpTimerApp/CountUpTimerApptoScreen`;
//export const WEATHER_APP = `${baseUrl}WeatherApp/AddWeatherApp`;
//export const GET_CountDownTimer_BY_ID = `${baseUrl}CountDownTimerApp/SelectCountDownTimerAppById`;
//export const countDownTimer_INSTANCE_ADD_URL = `${baseUrl}CountDownTimerApp/AddUpdateCountDownTimerApp`;
//export const WEATHER_ASSIGN_SECREEN = `${baseUrl}WeatherApp/AssignWeatherToScreen?`;
//export const WEATHER_ADD_TAG = `${baseUrl}WeatherApp/AddWeatherTags?`;
//export const USER_ROLE_GET = `${baseUrl}OrganizationUsersRole/ListOfModule`;
//export const USER_ROLE_COMBINE = `${baseUrl}OrganizationUsersRole/GetUserRolesCombine`;
//export const SCREEN_DELETE_ALL = `${baseUrl}NewScreen/DeleteAllScreenByIds`;

// countDownTimer App Api
export const COUNTDownTIMER_INSTANCE_ADD_URL = `${baseUrl}CountDownTimerApp/AddUpdateCountDownTimerApp`;
export const GET_All_countDownTimer = `${baseUrl}CountDownTimerApp/GetAllCountDownTimerApp`;
export const COUNTDownTIMER_SINGLE_DELETE = `${baseUrl}CountDownTimerApp/DeleteCountDownTimerApp`;
export const COUNTDownTIMER_ALL_DELETE = `${baseUrl}CountDownTimerApp/DeleteAllCountDownTimerApp`
export const GET_COUNTDownTIMER_BY_ID = `${baseUrl}CountDownTimerApp/SelectCountDownTimerAppById`
export const COUNTDownTIMER_ADD_TAG = `${baseUrl}CountDownTimerApp/AddCountDownTimerAppTags`
export const COUNTDownTIMER_ASSIGN_SECREEN = `${baseUrl}CountDownTimerApp/CountDownTimerApptoScreen`;

// Questions App Api
export const Questions_INSTANCE_ADD_URL = `${baseUrl}AskAQuestionApp/AddUpdateAskAQuestionApp`;
export const GET_All_QuestionsData = `${baseUrl}AskAQuestionApp/GetAllAskAQuestionApp`;
export const Questions_SINGLE_DELETE = `${baseUrl}AskAQuestionApp/DeleteAskAQuestionApp`;
export const Questions_ALL_DELETE = `${baseUrl}AskAQuestionApp/DeleteAllAskAQuestionApp`
export const GET_Questions_BY_ID = `${baseUrl}AskAQuestionApp/SelectAskAQuestionAppById`
export const Questions_ADD_TAG = `${baseUrl}AskAQuestionApp/AddAskAQuestionAppTags`
export const Questions_ASSIGN_SECREEN = `${baseUrl}AskAQuestionApp/AskAQuestionApptoScreen`;

// MicrosoftExcel App Api
export const MicrosoftExcel_INSTANCE_ADD_URL = `${baseUrl}MicrosoftExcelApp/AddUpdate`;
export const GET_All_MicrosoftExcelData = `${baseUrl}MicrosoftExcelApp/SelectAll`;
export const MicrosoftExcel_SINGLE_DELETE = `${baseUrl}MicrosoftExcelApp/Delete`;
export const MicrosoftExcel_ALL_DELETE = `${baseUrl}MicrosoftExcelApp/DeleteAll`
export const GET_MicrosoftExcel_BY_ID = `${baseUrl}MicrosoftExcelApp/Select`
export const MicrosoftExcel_ADD_TAG = `${baseUrl}MicrosoftExcelApp/AddTags`
export const MicrosoftExcel_ASSIGN_SECREEN = `${baseUrl}MicrosoftExcelApp/AddtoScreen`;

// Embed App Api
export const Embed_INSTANCE_ADD_URL = `${baseUrl}EmbedApp/AddUpdateEmbedApp`;
export const GET_All_EmbedData = `${baseUrl}EmbedApp/GetAllEmbedApp`;
export const Embed_SINGLE_DELETE = `${baseUrl}EmbedApp/DeleteEmbedApp`;
export const Embed_ALL_DELETE = `${baseUrl}EmbedApp/DeleteAllEmbedApp`
export const GET_Embed_BY_ID = `${baseUrl}EmbedApp/GetEmbedAppById`
export const Embed_ADD_TAG = `${baseUrl}EmbedApp/EmbedAppAddTags`
export const Embed_ASSIGN_SECREEN = `${baseUrl}EmbedApp/EmbedAppAssignScreen`;

// Group Screen 
export const GET_GROUP_SCREEN = `${baseUrl}GroupScreen/GetAllGroupData`;
export const ADD_GROUP_SCREEN = `${baseUrl}GroupScreen/AddGroupScreen`;
export const DELETE_SINGLE_GROUP_SCREEN = `${baseUrl}GroupScreen/DeleteGroupByID`;
export const DELETE_GROUP_SCREEN_ALL = `${baseUrl}GroupScreen/DeleteAllGroupScreen`;
export const GROUP_IN_SCREEN_DELETE_ALL = `${baseUrl}GroupScreen/DeleteScreenByID`;
export const GROUP_IN_SCREEN_ASSETS_UPDATE_ALL = `${baseUrl}GroupScreen/UpdateGroupMedia`;
export const PRIVIEW_GROUP_SCREEN = `${baseUrl}GroupScreen/GetGroupPreview`;
export const UPDATE_GROUP_NAME = `${baseUrl}GroupScreen/UpdateGroupScreen`;

// screen Marge

export const GET_MARGE_SCREEN = `${baseUrl}MergeScreen/GetAllMergeScreen`;
export const ADD_MERGE_SCREEN = `${baseUrl}MergeScreen/AddMergeScreen`;
export const DELETE_MERGE_SCREEN_ALL = `${baseUrl}MergeScreen/DeleteAllMergeScreen`;
export const ASSETS_UPLOAD_IN_SCREEN = `${baseUrl}MergeScreen/UpdateMergeScreenMedia`;
export const UPDATE_MERGE_NAME = `${baseUrl}MergeScreen/UpdateMergeScreen`;
export const SCREEN_DEACTIVATE_ACTIVATE = `${baseUrl}NewScreen/EnableScreen`;

// sidebar

export const GET_SIDEBAR_MENU = `${baseUrl}OrganizationUsersRole/SideBarMenu`;
export const MENU_ACCESS = `${baseUrl}OrganizationUsersRole/GetAllLevelData`;


// reports

export const AUDITREPORT = `${baseUrl}Report/GetAuditLogsReport`;
export const UPTIMEREPORT = `${baseUrl}Report/GetUptimeReport`;
export const SALESREPORT = `${baseUrl}Report/GetSalesReport`;
export const CANCELREPORT = `${baseUrl}Report/GetCancelReport`;
export const MEDIAREPORT = `${baseUrl}Report/GetAssetReport`;
export const BILLINGREPORT = `${baseUrl}Report/GetBillingReport`;
export const USERBOOKSLOTREPORT = `${baseUrl}Report/GetBookedSlotReport`;
export const BOOKSLOTREPORT = `${baseUrl}AdsCustomer/AllUserBookedSlotReport`;

//https://disploystage.disploy.com
//http://192.168.1.115

// Retailar

export const GETALLRETAILER = `${baseUrl}UserMaster/GetAllRetailer?IsRetailer=true`

// weatherschedule

export const ADD_OR_UPDATE_WEATHER = `${baseUrl}WeatherScheduling/AddorUpdateWeatherScheduling`;
export const ADD_WEATHERSCHEDULE_TAG = `${baseUrl}WeatherScheduling/AddWeatherSchedulingTags`;
export const GET_BY_ID_WEATHER = `${baseUrl}WeatherScheduling/GetWeatherScheduling`;
export const GET_WEATHER = `${baseUrl}WeatherScheduling/GetWeatherScheduling`;
export const DELETE_WEATHER = `${baseUrl}WeatherScheduling/DeleteWeatherScheduling`;
export const SET_TO_SCREEN_WEATHER = `${baseUrl}WeatherScheduling/AssignWeatherSchedulingToScreen`;

// Dashboard
export const USERDASHBOARD = `${baseUrl}NewScreen/GetDashboardData`
export const ADMINDASHBOARD = `${baseUrl}NewScreen/GetALLUserDashboardData`
export const ADMINUSERTOKEN = `${baseUrl}Register/GetUserToken`

// Advertisement

export const ADDEDITADVERTISEMENT = `${baseUrl}AdsCustomer/AddorUpdateAdsCustomer`
export const GETALLADS = `${baseUrl}AdsCustomer/GetAllAdsData`
export const ASSIGN_ADS = `${baseUrl}AdsCustomer/InsertAdvertisementScreen`
export const GET_NOTIFICATIONS = `${baseUrl}AdsCustomer/GetAdsDatabyID`
export const UPDATE_ADS_RATE = `${baseUrl}AdsCustomer/UpdateAdsRate`
export const ADD_ADMIN_RATE = `${baseUrl}AdsCustomer/AddAdminMargin`
export const ADVERTISEMENT_SCREEN = `${baseUrl}Common/AdvertisementScreen`
export const ADD_USER_LIST = `${baseUrl}AdsCustomer/GetAdsDatabyAdsID`
export const GET_COST_AREA = `${baseUrl}AdsCustomer/GetAllCostByArea`
export const DELETE_COST_AREA = `${baseUrl}AdsCustomer/DeleteCostByAreaID`
export const ADD_COST_AREA = `${baseUrl}AdsCustomer/AddORUpdateCostByArea`
export const GET_COMMISSION_RATE = `${baseUrl}AdsCustomer/GetAllCommissionRate`
export const DELETE_COMMISSION_RATE = `${baseUrl}AdsCustomer/DeleteCommissionRateByID`
export const ADD_COMMISSION_RATE = `${baseUrl}AdsCustomer/AddORUpdateCommissionRate`

// Approval

export const GETALLAPPROVAL = `${baseUrl}OrganizationUsersRole/GetApproveNotifications`
export const APPROVEDETAILBYID = `${baseUrl}OrganizationUsersRole/ApproveDetailsByID`

// Book Slot

export const SCREEN_LIST = `${baseUrl}AdsCustomer/GetAllRetaileScreen`
export const ALL_CITY = `${baseUrl}Common/GetAllCity`
export const ADDALLEVENT = `${baseUrl}AdsCustomer/AddorUpdateBookingSlotCustomerEvent`
export const ADDUPDATESLOT = `${baseUrl}AdsCustomer/AddorUpdateBookingSlotCustomer`

// Screen Authorize

export const PHONENUMBERVERIFY = `${baseUrl}UserMaster/SendOTP`
export const PHONE_OTP_VERIFY = `${baseUrl}UserMaster/SetAuthNumber`;
export const OTP_SCREEN_VERIFY = `${baseUrl}NewScreen/VerifyScreenOTP`;
export const SCREEN_STORAGE = `${baseUrl}NewScreen/CheckScreenCnt`;

// Digital Menu Board

export const POS_ITEM_LIST = `${baseUrl}DigitalMenu/GetPOSItemDetails`
export const ADD_EDIT_DIGITAL_MENU = `${baseUrl}DigitalMenu/AddORUpdateDigitalMenuApp`
export const GET_ALL_DIGITAL_MENU = `${baseUrl}DigitalMenu/GetAllDigitalMenuApp`
export const DELETE_DIGITAL_MENU = `${baseUrl}DigitalMenu/DeleteDigitalMenuApp`
export const GET_DIGITAL_MENU_BY_ID = `${baseUrl}DigitalMenu/GetDigitalMenuAppByID`
export const ADD_TAGS_DIGITAL_MENU = `${baseUrl}DigitalMenu/AddDigitalMenuAppTags`
export const ASSIGN_SCREEN_DIGITAL_MENU = `${baseUrl}DigitalMenu/AssignDigitalMenuAppToScreen`
export const POS_THEME = `${baseUrl}Common/GetPOSTheme`

// Billing

export const GET_ALL_PLANS = `${baseUrl}Common/GetAllPlans`
export const ADD_EDTT_PLAN = `${baseUrl}Common/AddEditPlan`
export const GET_TRIAL_PERIOD_DETAILS = `${baseUrl}Common/GetTrialPeriodDetails`
export const ADD_EDIT_TRIAL_PLAN = `${baseUrl}Common/EditTrialPeriod`
export const PAYMENT_INTENT_CREATE_REQUEST = `${baseUrl}Common/PaymentIntentCreateRequest`
export const PAYMENT_DETAILS = `${baseUrl}PaymentDetails/AddorUpdatePaymentDetails`
export const CREATE_SUBSCRIPTION = `${baseUrl}Common/CreateAutopay`
export const UPGRADE_SUBSCRIPTION = `${baseUrl}Common/UpgradeAutopay`
export const CANCEL_SUBSCRIPTION = `${baseUrl}Common/CancelAutopay`
export const INCREASE_TRIAL_DAYS = `${baseUrl}Common/IncreaseTrialDays`
export const GET_ALL_BILLING = `${baseUrl}Common/GetAllBillingDetails`
export const GET_BILLING_BY_ID = `${baseUrl}BillingMaster/GetBillingById`
export const ADD_CREDIT_CARD = `${baseUrl}common/AddCreditCard`
export const TALK_TO_SALE = `${baseUrl}common/TalkToSale`
export const ADD_EDIT_BILLINGDETAILS = `${baseUrl}BillingDetails/AddEditBillingDetails`
export const GET_BILLING_DETAILS = `${baseUrl}BillingDetails/GetBillingDetails`
export const GET_USER_BILLING_DETAILS = `${baseUrl}Common/GetUserBillingDetails`
export const GET_ALL_BILLING_DETAILS = `${baseUrl}Common/GetUserBillingDetailsByUserEmail`


// Notifications

export const GET_ALL_NOTIFICATIONS = `${baseUrl}Common/GetAllNotificationMaster`
export const GET_ALL_REMOVE_NOTIFICATIONS = `${baseUrl}Common/GetRemoveAllNotificationMaster`
export const GET_ALL_USER_NOTIFICATION = `${baseUrl}Common/GetAllNotificationFeature`
export const SAVE_NOTIFICATION = `${baseUrl}UserNotification/AddEditUserNotification`
export const GET_NOTIFICATION = `${baseUrl}UserNotification/GetUserNotification`

// Invoice

// export const GET_ALL_INVOICE = `${baseUrl}Invoice/GetAllInvoiceMaster`
export const GET_ALL_INVOICE = `${baseUrl}common/GetBillingDetails`
export const GET_INVOICE_BY_ID = `${baseUrl}Invoice/GetInvoiceById`
export const SEND_INVOICE = `${baseUrl}Invoice/GetInvoiceById`

// Discount

export const GET_ALL_DISCOUNT = `${baseUrl}DiscountMaster/GetAllDiscountMaster`
export const GET_DISCOUNT_BY_ID = `${baseUrl}DiscountMaster/GetDiscountMaster`
export const ADD_EDIT_DISCOUNT = `${baseUrl}DiscountMaster/AddorUpdateDiscountMaster`
export const GET_ALL_FEATURE_LIST = `${baseUrl}Common/GetAllPlansFeatures`
export const DELETE_DISCOUNT = `${baseUrl}DiscountMaster/DeleteDiscount`
export const GET_ALL_SEGMENT = `${baseUrl}common/GetAllSegment`
export const VERIFY_COUPON = `${baseUrl}common/VerfiyDiscountCoupon`

// Card

export const ADD_CARD = `${baseUrl}CardMaster/AddEditCard`
export const DELETE_CARD = `${baseUrl}CardMaster/DeleteCard`
export const DEFAULT_CARD = `${baseUrl}common/SetDefaultCard`
export const GET_ALL_CARD = `${baseUrl}CardMaster/GetAllCard`

// Sales Man
export const All_SAELS_MAN_LIST = `${baseUrl}SalesMan/GetAllSalesMan`
export const DEACTIVE_SAELS_MAN = `${baseUrl}UserMaster/ChangeCustomerStatus`
export const SAELS_MAN_DASHBOARD = `${baseUrl}SalesMan/GetSalesManAllRecord`
export const ADD_ASSOCIATED_SALESMAN = `${baseUrl}SalesMan/AddEditSalesMan`

// IndustryMaster
export const GET_INDUSTRY = `${baseUrl}IndustryMaster/GetAllIndustry`
export const ADD_EDIT_INDUSTRY = `${baseUrl}IndustryMaster/AddORUpdateIndustry`
export const DELETE_INDUSTRY = `${baseUrl}IndustryMaster/DeleteIndustry`


// advertisentScreen
export const GetAllAdvertisementScreen = `${baseUrl}NewScreen/GetAllAdvertisementScreen`

export const GET_PENDING_SCREEN = `${baseUrl}NewScreen/GetAllPendingScreen`
export const UPDATE_PENDING_SCREEN = `${baseUrl}NewScreen/UpdateAddStatus`
export const CANCEL_PENDING_SCREEN = `${baseUrl}NewScreen/CancelAddStatus`
export const GET_ALLUSER_SCREEN = `${baseUrl}AdsCustomer/GetAllUserAdScreen`
export const GET_ADVERTISER_SCREEN = `${baseUrl}AdsCustomer/GetAllUserAdvertiser`
export const UPDATE_ASSET_SCREEN = `${baseUrl}NewScreen/UpdateAssetByScreenID`
export const GET_PERPOSE_SCREEN = `${baseUrl}AdsCustomer/GetAllPurposeMaster`
export const ADD_PERPOSE_SCREEN = `${baseUrl}AdsCustomer/AddORUpdatePurposeMaster`
export const DELETE_PERPOSE_SCREEN = `${baseUrl}AdsCustomer/DeletePurposeMaster`
export const UPDATE_ADVERTISER_SCREEN = `${baseUrl}NewScreen/UpdateAssetForAdScreen`
export const CHECK_EMAIL_BOOKSLOT = `${baseUrl}AdsCustomer/CheckEmailExistsForBookSlot`
export const GET_BOOKSLOT_CUSTOMER = `${baseUrl}AdsCustomer/GetAdvBookSlotCustomer`
export const GETBYID_BOOKSLOT_CUSTOMER = `${baseUrl}AdsCustomer/GetAdvBookSlotCustomerByID`


// bookslot
export const GET_BOOKING_SLOT = `${baseUrl}AdsCustomer/GetAllBookingSlotCustomer`
export const GET_BOOKSLOT_REPORT = `${baseUrl}AdsCustomer/GetBookedSlotReport`
export const GET_BOOKSLOT_INVOICE = `${baseUrl}AdsCustomer/GetBookedSlotInvoice`


// Apps 
export const ADD_DATE_APPS = `${baseUrl}DateApp/AddUpdateDateApp`
export const DELETE_DATE_APPS = `${baseUrl}DateApp/DeleteDateApp`
export const ALL_DELETE_DATE = `${baseUrl}DateApp/DeleteAllDateApp`
export const ADD_DATE_TAGS = `${baseUrl}DateApp/AddDateAppTags`
export const ASSIGN_DATE_SCREEN = `${baseUrl}DateApp/DateApptoScreen`

// google Traffic
export const ADD_GOOGLE_TRAFFIC_APPS = `${baseUrl}GoogleTrafficApp/AddUpdate`
export const ADD_GOOGLE_TRAFFIC_TAGS = `${baseUrl}GoogleTrafficApp/AddTags`
export const ASSIGN_GOOGLE_TRAFFIC_SCREEN = `${baseUrl}GoogleTrafficApp/AddtoScreen`
export const DELETE_GOOGLE_TRAFFIC = `${baseUrl}GoogleTrafficApp/Delete`
export const ALL_DELETE_GOOGLE_TRAFFIC = `${baseUrl}GoogleTrafficApp/DeleteAll`

//FollowUsOnFacebookApp
export const ADD_FOLLOW_US_ON_FACEBOOK_APPS = `${baseUrl}FollowUsOnFacebookApp/AddUpdate`
export const ADD_FOLLOW_US_ON_FACEBOOK_TAGS = `${baseUrl}FollowUsOnFacebookApp/AddTags`
export const ASSIGN_FOLLOW_US_ON_FACEBOOK_SCREEN = `${baseUrl}FollowUsOnFacebookApp/AddtoScreen`
export const DELETE_FOLLOW_US_ON_FACEBOOK = `${baseUrl}FollowUsOnFacebookApp/Delete`
export const ALL_DELETE_FOLLOW_US_ON_FACEBOOK = `${baseUrl}FollowUsOnFacebookApp/DeleteAll`

// Digital signsApp
export const ADD_DIGITAL_SIGNS_APPS = `${baseUrl}DigitalSignsApp/AddUpdateDigitalSignsApp`
export const ADD_DIGITAL_SIGNS_TAGS = `${baseUrl}DigitalSignsApp/AddDigitalSignsAppTags`
export const ASSIGN_DIGITAL_SIGNS_SCREEN = `${baseUrl}DigitalSignsApp/DigitalSignsApptoScreen`
export const DELETE_DIGITAL_SIGNS = `${baseUrl}DigitalSignsApp/DeleteDigitalSignsApp`
export const ALL_DELETE_DIGITAL_SIGNS = `${baseUrl}DigitalSignsApp/DeleteAllDigitalSignsApp`

// Page ScrollApp
export const ADD_PAGE_SCROLL_APPS = `${baseUrl}PageScrollApp/AddUpdate`
export const ADD_PAGE_SCROLL_TAGS = `${baseUrl}PageScrollApp/AddTags`
export const ASSIGN_PAGE_SCROLL_SCREEN = `${baseUrl}PageScrollApp/AddtoScreen`
export const DELETE_PAGE_SCROLL = `${baseUrl}PageScrollApp/Delete`
export const ALL_DELETE_PAGE_SCROLL = `${baseUrl}PageScrollApp/DeleteAll`

// Live NewsApp
export const ADD_LIVE_NEWS_APPS = `${baseUrl}LiveNewsApp/AddUpdate`
export const ADD_LIVE_NEWS_TAGS = `${baseUrl}LiveNewsApp/AddTags`
export const ASSIGN_LIVE_NEWS_SCREEN = `${baseUrl}LiveNewsApp/AddtoScreen`
export const DELETE_LIVE_NEWS = `${baseUrl}LiveNewsApp/Delete`
export const ALL_DELETE_LIVE_NEWS = `${baseUrl}LiveNewsApp/DeleteAll`

// Sports Live Scores App
export const ADD_SPORTS_LIVE_SCORES_APPS = `${baseUrl}SportsLiveScoresApp/AddUpdate`
export const ADD_SPORTS_LIVE_SCORES_TAGS = `${baseUrl}SportsLiveScoresApp/AddTags`
export const ASSIGN_SPORTS_LIVE_SCORES_SCREEN = `${baseUrl}SportsLiveScoresApp/AddtoScreen`
export const DELETE_SPORTS_LIVE_SCORES = `${baseUrl}SportsLiveScoresApp/Delete`
export const ALL_DELETE_SPORTS_LIVE_SCORES = `${baseUrl}SportsLiveScoresApp/DeleteAll`

//FollowUs App
export const ADD_FOLLOW_US_APPS = `${baseUrl}FollowUsApp/AddUpdate`
export const ADD_FOLLOW_US_TAGS = `${baseUrl}FollowUsApp/AddTags`
export const ASSIGN_FOLLOW_US_SCREEN = `${baseUrl}FollowUsApp/AddtoScreen`
export const DELETE_FOLLOW_US = `${baseUrl}FollowUsApp/Delete`
export const ALL_DELETE_FOLLOW_US = `${baseUrl}FollowUsApp/DeleteAll`

//Soccer Scores App
export const ADD_SOCCER_SCORES_APPS = `${baseUrl}SoccerScoresApp/AddUpdate`
export const ADD_SOCCER_SCORES_TAGS = `${baseUrl}SoccerScoresApp/AddTags`
export const ASSIGN_SOCCER_SCORES_SCREEN = `${baseUrl}SoccerScoresApp/AddtoScreen`
export const DELETE_SOCCER_SCORES = `${baseUrl}SoccerScoresApp/Delete`
export const ALL_DELETE_SOCCER_SCORES = `${baseUrl}SoccerScoresApp/DeleteAll`

//Wallsio App
export const ADD_WALLSIO_APPS = `${baseUrl}WallsioApp/AddUpdate`
export const ADD_WALLSIO_TAGS = `${baseUrl}WallsioApp/AddTags`
export const ASSIGN_WALLSIO_SCREEN = `${baseUrl}WallsioApp/AddtoScreen`
export const DELETE_WALLSIO = `${baseUrl}WallsioApp/Delete`
export const ALL_DELETE_WALLSIO = `${baseUrl}WallsioApp/DeleteAll`

//Streama App
export const ADD_STREAMA_APPS = `${baseUrl}StreamaApp/AddUpdate`
export const ADD_STREAMA_TAGS = `${baseUrl}StreamaApp/AddTags`
export const ASSIGN_STREAMA_SCREEN = `${baseUrl}StreamaApp/AddtoScreen`
export const DELETE_STREAMA = `${baseUrl}StreamaApp/Delete`
export const ALL_DELETE_STREAMA = `${baseUrl}StreamaApp/DeleteAll`

//Poster My Wall App
export const ADD_POSTER_MY_WALL_APPS = `${baseUrl}PosterMyWallApp/AddUpdate`
export const ADD_POSTER_MY_WALL_TAGS = `${baseUrl}PosterMyWallApp/AddTags`
export const ASSIGN_POSTER_MY_WALL_SCREEN = `${baseUrl}PosterMyWallApp/AddtoScreen`
export const DELETE_POSTER_MY_WALL = `${baseUrl}PosterMyWallApp/Delete`
export const ALL_DELETE_POSTER_MY_WALL = `${baseUrl}PosterMyWallApp/DeleteAll`

//Bonusly App
export const ADD_BONUSLY_APPS = `${baseUrl}BonuslyApp/AddUpdate`
export const ADD_BONUSLY_TAGS = `${baseUrl}BonuslyApp/AddTags`
export const ASSIGN_BONUSLY_SCREEN = `${baseUrl}BonuslyApp/AddtoScreen`
export const DELETE_BONUSLY = `${baseUrl}BonuslyApp/Delete`
export const ALL_DELETE_BONUSLY = `${baseUrl}BonuslyApp/DeleteAll`

//Canva App
export const ADD_CANVA_APPS = `${baseUrl}CanvaApp/AddUpdate`
export const ADD_CANVA_TAGS = `${baseUrl}CanvaApp/AddTags`
export const ASSIGN_CANVA_SCREEN = `${baseUrl}CanvaApp/AddtoScreen`
export const DELETE_CANVA = `${baseUrl}CanvaApp/Delete`
export const ALL_DELETE_CANVA = `${baseUrl}CanvaApp/DeleteAll`

//GeckoBoard App
export const ADD_GECKO_BOARD_APPS = `${baseUrl}GeckoBoardApp/AddUpdate`
export const ADD_GECKO_BOARD_TAGS = `${baseUrl}GeckoBoardApp/AddTags`
export const ASSIGN_GECKO_BOARD_SCREEN = `${baseUrl}GeckoBoardApp/AddtoScreen`
export const DELETE_GECKO_BOARD = `${baseUrl}GeckoBoardApp/Delete`
export const ALL_DELETE_GECKO_BOARD = `${baseUrl}GeckoBoardApp/DeleteAll`

//Yelp App
export const ADD_YELP_APPS = `${baseUrl}YelpApp/AddUpdateYelpApp`
export const ADD_YELP_TAGS = `${baseUrl}YelpApp/AddYelpAppTags`
export const ASSIGN_YELP_SCREEN = `${baseUrl}YelpApp/YelpApptoScreen`
export const DELETE_YELP = `${baseUrl}YelpApp/DeleteYelpApp`
export const ALL_DELETE_YELP = `${baseUrl}YelpApp/DeleteAllYelpApp`

//NDTVNewsApp
export const ADD_NDTV_NEWS_APPS = `${baseUrl}NDTVNewsApp/AddUpdateNDTVNewsApp`
export const ADD_NDTV_NEWS_TAGS = `${baseUrl}NDTVNewsApp/AddNDTVNewsAppTags`
export const ASSIGN_NDTV_NEWS_SCREEN = `${baseUrl}NDTVNewsApp/NDTVNewsApptoScreen`
export const DELETE_NDTV_NEWS = `${baseUrl}NDTVNewsApp/DeleteNDTVNewsApp`
export const ALL_DELETE_NDTV_NEWS = `${baseUrl}NDTVNewsApp/DeleteAllNDTVNewsApp`

//HuffpostApp
export const ADD_HUFFPOST_APPS = `${baseUrl}HuffpostApp/AddUpdateHuffpostApp`
export const ADD_HUFFPOST_TAGS = `${baseUrl}HuffpostApp/AddHuffpostAppTags`
export const ASSIGN_HUFFPOST_SCREEN = `${baseUrl}HuffpostApp/HuffpostApptoScreen`
export const DELETE_HUFFPOST = `${baseUrl}HuffpostApp/DeleteHuffpostApp`
export const ALL_DELETE_HUFFPOST = `${baseUrl}HuffpostApp/DeleteAllHuffpostApp`

//FoxNewsApp
export const ADD_FOX_NEWS_APPS = `${baseUrl}FoxNewsApp/AddUpdateFoxNewsApp`
export const ADD_FOX_NEWS_TAGS = `${baseUrl}FoxNewsApp/AddFoxNewsAppTags`
export const ASSIGN_FOX_NEWS_SCREEN = `${baseUrl}FoxNewsApp/FoxNewsApptoScreen`
export const DELETE_FOX_NEWS = `${baseUrl}FoxNewsApp/DeleteFoxNewsApp`
export const ALL_DELETE_FOX_NEWS = `${baseUrl}FoxNewsApp/DeleteAllFoxNewsApp`

//NYTNewsApp
export const ADD_NYT_NEWS_APPS = `${baseUrl}NYTNewsApp/AddUpdateNYTNewsApp`
export const ADD_NYT_NEWS_TAGS = `${baseUrl}NYTNewsApp/AddNYTNewsAppTags`
export const ASSIGN_NYT_NEWS_SCREEN = `${baseUrl}NYTNewsApp/NYTNewsApptoScreen`
export const DELETE_NYT_NEWS = `${baseUrl}NYTNewsApp/DeleteNYTNewsApp`
export const ALL_DELETE_NYT_NEWS = `${baseUrl}NYTNewsApp/DeleteAllNYTNewsApp`

//TOINewsApp
export const ADD_TOI_NEWS_APPS = `${baseUrl}TOINewsApp/AddUpdateTOINewsApp`
export const ADD_TOI_NEWS_TAGS = `${baseUrl}TOINewsApp/AddTOINewsAppTags`
export const ASSIGN_TOI_NEWS_SCREEN = `${baseUrl}TOINewsApp/TOINewsApptoScreen`
export const DELETE_TOI_NEWS = `${baseUrl}TOINewsApp/DeleteTOINewsApp`
export const ALL_DELETE_TOI_NEWS = `${baseUrl}TOINewsApp/DeleteAllTOINewsApp`

//ESPNNewsApp
export const ADD_ESPN_NEWS_APPS = `${baseUrl}ESPNNewsApp/AddUpdateESPNNewsApp`
export const ADD_ESPN_NEWS_TAGS = `${baseUrl}ESPNNewsApp/AddESPNNewsAppTags`
export const ASSIGN_ESPN_NEWS_SCREEN = `${baseUrl}ESPNNewsApp/ESPNNewsApptoScreen`
export const DELETE_ESPN_NEWS = `${baseUrl}ESPNNewsApp/DeleteESPNNewsApp`
export const ALL_DELETE_ESPN_NEWS = `${baseUrl}ESPNNewsApp/DeleteAllESPNNewsApp`

//BBCNewsApp
export const ADD_BBC_NEWS_APPS = `${baseUrl}BBCNewsApp/AddUpdateBBCNewsApp`
export const ADD_BBC_NEWS_TAGS = `${baseUrl}BBCNewsApp/AddBBCNewsAppTags`
export const ASSIGN_BBC_NEWS_SCREEN = `${baseUrl}BBCNewsApp/BBCNewsApptoScreen`
export const DELETE_BBC_NEWS = `${baseUrl}BBCNewsApp/DeleteBBCNewsApp`
export const ALL_DELETE_BBC_NEWS = `${baseUrl}BBCNewsApp/DeleteAllBBCNewsApp`

//RegionalNewsApp
export const ADD_REGIONAL_NEWS_APPS = `${baseUrl}RegionalNewsApp/AddUpdateRegionalNewsApp`
export const ADD_REGIONAL_NEWS_TAGS = `${baseUrl}RegionalNewsApp/AddRegionalNewsAppTags`
export const ASSIGN_REGIONAL_NEWS_SCREEN = `${baseUrl}RegionalNewsApp/RegionalNewsApptoScreen`
export const DELETE_REGIONAL_NEWS = `${baseUrl}RegionalNewsApp/DeleteRegionalNewsApp`
export const ALL_DELETE_REGIONAL_NEWS = `${baseUrl}RegionalNewsApp/DeleteAllRegionalNewsApp`

//WallStreetJournalApp
export const ADD_WALL_STREET_NEWS_APPS = `${baseUrl}WallStreetJournalApp/AddUpdateWallStreetJournalApp`
export const ADD_WALL_STREET_NEWS_TAGS = `${baseUrl}WallStreetJournalApp/AddWallStreetJournalAppTags`
export const ASSIGN_WALL_STREET_NEWS_SCREEN = `${baseUrl}WallStreetJournalApp/WallStreetJournalApptoScreen`
export const DELETE_WALL_STREET_NEWS = `${baseUrl}WallStreetJournalApp/DeleteWallStreetJournalApp`
export const ALL_DELETE_WALL_STREET_NEWS = `${baseUrl}WallStreetJournalApp/DeleteAllWallStreetJournalApp`

//CalenderForGoogleApp
export const ADD_CALENDER_FOR_GOOGLE_APPS = `${baseUrl}CalenderForGoogleApp/AddUpdateCalenderForGoogleApp`
export const ADD_CALENDER_FOR_GOOGLE_TAGS = `${baseUrl}CalenderForGoogleApp/AddCalenderForGoogleAppTags`
export const ASSIGN_CALENDER_FOR_GOOGLE_SCREEN = `${baseUrl}CalenderForGoogleApp/CalenderForGoogleApptoScreen`
export const DELETE_CALENDER_FOR_GOOGLE = `${baseUrl}CalenderForGoogleApp/DeleteCalenderForGoogleApp`
export const ALL_DELETE_CALENDER_FOR_GOOGLE = `${baseUrl}CalenderForGoogleApp/DeleteAllCalenderForGoogleApp`

//TaggboxDisplayApp
export const ADD_TAGG_BOX_DISPLAY_APPS = `${baseUrl}TaggboxDisplayApp/AddUpdate`
export const ADD_TAGG_BOX_DISPLAY_TAGS = `${baseUrl}TaggboxDisplayApp/AddTags`
export const ASSIGN_TAGG_BOX_DISPLAY_SCREEN = `${baseUrl}TaggboxDisplayApp/AddtoScreen`
export const DELETE_TAGG_BOX_DISPLAY = `${baseUrl}TaggboxDisplayApp/Delete`
export const ALL_DELETE_TAGG_BOX_DISPLAY = `${baseUrl}TaggboxDisplayApp/DeleteAll`

//GoogleAlertsApp
export const ADD_GOOGLE_ALERTS_APPS = `${baseUrl}GoogleAlertsApp/AddUpdate`
export const ADD_GOOGLE_ALERTS_TAGS = `${baseUrl}GoogleAlertsApp/AddTags`
export const ASSIGN_GOOGLE_ALERTS_SCREEN = `${baseUrl}GoogleAlertsApp/AddtoScreen`
export const DELETE_GOOGLE_ALERTS = `${baseUrl}GoogleAlertsApp/Delete`
export const ALL_DELETE_GOOGLE_ALERTS = `${baseUrl}GoogleAlertsApp/DeleteAll`

//GoogleSheets App
export const ADD_GOOGLE_SHEETS_APPS = `${baseUrl}GoogleSheetsApp/AddUpdate`
export const ADD_GOOGLE_SHEETS_TAGS = `${baseUrl}GoogleSheetsApp/AddTags`
export const ASSIGN_GOOGLE_SHEETS_SCREEN = `${baseUrl}GoogleSheetsApp/AddtoScreen`
export const DELETE_GOOGLE_SHEETS = `${baseUrl}GoogleSheetsApp/Delete`
export const ALL_DELETE_GOOGLE_SHEETS = `${baseUrl}GoogleSheetsApp/DeleteAll`

//OnThisDay App
export const ADD_ON_THIS_DAY_APPS = `${baseUrl}OnThisDayApp/AddUpdate`
export const ADD_ON_THIS_DAY_TAGS = `${baseUrl}OnThisDayApp/AddTags`
export const ASSIGN_ON_THIS_DAY_SCREEN = `${baseUrl}OnThisDayApp/AddtoScreen`
export const DELETE_ON_THIS_DAY = `${baseUrl}OnThisDayApp/Delete`
export const ALL_DELETE_ON_THIS_DAY = `${baseUrl}OnThisDayApp/DeleteAll`

//SportsNewsApp
export const ADD_SPORTS_NEWS_APPS = `${baseUrl}SportsNewsApp/AddUpdateSportsNewsApp`
export const ADD_SPORTS_NEWS_TAGS = `${baseUrl}SportsNewsApp/AddSportsNewsAppTags`
export const ASSIGN_SPORTS_NEWS_SCREEN = `${baseUrl}SportsNewsApp/SportsNewsApptoScreen`
export const DELETE_SPORTS_NEWS = `${baseUrl}SportsNewsApp/DeleteSportsNewsApp`
export const ALL_DELETE_SPORTS_NEWS = `${baseUrl}SportsNewsApp/DeleteAllSportsNewsApp`

//BusinessAndFinanceNewsApp
export const ADD_BUSINESS_AND_FINANCE_NEWS_APPS = `${baseUrl}BusinessAndFinanceNewsApp/AddUpdateBusinessAndFinanceNewsApp`
export const ADD_BUSINESS_AND_FINANCE_NEWS_TAGS = `${baseUrl}BusinessAndFinanceNewsApp/AddBusinessAndFinanceNewsAppTags`
export const ASSIGN_BUSINESS_AND_FINANCE_NEWS_SCREEN = `${baseUrl}BusinessAndFinanceNewsApp/BusinessAndFinanceNewsApptoScreen`
export const DELETE_BUSINESS_AND_FINANCE_NEWS = `${baseUrl}BusinessAndFinanceNewsApp/DeleteBusinessAndFinanceNewsApp`
export const ALL_DELETE_BUSINESS_AND_FINANCE_NEWS = `${baseUrl}BusinessAndFinanceNewsApp/DeleteAllBusinessAndFinanceNewsApp`

//EntertainmentNewsApp
export const ADD_ENTERTAINMENT_NEWS_APPS = `${baseUrl}EntertainmentNewsApp/AddUpdateEntertainmentNewsApp`
export const ADD_ENTERTAINMENT_NEWS_TAGS = `${baseUrl}EntertainmentNewsApp/AddEntertainmentNewsAppTags`
export const ASSIGN_ENTERTAINMENT_NEWS_SCREEN = `${baseUrl}EntertainmentNewsApp/EntertainmentNewsApptoScreen`
export const DELETE_ENTERTAINMENT_NEWS = `${baseUrl}EntertainmentNewsApp/DeleteEntertainmentNewsApp`
export const ALL_DELETE_ENTERTAINMENT_NEWS = `${baseUrl}EntertainmentNewsApp/DeleteAllEntertainmentNewsApp`

//HeadlineNewsApp
export const ADD_HEADLINE_NEWS_APPS = `${baseUrl}HeadlineNewsApp/AddUpdateHeadlineNewsApp`
export const ADD_HEADLINE_NEWS_TAGS = `${baseUrl}HeadlineNewsApp/AddHeadlineNewsAppTags`
export const ASSIGN_HEADLINE_NEWS_SCREEN = `${baseUrl}HeadlineNewsApp/HeadlineNewsApptoScreen`
export const DELETE_HEADLINE_NEWS = `${baseUrl}HeadlineNewsApp/DeleteHeadlineNewsApp`
export const ALL_DELETE_HEADLINE_NEWS = `${baseUrl}HeadlineNewsApp/DeleteAllHeadlineNewsApp`

//InspirationalQuotes App
export const ADD_INSPIRATIONAL_QUOTES_APPS = `${baseUrl}InspirationalQuotesApp/AddUpdate`
export const ADD_INSPIRATIONAL_QUOTES_TAGS = `${baseUrl}InspirationalQuotesApp/AddTags`
export const ASSIGN_INSPIRATIONAL_QUOTES_SCREEN = `${baseUrl}InspirationalQuotesApp/AddtoScreen`
export const DELETE_INSPIRATIONAL_QUOTES = `${baseUrl}InspirationalQuotesApp/Delete`
export const ALL_DELETE_INSPIRATIONAL_QUOTES = `${baseUrl}InspirationalQuotesApp/DeleteAll`

export const ADD_CURRENICE_APPS = `${baseUrl}CurrenciesApp/AddUpdateCurrenciesApp`
export const DELETE_CURRENICE_APPS = `${baseUrl}CurrenciesApp/DeleteCurrenciesApp`
export const ALL_DELETE_CURRENICE = `${baseUrl}CurrenciesApp/DeleteAllCurrenciesApp`
export const ADD_CURRENICE_TAGS = `${baseUrl}CurrenciesApp/AddCurrenciesAppTags`
export const ASSIGN_CURRENICE_SCREEN = `${baseUrl}CurrenciesApp/CurrenciesApptoScreen`

export const ADD_CLOCK_APPS = `${baseUrl}ClockApp/AddUpdateClockApp`
export const DELETE_CLOCK_APPS = `${baseUrl}ClockApp/DeleteClockApp`
export const ALL_CLOCK_DATE = `${baseUrl}ClockApp/DeleteAllClockApp`
export const ADD_CLOCK_TAGS = `${baseUrl}ClockApp/AddClockAppTags`
export const ASSIGN_CLOCK_SCREEN = `${baseUrl}ClockApp/ClockApptoScreen`

export const ADD_COUNTUPTIME_APPS = `${baseUrl}CountUpTimerApp/AddUpdateCountUpTimerApp`
// export const DELETE_CLOCK_APPS = `${baseUrl}ClockApp/DeleteClockApp`
// export const ALL_CLOCK_DATE = `${baseUrl}ClockApp/DeleteAllClockApp`
// export const ADD_CLOCK_TAGS = `${baseUrl}ClockApp/AddClockAppTags`
// export const ASSIGN_CLOCK_SCREEN = `${baseUrl}ClockApp/ClockApptoScreen`


export const GET_BANK_DETAILS = `${baseUrl}UserMaster/GetAllBankDetails`

export const NOTICEBOARD_ALLDELETE = `${baseUrl}NoticeBoardApp/DeleteAllNoticeBoardApp`;
export const NOTICEBOARD_ADD_TAG = `${baseUrl}NoticeBoardApp/AddNoticeBoardAppTags`;
export const NOTICEBOARD_SETSCREEN = `${baseUrl}NoticeBoardApp/NoticeBoardApptoScreen`;
export const NOTICEBOARD_ADD = `${baseUrl}NoticeBoardApp/AddUpdateNoticeBoardApp`;

// Word_Clock Api

export const WORLD_CLOCK_ADD = `${baseUrl}WorldClockApp/AddUpdateWorldClock`;
export const WORLD_CLOCK_GET = `${baseUrl}WorldClockApp/GetAllWorldClock`;
export const WORLD_CLOCK_DELETE = `${baseUrl}WorldClockApp/DeletWorldClock`;
export const WORLD_CLOCK_ALLDELETE = `${baseUrl}WorldClockApp/DeletAllWorldClock`;
export const WORLD_CLOCK_ADD_TAGS = `${baseUrl}WorldClockApp/AddWorldClockTags`;
export const WORLD_CLOCK_GET_BYID = `${baseUrl}WorldClockApp/GetWorldClockById?`;
export const WORLD_CLOCK_SETSCREEN = `${baseUrl}WorldClockApp/AddWorldClockToScreen`;

export const DELETE_BUILDING_DIRECTORY_APPS = `${baseUrl}BuildingDirectoryApp/DeleteBuildingDirectoryApp`
export const ALL_DELETE_BUILDING_DIRECTORY = `${baseUrl}BuildingDirectoryApp/DeleteAllBuildingDirectoryApp`
export const ADD_BUILDING_DIRECTORY_TAGS = `${baseUrl}BuildingDirectoryApp/AddBuildingDirectoryAppTags`
export const ASSIGN_BUILDING_DIRECTORY_SCREEN = `${baseUrl}BuildingDirectoryApp/BuildingDirectoryApptoScreen`
export const ADD_BUILDING_DIRECTORY_APPS = `${baseUrl}BuildingDirectoryApp/AddUpdateBuildingDirectoryApp`


export const DELETE_GOOGLE_REVIEW_APPS = `${baseUrl}GoogleReviewsApp/Delete`
export const ALL_DELETE_GOOGLE_REVIEW = `${baseUrl}GoogleReviewsApp/DeleteAll`
export const ADD_GOOGLE_REVIEW_TAGS = `${baseUrl}GoogleReviewsApp/AddTags`
export const ASSIGN_GOOGLE_REVIEW_SCREEN = `${baseUrl}GoogleReviewsApp/AddtoScreen`
export const ADD_GOOGLE_REVIEW_APPS = `${baseUrl}GoogleReviewsApp/AddUpdate`

export const DELETE_AQI_APPS = `${baseUrl}AQIApp/DeleteAQIApp`
export const ALL_DELETE_AQI = `${baseUrl}AQIApp/DeleteAllAQIApp`
export const ADD_AQI_TAGS = `${baseUrl}AQIApp/AddAQIAppTags`
export const ASSIGN_AQI_SCREEN = `${baseUrl}AQIApp/AssignAQIAppToScreen`
export const ADD_AQI_APPS = `${baseUrl}AQIApp/AddUpdateAQIApp`


export const DELETE_STOCK_EXCHANGE_APPS = `${baseUrl}StockExchangeApp/DeleteStockExchangeApp`
export const ALL_DELETE_STOCK_EXCHANGE = `${baseUrl}StockExchangeApp/DeleteAllStockExchangeApp`
export const ADD_STOCK_EXCHANGE_TAGS = `${baseUrl}StockExchangeApp/StockExchangeAppAddTags`
export const ASSIGN_STOCK_EXCHANGE_SCREEN = `${baseUrl}StockExchangeApp/StockExchangeAppAddToScreen`
export const ADD_STOCK_EXCHANGE_APPS = `${baseUrl}StockExchangeApp/AddUpdateStockExchangeApp`

export const DELETE_GOOGLE_TRENDS_APPS = `${baseUrl}GoogleTrendsApp/Delete`
export const ALL_DELETE_GOOGLE_TRENDS = `${baseUrl}GoogleTrendsApp/DeleteAll`
export const ADD_GOOGLE_TRENDS_TAGS = `${baseUrl}GoogleTrendsApp/AddTags`
export const ASSIGN_GOOGLE_TRENDS_SCREEN = `${baseUrl}GoogleTrendsApp/AddtoScreen`
export const ADD_GOOGLE_TRENDS_APPS = `${baseUrl}GoogleTrendsApp/AddUpdate`

export const DELETE_FLIGHT_SCHEDULE_APPS = `${baseUrl}FlightScheduleApp/Delete`
export const ALL_DELETE_FLIGHT_SCHEDULE = `${baseUrl}FlightScheduleApp/DeleteAll`
export const ADD_FLIGHT_SCHEDULE_TAGS = `${baseUrl}FlightScheduleApp/AddTags`
export const ASSIGN_FLIGHT_SCHEDULE_SCREEN = `${baseUrl}FlightScheduleApp/AddtoScreen`
export const ADD_FLIGHT_SCHEDULE_APPS = `${baseUrl}FlightScheduleApp/AddUpdate`

export const DELETE_MEET_THE_TEAM_APPS = `${baseUrl}MeetTheTeamApp/Delete`  // MeetTheTeamApp/Select?teamId
export const ALL_DELETE_MEET_THE_TEAM = `${baseUrl}MeetTheTeamApp/DeleteAll`
export const ADD_MEET_THE_TEAM_TAGS = `${baseUrl}MeetTheTeamApp/AddTags`   // teamId tags
export const ASSIGN_MEET_THE_TEAM_SCREEN = `${baseUrl}MeetTheTeamApp/AddtoScreen`  // teamId screenId
export const ADD_MEET_THE_TEAM_APPS = `${baseUrl}MeetTheTeamApp/AddUpdate`

// MeetTheTeamApp/SelectAll
// MeetTheTeamApp/Select?teamId

// {
//   "meetTheTeamAppID": 0,
//   "instanceName": "string",
//   "loginURL": "string",
//   "googleSheetURL": "string",
//   "displayDuration": "string",
//   "isBirthday": true,
//   "isAnniversary": true,
//   "greetingText": "string",
//   "companyLogo": "string",
//   "userID": 0,
//   "screens": "string",
//   "screenIDs": "string",
//   "maciDs": "string",
//   "tags": "string"
// }
