import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { getUrl, NOTICEBOARD_ADD, NOTICEBOARD_ADD_TAG, postUrl } from "../Pages/Api";
import axios from "axios";

const initialState = {
    NoticeBoard: [],
    noticeBoardByID: []
}

export const handleGetNoticeBoard = createAsyncThunk("NoticeBoardApp/handleGetNoticeBoard", async (payload, thunkAPI) => {
    try {
        const token = thunkAPI.getState().root.auth.token;
        const response = await getUrl(`NoticeBoardApp/GetAllNoticeBoardApp`, { headers: { Authorization: `Bearer ${token}` }, });
        return response.data;
    } catch (error) {
        toast.error('Failed to fetch data');
        console.log(error);
        throw error;
    }
});

export const deleteNoticeBoard = createAsyncThunk("NoticeBoardApp/deleteNoticeBoard", async (id, thunkAPI) => {
    try {
        const queryParams = new URLSearchParams({ noticeBoardAppId: id, }).toString();
        const token = thunkAPI.getState().root.auth.token;
        const response = await postUrl(`NoticeBoardApp/DeleteNoticeBoardApp?${queryParams}`, { headers: { Authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        console.log('error', error)
        throw error;
    }
});

export const getNoticeBoardById = createAsyncThunk("NoticeBoardApp/getNoticeBoardById", async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().root.auth.token;
        const response = await getUrl(`NoticeBoardApp/SelectNoticeBoardAppById?noticeBoardAppId=${id}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        console.log("error", error);
        toast.error("Failed to fetch data");
        throw error;
    }
});

export const handleAddNoticeBoardApps = createAsyncThunk("NoticeBoardApp/handleAddNoticeBoardApps", async (payload, thunkAPI) => {
    try {
        const token = thunkAPI.getState().root.auth.token;
        const response = await axios.post(NOTICEBOARD_ADD, payload, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
});


export const NoticeBoardSetScreen = createAsyncThunk("data/NoticeBoardSetScreen", async ({ config }) => {
    try {
        const response = await axios.request(config);
        return response.data;
    } catch (error) {
        console.log("error", error);
        throw error;
    }
}
);

const NoticeBoardSlice = createSlice({
    name: "NoticeBoard",
    initialState,
    reducers: {
        resetStatus: (state) => {
            state.error = null;
            state.message = null;
            state.status = null;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(handleGetNoticeBoard.pending, (state) => {
                state.loading = true;
            })

            .addCase(handleGetNoticeBoard.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.NoticeBoard = payload?.data;
                state.error = null;
                state.token = payload?.data?.token;
            })

            .addCase(handleGetNoticeBoard.rejected, (state, { payload }) => {
                state.loading = false;
                state.NoticeBoard = null;
                state.error = payload ?? null;
                state.token = null;
            })

            .addCase(deleteNoticeBoard.pending, (state) => {
                state.status = "loading";
            })

            .addCase(deleteNoticeBoard.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
                state.message = action.payload?.message;
            })

            .addCase(deleteNoticeBoard.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload?.message;
                state.token = null;
                state.message = "Failed to delete project";
            })

            .addCase(getNoticeBoardById.pending, (state) => {
                state.status = false;
            })
            .addCase(getNoticeBoardById.fulfilled, (state, { payload }) => {
                state.status = true;
                state.noticeBoardByID = payload?.data;
                state.token = payload?.data?.token;
            })
            .addCase(getNoticeBoardById.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })

            .addCase(handleAddNoticeBoardApps.pending, (state) => {
                state.status = "loading";
            })
            .addCase(handleAddNoticeBoardApps.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
            })
            .addCase(handleAddNoticeBoardApps.rejected, (state, action) => {
                state.status = "failed";
                toast.error = action.payload.message;
            });


    },
});


export const { resetStatus } = NoticeBoardSlice.actions;
export default NoticeBoardSlice.reducer