import React, { Suspense, useState } from 'react'
import Footer from '../Footer';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import DisployLogo from "./Assets/social-media-disploy.png"
import { SocialMediaAccounts } from '../Common/Common';

const SocialMediaAccoutList = ({ sidebarOpen, setSidebarOpen }) => {
    const { token, user, userDetails } = useSelector((state) => state.root.auth);
    const [sidebarload, setSidebarLoad] = useState(false);

    return (
        <>
            {sidebarload && <Loading />}
            {!sidebarload && (
                <Suspense fallback={<Loading />}>
                    <>
                        <div className="flex bg-white border-b border-gray">
                            <Sidebar
                                sidebarOpen={sidebarOpen}
                                setSidebarOpen={setSidebarOpen}
                            />
                            <Navbar />
                        </div>

                        <div className={userDetails?.isTrial && user?.userDetails?.isRetailer === false && !userDetails?.isActivePlan ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain" : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"}>
                            <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
                                <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg h-full">
                                    <div className="w-full lg:w-2/5 sm-w-full">
                                        <img src={DisployLogo} alt='Social Medial Logo' className='w-full' />
                                    </div>
                                    <div className="w-full lg:w-3/5 sm-w-full p-10 pl-24 flex flex-col">
                                        <div className='flex flex-col gap-2 my-6'>
                                            <h3 className='font-semibold text-xl'>Let's add some <label className='text-blue-800'>Social Accounts</label></h3>
                                            <h5 className='text-gray'>You only need to add two <lable className="font-semibold">social account</lable> right now. you can always add more later!</h5>
                                        </div>
                                        <div className='mb-8'>
                                            <h3 className='font-semibold text-xl'>Choose a social network to add an account</h3>
                                        </div>
                                        <div className="flex flex-wrap gap-5 mb-8">
                                            {SocialMediaAccounts?.map((item) => (
                                                <div
                                                    key={item?.id}
                                                    className="cursor-pointer rounded-md flex flex-row p-3.5 border-2 items-center gap-4 w-[calc(50%-0.75rem)]" // 50% width minus the gap
                                                    style={{ borderColor: item?.color }}
                                                >
                                                    <img src={item?.logo} alt={item?.name} className="w-8 h-8 rounded-sm" />
                                                    <p style={{ color: item?.color }} className='font-semibold text-lg'>{item?.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <p className='text-sm font-semibold'>
                                                0 Social account added
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
                </Suspense>
            )}
        </>
    )
}

export default SocialMediaAccoutList
