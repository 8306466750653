import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleGetUserWithTokenDetails } from '../Redux/Authslice';
import Loading from '../Components/Loading';
import toast from 'react-hot-toast';

const Sample = () => {
    const { id } = useParams();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true)

    const fetchData = (id) => {
        try {
            let UserID = atob(id);
            let TimeZone = new Date()
                .toLocaleDateString(undefined, {
                    day: "2-digit",
                    timeZoneName: "long",
                })
                .substring(4);
            dispatch(handleGetUserWithTokenDetails({ id: UserID, TimeZone })).then((response) => {
                if (response?.payload?.status === 200) {
                    localStorage.setItem("role_access", "USER");
                    toast.success("Login successfully.");
                    setLoading(false)
                    window.location.href = `/dashboard`;
                }
            }).catch((error) => {
                console.log('error', error)
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        fetchData(id)
    }, [id])

    return (
        <>
            {loading && (
                <Loading />
            )}
        </>
    )
}

export default Sample
