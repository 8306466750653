import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Sidebar from '../../Sidebar';
import Navbar from '../../Navbar';
import { useSelector } from 'react-redux';
import { MdSave } from 'react-icons/md';
import moment from 'moment';
import { GoPencil } from 'react-icons/go';
import toast from 'react-hot-toast';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { handleAddCalenderForGoogle } from '../../../Redux/AppsSlice';
import { useDispatch } from 'react-redux';

export const GoogleCalenderDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const dispatch = useDispatch()
  // const clientId = "78054fb4-56f0-494c-b68c-54a4bc664efd"; // Update with your client ID
  const [authData, setAuthData] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState([]);

  const clientId = "1008201774927-4fd8ub0h218r8qcq4brva6kg1u335bc9.apps.googleusercontent.com"; // Your Google client ID
  const redirectUri = "http://localhost:3000"; // Your redirect URI
  const scope = "https://www.googleapis.com/auth/calendar"; // Scope for Google Calendar access
  const accessType = "offline"; // Use offline for refresh tokens

  useEffect(() => {
    // Check if there's an authorization code in the URL
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      console.log('Authorization code:', code);
      // Now you can exchange this code for an access token
      // Typically, you send this code to your server to get an access token
      // For example: 
      // fetch('http://your-backend.com/exchange-code', { method: 'POST', body: { code } })
    }
  }, []);


  const handleLoginSuccess = () => {
    const googleOAuthURL = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=1008201774927-4fd8ub0h218r8qcq4brva6kg1u335bc9.apps.googleusercontent.com&redirect_uri=http://localhost:3000&scope=https://www.googleapis.com/auth/calendar&access_type=offline';

    // Set the width and height for the popup
    const width = 600;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Open Google OAuth login page in a popup window
    const popupWindow = window.open(
      googleOAuthURL,
      '_blank',
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
    );

    window.addEventListener('message', (event) => {
      if (event.origin === 'http://localhost:3000') {
        const { code } = event.data;
        if (code) {
          console.log('Authorization code received:', code);
          popupWindow.close();
        }
      }
    });

    if (popupWindow) {
      popupWindow.focus();
    }
  };


  const fetchCalendarEvents = async (accessToken) => {
    try {
      // Fetch the user's Google Calendar events
      const response = await axios.get(
        'https://www.googleapis.com/calendar/v3/calendars/primary/events',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setCalendarEvents(response.data.items);
    } catch (error) {
      console.error('Error fetching Google Calendar events:', error);
    }
  };

  const { user, token, userDetails } = useSelector((state) => state.root.auth);
  const [edited, setEdited] = useState(false);
  const currentDate = new Date();
  const [showPreview, setShowPreview] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [calendarDetails, setcalendarDetails] = useState({
    instanceName: moment(currentDate).format("YYYY-MM-DD hh:mm"),
    view: "",
    monthSpeed: "",
    numberofMonth: 0,
    numberofEvent: 0,
    language: "",
    startCalendar: "",
    googleID: "",
    eventColor: "",
    fontSize: "",
    userID: 0,
    screens: "",
    screenIDs: "",
    maciDs: "",
    tags: ""
  });

  const handleOnSaveInstanceName = (e) => {
    if (!calendarDetails?.instanceName.replace(/\s/g, "").length) {
      toast.remove();
      return toast.error("Please enter at least minimum 1 character.");
    }
    setEdited(false);
  };


  const onSumbit = async () => {
    const payload = {
      ...calendarDetails,
      calenderForGoogleAppID: 0,
      // instanceName: "string",
      // view: "string",
      // monthSpeed: "string",
      // numberofMonth: 0,
      // startCalendar: "string",
      // eventColor: "string",
      // fontSize: "string",

      numberofEvent: 0,
      language: "",
      googleID: "",
      userID: 0,
      screens: "",
      screenIDs: "",
      maciDs: "",
      tags: ""
    }
    console.log('payload :>> ', payload);
    toast.loading('Saving ...')
    setSaveLoading(true);
    return
    try {
      dispatch(handleAddCalenderForGoogle(payload)).then((res) => {
        console.log('res  :>> ', res);
        setSaveLoading(false);
      })
    } catch (error) {
      console.log('error :>> ', error);
      setSaveLoading(false);

    }
  }


  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
            user?.userDetails?.isRetailer === false &&
            !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div>
              <div className="lg:flex lg:justify-between sm:block my-4 items-center">
                <div className="flex items-center">
                  {edited ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        className="w-full border border-primary rounded-md px-2 py-1"
                        placeholder="Enter schedule name"
                        value={calendarDetails?.instanceName}
                        onChange={(e) => {
                          setcalendarDetails({ ...calendarDetails, instanceName: e.target.value })
                        }}
                      />
                      <MdSave
                        onClick={() => handleOnSaveInstanceName()}
                        className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                        {calendarDetails?.instanceName}
                      </h1>
                      <button onClick={() => setEdited(true)}>
                        <GoPencil className="ml-4 text-lg" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => {
                      // if (CountUp?.Title === "") {
                      //   return toast.error("Please Enter Title");
                      // }
                      setShowPreview(!showPreview);
                    }}
                  >
                    {showPreview ? "Edit" : "Preview"}
                  </button>
                  <button
                    className="flex align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                    onClick={() => onSumbit()}
                    disabled={saveLoading}                  >
                    {saveLoading ? "Saving..." : "Save"}
                  </button>

                  <Link to="/Calender-For-Google">
                    <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                      <AiOutlineClose />
                    </button>
                  </Link>
                </div>
              </div>

              {!showPreview && (
                <div className="flex flex-col lg:flex-row bg-white shadow-lg rounded-lg h-full">
                  <div className="w-full lg:w-2/5 pr-0 lg:pr-4 mb-4 lg:mb-0 p-5">
                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        View
                      </label>
                      <select
                        value={calendarDetails?.view}
                        name="Style"
                        onChange={(e) => {
                          setcalendarDetails({ ...calendarDetails, view: e.target.value })
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="Month">Month</option>
                        <option value="Week">Week</option>
                        <option value="Day">Day</option>
                        <option value="Upcoming">Upcoming</option>
                      </select>
                    </div>
                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Speed the months will change
                      </label>
                      <select
                        value={calendarDetails?.monthSpeed}
                        name="Style"
                        onChange={(e) => {
                          setcalendarDetails({ ...calendarDetails, monthSpeed: e.target.value })
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="Fast">Fast</option>
                        <option value="Medium">Medium</option>
                        <option value="Slow">Slow</option>
                      </select>
                    </div>
                    <div className="mb-4 flex items-center justify-between placeHolderColor">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Number of months
                      </label>
                      <input
                        type="number"
                        id="first_name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="Days without accidents"
                        value={calendarDetails?.numberofMonth}
                        name="Title"
                        onChange={(e) => {
                          setcalendarDetails({ ...calendarDetails, numberofMonth: e.target.value })
                        }}
                      />
                    </div>

                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Start my Calendar on (English only)*
                      </label>

                      <select
                        onChange={(e) => {
                          setcalendarDetails({ ...calendarDetails, startCalendar: e.target.value })
                        }}
                        value={calendarDetails?.startCalendar}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="Sunday">Sunday</option>
                        <option value="Monday">Monday</option>
                      </select>
                    </div>
                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Login! *
                      </label>
                      <div className="text-right">
                        <button
                          onClick={handleLoginSuccess}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-3/2  p-2.5"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Events color scheme*
                      </label>
                      <select
                        value={calendarDetails?.eventColor}
                        name="Style"
                        onChange={(e) => {
                          setcalendarDetails({ ...calendarDetails, eventColor: e.target.value })
                        }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="Google Default">Google Default</option>
                        <option value="Blue">Blue</option>
                        <option value="Green">Green</option>
                        <option value="Yellow">Yellow</option>
                        <option value="Red">Red</option>
                        <option value="White">White</option>
                        <option value="Purple">Purple</option>
                      </select>
                    </div>
                    <div className="mb-4 flex items-center justify-between">
                      <label
                        htmlFor="countries"
                        className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Font Size*
                      </label>
                      <select
                        name="Style"
                        value={calendarDetails?.fontSize}
                        onChange={(e) => {
                          setcalendarDetails({ ...calendarDetails, fontSize: e.target.value })
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="Regular">Regular</option>
                        <option value="Large">Large</option>
                      </select>
                    </div>
                  </div>
                  <div className="border-r-0 lg:border-r-2 border-gray-300 h-auto mx-4 hidden lg:block"></div>

                  <div className="w-full lg:w-3/5 h-[35rem] flex items-center justify-center mt-4 lg:mt-0">
                    <div className="w-[85%] h-[25rem] bg-gray-50 rounded-sm shadow-md border-[6px] border-black flex items-center justify-center">


                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
