import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { GoPencil } from "react-icons/go";
import { MdSave } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {getWallStreetJournalById, handleAddWallStreetJournal } from "../../../Redux/AppsSlice";
import { NewsTheme, NewsTopic } from "../../Common/Common";
import Navbar from "../../Navbar";
import Sidebar from "../../Sidebar";
import { socket } from "../../../App";
export const WallStreetNewsDetails = ({ sidebarOpen, setSidebarOpen }) => {
  const { user, userDetails, token } = useSelector((state) => state.root.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [edited, setEdited] = useState(false);
  const [instanceName, setInstanceName] = useState(
    moment().format("YYYY-MM-DD hh:mm A")
  );
  const [wallStreetNews, setWallStreetNews] = useState({
    theme: "new",
    durationInSec: "10",
    newsName: "",
    newsTopic: "biblical",
  });
  const [showPreview, setShowPreview] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [maciDs, setMaciDs] = useState('');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setWallStreetNews((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleOnSaveInstanceName = () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    setEdited(false);
  };

  const onSubmit = async () => {
    if (!instanceName.replace(/\s/g, "").length) {
      return toast.error("Instance Name is Required.");
    }
    let Payload = {
      wallStreetJournalAppID: id ? id : 0,
      instanceName: instanceName,
      name: wallStreetNews?.newsName,
      topic: wallStreetNews?.newsTopic,
      theme: wallStreetNews?.theme,
      durationInSec: wallStreetNews?.durationInSec,
    };
    toast.loading("Saving ...");
    setSaveLoading(true);
    try {
      await dispatch(handleAddWallStreetJournal(Payload)).then((res) => {
        toast.remove();
        if(maciDs){
          const Params = {
            id: socket.id,
            connection: socket.connected,
            macId: maciDs,
          };
          socket.emit("ScreenConnected", Params);
        }
        setSaveLoading(false);
        navigate("/Wall-Street-Journal");
      });
    } catch (error) {
      console.log("error :>> ", error);
      setSaveLoading(false);
    }
  };
  useEffect(() => {
    if(id){
      dispatch(getWallStreetJournalById(id))
        .then((response) => {
          if (response?.payload?.status === 200) {
            const data = response?.payload?.data;
            setWallStreetNews((pre) => ({
              newsName: data?.name,
              newsTopic: data?.topic,
              theme: data?.theme,
              durationInSec: data?.durationInSec,
            }));
            setMaciDs(data?.maciDs)
            setInstanceName(data?.instanceName);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [id, dispatch]);

  return (
    <>
      <div className="flex border-b border-gray">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Navbar />
      </div>
      <div
        className={
          userDetails?.isTrial &&
          user?.userDetails?.isRetailer === false &&
          !userDetails?.isActivePlan
            ? "lg:pt-32 md:pt-32 sm:pt-20 xs:pt-20 px-5 page-contain"
            : "lg:pt-24 md:pt-24 pt-10 px-5 page-contain"
        }
      >
        <div className={`${sidebarOpen ? "ml-60" : "ml-0"}`}>
          <div className="px-6 page-contain">
            <div className="lg:flex lg:justify-between sm:block my-4 items-center">
              <div className="flex items-center">
                {edited ? (
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      className="w-full border border-primary rounded-md px-2 py-1"
                      placeholder="Enter Instance name"
                      value={instanceName}
                      onChange={(e) => {
                        setInstanceName(e.target.value);
                      }}
                    />
                    <MdSave
                      onClick={() => handleOnSaveInstanceName()}
                      className="min-w-[1.5rem] min-h-[1.5rem] cursor-pointer"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <h1 className="not-italic font-medium lg:text-2xl md:text-2xl sm:text-xl text-[#001737] lg:mb-0 md:mb-0 sm:mb-4 ">
                      {instanceName}
                    </h1>
                    <button onClick={() => setEdited(true)}>
                      <GoPencil className="ml-4 text-lg" />
                    </button>
                  </div>
                )}
              </div>
              <div className="flex md:mt-5 lg:mt-0 sm:flex-wrap md:flex-nowrap xs:flex-wrap youtubebtnpopup">
                <button
                  className="flex align-middle border-white bg-SlateBlue text-white  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 sm:mt-2  text-base sm:text-sm mr-2 hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                  onClick={() => {
                    if (
                      wallStreetNews?.durationInSec &&
                      wallStreetNews.newsName &&
                      wallStreetNews.newsTopic &&
                      wallStreetNews.theme
                    )
                      return setShowPreview(!showPreview);
                    toast.error("Please Enter All Value");
                  }}
                >
                  {showPreview ? "Edit" : "Preview"}
                </button>
                <button
                  className="flex  align-middle border-white bg-SlateBlue text-white sm:mt-2  items-center border rounded-full lg:px-6 sm:px-5 py-2.5 .  text-base sm:text-sm  hover:bg-primary hover:text-white hover:bg-primary-500 hover:shadow-lg hover:shadow-primary-500/50"
                  onClick={() => {
                    if (
                      wallStreetNews?.durationInSec &&
                      wallStreetNews.newsName &&
                      wallStreetNews.newsTopic &&
                      wallStreetNews.theme
                    )
                      return onSubmit();
                    toast.error("Please Enter All Value");
                  }}
                  disabled={saveLoading}
                >
                  {saveLoading ? "Saving..." : "Save"}
                </button>
                <Link to="/Wall-Street-Journal">
                  <button className="sm:ml-2 xs:ml-1 sm:mt-2 border-primary items-center border-2  rounded-full text-xl  hover:text-white hover:bg-SlateBlue hover:border-white hover:shadow-lg hover:shadow-primary-500/50 p-2 ">
                    <AiOutlineClose />
                  </button>
                </Link>
              </div>
            </div>

            {showPreview ? (
              <div className="w-full h-[35rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center mt-5">
                <Swiper
                  modules={[Pagination, Autoplay]}
                  centeredSlides={true}
                  slidesPerView={1}
                  autoplay={{
                    delay: wallStreetNews?.durationInSec * 1000,
                    disableOnInteraction: false,
                  }}
                >
                  {NewsTopic?.[wallStreetNews.newsTopic]?.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="w-full h-full flex items-center justify-center bg-cover bg-center text-white relative"
                        style={{
                          backgroundImage: NewsTheme[wallStreetNews.theme],
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center p-5 text-center">
                          <div>
                            <h2 className="text-3xl font-bold">{slide.news}</h2>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            ) : (
              <div className="flex flex-col lg:flex-row rounded-lg h-full gap-8">
                <div className="w-full lg:w-2/4 pr-0 lg:pr-4 rounded-xl mb-4 lg:mb-0 p-5 bg-white shadow-lg">
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      News Name *
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      name="newsName"
                      placeholder="News Name"
                      value={wallStreetNews?.newsName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="countries"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Quote Duration (seconds) *
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="number"
                      name="durationInSec"
                      placeholder="Duration in seconds (10)"
                      value={wallStreetNews?.durationInSec}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="message"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Topic*
                    </label>
                    <select
                      id="countries"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      defaultValue={""}
                      name="newsTopic"
                      value={wallStreetNews?.newsTopic}
                      onChange={handleChange}
                    >
                      <option value="education">Education</option>
                      <option value="biblical">Biblical</option>
                      <option value="spiritual">Spiritual</option>
                    </select>
                  </div>
                  <div className="mb-3 relative flex flex-row justify-between items-center w-full">
                    <label
                      htmlFor="message"
                      className="w-2/5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Theme*
                    </label>
                    <select
                      id="countries"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      defaultValue={""}
                      name="theme"
                      value={wallStreetNews?.theme}
                      onChange={handleChange}
                    >
                      <option value="classic">Classic</option>
                      <option value="new">New</option>
                      <option value="light">Light</option>
                    </select>
                  </div>
                </div>
                <div className="w-full lg:w-2/4 h-[full] flex items-center rounded-xl justify-center lg:mt-0 bg-white shadow-lg p-5">
                  <div className="w-full h-[30rem] bg-gray-50 rounded-sm shadow-md border-4 border-black bg-sky-100 flex items-center justify-center overscroll-none">
                    {wallStreetNews?.durationInSec &&
                    wallStreetNews.newsName &&
                    wallStreetNews.newsTopic &&
                    wallStreetNews.theme ? (
                      loading ? (
                        ""
                      ) : (
                        <div className="w-full h-full flex items-center justify-center bg-cover bg-center text-white relative overflow-hidden">
                          <Swiper
                            modules={[Pagination, Autoplay]}
                            centeredSlides={true}
                            slidesPerView={1}
                            autoplay={{
                              delay: wallStreetNews?.durationInSec * 1000,
                              disableOnInteraction: false,
                            }}
                          >
                            {NewsTopic?.[wallStreetNews.newsTopic]?.map(
                              (slide, index) => (
                                <SwiperSlide key={index}>
                                  <div
                                    className="w-full h-full flex items-center justify-center bg-cover bg-center text-white relative"
                                    style={{
                                      backgroundImage:
                                        NewsTheme[wallStreetNews.theme],
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                    }}
                                  >
                                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center p-5 text-center">
                                      <div>
                                        <h2 className="text-3xl font-bold">
                                          {slide.news}
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              )
                            )}
                          </Swiper>
                        </div>
                      )
                    ) : (
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-16 w-16 mx-auto text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M3 7V3h18v4M4 10h16v4H4v-4z"
                          />
                        </svg>
                        <p className="text-gray-500">App instance preview</p>
                        <p className="text-sm text-gray-400">
                          Please edit the app settings to get started
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
